import { parseError, parseResponse } from 'utils/ResponseHelper'

import base from './base'

export const list = () => {
  return base
    .get('/v1/causes')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const listAuth = () => {
  return base
    .get('/v1/causes/get')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const get = (id: number) => {
  return base.get(`/v1/causes/${id}`)
}

export const create = (data: any) => {
  return base.post('/v1/causes', data)
}

export const update = (id: number, data: any) => {
  return base.put(`/v1/causes/${id}`, data)
}

export const destroy = (id: number, dryRun: boolean) => {
  return base.delete(`/v1/causes/${id}`, { params: { dry_run: dryRun } })
}

export const exportFile = (charset: string) => {
  return base.get(`/v1/export/causes?charset=${charset}`, {
    responseType: 'blob',
  })
}

export const importFile = (charset: string, file: any) => {
  const formData = new FormData()
  formData.append('charset', charset)
  formData.append('file', file)

  return base.post('/v1/causes/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const checkUsed = (id: number) => {
  return base.get(`/v1/causes/${id}/is_used`)
}
