import { messageFn } from 'aa_common/front-end/antd'
import { API_STATUS_CODE } from 'constants/apiCode'
import i18n from 'i18n'
import { get } from 'lodash'

const removeFalsy = (arr: string[]) => arr.filter(Boolean)

const getFieldName = (error: any, isDividingEvent: boolean): string => {
  const pointer = get(error, 'source.pointer', '') as string
  const parts = pointer.split('/')

  switch (parts.length) {
    case 2:
    case 3:
      if (parts[1] === 'acquisition_cost' && isDividingEvent) {
        return 'acquisition_cost_input'
      }

      if (parts[1] !== 'acquisition_cost' && isDividingEvent) {
        return removeFalsy(parts).join('_')
      }

      return parts[1]

    case 4:
      return `${parts[1]}[${parts[2]}].${parts[3]}`

    default:
      return ''
  }
}

export const parseValidationError = (
  errors: any,
  options: { isDividingEvent: boolean } = { isDividingEvent: false }
): any => {
  const result: any = {}
  const { isDividingEvent } = options
  let field = ''

  if (Array.isArray(errors)) {
    errors.forEach(error => {
      field = getFieldName(error, isDividingEvent)
      if (field) {
        result[field] = error.detail
      }
    })
  }

  return result
}

export const calculatePaginationTotalPages = (totalRecords: number, pageSize: number) =>
  Math.ceil(totalRecords / pageSize) || 0

export const parseImportErrors = (response: any): any => {
  const { meta, errors } = response || {}

  const result: any = { message: '', details: [] }
  result.message = meta?.message
  errors?.forEach((error: any) => {
    result.details.push(...error.detail.split('\n'))
  })

  return result
}

export const getFileNameFromHeader = (header: any): string => {
  const contentDisposition = header['content-disposition']
  const fileName = contentDisposition.split('=')[1]

  return fileName
}

export const parseResponse = (res: any) => {
  return {
    data: res?.data,
  }
}

export const parseError = (err: any) => {
  let errors = []
  switch (err?.response?.status) {
    case API_STATUS_CODE.FORBIDDEN:
      errors = [
        { detail: err?.response?.data?.meta?.message || i18n.t('common.messages.no_permissions_error_occured') },
      ]
      break
    default:
      errors = err?.response?.data?.errors || [
        { detail: err?.response?.statusText || i18n.t('common.messages.unexpected_error_occured') },
      ]
      break
  }
  return {
    errors,
  }
}

export const parseErrorResponse = (e: any, callbackFnc?: (params: any) => void) => {
  const responseError = get(e, 'response')
  let errBiids: any = []
  if (responseError?.status === API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
    const error = parseValidationError(get(responseError, 'data.errors'))

    callbackFnc && callbackFnc(error)
  } else {
    const errors = parseError(e)?.errors
    errors.length > 0 &&
      errors.forEach((err: any) => {
        messageFn().error(err?.detail)
        if (err?.biids) {
          errBiids = [...errBiids, ...err.biids]
        }
      })
  }
  return errBiids
}
