import { BasicTable } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import useModal from 'hooks/useModalHook'
import React, { useCallback, useMemo } from 'react'

import { convertDataToDisplay, getDepreciationFixedAssetColumns } from './helper'
import HistoryList from './HistoryList'
import { ListWrapper } from './styles'

interface Props {
  data: any[]
  taxYear: number
}

const t = loadNs(['common', 'pages/accounting/depreciation-fixed-asset-page'])
const DepreciationFixedAssetList = ({ data, taxYear }: Props) => {
  const modal = useModal()

  const openHistoryModal = useCallback(
    (id: number, name: string) => {
      const title = t('columns.output_history')
      const cancelText = t('actions.close')
      modal.confirm({
        title,
        cancelText,
        closable: true,
        message: <HistoryList key={id} id={id} name={name} taxYear={taxYear} />,
      })
    },
    [modal, taxYear]
  )

  const memoizedAreas = useMemo(
    () => convertDataToDisplay(data, taxYear, openHistoryModal),
    [data, openHistoryModal, taxYear]
  )

  return (
    <ListWrapper>
      <BasicTable
        data={memoizedAreas}
        columns={getDepreciationFixedAssetColumns()}
        columnsWidth={[150]}
        style={{
          margin: '15px 0',
        }}
      />
    </ListWrapper>
  )
}

export default DepreciationFixedAssetList
