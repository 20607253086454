import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'
import { Table as AntdTable } from 'antd'
import { LINK_COLOR } from 'constants/styles'

export const Link = styled(RouterLink)`
  color: ${LINK_COLOR};
  cursor: pointer;
  display: block;
  padding-left: 30px;

  &.disabled {
    color: black;
    cursor: default;
  }
`

export const Table = styled(AntdTable)`
  margin-top: 15px;

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 10px;
  }

  .ant-table {
    border: 1px solid #d4d8dd;
    border-radius: 4px;

    .ant-table-thead > tr > th {
      white-space: pre-line;
      text-align: center;
    }
  }

  .ant-table-container {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .ant-table-header {
      border-radius: 4px;
      table {
        border-radius: 4px 4px 0 0;
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
      }
    }

    .ant-table-thead > tr > th:first-of-type {
      border-top-left-radius: 4px;
    }

    .ant-table-thead > tr > th:last-child {
      border-top-right-radius: 4px;
    }

    .anticon {
      color: #7c8291;
      margin-right: 5px;
    }
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-tbody > tr > td {
    font-size: 13px;
    text-align: right;
  }

  .ant-table-tbody > tr > td:first-of-type {
    text-align: left;
  }
`
