import { ICON_DEFAULT_COLOR } from 'constants/styles'
import React from 'react'

type Props = {
  width?: number
  height?: number
  color?: string
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}

const TagIcon: React.FC<Props> = ({
  width = 15,
  height = 15,
  color = ICON_DEFAULT_COLOR,
  className,
  style,
  onClick,
}) => (
  <svg
    className={className}
    style={{ color, width, height, ...style }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M14.5575 7.185L7.8075 0.435C7.5375 0.165 7.1625 0 6.75 0H1.5C0.675 0 0 0.675 0 1.5V6.75C0 7.1625 0.165 7.5375 0.4425 7.815L7.1925 14.565C7.4625 14.835 7.8375 15 8.25 15C8.6625 15 9.0375 14.835 9.3075 14.5575L14.5575 9.3075C14.835 9.0375 15 8.6625 15 8.25C15 7.8375 14.8275 7.455 14.5575 7.185ZM2.625 3.75C2.0025 3.75 1.5 3.2475 1.5 2.625C1.5 2.0025 2.0025 1.5 2.625 1.5C3.2475 1.5 3.75 2.0025 3.75 2.625C3.75 3.2475 3.2475 3.75 2.625 3.75Z"
    />
  </svg>
)

export default TagIcon
