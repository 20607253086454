import { loadNs } from 'aa_common/front-end/i18n-config'
import { Dropdown, Menu } from 'antd'
import classNames from 'classnames'
import { CaretIcon } from 'components/atoms'
import { ASSET_CODE_SETTING_RULE_TYPE_CODE } from 'constants/masterData'
import { isEqual } from 'lodash'
import { INPUT_TYPES, SettingRule } from 'models/assetCode'
import { AssetCodeSettingRule } from 'models/masterData'
import React, { useEffect, useMemo, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'

import { displayValueTextOfRule } from './helper'
import { MenuWrapper, overlayDropdownClass, popupMenuClassName, RulesSelectionWrapper } from './styles'

const t = loadNs(['components/organisms/settings/asset-code-auto-generation-form-modal'])

const { SubMenu } = Menu

type Props = {
  value: any
  rules: SettingRule[]
  invalid: boolean
  onChange: (val: any) => void
}

function calcRules(masterAssetCodeGenerateRules: AssetCodeSettingRule[], rules: SettingRule[], value: any) {
  const { SYMBOL, FIXED_VALUE } = ASSET_CODE_SETTING_RULE_TYPE_CODE
  return masterAssetCodeGenerateRules
    .filter(({ code }) => {
      return !rules.find(ruleItem => ruleItem?.field === code) || code === SYMBOL || code === value?.field
    })
    .map(rule => {
      if (rule.code !== FIXED_VALUE) {
        return rule
      }
      // Add data for FixedValue rule
      return {
        ...rule,
        values: [
          {
            code: 0,
            name_jp: t('free_input'),
          },
        ],
      }
    }) as AssetCodeSettingRule[]
}

const InnerRulesSelection: React.FC<Props> = ({ value, rules, invalid, onChange }) => {
  const { auto_generating_serial_number_setting_rules: masterAssetCodeGenerateRules = [] } =
    useSelector(selectMasterData, isEqual)?.asset_code_settings || {}

  const assetCodeGenerateRules = useMemo(
    () => calcRules(masterAssetCodeGenerateRules, rules, value),
    [masterAssetCodeGenerateRules, rules, value]
  )

  useEffect(() => {
    if (value && value.type !== 0 && !value.type) {
      const value_text = displayValueTextOfRule(assetCodeGenerateRules, value.field, value.value)
      const type =
        value?.field === ASSET_CODE_SETTING_RULE_TYPE_CODE.FIXED_VALUE ? INPUT_TYPES.INPUT : INPUT_TYPES.SELECT
      onChange({ ...value, type, value_text })
    }
  }, [value, assetCodeGenerateRules, onChange])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newFieldValue: string | null = e.currentTarget.value.trim()
    if (value?.field === ASSET_CODE_SETTING_RULE_TYPE_CODE.FIXED_VALUE && newFieldValue === '') {
      newFieldValue = null
    }

    onChange({ ...value, value: newFieldValue })
  }

  const handleInputBlur = () => {
    onChange(value)
  }

  const rulesMenu = useMemo(() => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (assetCodeGenerateRules.length === 0) return <></>

    const handleSelectItem = (fieldCode: number, selectedItem: any) => {
      const newValue = {
        ...value,
        field: fieldCode,
        value: selectedItem.code,
        value_text: displayValueTextOfRule(assetCodeGenerateRules, fieldCode, selectedItem.code),
      } as SettingRule

      if (fieldCode === ASSET_CODE_SETTING_RULE_TYPE_CODE.FIXED_VALUE) {
        newValue.value = null
        newValue.type = INPUT_TYPES.INPUT
      } else {
        newValue.type = INPUT_TYPES.SELECT
      }
      onChange(newValue)
    }

    return (
      <MenuWrapper>
        {assetCodeGenerateRules.map(item => (
          <SubMenu
            className="dropdown-item"
            key={item.code}
            title={item.name_jp}
            popupClassName={popupMenuClassName}
            popupOffset={[-3, -9]}
          >
            {item.values &&
              item.values.map((subItem: any) => (
                <Menu.Item
                  className="dropdown-item"
                  key={item.code}
                  onClick={() => handleSelectItem(item.code, subItem)}
                >
                  <span>{subItem.name_jp}</span>
                </Menu.Item>
              ))}
          </SubMenu>
        ))}
      </MenuWrapper>
    )
  }, [assetCodeGenerateRules, value, onChange])

  const refContainer = useRef(null)
  return (
    <RulesSelectionWrapper ref={refContainer} className={classNames('select-input', { error: invalid })}>
      {value?.type === INPUT_TYPES.INPUT ? (
        <>
          <input
            placeholder={t('please_enter')}
            value={value?.value || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
          <Dropdown
            placement="bottomRight"
            overlay={rulesMenu}
            trigger={['click']}
            overlayClassName={overlayDropdownClass}
            getPopupContainer={() => refContainer.current as any}
          >
            <div className="icon">
              <CaretIcon />
            </div>
          </Dropdown>
        </>
      ) : (
        <Dropdown
          placement="bottomRight"
          overlay={rulesMenu}
          trigger={['click']}
          overlayClassName={overlayDropdownClass}
          getPopupContainer={() => refContainer.current as any}
        >
          <div style={{ display: 'flex' }}>
            <input placeholder={t('please_select')} readOnly value={value?.value_text || ''} />
            <div className="icon">
              <CaretIcon />
            </div>
          </div>
        </Dropdown>
      )}
    </RulesSelectionWrapper>
  )
}

type RulesSelectionProps = {
  name: string
  rules: SettingRule[]
  defaultValue: any
}

const RulesSelection = (props: RulesSelectionProps) => {
  return (
    <Controller
      name={props.name}
      defaultValue={props.defaultValue}
      render={({ onChange, value }, { invalid }) => (
        <InnerRulesSelection {...props} value={value} invalid={invalid} onChange={onChange} />
      )}
    />
  )
}

export default RulesSelection
