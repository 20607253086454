import { Spin } from 'aa_common/front-end/antd'
import { formatMonth } from 'aa_common/front-end/helpers'
import { BreadcrumbNavigation, ImportExportButton } from 'components/molecules'
import JournalListTable from 'components/organisms/accounting/JournalList'
import MessageNoJournals from 'components/organisms/accounting/JournalList/MessageNoJournals'
import JournalLinkageExportModal from 'components/templates/modal/ExportModal'
import TabPageTemplate from 'components/templates/TabPageTemplate'
import i18n from 'i18n'
import isEqual from 'lodash/isEqual'
import { convertJournalResponse } from 'pages/accounting/JournalListPage/helper'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { exportJournalsLinkage, fetchJournalLinkageDetail } from 'store/accounting/journal/actions'
import { selectJournalLinkage } from 'store/accounting/journal/selectors'
import { fetchExciseList, fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList, selectExciseList } from 'store/settings/excise/selectors'

import { genErrorMessages } from './helper'
import { PageWrapper } from './styles'

const JournalLinkageHistoryDetailPage = () => {
  const dispatch = useDispatch()
  const { id: linkageHistoryId } = useParams<{ id: string }>()

  const [activeTab, setActiveTab] = useState<string>('acquisition')
  const [isShowExportModal, setIsShowExportModal] = useState(false)
  const journalLinkage = useSelector(selectJournalLinkage, isEqual)
  const exciseSettingByDate = useSelector(getExciseSettingsByDateList, isEqual)
  const exciseList = useSelector(selectExciseList, isEqual)

  useEffect(() => {
    dispatch(
      fetchExciseList({
        filters: {
          is_active: true,
        },
      })
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchJournalLinkageDetail(linkageHistoryId))
  }, [dispatch, linkageHistoryId])

  useEffect(() => {
    const startDate = journalLinkage.termMonth?.from_term_month?.start_date
    startDate && dispatch(fetchExciseSettingsByDate(startDate))
  }, [dispatch, journalLinkage.termMonth])

  const [breadcrumb, journalSummitErrs] = useMemo(() => {
    const { termMonth, exportNumber } = journalLinkage || {}
    const sectionName = termMonth
      ? i18n.t('pages.accounting.JournalList.title_with_export_number', {
          year: termMonth.from_term_month?.term?.year,
          month: formatMonth(termMonth.from_term_month.month),
          exportNumber,
        })
      : ''

    return [
      // eslint-disable-next-line react/jsx-key
      <BreadcrumbNavigation enableBackButton sectionName={sectionName} key="breakcrumb-navigation" />,
      genErrorMessages(journalLinkage),
    ]
  }, [journalLinkage])

  const [groupedJournals, journalsCount] = useMemo(
    () => convertJournalResponse(journalLinkage.data, exciseList),
    [exciseList, journalLinkage.data]
  )

  const tabs = useMemo(() => {
    return Object.entries(groupedJournals).map(([key, journals]) => {
      const children =
        journals.length === 0 ? (
          <MessageNoJournals />
        ) : (
          <PageWrapper>
            <JournalListTable journals={journals} exciseSettingByDate={exciseSettingByDate} />
          </PageWrapper>
        )
      const num = journalsCount[key as keyof typeof groupedJournals]
      return {
        key,
        children,
        label: i18n.t(`pages.accounting.JournalList.${key}`, { num }),
      }
    })
  }, [groupedJournals, journalsCount, exciseSettingByDate])

  const tabSettings = {
    activeKey: activeTab,
    onTabChange: setActiveTab,
    tabs,
  }

  return (
    <Spin loading={journalLinkage.loading}>
      <TabPageTemplate
        tabsSettings={tabSettings}
        breadcrumb={breadcrumb}
        topChildren={journalSummitErrs}
        actions={
          <div>
            <ImportExportButton
              key="export"
              type="export"
              style={{ marginRight: 10 }}
              onClick={() => setIsShowExportModal(true)}
            />
          </div>
        }
      />
      <JournalLinkageExportModal
        journalCount={journalLinkage?.data?.length ?? 0}
        isShow={isShowExportModal}
        selectJournalFormat={false}
        setIsShowModal={setIsShowExportModal}
        onExportClick={({ charset }) => dispatch(exportJournalsLinkage(linkageHistoryId, charset))}
      />
    </Spin>
  )
}

export default JournalLinkageHistoryDetailPage
