import { PlusOutlined } from '@ant-design/icons'
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spin } from 'aa_common/front-end/antd'
import { BasicModal, ConfirmModal } from 'aa_common/front-end/components'
import { validateFileSize } from 'aa_common/front-end/helpers'
import { message, Tooltip as TooltipAnt, Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { IMAGE_UPLOAD_PATH, MAX_IMAGE_UPLOAD_SIZE } from 'constants/app'
import { CSRF_TOKEN_KEY } from 'constants/auth'
import { getIn, useFormikContext } from 'formik'
import i18n from 'i18n'
import { List } from 'immutable'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import uniqueId from 'lodash/uniqueId'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateUploadPhotoStatus } from 'store/asset/actions'

import { EditIcon, Image, RemoveIcon, Thumbnail, Wrapper } from './styles'

type Props = {
  name: string
  mode?: 'view' | 'edit'
}

const renderUploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>{i18n.t('components.UploadImageListField.upload')}</div>
  </div>
)

const UploadImageListField: React.FC<Props> = ({ name, mode = 'edit' }) => {
  const dispatch = useDispatch()
  const { values, setFieldValue, errors } = useFormikContext<any>()

  const [imageList, setImageList] = useState(getIn(values, name) || [])
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [previewVisible, setPreviewVisible] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)
  const fieldValue = getIn(values, name)
  const isEditMode = mode === 'edit'

  const handleCloseModal = () => {
    const index = imageList.findIndex((image: any) => image.id === (selectedImage as any).id)
    imageList.splice(index, 1, selectedImage)
    setImageList([...imageList])
    setPreviewVisible(false)
    setFieldValue(name, imageList)
  }

  const handlePreview = (image: any) => {
    setSelectedImage(image)
    setPreviewVisible(true)
  }

  const handleBeforeUpload = (image: any) => {
    if (!validateFileSize(image, MAX_IMAGE_UPLOAD_SIZE)) {
      message.error(i18n.t('common.messages.over_file_size_limit', { name: image.name }))
      return false
    }

    return true
  }

  const handleChange = ({ file, fileList }: any) => {
    let newImageList = fileList.filter((item: any) => item.status && item.status !== 'error')
    newImageList = newImageList.map((imageItem: any) => {
      if (imageItem.response) {
        // when imageItem is uploaded
        const { id, filename, thumbnail_url, url } = get(imageItem, ['response', 'data']) || {}

        const image = {
          id,
          filename,
          thumbnail_url,
          url,
        }

        return image
      }

      return {
        // When imageItem uploading
        ...imageItem,
        id: imageItem.uid,
      }
    })

    let newUploadedImageList = uniqBy([...(imageList || []), ...newImageList], 'id')

    if (file.status === 'error') {
      message.error(i18n.t('components.UploadImageListField.upload_failure', { name: file.name }))

      newUploadedImageList = newUploadedImageList.filter(image => {
        return image.id !== file.uid && image.status !== 'error'
      })
    }

    if (file.status === 'done') {
      newUploadedImageList = newUploadedImageList.filter(image => image.id !== file.uid)
    }

    const isUploadDone = fileList.every((file: any) => file?.status === 'done' || file?.status === 'error')
    dispatch(updateUploadPhotoStatus(!isUploadDone))

    setImageList(newUploadedImageList)
    setFieldValue(name, newUploadedImageList)
    setFileList(fileList)
  }

  const handleRemove = (image: any) => {
    setSelectedImage(image)
    setIsConfirmingDelete(true)

    return false
  }

  const handleCancelDelete = (): void => {
    setSelectedImage(null)
    setIsConfirmingDelete(false)
  }

  const handleDelete = (): void => {
    const newImageList = imageList.filter((item: any) => item.id !== (selectedImage as any).id)
    const newUpdatedFileList = List(fileList)
      .filter(item => item.response?.data?.id !== (selectedImage as any).id)
      .toArray()
    setImageList(newImageList)
    setFileList(newUpdatedFileList)
    setFieldValue(name, newImageList)
    setIsConfirmingDelete(false)
  }

  // TODO: use below code when BE support memo
  // const handleOnMemoChanged = (value: any): void => {
  //   setSelectedImage({...(selectedImage as any), memo: value.target.value});
  // }

  const headers: any = {
    'X-CSRF-Token': localStorage.getItem(CSRF_TOKEN_KEY),
  }

  const renderImageList = () => {
    return (
      imageList &&
      imageList.map((image: any) => {
        const loading = get(image, 'status') === 'uploading'

        return (
          <Thumbnail key={uniqueId()}>
            <TooltipAnt title={image.memo} placement="topLeft" arrowPointAtCenter>
              {isEditMode && !loading && (
                <EditIcon className="edit-icon" onClick={() => handlePreview(image)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </EditIcon>
              )}
              {isEditMode && !loading && (
                <RemoveIcon className="remove-icon" onClick={() => handleRemove(image)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </RemoveIcon>
              )}
              <div className="image-item" onClick={() => !isEditMode && handlePreview(image)}>
                <Spin loading={loading} size="default">
                  <img alt={get(image, 'filename') ? get(image, 'filename') : ''} src={get(image, 'thumbnail_url')} />
                </Spin>
              </div>
            </TooltipAnt>
          </Thumbnail>
        )
      })
    )
  }

  useEffect(() => {
    setImageList(fieldValue)
  }, [fieldValue]) // eslint-disable-line

  return (
    <Wrapper>
      {get(errors, 'photos') && <span style={{ color: '#dc3545' }}>{i18n.t('common.messages.upload_in_process')}</span>}
      <div className="upload-wrapper">
        {renderImageList()}
        {isEditMode && (
          <Upload
            action={IMAGE_UPLOAD_PATH}
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={handleBeforeUpload}
            showUploadList={false}
            accept="image/*"
            multiple
            headers={headers}
            withCredentials
          >
            {renderUploadButton}
          </Upload>
        )}
      </div>
      <BasicModal isShow={previewVisible} title="&nbsp;" width={390} onCancel={handleCloseModal}>
        <>
          <Image>
            <img alt={get(selectedImage, 'filename') || ''} src={get(selectedImage, 'url')} />
          </Image>
          {/* TODO: use below code when BE support memo */}
          {/* <Memo>
          <label>{ i18n.t('components.UploadImageListField.memo') }</label>
            <Input value={get(selectedImage, 'memo') || ''} onChange={handleOnMemoChanged} maxLength={200} />
          </Memo> */}
        </>
      </BasicModal>
      <ConfirmModal
        message={i18n.t('common.messages.confirm_delete', { name: `${get(selectedImage, 'filename') || ''}` })}
        visible={isConfirmingDelete}
        onOK={handleDelete}
        onCancel={handleCancelDelete}
      />
    </Wrapper>
  )
}

export default UploadImageListField
