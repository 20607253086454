import { loadNs } from 'aa_common/front-end/i18n-config'
import { ColumnsType } from 'antd/lib/table'
import { get } from 'lodash'
import { BaseAccountItem } from 'models/accountItem'
import React from 'react'

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const HeaderCellTemplate = () => {
  return (
    <div className="header-template">
      <h4>{t('account_item')}</h4>
      <h4>{t('account_subitem')}</h4>
    </div>
  )
}

interface CellTemplateProps {
  data: any
  dataKey: string
}

const CellTemplate = ({ data, dataKey }: CellTemplateProps) => {
  const accountItem = get(data, `${dataKey}_item`) as BaseAccountItem
  const accountSubItem = get(data, `${dataKey}_sub_item`) as BaseAccountItem

  return (
    <div className="cell-template">
      <div style={{ minHeight: 20 }}>
        <h4>{accountItem ? `(${accountItem.code}) ${accountItem.name}` : ''}</h4>
      </div>
      <div style={{ minHeight: 20 }}>
        <h4>{accountSubItem ? `(${accountSubItem.code}) ${accountSubItem.name}` : ''}</h4>
      </div>
    </div>
  )
}

export const getColumns = (): ColumnsType<any> => {
  return [
    {
      title: t('asset_category_name'),
      dataIndex: 'name',
      key: 'name',
      width: 140,
    },
    {
      title: t('acquisition'),
      key: 'acquisition',
      className: 'parent-header',
      children: [
        {
          title: t('asset_category'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'asset_category',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.acquisition_debit_asset_category_account" />
              ),
            },
          ],
        },
        {
          title: t('payment_category'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'payment_category',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.acquisition_credit_payment_category_account" />
              ),
            },
          ],
        },
      ],
    },
    {
      title: t('depreciation_title'),
      key: 'depreciation_title',
      className: 'parent-header',
      children: [
        {
          title: t('depreciation'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'depreciation',
              width: 140,
              render: (data: any) => <CellTemplate data={data} dataKey="latestSettings.depreciation_debit_account" />,
            },
          ],
        },
        {
          title: t('accumulated'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'accumulated',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.depreciation_credit_accumulated_account" />
              ),
            },
          ],
        },
        {
          title: t('asset_category'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'asset_category',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.depreciation_credit_asset_category_account" />
              ),
            },
          ],
        },
      ],
    },
    {
      title: t('impairment_settings_table'),
      key: 'depreciation_title',
      className: 'parent-header',
      children: [
        {
          title: t('impairment_loss'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'impairment_loss',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.impairment_debit_loss_account" />
              ),
            },
          ],
        },
        {
          title: t('accumulated_impairment_loss'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'accumulated_impairment_loss',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.impairment_credit_accumulated_impairment_account" />
              ),
            },
          ],
        },
        {
          title: t('asset_category'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'asset_category',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.impairment_credit_asset_category_account" />
              ),
            },
          ],
        },
      ],
    },
    {
      title: t('retirement'),
      key: 'retirement',
      className: 'parent-header',
      children: [
        {
          title: t('accumulated'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'accumulated',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.retirement_debit_accumulated_depreciation_account" />
              ),
            },
          ],
        },
        {
          title: t('accumulated_impairment_loss'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'accumulated_impairment_loss',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.retirement_debit_accumulated_impairment_account" />
              ),
            },
          ],
        },
        {
          title: t('payment_category'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'payment_category_retirement',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.retirement_credit_payment_category_account" />
              ),
            },
          ],
        },
        {
          title: t('retirement_loss'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'retirement_loss',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.retirement_debit_loss_account" />
              ),
            },
          ],
        },
        {
          title: t('asset_category'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'asset_category',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.retirement_credit_asset_category_account" />
              ),
            },
          ],
        },
      ],
    },
    {
      title: t('sell'),
      className: 'parent-header',
      key: 'sell',
      children: [
        {
          title: t('accumulated'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'accumulated',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.sale_debit_accumulated_depreciation_account" />
              ),
            },
          ],
        },
        {
          title: t('accumulated_impairment_loss'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'accumulated_impairment_loss',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.sale_debit_accumulated_impairment_account" />
              ),
            },
          ],
        },
        {
          title: t('asset_category'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'asset_category',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.sale_credit_asset_category_account" />
              ),
            },
          ],
        },
        {
          title: t('income_subject'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'income_subject',
              width: 140,
              render: (data: any) => <CellTemplate data={data} dataKey="latestSettings.sale_debit_revenue_account" />,
            },
          ],
        },
        {
          title: t('payment_category'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'payment_category_sale',
              width: 140,
              render: (data: any) => (
                <CellTemplate data={data} dataKey="latestSettings.sale_credit_payment_category_account" />
              ),
            },
          ],
        },
        {
          title: t('sale_loss'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'sale_loss',
              width: 140,
              render: (data: any) => <CellTemplate data={data} dataKey="latestSettings.sale_debit_loss_account" />,
            },
          ],
        },
        {
          title: t('sale_gain'),
          children: [
            {
              title: <HeaderCellTemplate />,
              key: 'sale_gain',
              width: 140,
              render: (data: any) => <CellTemplate data={data} dataKey="latestSettings.sale_credit_gain_account" />,
            },
          ],
        },
      ],
    },
  ]
}
