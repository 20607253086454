import { RightDrawer } from 'aa_common/front-end/components'
import { RETIREMENT_DEPRECIATION_ENDED_METHOD } from 'aa_common/front-end/constants'
import { updateObjectValues } from 'aa_common/front-end/helpers'
import { getNs } from 'aa_common/front-end/i18n-config'
import * as assetApi from 'api/app/asset'
import * as exciseApi from 'api/app/excise'
import { BasicForm } from 'components/molecules'
import { DATE_FORMAT_RFC } from 'constants/app'
import { CAUSE_TYPES, EXCISE_TAX_INPUT_FORMAT, EXCISE_TAX_METHOD } from 'constants/masterData'
import useResource from 'lib/hooks/useResource'
import { get, isEqual } from 'lodash'
import { Asset } from 'models/asset'
import { Excise } from 'models/excise'
import { FORM_MODES } from 'models/form'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExciseList } from 'store/settings/excise/actions'
import { selectExciseList } from 'store/settings/excise/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { useGetSelectedItemOnPage } from '../AssetList/components/AssetListTopTemplate/hooks'
import { useExpandLedgersHook } from '../events/ExpandableLedger'
import Form from './Form'
import { getDepreciationEndedMethods } from './helper'

const { t, useNsReady } = getNs('components/organisms/assets/asset-retirement-form')
type Props = {
  errors?: any
  causes: any[]
  isShow?: boolean
  loading?: boolean
  data?: Asset
  latestEvent?: any
  formMode?: FORM_MODES
  onClose?: (e: any) => void
  onSubmit?: (data: any) => void
  onHandleReloadCauses?: () => void
  isBulkChangeEvent?: boolean
}

const initialDefaultValues = {
  retired_at: moment(),
  event_cause_id: null,
  disposal_cost: null,
  disposal_cost_input: 0,
  disposal_cost_excluded_excise: null,
  calculated_disposal_excise_value: null,
  manual_disposal_excise_value: null,
  disposal_destination_name: '',
  disposal_excise_id: null,
  event_photos: [],
  event_attachments: [],
  event_urls: [],
}

const AssetRetirementForm: React.FC<Props> = ({
  isShow = false,
  loading = false,
  errors,
  causes,
  data,
  latestEvent,
  formMode = FORM_MODES.NEW,
  onClose,
  onSubmit,
  onHandleReloadCauses,
  isBulkChangeEvent = false,
}) => {
  useNsReady()
  const dispatch = useDispatch()
  const biidOfSelectedItems = useGetSelectedItemOnPage().selectedIds
  const [visible, setVisible] = useState(isShow)
  const [retirementCauses, setRetirementCauses] = useState<any[]>([])
  const ledgerSettings = useSelector(selectLedgerSettingList, isEqual)
  const exciseList = useSelector<any, Excise[]>(selectExciseList)
  const [formValues, setFormValues] = useState<any>(null)
  const [previousAssetResponse, getPreviousAssetOfId] = useResource(assetApi.getPreviousOfId)
  const [exciseSettingResponse, getExciseSettingByDate] = useResource(exciseApi.getExciseSettingsByDate)

  const getDefaultRetiredAt = () => {
    const validFrom = get(latestEvent, 'valid_from')
    const today = moment().format(DATE_FORMAT_RFC)

    if (validFrom > today) {
      return validFrom
    }

    return today
  }

  const handleSubmit = (values: any) => {
    onSubmit && onSubmit({ ...values, ...(isBulkChangeEvent && { biids: biidOfSelectedItems }) })
  }

  useEffect(() => {
    const filteredCauses = causes?.filter(cause => get(cause, 'type') === CAUSE_TYPES.RETIREMENT)
    setRetirementCauses(filteredCauses)
  }, [causes])

  useEffect(() => {
    setVisible(isShow)
    if (isShow && ledgerSettings) {
      if (isBulkChangeEvent) {
        const retirementFixedAssetLedgerRequests = ledgerSettings?.map(ledger => ({
          ledger_setting_id: ledger.id,
          retirement_depreciation_ended_method:
            ledger?.retirement_depreciation_ended_method ===
            RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT
              ? RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_CURRENT_MONTH
              : ledger?.retirement_depreciation_ended_method,
        }))

        setFormValues({
          retired_at: getDefaultRetiredAt(),
          retirement_fixed_asset_ledger_requests: retirementFixedAssetLedgerRequests,
        })
      }

      if (latestEvent && formMode === FORM_MODES.NEW) {
        setFormValues({
          ...initialDefaultValues,
          biid: get(latestEvent, 'biid'),
          min_date: get(latestEvent, 'valid_from') || get(latestEvent, 'acquired_at'),
          retired_at: getDefaultRetiredAt(),
        })
      } else if (data && previousAssetResponse.data && exciseSettingResponse.data) {
        const objectValues = updateObjectValues(initialDefaultValues, data)
        const isTaxMethodExcluded = get(exciseSettingResponse.data, 'tax_method') === EXCISE_TAX_METHOD.EXCLUDED_TAX
        const isTaxInputFormatExternalTax =
          get(exciseSettingResponse.data, 'tax_input_format') === EXCISE_TAX_INPUT_FORMAT.EXTERNAL_TAX

        let disposalCostInput = data.disposal_cost
        if (isTaxMethodExcluded && isTaxInputFormatExternalTax) {
          disposalCostInput = data.disposal_cost_excluded_excise as number
        }

        setFormValues({
          ...objectValues,
          calculated_disposal_excise_value:
            objectValues.manual_disposal_excise_value ?? objectValues.calculated_disposal_excise_value,
          id: data.id,
          biid: data.biid,
          event_cause_id: get(data, ['event_cause', 'id']),
          min_date: get(previousAssetResponse.data, 'valid_from'),
          retirement_fixed_asset_ledger_requests: getDepreciationEndedMethods(ledgerSettings, data.fixed_asset_ledgers),
          disposal_cost_input: disposalCostInput || 0,
        })
      }
    }
  }, [isShow, data, ledgerSettings, latestEvent, previousAssetResponse.data, exciseSettingResponse.data]) // eslint-disable-line

  useEffect(() => {
    const filters = { is_active: true }

    !ledgerSettings && dispatch(fetchLedgerSettingsList())
    !exciseList?.length && dispatch(fetchExciseList({ filters }))
  }, []) // eslint-disable-line

  useEffect(() => {
    if (data) {
      const biid = get(data, 'biid')
      const id = get(data, 'id')

      getPreviousAssetOfId(biid, id)
      data.retired_at && getExciseSettingByDate(data.retired_at)
    }
  }, [data]) // eslint-disable-line

  const { width, className, renderer } = useExpandLedgersHook()
  return (
    <RightDrawer
      title={
        isBulkChangeEvent
          ? t('modal_title_for_bulk_change', { numberOfSelectedItems: biidOfSelectedItems.length })
          : t('retirement')
      }
      visible={visible}
      onClose={onClose}
      width={width}
      className={className}
    >
      {ledgerSettings && (
        <BasicForm
          initialValues={formValues}
          onSubmit={handleSubmit}
          errors={errors}
          loading={loading}
          preventSubmitOnEnter
        >
          <Form
            causes={retirementCauses}
            formMode={formMode}
            ledgerSettings={ledgerSettings}
            onClose={onClose}
            onHandleReloadCauses={onHandleReloadCauses}
            renderDrawerExpandToggle={renderer}
            isBulkChangeEvent={isBulkChangeEvent}
          />
        </BasicForm>
      )}
    </RightDrawer>
  )
}

export default AssetRetirementForm
