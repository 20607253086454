export const ASSET_CODE_FIELD = 'asset_code'

export const codeField = {
  text: 'code',
  maxLength: 15,
}

export const branchCodeField = {
  text: 'branch_code',
  maxLength: 3,
}

export const nameField = {
  text: 'name',
  maxLength: 50,
}
