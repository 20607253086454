import {
  CleanUpUnitAction,
  CreateUnitRequest,
  DeleteUnitRequest,
  FetchUnitRequest,
  FetchUnitsRequest,
  UpdateUnitOrderRequest,
} from './action.model'
import {
  CHANGE_UNIT_POSITION_REQUEST,
  CLEANUP_UNIT,
  CREATE_UNIT_REQUEST,
  DELETE_UNIT_REQUEST,
  FETCH_UNIT_REQUEST,
  FETCH_UNITS_FAILURE,
  FETCH_UNITS_REQUEST,
  FETCH_UNITS_REQUEST_AUTH,
  FETCH_UNITS_SUCCESS,
  UPDATE_UNIT_REQUEST,
} from './constant'
import { DeleteUnitPayload, UnitSubmitPayload, UpdateUnitOrderPayload } from './model'

export const fetchUnitsRequest = (): FetchUnitsRequest => {
  return { type: FETCH_UNITS_REQUEST }
}

export const fetchUnitsRequestAuth = () => ({
  type: FETCH_UNITS_REQUEST_AUTH,
})

export const fetchUnitsRequestSuccess = (payload: any) => ({
  type: FETCH_UNITS_SUCCESS,
  payload,
})

export const fetchUnitsRequestFailure = () => ({
  type: FETCH_UNITS_FAILURE,
})

export const createUnitRequest = (payload: UnitSubmitPayload): CreateUnitRequest => {
  return { type: CREATE_UNIT_REQUEST, payload }
}

export const fetchUnitRequest = (id: number): FetchUnitRequest => {
  return { type: FETCH_UNIT_REQUEST, id }
}

export const updateUnitRequest = (id: number, data: UnitSubmitPayload) => {
  return { type: UPDATE_UNIT_REQUEST, id, payload: data }
}

export const deleteUnitRequest = (payload: DeleteUnitPayload): DeleteUnitRequest => {
  return { type: DELETE_UNIT_REQUEST, payload }
}

export const changePositionRequest = (payload: UpdateUnitOrderPayload): UpdateUnitOrderRequest => {
  return { type: CHANGE_UNIT_POSITION_REQUEST, payload }
}

export const cleanupUnit = (): CleanUpUnitAction => {
  return { type: CLEANUP_UNIT }
}
