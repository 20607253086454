import styled from '@emotion/styled'
import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { getNs } from 'aa_common/front-end/i18n-config'
import { useShallow } from 'zustand/react/shallow'

import { reportSlice } from '../../store'
import { useInitTerm } from './use-init-term'

const { t, useNsReady } = getNs(['common', 'pages/accounting/corporate-income-tax'])
export const Filter = () => {
  useNsReady()
  const { accountingPeriod } = useInitTerm()
  const filters = reportSlice.useSlice(useShallow(state => state.filters))
  const { setFilters } = reportSlice
  const reportTypes = [
    {
      type: REPORT_TYPE.BY_ASSET_TYPE,
      text: t('by_each_asset_type'),
    },
    {
      type: REPORT_TYPE.BY_ASSET_CATEGORY,
      text: t('by_each_asset_category'),
    },
  ]

  return (
    <StyledFilter>
      <StyledLabel>{t('output_target_accounting_period')}</StyledLabel>
      <AutoCompleteSelect
        name="term"
        allowClear={false}
        valueField="id"
        textField="text"
        value={filters?.currentTermId}
        items={accountingPeriod}
        onChange={(currentTermId: number) => setFilters(prevFilters => ({ ...prevFilters, currentTermId }), true)} // trigger query
      />
      <StyledLabel>{t('output_method')}</StyledLabel>
      <AutoCompleteSelect
        name="report_type"
        allowClear={false}
        value={filters?.reportType}
        valueField="type"
        textField="text"
        items={reportTypes}
        onChange={(reportType: number) => setFilters(prevFilters => ({ ...prevFilters, reportType }))}
      />
    </StyledFilter>
  )
}

export enum REPORT_TYPE {
  BY_ASSET_TYPE = 1,
  BY_ASSET_CATEGORY = 2,
}

const StyledFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  max-width: 711px;
  font-size: 13px;

  .auto-complete-select-wrapper {
    height: 32px;

    .ant-select {
      height: 100%;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 100%;
    }

    &:first-of-type {
      width: 320px;
    }

    &:last-of-type {
      width: 200px;
    }
  }
`

const StyledLabel = styled.span`
  font-weight: 500;
  margin-right: -5px;
`
