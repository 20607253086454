import { Spin } from 'aa_common/front-end/antd'
import { BasicTable, CONFIRM_TYPES } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { NoDataImage } from 'components/atoms'
import { FEATURES_ID } from 'constants/const'
import useModal from 'hooks/useModalHook'
import useUserPermission from 'lib/hooks/useUserPermission'
import { Unit } from 'models/unit'
import React, { useCallback, useMemo } from 'react'

import { columns, convertDataToDisplay, UNIT_LIST_COLUMNS_WIDTH } from './helper'
import { EmptyImage, Wrapper } from './styles'

const t = loadNs(['common', 'components/organisms/settings/unit-list'])

export type OnRowDragged = {
  beforeItem?: Unit
  afterItem?: Unit
  currentItem: Unit
}

type Props = {
  units: Unit[]
  loading?: boolean
  onEdit: (item: Unit) => void
  onDelete: (item: Unit) => void
  onRowFinishDragging: (event: OnRowDragged) => void
}

const UnitList = ({ units = [], loading, onEdit, onDelete, onRowFinishDragging }: Props) => {
  const modal = useModal()
  const { permissions: unitPermission } = useUserPermission(FEATURES_ID.MASTER_UNITS)

  const handleClickDelete = useCallback(
    (item: any) => {
      modal
        .confirm({
          title: '',
          message: t('messages.confirm_delete', { name: item?.name }),
          type: CONFIRM_TYPES.DELETE,
        })
        .then(isOKClick => {
          if (isOKClick) {
            onDelete && onDelete(item)
          }
        })
    },
    [onDelete]
  ) //eslint-disable-line

  const memoizedUnits = useMemo(() => convertDataToDisplay(units), [units])

  const disableActions = useMemo(
    () => ({
      edit: () => !unitPermission.isEdit,
      delete: () => !unitPermission.isDelete,
    }),
    [unitPermission]
  )

  const showActionsTooltip = useMemo(
    () => ({
      edit: () => (!unitPermission.isEdit ? t('actions.no_permission') : undefined),
      delete: () => (!unitPermission.isDelete ? t('actions.no_permission') : undefined),
    }),
    [unitPermission]
  )

  return (
    <Wrapper>
      <Spin loading={loading}>
        {memoizedUnits && memoizedUnits.length === 0 ? (
          <EmptyImage>
            <NoDataImage text={t('msg_no_data')} />
          </EmptyImage>
        ) : (
          <BasicTable
            columns={columns()}
            columnsWidth={UNIT_LIST_COLUMNS_WIDTH}
            data={memoizedUnits}
            disableDragRow={!unitPermission.isEdit}
            draggableTooltipMessage={!unitPermission.isEdit ? t('actions.no_permission') : undefined}
            showActionsTooltip={showActionsTooltip}
            onEdit={onEdit}
            onDelete={handleClickDelete}
            onRowFinishDragging={onRowFinishDragging}
            draggable
            disableActions={disableActions}
          />
        )}
      </Spin>
    </Wrapper>
  )
}

export default React.memo(UnitList)
