import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { RenderFormProps } from 'components/molecules/Form'
import { List as ImmutableList } from 'immutable'
import React from 'react'
import useVirtual from 'react-cool-virtual'

import { Empty, ListItem } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet'])

interface MultiSelectPickerContentProps {
  name: string
  selectedItems: any[]
  formModule: RenderFormProps
  onFormSubmit: (event: any) => void
}

const MultiSelectPickerContent = ({ name, selectedItems, formModule, onFormSubmit }: MultiSelectPickerContentProps) => {
  const { handleSubmit } = formModule

  const handleFormSubmit = () => handleSubmit(onFormSubmit, onFormSubmit)()

  const handleClick = (value: any) => {
    const immutableSelectedItems = ImmutableList(selectedItems)
    const deletedIndex = immutableSelectedItems.findIndex((selectedValue: any) => selectedValue.value === value)
    if (deletedIndex >= 0) {
      const newValue = immutableSelectedItems.remove(deletedIndex).toArray()
      formModule.setValue(name, newValue)
      const submitFormValues = {
        [name]: newValue,
      }
      formModule.setValue('submitFormValues', submitFormValues)
      handleFormSubmit()
    }
  }

  const { outerRef, innerRef, items } = useVirtual<HTMLDivElement>({
    itemCount: selectedItems?.length || 0,
  })

  return selectedItems?.length ? (
    <div className="outer" style={{ maxHeight: '160px', overflow: 'auto' }} ref={outerRef}>
      <div ref={innerRef}>
        {items.map(
          ({ index, measureRef }) =>
            selectedItems[index] && (
              <ListItem key={selectedItems[index].value} ref={measureRef}>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ marginRight: 6, cursor: 'pointer' }}
                  onClick={() => handleClick(selectedItems[index].value)}
                />
                {selectedItems[index].label}
              </ListItem>
            )
        )}
      </div>
    </div>
  ) : (
    <Empty>{t('empty')}</Empty>
  )
}

export default MultiSelectPickerContent
