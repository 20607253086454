import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import TagForm from 'components/organisms/settings/TagForm'
import { MainTemplate } from 'components/templates'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { cleanupTag, createTag } from 'store/settings/tag/actions'

const t = loadNs(['pages/settings/tags'])

interface TagNewPageProps {
  errors: any
  createTag: (values: any) => void
  cleanupTag: () => void
  formLoading: boolean
}

const TagNewPage = ({ errors, formLoading = false, createTag, cleanupTag }: TagNewPageProps) => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('edit')} />

  const handleSubmit = (values: any): void => {
    createTag && createTag(values)
  }

  useEffect(() => {
    return function cleanup(): void {
      cleanupTag()
    }
  }, [cleanupTag])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <TagForm errors={errors} onSubmit={handleSubmit} type="new" loading={formLoading} />
    </MainTemplate>
  )
}

const mapStateToProps = (state: Readonly<any>) => ({
  errors: state.getIn(['settings', 'tag', 'item', 'errors']),
  formLoading: state.getIn(['settings', 'tag', 'item', 'loading']),
})

const mapDispatchToProps = {
  createTag,
  cleanupTag,
}

export default connect(mapStateToProps, mapDispatchToProps)(TagNewPage)
