import Form from 'aa_common/front-end/components/form'
import { loadNs } from 'aa_common/front-end/i18n-config'
import Input from 'components/atoms/v2/Input'
import RadioGroup from 'components/atoms/v2/RadioGroup'
import { RenderFormProps } from 'components/molecules/Form/index'
import React from 'react'

const t = loadNs(['common', 'components/organisms/settings/unit-form'])

const { Group, Header, Item, SubmitArea } = Form

const getMemorandumValueOptions = () => [
  { label: t('memorandum_value_quantity_options.interlock'), value: true },
  { label: t('memorandum_value_quantity_options.not_linked'), value: false },
]

const renderUnitForm = (form: RenderFormProps) => {
  const { loading } = form

  return (
    <>
      <Group>
        <Header>{t('form_title')}</Header>
        <Item required label={t('name')}>
          <Input
            name="name"
            maxLength={50}
            placeholder={t('placeholder.under_50')}
            disabled={loading}
            control={form.control}
          />
        </Item>
        <Item label={t('link_memorandum_value_quantity')}>
          <RadioGroup
            disabled={loading}
            name="link_memorandum_value_quantity"
            options={getMemorandumValueOptions()}
            formModule={form}
          />
        </Item>
      </Group>
      <SubmitArea formControl={form.control} loading={form.loading} requiredFields={[]} />
    </>
  )
}

export default renderUnitForm
