import { loadNs } from 'aa_common/front-end/i18n-config'
import parser from 'html-react-parser'
import React, { useEffect, useState } from 'react'

import { validateRequiredField } from './helper'
import { ErrorMessage } from './styles'

const t = loadNs(['common'])

type Props = {
  error: any
  values: any
  currentSetting?: any
}

const ErrorComponent: React.FC<Props> = ({ error, values, currentSetting }) => {
  const [errorContent, setErrorContent] = useState('')

  useEffect(() => {
    Promise.resolve().then(() => {
      const errorFields = Array.from(document.querySelectorAll('[data-js-label]')).filter(field => {
        return field.querySelector('.error')
      })
      if (errorFields.length === 1) {
        const target = errorFields[0]
        target.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' })
      } else if (errorFields.length > 1) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
      const errorFieldLabels = errorFields.map(field => field.getAttribute('data-js-label'))
      const requiredErrorFields = validateRequiredField(values, currentSetting)
      setErrorContent(requiredErrorFields || errorFieldLabels.join('<br>'))
    })
  }, [error, values, currentSetting])

  return !errorContent ? null : (
    <ErrorMessage>
      <div>{t('messages.please_validate')}</div>
      {parser(errorContent)}
    </ErrorMessage>
  )
}

export default ErrorComponent
