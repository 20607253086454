import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { Tag } from 'antd'
import { BLUE_COLOR } from 'constants/styles'

export const TagIconClass = css`
  &.enable {
    &:hover {
      color: ${BLUE_COLOR} !important;
    }
  }
`
export const TagStyled = styled(Tag)`
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #7c8291;
  color: white;
  border: none;
  border-radius: 4px;
`

export const PopupOverlayClass = css`
  .ant-popover-inner {
    max-width: 400px;
  }
`

export const ContentWrapper = styled.div`
  border-radius: 4px;
`

export const IconCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
