import { buildQueryFilterParams, convertSortValue } from 'aa_common/front-end/helpers/request-helper'
import { parseError, parseResponse } from 'utils/ResponseHelper'

import base from './base'
import { ILedgerSettingsGetSearch, ILedgerSettingsSave } from './model/ledgerSetting'

export const get: ILedgerSettingsGetSearch = payload => {
  return base
    .get('/v1/ledger_settings', {
      params: {
        ...buildQueryFilterParams(payload.filters),
        sort: convertSortValue(payload.sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const getAuth: ILedgerSettingsGetSearch = payload => {
  return base
    .get('/v1/ledger_settings/get', {
      params: {
        ...buildQueryFilterParams(payload.filters),
        sort: convertSortValue(payload.sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const save: ILedgerSettingsSave = payload => {
  return base.post('/v1/ledger_settings/save', {
    dry_run: false,
    items: payload.items,
  })
}
