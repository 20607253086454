import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import AssetCategoryForm from 'components/organisms/settings/AssetCategoryForm'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import { FORM_MODES } from 'models/form'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cleanupAssetCategory, fetchAssetCategory, updateAssetCategory } from 'store/settings/assetCategory/actions'
import { selectorAssetCategoryItem } from 'store/settings/assetCategory/selectors'

const t = loadNs(['pages/settings/asset-categories'])

const AssetCategoryEditPage = () => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('edit')} />
  const { id } = useParams<{ id: string }>()

  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    (values: any) => {
      dispatch(updateAssetCategory(id, values))
    },
    [dispatch, id]
  )

  const { data } = useSelector(selectorAssetCategoryItem, isEqual)

  useEffect(() => {
    dispatch(fetchAssetCategory(id))

    return function cleanup() {
      dispatch(cleanupAssetCategory())
    }
  }, []) // eslint-disable-line

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <AssetCategoryForm values={useMemo(() => data, [data])} onSubmit={handleSubmit} type={FORM_MODES.EDIT} />
    </MainTemplate>
  )
}

export default AssetCategoryEditPage
