import * as mcAPI from 'api/app/monthlyClosing'
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg'
import { BreadcrumbNavigation } from 'components/molecules'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import useJournalStatus from 'lib/hooks/useJournalStatus'
import React, { ComponentProps, useMemo } from 'react'
import { Link as BackButton, NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import HistoryContentWrapper from './HistoryContentWrapper'
import JournalLinkageCellRender from './JournalLinkageCellRender'
import { Wrapper, WrapperActions } from './styles'

const Link = (props: ComponentProps<typeof NavLink>) => (
  <NavLink
    className="history-tab"
    activeStyle={{
      backgroundColor: '#3B7DE9',
      color: '#FFFFFF',
    }}
    {...props}
  />
)

const JournalLinkageHistoryContent = () => {
  return (
    <HistoryContentWrapper
      dataGetter={mcAPI.getSendingJournalHistories}
      showAccPlusLink
      firstCellRenderer={JournalLinkageCellRender}
    />
  )
}

const JournalExportHistoryContent = () => {
  return <HistoryContentWrapper dataGetter={mcAPI.getExportJournalHistories} />
}

const JournalHistoryPage = () => {
  const { path } = useRouteMatch()
  const { isSendJournalPermission } = useJournalStatus()

  const breadcrumb = useMemo(() => {
    const cancelLink = (
      <BackButton to="/accounting/monthly-closing/journals" style={{ paddingRight: '15px' }}>
        <CrossIcon />
      </BackButton>
    )

    const context = isSendJournalPermission ? null : 'export'

    return (
      <BreadcrumbNavigation
        sectionName={i18n.t('pages.accounting.HistoryList.title', { context })}
        customBackElement={cancelLink}
      />
    )
  }, [isSendJournalPermission])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <Wrapper>
        {isSendJournalPermission ? (
          <>
            <WrapperActions className="history-tab-wrapper">
              <Link to={`${path}/sending`}>{i18n.t('pages.accounting.HistoryList.tabs_title.linkage')}</Link>
              <Link to={`${path}/export`}>{i18n.t('pages.accounting.HistoryList.tabs_title.export')}</Link>
            </WrapperActions>
            <Switch>
              <Route path={`${path}/sending`}>
                <JournalLinkageHistoryContent />
              </Route>
              <Route path={`${path}/export`}>
                <JournalExportHistoryContent />
              </Route>
              <Redirect to={`${path}/sending?page=1`} />
            </Switch>
          </>
        ) : (
          <JournalExportHistoryContent />
        )}
      </Wrapper>
    </MainTemplate>
  )
}

export default JournalHistoryPage
