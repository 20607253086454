import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yen } from 'aa_common/front-end/helpers/format-helper'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { RenderFormProps } from 'components/molecules/Form'
import React from 'react'

import { Empty, List, ListItem } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet'])
interface MoneyAmountContentProps {
  formModule: RenderFormProps
  formState: any
  onFormSubmit: (event: any) => void
}

const MoneyAmountContent = ({ formModule, formState, onFormSubmit }: MoneyAmountContentProps) => {
  const { acquisition_cost } = formState
  const { handleSubmit } = formModule

  const handleFormSubmit = () => handleSubmit(onFormSubmit, onFormSubmit)()

  const handleClick = (name: string) => {
    formModule.setValue(name, null)
    const submitFormValues = {
      [name]: null,
    }
    formModule.setValue('submitFormValues', submitFormValues)
    handleFormSubmit()
  }

  return !acquisition_cost ? (
    <Empty>{t('empty')}</Empty>
  ) : (
    <List>
      {acquisition_cost && (
        <ListItem>
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ marginRight: 6, cursor: 'pointer' }}
            onClick={() => handleClick('acquisition_cost')}
          />
          <label>{t('form.acquisition_cost')}</label>
          <br />
          <span>{`${yen(acquisition_cost[0])} ~ ${yen(acquisition_cost[1])}`}</span>
        </ListItem>
      )}
    </List>
  )
}

export default MoneyAmountContent
