import { LinkLabel } from 'aa_common/front-end/components'
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg'
import { BreadcrumbNavigation } from 'components/molecules'
import { DepreciationResultList } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { changeLocation } from 'store/app/actions'

const AmountSummaryPage: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isRedirectFromMonthlyClosing = location.pathname.includes('/accounting/monthly-closing')

  const cancelLink = useMemo(
    () => (
      <LinkLabel
        onClick={() => dispatch(changeLocation('/accounting/monthly-closing'))}
        style={{ paddingRight: '15px' }}
      >
        <CrossIcon />
      </LinkLabel>
    ),
    [dispatch]
  )

  const breadcrumb = useMemo(() => {
    return (
      <BreadcrumbNavigation
        sectionName={i18n.t('components.MainMenu.depreciation.d_result_report')}
        customBackElement={isRedirectFromMonthlyClosing ? cancelLink : undefined}
      />
    )
  }, [isRedirectFromMonthlyClosing, cancelLink])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <DepreciationResultList />
    </MainTemplate>
  )
}

export default AmountSummaryPage
