import { Button } from 'aa_common/front-end/antd'
import { LinkLabel } from 'aa_common/front-end/components'
import { formatMonth } from 'aa_common/front-end/helpers/format-helper'
import { Spin } from 'antd'
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg'
import { BreadcrumbNavigation, ImportExportButton } from 'components/molecules'
import { JournalLinkageSender } from 'components/organisms'
import JournalListTable from 'components/organisms/accounting/JournalList'
import MessageNoJournals from 'components/organisms/accounting/JournalList/MessageNoJournals'
import JournalExportModal from 'components/templates/modal/ExportModal'
import TabPageTemplate from 'components/templates/TabPageTemplate'
import { TabPageTemplateSettings } from 'components/templates/TabPageTemplate/model'
import { FEATURES_ID } from 'constants/const'
import useActiveTab from 'hooks/useActiveTabHook'
import i18n from 'i18n'
import useUserPermission from 'lib/hooks/useUserPermission'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { exportJournals, fetchJournals } from 'store/accounting/journal/actions'
import { selectJournalList, selectSendJournalLoading } from 'store/accounting/journal/selectors'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'
import { fetchExciseList, fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList, selectExciseList } from 'store/settings/excise/selectors'

import { convertJournalResponse } from './helper'
import { LinkItem, TabButtons } from './styles'

const JournalListPage = () => {
  const dispatch = useDispatch()
  const [isShowExportModal, setIsShowExportModal] = useState(false)
  const journalList = useSelector(selectJournalList, isEqual)
  const monthlyClosingCurrent = useSelector(selectMonthlyClosingCurrent, isEqual)
  const exciseSettingByDate = useSelector(getExciseSettingsByDateList, isEqual)
  const exciseList = useSelector(selectExciseList, isEqual)
  const sendJournalLoading = useSelector(selectSendJournalLoading, isEqual)

  const { permissions: autoJournalPermissions } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)
  const { permissions: exportJournalPermissions } = useUserPermission(FEATURES_ID.EXPORT_JOURNAL)

  useEffect(() => {
    dispatch(
      fetchExciseList({
        filters: {
          is_active: true,
        },
      })
    )
    dispatch(fetchJournals())
  }, []) // eslint-disable-line

  useEffect(() => {
    if (monthlyClosingCurrent === null) {
      dispatch(fetchMonthlyClosingCurrent())
    }
  }, [monthlyClosingCurrent, dispatch])

  useEffect(() => {
    if (monthlyClosingCurrent) {
      const date = monthlyClosingCurrent?.from_term_month?.start_date
      dispatch(fetchExciseSettingsByDate(date))
    }
  }, [dispatch, monthlyClosingCurrent])

  const [journals, journalsCount] = convertJournalResponse(journalList.data, exciseList)
  const { errors } = journalList

  const handleCancel = () => dispatch(changeLocation('/accounting/monthly-closing'))

  const cancelLink = (
    <LinkLabel onClick={handleCancel} style={{ paddingRight: '15px' }}>
      <CrossIcon />
    </LinkLabel>
  )

  const breadcrumb = useMemo(() => {
    const sectionName = i18n.t('pages.accounting.JournalList.title', {
      year: monthlyClosingCurrent?.from_term_month?.term?.year,
      month: formatMonth(monthlyClosingCurrent?.from_term_month?.month),
    })

    return <BreadcrumbNavigation sectionName={sectionName} customBackElement={cancelLink} />
  }, [monthlyClosingCurrent]) // eslint-disable-line

  const journalCount = journalList?.data?.length ?? 0

  const actions = (
    <div className={TabButtons}>
      <Button
        showTooltipMessage={!autoJournalPermissions.isRead ? i18n.t('common.actions.no_permission') : undefined}
        disabled={!autoJournalPermissions.isRead}
        key="auto-journal-setting"
        color="grey"
        onClick={() => dispatch(changeLocation('/setting/auto-journal-settings'))}
      >
        {i18n.t('pages.accounting.JournalList.auto_journal_setting')}
      </Button>
      <ImportExportButton
        key="export"
        type="export"
        showTooltipMessage={!exportJournalPermissions.isExecute ? i18n.t('common.actions.no_permission') : undefined}
        disabled={!exportJournalPermissions.isExecute}
        onClick={() => setIsShowExportModal(true)}
      />
      <JournalLinkageSender isHasJournal={journalCount > 0} />
      <Link key="link" className={LinkItem} to="/accounting/monthly-closing/journals/histories">
        {i18n.t('components.JournalList.export_history')}
      </Link>
    </div>
  )

  const { activeTab, handleOnTabChange } = useActiveTab()

  const tabs = [
    {
      children:
        monthlyClosingCurrent && journals.acquisition.length === 0 ? (
          <MessageNoJournals exciseSettingByDate={exciseSettingByDate} />
        ) : (
          <JournalListTable journals={journals.acquisition} exciseSettingByDate={exciseSettingByDate} />
        ),
      key: 'acquisition',
      label: i18n.t('pages.accounting.JournalList.acquisition', { num: journalsCount.acquisition }),
      disabled: false,
    },
    {
      children:
        monthlyClosingCurrent && journals.retirement.length === 0 ? (
          <MessageNoJournals />
        ) : (
          <JournalListTable journals={journals.retirement} exciseSettingByDate={exciseSettingByDate} />
        ),
      key: 'retirement',
      label: i18n.t('pages.accounting.JournalList.retirement', { num: journalsCount.retirement }),
      disabled: false,
    },
    {
      children:
        monthlyClosingCurrent && journals.sold.length === 0 ? (
          <MessageNoJournals />
        ) : (
          <JournalListTable journals={journals.sold} exciseSettingByDate={exciseSettingByDate} />
        ),
      key: 'sold',
      label: i18n.t('pages.accounting.JournalList.sold', { num: journalsCount.sold }),
      disabled: false,
    },
    {
      children:
        monthlyClosingCurrent && journals.depreciation.length === 0 ? (
          <MessageNoJournals />
        ) : (
          <JournalListTable journals={journals.depreciation} exciseSettingByDate={exciseSettingByDate} />
        ),
      key: 'depreciation',
      label: i18n.t('pages.accounting.JournalList.depreciation', { num: journalsCount.depreciation }),
      disabled: false,
    },
    {
      children:
        monthlyClosingCurrent && journals.impairment.length === 0 ? (
          <MessageNoJournals />
        ) : (
          <JournalListTable journals={journals.impairment} exciseSettingByDate={exciseSettingByDate} />
        ),
      key: 'impairment',
      label: i18n.t('pages.accounting.JournalList.impairment', { num: journalsCount.impairment }),
      disabled: false,
    },
  ]

  const tabSettings: TabPageTemplateSettings = {
    activeKey: activeTab,
    onTabChange: handleOnTabChange,
    tabs,
  }

  return (
    <Spin spinning={sendJournalLoading?.loading}>
      <TabPageTemplate tabsSettings={tabSettings} breadcrumb={breadcrumb} actions={actions} errors={errors} />
      <JournalExportModal
        journalCount={journalCount}
        isShow={isShowExportModal}
        setIsShowModal={setIsShowExportModal}
        onExportClick={({ charset, format }) => {
          dispatch(exportJournals(format, charset))
        }}
      />
    </Spin>
  )
}

export default JournalListPage
