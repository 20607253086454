import { RightDrawer } from 'aa_common/front-end/components'
import { updateObjectValues } from 'aa_common/front-end/helpers'
import { getNs } from 'aa_common/front-end/i18n-config'
import * as assetApi from 'api/app/asset'
import { BasicForm } from 'components/molecules'
import { useGetSelectedItemOnPage } from 'components/organisms/assets/AssetList/components/AssetListTopTemplate/hooks'
import { CAUSE_TYPES } from 'constants/masterData'
import useResource from 'lib/hooks/useResource'
import { get } from 'lodash'
import { FORM_MODES } from 'models/form'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import Form from './Form'

const { t, useNsReady } = getNs(['components/organisms/assets/asset-usage-started-form', 'common'])

type Props = {
  errors?: any
  causes: any[]
  isShow?: boolean
  data?: any
  latestEvent?: any
  formMode?: FORM_MODES
  onClose?: (e: any) => void
  onSubmit?: (data: any) => void
  onHandleReloadCauses?: () => void
  isBulkChangeEvent?: boolean
}

const initialValues = {
  usage_started_at: null,
  event_cause_id: null,
  event_photos: [],
  event_attachments: [],
  event_urls: [],
}

const AssetUsageStartedForm: React.FC<Props> = ({
  isShow = false,
  errors,
  data,
  causes,
  formMode = FORM_MODES.NEW,
  latestEvent,
  onClose,
  onSubmit,
  onHandleReloadCauses,
  isBulkChangeEvent,
}) => {
  useNsReady()
  const [visible, setVisible] = useState(isShow)
  const [usageCauses, setUsageCause] = useState<string[]>([])
  const [formValues, setFormValues] = useState<any>(null)
  const [previousAssetResponse, getPreviousAssetOfId] = useResource(assetApi.getPreviousOfId)

  const handleSubmit = (values: any) => {
    onSubmit && onSubmit(values)
  }
  const biidOfSelectedItems = useGetSelectedItemOnPage().selectedIds

  useEffect(() => {
    const usageStartedCauses = causes.filter(cause => get(cause, 'type') === CAUSE_TYPES.USAGE_STARTED)
    setUsageCause(usageStartedCauses)
  }, [causes])

  useEffect(() => {
    setVisible(isShow)

    if (isShow) {
      if (isBulkChangeEvent) {
        setFormValues({
          ...initialValues,
          usage_started_at: moment(),
        })
      }
      if (latestEvent && formMode === FORM_MODES.NEW) {
        let usageStartedAtDefault = moment()
        const validFromOfLastEvent = moment(get(latestEvent, 'valid_from'))

        if (usageStartedAtDefault < validFromOfLastEvent) {
          usageStartedAtDefault = validFromOfLastEvent
        }
        setFormValues({
          ...initialValues,
          usage_started_at: usageStartedAtDefault,
          min_date: validFromOfLastEvent,
        })
      } else if (data && previousAssetResponse.data) {
        const objectValues = updateObjectValues(initialValues, data)
        setFormValues({
          ...objectValues,
          id: data.id,
          event_cause_id: get(data, ['event_cause', 'id']),
          min_date: get(previousAssetResponse.data, 'valid_from'),
        })
      }
    }
  }, [formMode, isShow, data, latestEvent, previousAssetResponse.data])

  useEffect(() => {
    if (data) {
      const biid = get(data, 'biid')
      const id = get(data, 'id')

      getPreviousAssetOfId(biid, id)
    }
  }, [data]) // eslint-disable-line

  return (
    <RightDrawer
      title={
        isBulkChangeEvent ? t('usage_started_amount_title', { amount: biidOfSelectedItems.length }) : t('usage_started')
      }
      visible={visible}
      onClose={onClose}
      width={494}
    >
      <BasicForm initialValues={formValues} onSubmit={handleSubmit} errors={errors} loading={false}>
        <Form usageCauses={usageCauses} onClose={onClose} onHandleReloadCauses={onHandleReloadCauses} />
      </BasicForm>
    </RightDrawer>
  )
}

export default AssetUsageStartedForm
