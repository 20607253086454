import { loadNs } from 'aa_common/front-end/i18n-config'
import { JournalItemSettingTable } from 'components/templates'
import get from 'lodash/get'
import React from 'react'

import { BaseTableViewProps, renderAccountNameFieldValue } from './helper'

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const ImpairmentTableView = ({ item }: BaseTableViewProps) => {
  return (
    <JournalItemSettingTable title={t('impairment_settings_table')}>
      <tbody>
        <tr>
          <th className="section-name">{t('impairment_loss')}</th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'impairment_debit_loss_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'impairment_debit_loss_account_sub_item.name'))}
          </td>

          <th className="section-name">{t('accumulated_impairment_loss')}</th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'impairment_credit_accumulated_impairment_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'impairment_credit_accumulated_impairment_account_sub_item.name'))}
          </td>
        </tr>
        <tr>
          <th className="section-name" />
          <td colSpan={2} />

          <th className="section-name">{t('asset_category')}</th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'impairment_credit_asset_category_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'impairment_credit_asset_category_account_sub_item.name'))}
          </td>
        </tr>
      </tbody>
    </JournalItemSettingTable>
  )
}

export default ImpairmentTableView
