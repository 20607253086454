import { formatDate } from 'aa_common/front-end/helpers/format-helper'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { get } from 'lodash'
import { TableColumn } from 'models/sorting'
import React from 'react'
import { Link } from 'react-router-dom'

const t = loadNs([
  'common',
  'components/organisms/settings/department-list',
  'components/organisms/settings/department-form',
])

export const displayValidPeriod = (fromValue: string | null, toValue: string | null): string => {
  let result = t('none')

  if (fromValue || toValue) {
    result = `${formatDate(fromValue)} - ${formatDate(toValue) || t('form_values.unset')}`
  }

  return result
}

export const getColumns = (): TableColumn[] => [
  { field: 'code', text: t('code') },
  { field: 'name', text: t('name') },
  { field: 'ref_name', text: t('department_on_cloud') },
  {
    field: 'prepress_category_name',
    text: t('prepress_category'),
  },
  {
    field: 'valid_period',
    text: t('valid_period'),
    sortable: false,
  },
  { field: 'search_key', text: t('search_key') },
]
export const DEPARTMENT_LIST_COLUMNS_WIDTH = [140, 'auto', 170, 170, 200, 120, 112]

export const convertDataToDisplay = (data: any, columns: any, dateValueField: string) => {
  return data.map((dataItem: any) => {
    const displayItem: any = { id: dataItem.id, biid: dataItem.biid }

    columns.forEach(({ field }: { field: string }) => {
      switch (field) {
        case 'name':
          displayItem[field] = (
            <Link
              style={{ wordBreak: 'break-word' }}
              to={{
                pathname: `/master/departments/${dataItem.biid}/detail`,
                state: {
                  dateValueField,
                  department: dataItem,
                },
              }}
            >
              {dataItem[field]}
            </Link>
          )
          displayItem.original_name = dataItem[field]
          break
        case 'valid_period':
          displayItem[field] = displayValidPeriod(dataItem.valid_from, dataItem.valid_to)
          break
        case 'prepress_category_name':
          displayItem[field] = get(dataItem, ['prepress_category', 'name_jp'])
          break
        default:
          displayItem[field] = dataItem[field]
          break
      }
    })

    return displayItem
  })
}
