import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { GREY_BORDER_COLOR } from 'constants/styles'

const CONTAINER_MAX_WIDTH = '1020px'
export const Section = styled.div`
  max-width: ${CONTAINER_MAX_WIDTH};
  min-width: ${CONTAINER_MAX_WIDTH};
  padding-right: 15px;
`

export const ContainerClass = css`
  overflow-x: auto;
`

export const TopMessage = styled.div`
  background: #ffffff;
  border: 1px solid ${GREY_BORDER_COLOR};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;

  span {
    font-size: 13px;
  }
`
