import styled from '@emotion/styled'
import { LINK_COLOR } from 'constants/styles'

export const Wrapper = styled.div`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 10px;
  }

  .ant-table {
    border: 1px solid #d4d8dd;
    border-radius: 4px;

    .ant-table-thead > tr > th.highlight,
    .ant-table-tbody > tr > td.highlight {
      background: linear-gradient(0deg, rgba(59, 125, 233, 0.1), rgba(59, 125, 233, 0.1)), #ffffff;
    }
  }

  .ant-table-container {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .ant-table-header {
      border-radius: 4px;
      table {
        border-radius: 4px 4px 0 0;
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
      }
    }

    .ant-table-thead > tr > th:first-of-type {
      border-top-left-radius: 4px;
    }

    .ant-table-thead > tr > th:last-child {
      border-top-right-radius: 4px;
    }

    .anticon {
      color: #7c8291;
      margin-right: 5px;
    }
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-tbody > tr > td {
    font-size: 13px;
    text-align: right;
  }

  .ant-table-tbody > tr > td:first-of-type {
    text-align: left;
  }
`

export const NoData = styled.div`
  text-align: center;
  margin-top: 55px;
`

export const Link = styled.label`
  color: ${LINK_COLOR};
  cursor: pointer;
  display: block;
  padding-left: 30px;
`

export const ExportModalBody = styled.div`
  h4,
  .ant-radio-group {
    font-size: 13px;
  }

  .ant-radio-group {
    margin-bottom: 30px;
  }
`

export const WrapperForm = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  &:first-of-type {
    border-bottom: 1px solid #d4d8dd;
    padding-bottom: 15px;
  }
`
export const RadioGroupWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-radio-button {
    &-wrapper,
    &-wrapper-checked {
      min-width: 60px;
      border: 1px solid #d4d8dd;
      color: #3b7de9;
      text-align: center;

      &:first-of-type {
        border-radius: 4px 0px 0px 4px;
      }

      &:last-of-type {
        border-radius: 0px 4px 4px 0px;
      }
    }

    &-wrapper {
      &:hover,
      &-checked {
        border: 1px solid #3b7de9;
        border-color: #3b7de9;
      }

      &-checked {
        background-color: #e0e5f2;
      }

      &-disabled {
        color: #d4d8dd;
        border-color: #d4d8dd !important;
      }
    }
  }
`

export const WrapperLedger = styled.div`
  .ant-radio-group {
    display: flex;
  }

  .ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 15px;
    margin-right: 10px;
  }

  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper {
    background-color: #d4d8dd;
  }

  .ant-radio-button-wrapper:hover,
  .active {
    background-color: #3b7de9;
    color: #ffffff;
  }

  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper-checked {
    &:before {
      width: 0;
      height: 0;
    }
  }
`
