import { find, get } from 'lodash'
import {
  GeneralJournalSettingItem,
  GeneralJournalSettingItemFormPayload,
  GeneralJournalSettingItemSubmitPayload,
} from 'models/JournalData'
import React from 'react'

export const renderItemFieldValue = (value: any, valueField: string, labelField: string, dataSource: any[]) => {
  const targetItem = find(dataSource, item => get(item, valueField) === value)
  const renderText = targetItem ? get(targetItem, labelField) : ''

  return renderText !== '' ? <span>{renderText}</span> : <div style={{ minHeight: 24 }} />
}

export const convertFormPayload = (item: GeneralJournalSettingItem): GeneralJournalSettingItemFormPayload | null => {
  if (!item) return null

  return {
    depreciation_journal_cycle_type: item.depreciation_journal_cycle_type,
    depreciation_journal_method: item.depreciation_journal_method,
    depreciation_journal_type: item.depreciation_journal_type,
    create_journal_method: item.create_journal_method,
    paid_excise_debit_account_item: item.paid_excise_debit_account_item,
    paid_excise_debit_account_item_id: item?.paid_excise_debit_account_item?.is_active
      ? item.paid_excise_debit_account_item_id
      : null,
    received_excise_debit_account_item: item.received_excise_debit_account_item,
    received_excise_debit_account_item_id: item?.received_excise_debit_account_item?.is_active
      ? item.received_excise_debit_account_item_id
      : null,
    slip_date_type: item.slip_date_type,
    term_year_period: item.from_term_month.term_id,
    month_id: item.from_term_month.id,
    journal_linkage_status: item.journal_linkage_status,
  }
}

export const convertSubmitPayload = (
  item: GeneralJournalSettingItemFormPayload
): GeneralJournalSettingItemSubmitPayload | null => {
  if (!item) return null

  return {
    depreciation_journal_cycle_type: item.depreciation_journal_cycle_type,
    depreciation_journal_method: item.depreciation_journal_method,
    depreciation_journal_type: item.depreciation_journal_type,
    create_journal_method: item.create_journal_method,
    paid_excise_debit_account_item_id: item.paid_excise_debit_account_item_id,
    received_excise_debit_account_item_id: item.received_excise_debit_account_item_id,
    slip_date_type: item.slip_date_type,
    from_term_month_id: item.month_id,
    journal_linkage_status: item.journal_linkage_status,
  }
}
