import { RightArrowIcon } from 'aa_common/front-end/components'
import { formatDate, formatDateTime } from 'aa_common/front-end/helpers/format-helper'
import { ColumnsType } from 'antd/es/table'
import { FileLink, PhotoView, ReferenceLink } from 'components/atoms'
import { EMPTY_SYMBOL } from 'constants/app'
import { ASSET_CHANGE_SITUATION_TYPES } from 'constants/masterData'
import i18n from 'i18n'
import { get } from 'lodash'
import React from 'react'

import { DetailList, DetailListField, ExtraLine } from './styles'

const DateTemplate = (date: string) => <span>{formatDate(date)}</span>
type DetailTemplateProps = {
  data: any
}

const renderAttachments = (assetData: any) => {
  let attachments = get(assetData, 'attachments') || []

  if (get(assetData, ['change_situation', 'code']) !== ASSET_CHANGE_SITUATION_TYPES.ACQUISITION) {
    attachments = get(assetData, 'event_attachments') || []
  }

  if (attachments.length === 0) return EMPTY_SYMBOL

  return attachments.map((item: any) => (
    <div key={item?.id}>
      <FileLink file={item} maxLength={28} />
    </div>
  ))
}

const renderUrls = (assetData: any) => {
  let urls = get(assetData, 'urls') || []

  if (get(assetData, ['change_situation', 'code']) !== ASSET_CHANGE_SITUATION_TYPES.ACQUISITION) {
    urls = get(assetData, 'event_urls') || []
  }

  if (urls.length === 0) return EMPTY_SYMBOL

  return urls.map((url: string) => <ReferenceLink key={url} url={url} style={{ marginRight: 10 }} />)
}

const renderPhotos = (assetData: any) => {
  let photos = get(assetData, 'photos') || []

  if (get(assetData, ['change_situation', 'code']) !== ASSET_CHANGE_SITUATION_TYPES.ACQUISITION) {
    photos = get(assetData, 'event_photos') || []
  }

  if (photos.length === 0) return EMPTY_SYMBOL

  return photos.map((photo: any) => (
    <div key={photo?.id}>
      <PhotoView src={get(photo, 'url')} style={{ marginRight: 10 }} />
    </div>
  ))
}

const DetailTemplate: React.FC<DetailTemplateProps> = ({ data }) => {
  const isMovementEvent = get(data, ['change_situation', 'code']) === ASSET_CHANGE_SITUATION_TYPES.MOVEMENT
  const isSwitchEvent = get(data, ['change_situation', 'code']) === ASSET_CHANGE_SITUATION_TYPES.SWITCH

  return (
    <>
      <ExtraLine />
      <DetailList>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">
              {get(data, ['change_situation', 'name_jp']) || ''}
              {i18n.t('components.AssetDetail.reason')}
            </div>
            <div className="value">{get(data, ['event_cause', 'content']) || EMPTY_SYMBOL}</div>
          </div>
        </DetailListField>
        {isMovementEvent && (
          <>
            <DetailListField>
              <div className="detail-field-wrapper">
                <div className="title">{i18n.t('components.AssetDetail.area')}</div>
                <div className="value" style={{ display: 'flex', alignItems: 'center' }}>
                  <label>{get(data, ['previous', 'area', 'name']) || EMPTY_SYMBOL}</label>
                  <RightArrowIcon style={{ marginLeft: 20, marginRight: 20 }} />
                  <label>{get(data, ['area', 'name']) || EMPTY_SYMBOL}</label>
                </div>
              </div>
            </DetailListField>
            <DetailListField>
              <div className="detail-field-wrapper">
                <div className="title">{i18n.t('components.AssetDetail.submit_destination')}</div>
                <div className="value" style={{ display: 'flex' }}>
                  <label>{get(data, ['previous', 'area', 'submit_destination', 'name']) || EMPTY_SYMBOL}</label>
                  <RightArrowIcon style={{ marginLeft: 20, marginRight: 20 }} />
                  <label>{get(data, ['area', 'submit_destination', 'name']) || EMPTY_SYMBOL}</label>
                </div>
              </div>
            </DetailListField>
            <DetailListField>
              <div className="detail-field-wrapper">
                <div className="title">{i18n.t('components.AssetDetail.department')}</div>
                <div className="value" style={{ display: 'flex' }}>
                  <label>{get(data, ['previous', 'department', 'name']) || EMPTY_SYMBOL}</label>
                  <RightArrowIcon style={{ marginLeft: 20, marginRight: 20 }} />
                  <label>{get(data, ['department', 'name']) || EMPTY_SYMBOL}</label>
                </div>
              </div>
            </DetailListField>
          </>
        )}
        {isSwitchEvent && (
          <DetailListField>
            <div className="detail-field-wrapper">
              <div className="title">{i18n.t('components.AssetDetail.asset_category')}</div>
              <div className="value" style={{ display: 'flex', alignItems: 'center' }}>
                <label>{get(data, ['previous', 'asset_category', 'name']) || EMPTY_SYMBOL}</label>
                <RightArrowIcon style={{ marginLeft: 20, marginRight: 20 }} />
                <label>{get(data, ['asset_category', 'name']) || EMPTY_SYMBOL}</label>
              </div>
            </div>
          </DetailListField>
        )}
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.photo')}</div>
            <div className="value" style={{ display: 'flex' }}>
              {renderPhotos(data)}
            </div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.attachment_file')}</div>
            <div className="value">{renderAttachments(data)}</div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.reference_link')}</div>
            <div className="value">{renderUrls(data)}</div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.registered_by')}</div>
            <div className="value">{get(data, ['user_info', 'display_name'])}</div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.created_at_without_date')}</div>
            <div className="value">{formatDateTime(data.created_at)}</div>
          </div>
        </DetailListField>
      </DetailList>
    </>
  )
}

export const buildEventHistoryColumns = (): ColumnsType<any> => {
  return [
    {
      title: i18n.t('components.AssetDetail.date'),
      dataIndex: 'valid_from',
      key: 'valid_from',
      showSorterTooltip: false,
      sorter: false,
      width: 100,
      render: DateTemplate,
    },
    {
      title: i18n.t('components.AssetDetail.change_situation_type'),
      dataIndex: ['change_situation', 'name_jp'],
      key: 'change_situation',
      sorter: false,
      showSorterTooltip: false,
      width: 160,
    },
    {
      title: i18n.t('components.AssetDetail.content'),
      key: 'details',
      sorter: false,
      showSorterTooltip: false,
      width: 600,
      className: 'content',
      render: function renderDetail(_: string, record: any) {
        return <DetailTemplate data={record} />
      },
    },
  ]
}
