import {
  ASSET_CHANGE_SITUATION_TYPES,
  AssetReport,
  formatDate,
  identifyJournalStatusType,
  loadNs,
  objectToQueryParams,
} from 'aa_common/front-end'
import { FileLink, NoImage, PhotoView, ReferenceLink } from 'components/atoms'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  cleanupMonthlyClosingAssetEvents,
  fetchMonthlyClosingPreviousAssetEvents,
} from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingPreviousAssetEvents } from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'

import JournalStatus from './JournalStatus'
import { AssetDisplay, AssetDisplayDataRow, AssetDisplayHeader } from './styles'

type Props = {
  assets: AssetReport[]
}

const t = loadNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
const SwitchEventView: React.FC<Props> = ({ assets }) => {
  const dispatch = useDispatch()
  const previousAssetEvents = useSelector(selectMonthlyClosingPreviousAssetEvents, isEqual)

  const previousAssetEventsMap = useMemo(() => {
    if (!previousAssetEvents || previousAssetEvents.length === 0) return null

    const result: any = {}
    previousAssetEvents.forEach(item => {
      result[item.id as number] = get(item, 'previous')
    })

    return result
  }, [previousAssetEvents])

  const renderAssetPhoto = useCallback((asset: AssetReport) => {
    const isEmpty = !asset.photos || asset.photos.length === 0

    return isEmpty ? <NoImage /> : <img src={get(asset, 'photos[0].thumbnail_url')} alt="" />
  }, [])

  const handleAssetClick = (asset: AssetReport) => {
    const switchAt = asset.valid_from
    const paramObj = {
      valid_at: switchAt,
    }
    const params = `?${objectToQueryParams(paramObj)}`
    dispatch(changeLocation(`/assets/${asset.biid}${params}`))
  }

  useEffect(() => {
    if (assets && assets.length > 0) {
      // get all switch events of each asset;
      const assetIds: number[] = []

      assets.forEach(asset => {
        const switchEvents = asset.change_situations?.filter(
          item => item.change_situation.code === ASSET_CHANGE_SITUATION_TYPES.SWITCH
        )
        switchEvents?.forEach(item => {
          assetIds.push(item.id as number)
        })
      })

      assetIds.length > 0 && dispatch(fetchMonthlyClosingPreviousAssetEvents(assetIds))
    }
  }, [assets, dispatch])

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupMonthlyClosingAssetEvents())
    }
  }, []) // eslint-disable-line

  return (
    <>
      {assets?.map(asset => (
        <AssetDisplay key={asset?.biid} onClick={() => handleAssetClick(asset)}>
          <AssetDisplayHeader>
            <div className="status">
              <JournalStatus type={identifyJournalStatusType(asset)} />
            </div>
            <div className="photo">{renderAssetPhoto(asset)}</div>
            <div className="asset-code">{`${asset.code}-${asset.branch_code}`}</div>
            <div className="asset-name">{asset.name}</div>
          </AssetDisplayHeader>
          {asset?.change_situations?.map((asset, index) => (
            <div key={asset?.code}>
              <AssetDisplayDataRow className={index === 0 ? '' : 'border-top'}>
                <div className="short-label">{asset.valid_from}</div>
                <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
                <div className="label">{t('asset_category')}</div>
                <div className="value">
                  {get(previousAssetEventsMap, `[${asset.id}].asset_category.name`)}
                  <label className="arrow">→</label>
                  {get(asset.asset_category, 'name')}
                </div>
              </AssetDisplayDataRow>
              <AssetDisplayDataRow>
                <div className="label">{t('photo')}</div>
                <div className="value">
                  {asset?.event_photos?.map((photo: any) => (
                    <div key={photo?.id}>
                      <PhotoView src={get(photo, 'url')} style={{ marginRight: 10 }} />
                    </div>
                  ))}
                </div>
              </AssetDisplayDataRow>
              <AssetDisplayDataRow>
                <div className="label">{t('attachment')}</div>
                <div className="value" style={{ display: 'block' }}>
                  {asset?.event_attachments?.map((attachment: any) => (
                    <div key={attachment?.id}>
                      <FileLink file={attachment} />
                    </div>
                  ))}
                </div>
              </AssetDisplayDataRow>
              <AssetDisplayDataRow>
                <div className="label">{t('reference_link')}</div>
                <div className="value" style={{ display: 'block' }}>
                  {asset?.event_urls?.map((url: any) => (
                    <div key={url}>
                      <ReferenceLink url={url} />
                    </div>
                  ))}
                </div>
              </AssetDisplayDataRow>
              <AssetDisplayDataRow>
                <div className="label">{t('switch_by')}</div>
                <div className="value">{get(asset.user_info, 'display_name')}</div>
              </AssetDisplayDataRow>
              <AssetDisplayDataRow className="last">
                <div className="label">{t('switch_at')}</div>
                <div className="value">{formatDate(asset.created_at as string)}</div>
              </AssetDisplayDataRow>
            </div>
          ))}
        </AssetDisplay>
      ))}
    </>
  )
}

export default SwitchEventView
