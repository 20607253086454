import styled from '@emotion/styled'

import { GREY_BORDER_COLOR } from 'constants/styles'

export const Wrapper = styled.div`
  width: 600px;

  .processing-month-action {
    margin-left: 15px;
    display: flex;
    flex: 1 1 0%;
    justify-content: space-between;
    align-items: center;

    .reopen-monthly-closing {
      display: inline-flex;
      align-items: center;
    }

    a,
    span {
      font-size: 14px;
    }
  }
`

export const Title = styled.div`
  margin-top: 10px;
`

export const BoxWrapper = styled.div`
  margin-top: 10px;
  background-color: #ffffff;
  border: 1px solid ${GREY_BORDER_COLOR};
  border-radius: 4px;
`

export const BoxContent = styled.div`
  padding: 15px 15px 5px 15px;
`

export const BoxItem = styled.div`
  margin-bottom: 15px;

  .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid ${GREY_BORDER_COLOR};
    font-weight: 500;
    font-size: 13px;
  }

  .body {
    font-size: 12px;

    ul {
      list-style-type: none;

      li {
        margin-top: 5px;
      }
    }
  }

  .export-date {
    color: #999999;
    font-size: 12px;
  }
`

export const BoxFooter = styled.div`
  border-top: 1px solid ${GREY_BORDER_COLOR};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RecalculateMessageWrapper = styled.div`
  padding: 15px;
  margin-top: 15px;
  font-size: 13px;
  line-height: 19px;
  border-radius: 4px;
  border: 1px solid ${GREY_BORDER_COLOR};
  background-color: #ffffff;
`
