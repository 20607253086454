import { css } from '@emotion/css'
import styled from '@emotion/styled'

import { BLACK_COLOR, BLUE_COLOR, GREY_BORDER_COLOR, ICON_DEFAULT_COLOR } from '../../constants/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 8px 10px 8px 10px;
  color: #3a3a3a;
  justify-content: space-between;
  max-height: 100px;
  width: 100%;
  border-bottom: 1px solid ${GREY_BORDER_COLOR};
  background-color: #ffffff;

  svg {
    fill: ${ICON_DEFAULT_COLOR};
    color: ${ICON_DEFAULT_COLOR};
  }
  .header-icon:hover,
  .ant-dropdown-open,
  .ant-popover-open {
    svg {
      fill: ${BLUE_COLOR};
      color: ${BLUE_COLOR};
    }
  }

  @-moz-document url-prefix() {
    svg {
      bottom: 15px !important;
    }
  }

  .ant-dropdown {
    position: fixed;
    top: 40px;
  }
`

export const Divide = styled.div`
  height: 25px;
  border-left: 1px solid #d8d8d8;
  margin: 0 11px 0 15px;

  @-moz-document url-prefix() {
    height: 27px;
  }
`

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  box-sizing: content-box;
  height: 27px;
  padding-top: 2px;
`

export const RightContent = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  line-height: 0;

  .ant-dropdown-trigger {
    margin-top: 0 !important;
  }

  svg {
    margin-top: 0 !important;
  }
`

export const Item = styled.div`
  margin-right: 32px;
  display: flex;
`

export const FaqWrapperClass = css`
  position: fixed;
  top: 0;
  .ant-popover-inner {
    padding: 10px 5px;
    border: none;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgb(0 0 0 / 30%);
    &-content {
      padding: 0 10px;
    }
  }
`

export const LinkStyled = styled.a`
  display: block;
  padding: 5px 0;
  text-decoration: none;
  font-size: 13px;
  line-height: 1.5;
  color: ${BLACK_COLOR};

  &:hover {
    color: ${BLUE_COLOR};
  }
`

export const LastLinkStyled = styled.a`
  display: block;
  padding: 1px 0;
  margin-left: 5px;
  text-decoration: none;
  font-size: 13px;
  line-height: 1.5;
  color: ${BLACK_COLOR};

  &:hover {
    color: ${BLUE_COLOR};
  }
`

export const ACLinkStyled = styled.a`
  display: block;
  padding: 8px 0;
  text-decoration: none;
  font-size: 13px;
  line-height: 1.5;
  color: ${BLACK_COLOR};

  &:hover {
    color: ${BLUE_COLOR};
  }

  &:nth-of-type(2) {
    padding-bottom: 16px;
  }

  &:nth-of-type(3) {
    margin: 0 -15px;
    padding: 16px 8px 8px 16px;
    border-top: 1px solid ${GREY_BORDER_COLOR};
  }
`

export const NotificationWrapperClass = css`
  position: fixed;
  width: 300px;

  .ant-popover-inner {
    padding-top: 10px;
    border: none;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgb(0 0 0 / 30%);
    &-content {
      padding: 0;
    }
  }

  .release_date {
    padding: 0 10px;
    color: #999;
    font-size: 12px;
  }

  .no_notification {
    display: inline-block;
    padding: 10px 15px;
    font-size: 13px;
    color: ${ICON_DEFAULT_COLOR};
  }
`

export const LinkNotificationStyled = styled.a<{ highlight?: boolean }>`
  display: block;
  padding: 2px 10px 15px 10px;
  white-space: pre-wrap;
  text-decoration: none;
  font-size: 13px;
  font-weight: ${({ highlight }) => (highlight ? 'bold' : 'normal')};
  line-height: 1.5;
  color: ${BLACK_COLOR};

  &:hover {
    color: ${BLUE_COLOR};
  }

  &.border-bottom {
    border-bottom: 1px solid #ccc;
  }
`

export const LinkNotificationSecondStyled = styled.a`
  display: block;
  padding: 10px 10px;
  border-top: 1px solid ${GREY_BORDER_COLOR};
  text-decoration: none;
  font-size: 13px;
  line-height: 1.5;
  color: ${BLACK_COLOR};

  &:hover {
    color: ${BLUE_COLOR};
  }

  &.border-bottom {
    border-bottom: 1px solid #ccc;
  }
`

export const DropdownMenuStyled = css`
  width: 210px;

  .ant-dropdown-menu-item {
    border-bottom: none;
  }

  .ant-dropdown-menu-item:last-child {
    border-top: 1px solid ${GREY_BORDER_COLOR};
    padding: 10px;
  }
`
