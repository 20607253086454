export interface ValueFields {
  code: string
  name: string
  asset_type: null | number
  excise_id: null | number
  depreciation_method: null | number
  service_life: null | number
  asset_type_for_depreciable_property_tax: null | number
  asset_type_for_corporate_tax: null | number
  asset_structure_for_corporate_tax: string
  asset_detail_for_corporate_tax: string
  search_key: string
}

export const validateForm = (values: ValueFields) => {
  const { code, name, asset_type } = values

  return code.trim() && name.trim() && asset_type
}
