import { CAUSE_TYPES } from 'aa_common/front-end/constants'
import Form from 'components/molecules/Form'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'

import CauseForm from './Form'
import { ValueFields } from './helpers'
import { Wrapper } from './style'

const initialValues = {
  code: '',
  type: null,
  content: '',
  search_key: '',
}

type Props = {
  loading?: boolean
  errors?: any
  values?: any
  onSubmit: (values: ValueFields) => void
}

const CauseSubmitForm = ({ errors, loading = false, values, onSubmit }: Props) => {
  const [formValues, setFormValues] = useState(initialValues)
  const masterData = useSelector(selectMasterData, isEqual)
  const causeTypes = masterData.cause_type?.filter(cause => CAUSE_TYPES[cause.code])
  const isHiddenOption = !causeTypes.some(cause => cause.code === values?.type)

  useEffect(() => {
    setFormValues({ ...values, type: !isHiddenOption ? values.type : null })
  }, [values])

  return (
    <Wrapper>
      <Form value={formValues} errors={errors} loading={loading} onFormSubmit={onSubmit}>
        {form => <CauseForm item={values} formModule={form} causeTypes={causeTypes} />}
      </Form>
    </Wrapper>
  )
}

export default CauseSubmitForm
