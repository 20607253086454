import { CSVCharset } from 'models/csvCharset'

import { DeleteAssetCategoryRequest, FetchAssetCategoriesSuccess } from './action.model'
import {
  CLEANUP_ASSET_CATEGORY,
  CREATE_ASSET_CATEGORY_REQUEST,
  DELETE_ASSET_CATEGORY_REQUEST,
  EXPORT_ASSET_CATEGORY_REQUEST,
  FETCH_ASSET_CATEGORIES_FAILURE,
  FETCH_ASSET_CATEGORIES_REQUEST,
  FETCH_ASSET_CATEGORIES_REQUEST_AUTH,
  FETCH_ASSET_CATEGORIES_SUCCESS,
  FETCH_ASSET_CATEGORY_REQUEST,
  IMPORT_ASSET_CATEGORY_REQUEST,
  UPDATE_ASSET_CATEGORY_REQUEST,
} from './constant'
import { AssetCategoriesSearchPayload, DeleteAssetCategoryPayload } from './model'

export const fetchAssetCategories = (payload: AssetCategoriesSearchPayload = {}) => {
  return {
    type: FETCH_ASSET_CATEGORIES_REQUEST,
    payload,
  }
}

export const fetchAssetCategoriesAuth = (payload: AssetCategoriesSearchPayload) => {
  return {
    type: FETCH_ASSET_CATEGORIES_REQUEST_AUTH,
    payload,
  }
}

export const fetchAssetCategoriesSuccess = (payload: any): FetchAssetCategoriesSuccess => {
  return {
    type: FETCH_ASSET_CATEGORIES_SUCCESS,
    payload,
  }
}

export const fetchAssetCategoriesFailure = () => {
  return {
    type: FETCH_ASSET_CATEGORIES_FAILURE,
  }
}

export const fetchAssetCategory = (id: any) => {
  return {
    type: FETCH_ASSET_CATEGORY_REQUEST,
    id,
  }
}

export const createAssetCategory = (payload: any) => {
  return {
    type: CREATE_ASSET_CATEGORY_REQUEST,
    payload,
  }
}

export const updateAssetCategory = (id: any, payload: any) => {
  return {
    type: UPDATE_ASSET_CATEGORY_REQUEST,
    id,
    payload,
  }
}

export const deleteAssetCategory = (payload: DeleteAssetCategoryPayload): DeleteAssetCategoryRequest => {
  return {
    type: DELETE_ASSET_CATEGORY_REQUEST,
    payload,
  }
}

export const importAssetCategories = (charset: CSVCharset, file: any) => {
  return {
    type: IMPORT_ASSET_CATEGORY_REQUEST,
    payload: charset,
    file,
  }
}

export const exportAssetCategories = (charset: CSVCharset) => {
  return {
    type: EXPORT_ASSET_CATEGORY_REQUEST,
    payload: charset,
  }
}

export const cleanupAssetCategory = () => {
  return {
    type: CLEANUP_ASSET_CATEGORY,
  }
}
