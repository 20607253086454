import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Checkbox } from 'antd'
import React from 'react'
import { Controller } from 'react-hook-form'

import { CoreCheckboxGroupProps } from './model'
import { CheckboxGroupDefaultClass } from './styles'

const CheckboxGroup = ({
  name,
  className,
  options,
  control,
  error,
  disabled,
  onChange,
  isShowError,
  ...componentRest
}: CoreCheckboxGroupProps) => {
  // *** Form Input ***
  if (control) {
    const isInvalid = !!error

    return (
      <>
        <Controller
          name={name}
          control={control}
          defaultValue={componentRest.defaultValue}
          render={({ value, onChange: controllerOnChange }) => (
            <Checkbox.Group
              value={value}
              className={className || CheckboxGroupDefaultClass}
              onChange={event => {
                controllerOnChange(event)
                onChange && onChange(event)
              }}
              options={options}
              disabled={disabled}
              {...componentRest}
            />
          )}
        />
        {isInvalid && isShowError && <CommonErrorMsg fontSize="13px">{error}</CommonErrorMsg>}
      </>
    )
  }

  // *** Regular CheckboxGroup ***
  return (
    <Checkbox.Group
      name={name}
      className={className}
      onChange={onChange}
      options={options}
      disabled={disabled}
      {...componentRest}
    />
  )
}

export default React.memo(CheckboxGroup)
