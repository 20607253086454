import { RequiredLabel } from 'aa_common/front-end/components'
import { Col, Row } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { RowFieldClass } from './styles'

interface FormFieldProps {
  children: React.ReactNode
  className?: string
  required?: boolean
  title: string
}

const FormField: React.FC<FormFieldProps> = ({ children, required, className, title }) => {
  return (
    <Row className={classNames(RowFieldClass, className, 'form-field')} align="middle" wrap={false}>
      <Col className="label" flex="200px">
        <Row className="label-wrapper" align="middle" wrap={false}>
          <Col className="label-text" flex="auto">
            <h4>{title}</h4>
          </Col>
          {required && (
            <Col className="requiredLabel" flex="none">
              <RequiredLabel />
            </Col>
          )}
        </Row>
      </Col>
      <Col className="field-content" flex="auto">
        {children}
      </Col>
    </Row>
  )
}

export default FormField
