import styled from '@emotion/styled'
import { GREY_BORDER_COLOR } from 'constants/styles'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 15px;
  height: 72px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  z-index: 10;
  border-top: 1px ${GREY_BORDER_COLOR} solid;
  padding: 15px;
`
