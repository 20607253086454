import { downloadFileByResponse } from 'aa_common/front-end/helpers/file-helper'
import * as departmentApi from 'api/app/department'
import { AxiosResponse } from 'axios'
import { NOT_INTEGRAL_DATA_RELATION_ERROR_CODE } from 'constants/apiCode'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { CHANGE_LOCATION, setAppMessage, showErrorsAppCall } from 'store/app/actions'
import { parseError, parseImportErrors, parseValidationError } from 'utils/ResponseHelper'

import { DeleteDepartmentRequest, FetchDepartmentsRequest, HandleSubmitDepartmentFailure } from './action.model'
import {
  ExportDepartmentsAction,
  fetchDepartmentsFailure,
  fetchDepartmentsSuccess,
  handleFetchDepartmentsOnCloud,
  ImportDepartmentsAction,
} from './actions'
import {
  CREATE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_BY_ID_FAILURE,
  DELETE_DEPARTMENT_BY_ID_REQUEST,
  DELETE_DEPARTMENT_BY_ID_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  EXPORT_DEPARTMENT_FAILURE,
  EXPORT_DEPARTMENT_REQUEST,
  EXPORT_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENT_BY_BIID_REQUEST,
  FETCH_DEPARTMENT_BY_BIID_SUCCESS,
  FETCH_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENTS_ON_CLOUD_REQUEST,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_REQUEST_AUTH,
  HANDLE_SUBMIT_DEPARTMENT_FAILURE,
  IMPORT_DEPARTMENT_FAILURE,
  IMPORT_DEPARTMENT_REQUEST,
  IMPORT_DEPARTMENT_SUCCESS,
  INSERT_DEPARTMENT_REQUEST,
  SUBMIT_DEPARTMENT_FAILURE,
  SUBMIT_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_REQUEST,
} from './constant'

function* handleSubmitDeparmentFailure({ error }: HandleSubmitDepartmentFailure) {
  const errorResponse: AxiosResponse<any> = get(error, 'response')
  switch (errorResponse?.status) {
    case 422: {
      const validationErrors = get(error, 'response.data.errors')
      const payload = parseValidationError(validationErrors)
      yield put({ type: SUBMIT_DEPARTMENT_FAILURE, payload })
      break
    }
    default: {
      yield put(showErrorsAppCall(parseError(error)))
      yield put({ type: SUBMIT_DEPARTMENT_FAILURE })
    }
  }
}

export function* fetchDepartments({ payload }: FetchDepartmentsRequest) {
  const { data, errors } = yield call(departmentApi.search, payload)
  if (data) {
    const totalRecords = get(data, 'meta.total')
    yield put(
      fetchDepartmentsSuccess({
        data: data?.data || [],
        totalRecords: totalRecords || 0,
      })
    )
  } else if (errors) {
    yield put(fetchDepartmentsFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchDepartmentsAuth({ payload }: FetchDepartmentsRequest) {
  const { data, errors } = yield call(departmentApi.searchAuth, payload)
  if (data) {
    const totalRecords = get(data, 'meta.total')
    yield put(
      fetchDepartmentsSuccess({
        data: data?.data || [],
        totalRecords: totalRecords || 0,
      })
    )
  } else if (errors) {
    yield put(fetchDepartmentsFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchDepartmentsOnCloud() {
  const { data, errors } = yield call(departmentApi.getListDepartmentOnCloud)
  if (data) {
    yield put(handleFetchDepartmentsOnCloud(data?.data || []))
  } else if (errors) {
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchDepartment({ id }: any): Generator<any> {
  try {
    const response = (yield call(departmentApi.get, id)) as AxiosResponse
    yield put({ type: FETCH_DEPARTMENT_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchDepartmentByBiid({ biid }: any): Generator<any> {
  try {
    const response = (yield call(departmentApi.getByBiid, biid)) as AxiosResponse

    yield put({ type: FETCH_DEPARTMENT_BY_BIID_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createDepartment({ payload }: any): Generator<any> {
  try {
    yield call(departmentApi.create, payload)
    yield put({ type: SUBMIT_DEPARTMENT_SUCCESS })
    yield put({ type: CHANGE_LOCATION, payload: '/master/departments' })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitDepartmentFailure>({
      type: HANDLE_SUBMIT_DEPARTMENT_FAILURE,
      error,
    })
  }
}

export function* insertDepartment({ payload, callback }: any): Generator<any> {
  try {
    yield call(departmentApi.insert, payload)

    const { setIsAddNewDepartment, setIsCopy } = callback || {}
    setIsAddNewDepartment && setIsAddNewDepartment(false)
    setIsCopy && setIsCopy(false)

    yield put({ type: SUBMIT_DEPARTMENT_SUCCESS })
    yield put({ type: FETCH_DEPARTMENT_BY_BIID_REQUEST, biid: payload.biid })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitDepartmentFailure>({
      type: HANDLE_SUBMIT_DEPARTMENT_FAILURE,
      error,
    })
  }
}

export function* updateDepartment({ id, payload, callback }: any): Generator<any> {
  try {
    yield call(departmentApi.update, id, payload)

    const { setIsAddNewDepartment, setIsCopy } = callback || {}
    setIsAddNewDepartment && setIsAddNewDepartment(false)
    setIsCopy && setIsCopy(false)

    yield put({ type: SUBMIT_DEPARTMENT_SUCCESS })
    yield put({ type: FETCH_DEPARTMENT_BY_BIID_REQUEST, biid: payload.biid })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitDepartmentFailure>({
      type: HANDLE_SUBMIT_DEPARTMENT_FAILURE,
      error,
    })
  }
}

export function* deleteDepartment({ payload }: DeleteDepartmentRequest) {
  const { biid, original_name } = payload

  try {
    yield call(departmentApi.destroy, biid, false)
    yield put({ type: DELETE_DEPARTMENT_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
    yield put({ type: CHANGE_LOCATION, payload: '/master/departments' })
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400 && get(data, 'errors[0].code') === NOT_INTEGRAL_DATA_RELATION_ERROR_CODE) {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure_by_in_used', { name: original_name }),
          })
        )
      } else {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure', { name: original_name }),
          })
        )
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
    yield put({ type: DELETE_DEPARTMENT_FAILURE })
  }
}

export function* deleteDepartmentById({ payload }: any): Generator<any> {
  const { id, biid, name } = payload

  try {
    yield call(departmentApi.deleteById, id)
    yield put({ type: DELETE_DEPARTMENT_BY_ID_SUCCESS })
    yield put({ type: FETCH_DEPARTMENT_BY_BIID_REQUEST, biid })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
  } catch (error) {
    const errorResponse: AxiosResponse<any> = get(error, 'response')
    switch (errorResponse?.status) {
      case 400:
      case 422: {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure', { name }),
          })
        )
        break
      }
      default: {
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
    yield put({ type: DELETE_DEPARTMENT_BY_ID_FAILURE })
  }
}

export function* importDepartments({ payload, meta }: ImportDepartmentsAction) {
  try {
    yield call(departmentApi.importFile, payload.charset, payload.file)
    yield put({ type: IMPORT_DEPARTMENT_SUCCESS, meta })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.imported_success'),
      })
    )
  } catch (error: any) {
    const action = { type: IMPORT_DEPARTMENT_FAILURE, meta, error: true }
    const { status, data } = (error?.response || {}) as AxiosResponse<any>
    switch (status) {
      case 400:
      case 422: {
        yield put({ ...action, payload: parseImportErrors(data) })
        break
      }
      default: {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.imported_failure'),
          })
        )
        yield put(showErrorsAppCall(parseError(error)))
        yield put(action)
      }
    }
  }
}

export function* exportDepartments(action: ExportDepartmentsAction): Generator<any> {
  try {
    const { charset, filters } = action?.payload || {}
    const response = (yield call(departmentApi.exportFile, charset, filters)) as AxiosResponse
    yield call(downloadFileByResponse, response)
    yield put({ type: EXPORT_DEPARTMENT_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      })
    )
  } catch (error) {
    yield put({ type: EXPORT_DEPARTMENT_FAILURE })
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.exported_failure'),
      })
    )
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher(): Generator<any> {
  yield takeLatest(FETCH_DEPARTMENTS_REQUEST, fetchDepartments)
  yield takeLatest(FETCH_DEPARTMENTS_REQUEST_AUTH, fetchDepartmentsAuth)
  yield takeLatest(FETCH_DEPARTMENTS_ON_CLOUD_REQUEST, fetchDepartmentsOnCloud)
  yield takeLatest(FETCH_DEPARTMENT_REQUEST, fetchDepartment)
  yield takeLatest(FETCH_DEPARTMENT_BY_BIID_REQUEST, fetchDepartmentByBiid)
  yield takeLatest(CREATE_DEPARTMENT_REQUEST, createDepartment)
  yield takeLatest(INSERT_DEPARTMENT_REQUEST, insertDepartment)
  yield takeLatest(UPDATE_DEPARTMENT_REQUEST, updateDepartment)
  yield takeLatest(DELETE_DEPARTMENT_REQUEST, deleteDepartment)
  yield takeLatest(DELETE_DEPARTMENT_BY_ID_REQUEST, deleteDepartmentById)
  yield takeLatest(IMPORT_DEPARTMENT_REQUEST, importDepartments)
  yield takeLatest(EXPORT_DEPARTMENT_REQUEST, exportDepartments)
  yield takeLatest(HANDLE_SUBMIT_DEPARTMENT_FAILURE, handleSubmitDeparmentFailure)
}
