import { ASSET_CHANGE_SITUATION_TYPES } from 'constants/masterData'
import { Term, TermMonth } from 'models/term'
import { User } from 'models/user'

export enum MONTHLY_CLOSING_STATUSES {
  OPENING = 1,
  CLOSED = 2,
}

export enum MONTHLY_CLOSING_LINKED_STATUS {
  LINKED = 1,
  NOT_EXPORTED = 0,
  NEED_TO_LINK_AGAIN = 2,
}

export enum MONTHLY_CLOSING_EXPORT_STATUS {
  EXPORTED = 1,
  NOT_EXPORTED = 0,
  NEED_TO_EXPORT_AGAIN = 2,
}

export enum MONTHLY_CLOSING_CYCLES {
  MONTHLY = 1,
  QUARTER = 2,
}

export type MonthlyClosing = {
  id: number
  from_term_month: TermMonth
  to_term_month: TermMonth
  status: MONTHLY_CLOSING_STATUSES
  monthly_closing_setting: MonthlyClosingSetting
  linked_at: string | null
  linked_status: {
    code: MONTHLY_CLOSING_LINKED_STATUS
    text: string
  }
  closed_at: string
  closed_by: User
  exported_at: string
  exported_status: {
    text: string
    code: MONTHLY_CLOSING_EXPORT_STATUS
  }
  is_first_monthly_closing: boolean
}

export type MonthlyClosingSetting = {
  id: number
  term: Term
  closing_cycle: MONTHLY_CLOSING_CYCLES
  is_next_year?: boolean
}

export type SummaryAssetEventMetrics = {
  acquire_amount: number
  retire_amount: number
  sell_amount: number
  move_amount: number
  switch_amount: number
  impair_amount: number
  dividing: number
}

export type AssetEventSearchParams = {
  monthly_closing_id: number
  change_situation_code: ASSET_CHANGE_SITUATION_TYPES
  is_notlinked: boolean | null
  page_number: number
  page_size: number
}
