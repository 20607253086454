import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import flattenDepth from 'lodash/flattenDepth'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import moment, { Moment } from 'moment'

import { MonthlyClosing, Term, TermMonth } from '../models'

export const getCurrentJournalSettingsItem = (settingTerms: any[], currentMonthlyClosing: MonthlyClosing) => {
  if (!settingTerms) {
    return null
  }

  let settings = null

  if (settingTerms.length === 1) {
    settings = last(settingTerms)
  }

  if (settingTerms?.length > 1) {
    settingTerms.forEach((term, index) => {
      if (!isEmpty(term.to_term_month)) {
        const currentMonthlyClosingDate = moment(currentMonthlyClosing.from_term_month.start_date)
        const targetSettingsTermDate = moment(term.to_term_month.end_date)
        const previousItem = index > 0 && settingTerms[index - 1]
        if (currentMonthlyClosingDate.isBefore(targetSettingsTermDate)) {
          settings = term
        } else if (currentMonthlyClosingDate.isAfter(targetSettingsTermDate) && !previousItem.to_term_month) {
          settings = previousItem
        }
      }
    })
  }

  return settings
}

export const getSettingByValidAt = (settingTerms: any[], validAt: Moment | string) => {
  const validAtValue = moment(validAt)

  const settingByValidAt = filter(settingTerms, item => {
    const validFrom = moment(item.from_term_month.start_date)
    const validTo = item.to_term_month ? moment(item.to_term_month.end_date) : undefined

    const isInRange = validAtValue.isBetween(validFrom, validTo, 'date', '[]')
    const isLatestSettings = !validTo && validAtValue.isSameOrAfter(validFrom, 'date')

    if (isInRange || isLatestSettings) {
      return item
    }

    return false
  })

  return settingByValidAt
}

export const getAddNewNextSettings = (latestSettings: any, accountPeriods: Term[]) => {
  if (latestSettings && accountPeriods?.length > 0) {
    const monthLists: TermMonth[] = flattenDepth(accountPeriods.map(item => item.term_months))
    const currentTermMonthIndex = findIndex(monthLists, item => item.id === latestSettings.from_term_month.id)
    const nextTermMonthIndex = currentTermMonthIndex >= 0 ? currentTermMonthIndex + 1 : 0
    const findNextTermMonth = monthLists[nextTermMonthIndex]
    return findNextTermMonth
  }

  return null
}

export const getTermMonthById = (termMonthId: number, accountPeriods: Term[]) => {
  if (termMonthId && accountPeriods?.length > 0) {
    const monthLists: TermMonth[] = flattenDepth(accountPeriods.map(item => item.term_months))
    const result = find(monthLists, item => item.id === termMonthId)
    return result
  }

  return null
}

export const convertTermListData = (terms: any[]) => {
  if (!terms || terms.length <= 0) {
    return []
  }

  return terms.map(term => {
    return {
      ...term,
      key_id: term.id,
    }
  })
}

export const getCountItems = (settingData: any, currentMonthlyClosing: MonthlyClosing) => {
  const convertedData = convertTermListData(settingData)
  const currentMonthlyClosingStartDate = moment(currentMonthlyClosing?.from_term_month?.start_date)
  const currentMonthlyClosingEndDate = moment(currentMonthlyClosing?.from_term_month?.end_date)
  const futureItems = convertedData.filter(({ from_term_month }) => {
    const { start_date } = from_term_month
    return currentMonthlyClosingStartDate.isBefore(moment(start_date), 'date')
  }).length
  const pastItems = convertedData.filter(item => {
    const toTermMonthEndDate = item.to_term_month ? moment(item.to_term_month.end_date) : null
    return toTermMonthEndDate && currentMonthlyClosingEndDate.isAfter(toTermMonthEndDate, 'date')
  }).length
  return {
    pastItems,
    futureItems,
  }
}
