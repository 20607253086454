import { AxiosResponse } from 'axios'
import { AssetCategoriesSearchPayload } from 'store/settings/assetCategory/model'
import { parseError, parseResponse } from 'utils/ResponseHelper'

import {
  buildPaginationParams,
  buildQueryFilterParams,
  convertSortValue,
  objectToQueryParams,
  RequestParam,
} from '../../aa_common/front-end/helpers'
import base from './base'

export const search = ({ filters, page, pageSize, sort }: AssetCategoriesSearchPayload) => {
  return base
    .get('/v1/asset_categories', {
      params: {
        ...buildQueryFilterParams(filters),
        ...buildPaginationParams(page, pageSize),
        sort: convertSortValue(sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const searchAuth = (payload: AssetCategoriesSearchPayload) => {
  return base
    .get('/v1/asset_categories/get', {
      params: {
        ...buildQueryFilterParams(payload.filters),
        sort: convertSortValue(payload.sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const createAssetCategory = (data: any): Promise<AxiosResponse<any>> => {
  return base.post('/v1/asset_categories', data)
}

export const getAssetCategory = (id: number): Promise<AxiosResponse<any>> => {
  return base.get(`/v1/asset_categories/${id}`)
}

export const updateAssetCategory = (id: number, data: any): Promise<AxiosResponse<any>> => {
  return base.put(`/v1/asset_categories/${id}`, data)
}

export const destroyAssetCategory = (id: number, dryRun: boolean) => {
  return base.delete(`/v1/asset_categories/${id}`, { params: { dry_run: dryRun } })
}

export const importAssetCategories = (charset: string, file: any): Promise<AxiosResponse<any>> => {
  const formData = new FormData()
  formData.append('charset', charset)
  formData.append('file', file)

  return base.post('/v1/asset_categories/import_one', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const exportAssetCategories = (charset: string, params: RequestParam) => {
  const filter = objectToQueryParams(buildQueryFilterParams(params))
  return base.get(`/v1/export/asset_categories?charset=${charset}&${filter}`, {
    responseType: 'blob',
  })
}
