import { Popover } from 'antd'
import classNames from 'classnames'
import TagIcon from 'components/atoms/icons/TagIcon'
import { GREY_BORDER_COLOR } from 'constants/styles'
import React, { memo } from 'react'

import { ContentWrapper, IconCell, PopupOverlayClass, TagIconClass, TagStyled } from './styles'

interface TagInterface {
  id: string
  name: string
  position: string
}

interface TagPopupProps {
  tags: TagInterface[]
}

const TagPopup = ({ tags }: TagPopupProps) => {
  return (
    <>
      {tags?.length ? (
        <Popover
          placement="bottomRight"
          overlayClassName={PopupOverlayClass}
          arrowPointAtCenter
          content={
            <ContentWrapper>
              {tags.map((tag, index: number) => (
                <TagStyled key={tag?.id || index}>{tag?.name || ''}</TagStyled>
              ))}
            </ContentWrapper>
          }
        >
          <IconCell>
            <TagIcon className={classNames(TagIconClass, { enable: true })} style={{ marginTop: 3 }} />
          </IconCell>
        </Popover>
      ) : (
        <TagIcon color={GREY_BORDER_COLOR} style={{ marginTop: 6 }} />
      )}
    </>
  )
}

export default memo(TagPopup)
