import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { LEDGER_PURPOSE } from 'constants/masterData'
import { IMasterData } from 'models/masterData'
import React from 'react'
import { Control, useWatch } from 'react-hook-form'

const ImpairmentField = ({
  index,
  defaultValue,
  error,
  control,
  masterData,
}: {
  index: number
  error: string
  defaultValue: any
  control: Control<any>
  masterData: IMasterData
}) => {
  const fieldName = `settings[${index}].impairment_loss_apply_method`
  const ledgerPurpose = useWatch({
    name: `settings[${index}].ledger_purpose`,
    control,
  }) as LEDGER_PURPOSE

  const isAccountingPurpose = [LEDGER_PURPOSE.ACCOUNTING, LEDGER_PURPOSE.TAX_AND_ACCOUNTING].includes(ledgerPurpose)

  return isAccountingPurpose ? (
    <AutoCompleteSelect
      name={fieldName}
      items={masterData.impairment_loss_apply_method}
      defaultValue={defaultValue}
      textField="name_jp"
      valueField="code"
      control={control}
      isShowError
      error={error}
      allowClear={false}
    />
  ) : null
}

export default ImpairmentField
