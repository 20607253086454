import { Button } from 'aa_common/front-end/antd'
import { loadNs } from 'aa_common/front-end/i18n-config'
import RangeDatePickerInput from 'components/molecules/v2/RangeDatePickerInput'
import React, { useCallback } from 'react'

import { BaseFormFilter } from '../../model'
import { ActionButtonWrapper, Field, Label, UsageStartFormWrapper } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet', 'common'])

const UsageStartForm = ({ formModule, setPopupShow, onFormSubmit }: BaseFormFilter) => {
  const { handleSubmit, getValues } = formModule

  const handleFormSubmit = useCallback(() => handleSubmit(onFormSubmit, onFormSubmit)(), [handleSubmit, onFormSubmit])

  const handleSubmitClick = () => {
    const submitFormValues = {
      usage_started_at: getValues('usage_started_at'),
    }
    formModule.setValue('submitFormValues', submitFormValues)
    handleFormSubmit()
    setPopupShow(false)
  }

  return (
    <UsageStartFormWrapper>
      <Label>{t('form.used_at')}</Label>
      <Field>
        <RangeDatePickerInput
          placeholder={t('select_date_placeholder')}
          name="usage_started_at"
          control={formModule.control}
        />
      </Field>
      <ActionButtonWrapper>
        <Button type="button" color="blue" onClick={handleSubmitClick}>
          {t('actions.ok')}
        </Button>
      </ActionButtonWrapper>
    </UsageStartFormWrapper>
  )
}

export default UsageStartForm
