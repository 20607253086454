import { downloadFileByResponse } from 'aa_common/front-end/helpers/file-helper'
import * as areaApi from 'api/app/area'
import { AxiosResponse } from 'axios'
import { NOT_INTEGRAL_DATA_RELATION_ERROR_CODE } from 'constants/apiCode'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { CHANGE_LOCATION, setAppMessage, showErrorsAppCall } from 'store/app/actions'
import { parseError, parseImportErrors, parseValidationError } from 'utils/ResponseHelper'

import { DeleteAreaRequest, FetchAreasAuthRequest, FetchAreasRequest, HandleSubmitAreaFailure } from './action.model'
import { ExportAreasAction, fetchAreasFailure, fetchAreasSuccess, ImportAreasAction } from './actions'
import {
  CREATE_AREA_REQUEST,
  DELETE_AREA_BY_ID_FAILURE,
  DELETE_AREA_BY_ID_REQUEST,
  DELETE_AREA_BY_ID_SUCCESS,
  DELETE_AREA_FAILURE,
  DELETE_AREA_REQUEST,
  DELETE_AREA_SUCCESS,
  EXPORT_AREA_FAILURE,
  EXPORT_AREA_REQUEST,
  EXPORT_AREA_SUCCESS,
  FETCH_AREA_BY_BIID_REQUEST,
  FETCH_AREA_BY_BIID_SUCCESS,
  FETCH_AREA_REQUEST,
  FETCH_AREA_SUCCESS,
  FETCH_AREAS_REQUEST,
  FETCH_AREAS_REQUEST_AUTH,
  HANDLE_SUBMIT_AREA_FAILURE,
  IMPORT_AREA_FAILURE,
  IMPORT_AREA_REQUEST,
  IMPORT_AREA_SUCCESS,
  INSERT_AREA_REQUEST,
  SUBMIT_AREA_FAILURE,
  SUBMIT_AREA_SUCCESS,
  UPDATE_AREA_REQUEST,
} from './constant'

function* handleSubmitAreaFailure({ error }: HandleSubmitAreaFailure) {
  const errorResponse: AxiosResponse<any> = get(error, 'response')
  switch (errorResponse?.status) {
    case 422: {
      const validationErrors = get(error, 'response.data.errors')
      const payload = parseValidationError(validationErrors)
      yield put({ type: SUBMIT_AREA_FAILURE, payload })
      break
    }
    default: {
      yield put(showErrorsAppCall(parseError(error)))
      yield put({ type: SUBMIT_AREA_FAILURE })
    }
  }
}

export const selector = {
  getFilterConditions: (state: any) => state.getIn(['settings', 'area', 'list', 'filters']),
  getSort: (state: any) => state.getIn(['settings', 'area', 'list', 'sort']),
}

export function* fetchAreas({ payload }: FetchAreasRequest) {
  const { data, errors } = yield call(areaApi.search, payload)
  if (data) {
    const totalRecords = get(data, 'meta.total')
    yield put(
      fetchAreasSuccess({
        data: data?.data || [],
        totalRecords: totalRecords || 0,
      })
    )
  } else if (errors) {
    yield put(fetchAreasFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchAreasAuth({ payload }: FetchAreasAuthRequest) {
  const { data, errors } = yield call(areaApi.searchAuth, payload)
  if (data) {
    const totalRecords = get(data, 'meta.total')
    yield put(
      fetchAreasSuccess({
        data: data?.data || [],
        totalRecords: totalRecords || 0,
      })
    )
  } else if (errors) {
    yield put(fetchAreasFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchArea({ id }: any) {
  try {
    const response: AxiosResponse = yield call(areaApi.getById, id)
    yield put({ type: FETCH_AREA_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchAreaByBiid({ biid }: any) {
  try {
    const response: AxiosResponse = yield call(areaApi.getByBiid, biid)

    if (response.data.data.length === 0) {
      throw Error()
    }

    yield put({ type: FETCH_AREA_BY_BIID_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createArea({ payload }: any) {
  try {
    yield call(areaApi.createArea, payload)
    yield put({ type: SUBMIT_AREA_SUCCESS })
    yield put({ type: CHANGE_LOCATION, payload: '/master/areas' })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAreaFailure>({
      type: HANDLE_SUBMIT_AREA_FAILURE,
      error,
    })
  }
}

export function* insertArea({ payload, callback }: any) {
  try {
    yield call(areaApi.insertArea, payload)
    const { setIsAddNewArea, setIsCopy } = callback || {}
    setIsAddNewArea && setIsAddNewArea(false)
    setIsCopy && setIsCopy(false)
    yield put({ type: SUBMIT_AREA_SUCCESS })
    yield put({ type: FETCH_AREA_BY_BIID_REQUEST, biid: payload.biid })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    const errorResponse: AxiosResponse<any> = get(error, 'response')

    switch (errorResponse?.status) {
      case 400:
      case 422: {
        const data = get(errorResponse, 'data')
        const errorResult = data?.errors

        if (errorResult && errorResult[0] && errorResult[0]?.code === 'ERR_UNPROCESSABLE_ENTITY') {
          const errors = parseValidationError(data?.errors)

          yield put({ type: HANDLE_SUBMIT_AREA_FAILURE, error: errors })
        } else {
          yield put(showErrorsAppCall(parseError(error)))
        }
        break
      }
      default: {
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
  }
}

export function* updateArea({ id, payload, isLatest, callback }: any) {
  try {
    if (isLatest) {
      yield call(areaApi.updateLatest, id, payload)
    } else {
      yield call(areaApi.update, id, payload)
    }
    const { setIsAddNewArea, setIsCopy } = callback || {}
    setIsAddNewArea && setIsAddNewArea(false)
    setIsCopy && setIsCopy(false)
    yield put({ type: SUBMIT_AREA_SUCCESS })
    yield put({ type: FETCH_AREA_BY_BIID_REQUEST, biid: payload.biid })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    const errorResponse: AxiosResponse<any> = get(error, 'response')

    switch (errorResponse?.status) {
      case 400:
      case 422: {
        const data = get(errorResponse, 'data')
        const errorResult = data?.errors

        if (errorResult && errorResult[0] && errorResult[0]?.code === 'ERR_UNPROCESSABLE_ENTITY') {
          const errors = parseValidationError(data?.errors)

          yield put({ type: HANDLE_SUBMIT_AREA_FAILURE, error: errors })
        } else {
          yield put(showErrorsAppCall(parseError(error)))
        }
        break
      }
      default: {
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
  }
}

export function* deleteArea({ payload }: DeleteAreaRequest) {
  const { biid, original_name } = payload

  try {
    yield call(areaApi.destroy, biid, false)
    yield put({ type: DELETE_AREA_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
    yield put({ type: CHANGE_LOCATION, payload: '/master/areas' })
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400 && get(data, 'errors[0].code') === NOT_INTEGRAL_DATA_RELATION_ERROR_CODE) {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure_by_in_used', { name: original_name }),
          })
        )
      } else {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure', { name: original_name }),
          })
        )
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
    yield put({ type: DELETE_AREA_FAILURE })
  }
}

export function* deleteAreaById({ payload }: any) {
  const { id, biid, name } = payload

  try {
    yield call(areaApi.deleteById, id)
    yield put({ type: DELETE_AREA_BY_ID_SUCCESS })
    yield put({ type: FETCH_AREA_BY_BIID_REQUEST, biid })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
  } catch (error) {
    const errorResponse: AxiosResponse<any> = get(error, 'response')
    switch (errorResponse?.status) {
      case 400:
      case 422: {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure', { name }),
          })
        )
        break
      }
      default: {
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
    yield put({ type: DELETE_AREA_BY_ID_FAILURE })
  }
}

export function* importAreas({ payload, meta }: ImportAreasAction) {
  const { charset, file } = payload
  try {
    yield call(areaApi.importArea, charset, file)
    yield put({ type: IMPORT_AREA_SUCCESS, meta })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.imported_success'),
      })
    )
  } catch (error) {
    const errorResponse: AxiosResponse<any> = get(error, 'response')
    switch (errorResponse?.status) {
      case 400:
      case 422: {
        const data = get(errorResponse, 'data')
        const importErrors = parseImportErrors(data)
        yield put({
          type: IMPORT_AREA_FAILURE,
          payload: importErrors,
          meta,
          error: true,
        })
        break
      }
      default: {
        yield put(
          setAppMessage(
            {
              type: 'failure',
              content: i18n.t('common.messages.imported_failure'),
            },
            meta,
            true
          )
        )
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
  }
}

export function* exportAreas(action: ExportAreasAction) {
  try {
    const { charset, filters } = action?.payload || {}
    const response: AxiosResponse = yield call(areaApi.exportArea, charset, filters)
    yield downloadFileByResponse(response)
    yield put({ type: EXPORT_AREA_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      })
    )
  } catch (error) {
    yield put({ type: EXPORT_AREA_FAILURE })
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.exported_failure'),
      })
    )
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_AREAS_REQUEST, fetchAreas)
  yield takeLatest(FETCH_AREAS_REQUEST_AUTH, fetchAreasAuth)
  yield takeLatest(FETCH_AREA_REQUEST, fetchArea)
  yield takeLatest(FETCH_AREA_BY_BIID_REQUEST, fetchAreaByBiid)
  yield takeLatest(CREATE_AREA_REQUEST, createArea)
  yield takeLatest(INSERT_AREA_REQUEST, insertArea)
  yield takeLatest(UPDATE_AREA_REQUEST, updateArea)
  yield takeLatest(DELETE_AREA_REQUEST, deleteArea)
  yield takeLatest(DELETE_AREA_BY_ID_REQUEST, deleteAreaById)
  yield takeLatest(IMPORT_AREA_REQUEST, importAreas)
  yield takeLatest(EXPORT_AREA_REQUEST, exportAreas)
  yield takeLatest(HANDLE_SUBMIT_AREA_FAILURE, handleSubmitAreaFailure)
}
