import { get } from 'lodash'
import { OperationLog } from 'models/operationLog'

export const convertOperationLogs = (responseData: any[]): OperationLog[] => {
  if (!responseData || responseData.length === 0) return []

  return responseData.map(item => {
    return {
      id: get(item, 'id'),
      afterId: get(item, 'after_id'),
      beforeId: get(item, 'before_id'),
      type: get(item, 'operation_type'),
      target: get(item, 'operation_target'),
      createdAt: get(item, 'created_at'),
      asset: {
        name: get(item, 'cached_fixed_asset_name'),
        biid: get(item, 'cached_fixed_asset_biid'),
        branch_code: get(item, 'cached_fixed_asset_branch_code'),
        code: get(item, 'cached_fixed_asset_code'),
      },
      user: {
        id: get(item, 'office_user.id'),
        display_name: get(item, 'office_user.display_name'),
      },
    }
  }) as OperationLog[]
}
