import * as roleApi from 'api/app/role'
import i18n from 'i18n'
import { call, put, takeLatest } from 'redux-saga/effects'
import { changeLocation, setAppMessage, showErrorsAppCall } from 'store/app/actions'
import { parseError } from 'utils/ResponseHelper'

import { UpdateRoleCall } from './action.model'
import {
  fetchUserRoles as fetchUserRolesCall,
  handleFetchRoleDetail,
  handleFetchUserRolesFail,
  handleFetchUserRolesSuccess,
  setLoadingItem,
} from './actions'
import {
  CREATE_NEW_ROLE_CALL,
  DELETE_ROLE_CALL,
  FETCH_ROLE_DETAIL,
  FETCH_ROLE_SAMPLE,
  FETCH_USER_ROLES_REQUEST,
  UPDATE_ROLE_CALL,
} from './constant'

function* fetchUserRoles() {
  try {
    const { data } = yield call(roleApi.list)
    yield put(handleFetchUserRolesSuccess(data?.data || []))
  } catch (error) {
    yield put(handleFetchUserRolesFail())
    yield put(showErrorsAppCall(parseError(error)))
  }
}

function* fetchRoleSample() {
  try {
    const { data } = yield call(roleApi.getRolesDefault)
    yield put(handleFetchRoleDetail(data?.data || null))
  } catch (error) {
    yield put(handleFetchRoleDetail(null))
    yield put(showErrorsAppCall(parseError(error)))
  }
}

function* fetchRoleDetail({ payload }: any) {
  try {
    const { data } = yield call(roleApi.getRoleDetail, payload)
    yield put(handleFetchRoleDetail(data?.data || null))
  } catch (error) {
    yield put(handleFetchRoleDetail(null))
    yield put(showErrorsAppCall(parseError(error)))
  }
}

function* createNewRole({ payload }: any) {
  try {
    yield put(setLoadingItem(true))
    yield call(roleApi.createNewRole, payload)
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
    yield put(setLoadingItem(false))
    yield put(changeLocation('/setting/roles'))
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
    yield put(setLoadingItem(false))
  }
}

function* updateRole({ payload: { biid, postData } }: UpdateRoleCall) {
  try {
    yield put(setLoadingItem(true))
    yield call(roleApi.updateRole, biid, postData)
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
    yield put(setLoadingItem(false))
    yield put(changeLocation('/setting/roles'))
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
    yield put(setLoadingItem(false))
  }
}

function* deleteRole({ payload }: any) {
  try {
    yield call(roleApi.deleteRole, payload)
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
    yield put(fetchUserRolesCall())
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_USER_ROLES_REQUEST, fetchUserRoles)
  yield takeLatest(FETCH_ROLE_SAMPLE, fetchRoleSample)
  yield takeLatest(FETCH_ROLE_DETAIL, fetchRoleDetail)
  yield takeLatest(CREATE_NEW_ROLE_CALL, createNewRole)
  yield takeLatest(UPDATE_ROLE_CALL, updateRole)
  yield takeLatest(DELETE_ROLE_CALL, deleteRole)
}
