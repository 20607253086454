import { getNs } from 'aa_common/front-end/i18n-config'
import { ListPageTemplate } from 'components/templates'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import { BreadcrumbNavigation } from '../../../components/molecules'
import { CorporateIncomeTaxReportContainer } from '../../../containers/accounting/corporate-income-tax-report-container'
import { StyledWrapper } from './styles'

const { t, useNsReady } = getNs([
  'pages/accounting/corporate-income-tax-report',
  'common',
  'pages/accounting/corporate-income-tax',
])

const CorporateIncomeTaxReportPage = () => {
  useNsReady()

  const {
    id,
    term_id: termId,
    report_type: reportType,
  } = queryString.parse(useLocation().search) as unknown as ParamsReportType

  return (
    <StyledWrapper>
      <ListPageTemplate breadcrumb={<Breadcrumb reportId={+id} />} maxWidth="unset">
        <CorporateIncomeTaxReportContainer reportId={id} termId={termId} reportType={reportType} />
      </ListPageTemplate>
    </StyledWrapper>
  )
}

const Breadcrumb = ({ reportId }: { reportId: number }) => {
  const reportNames: Record<number, string> = {
    1: t('reports.report_1.name'),
    2: t('reports.report_2.name'),
    4: t('reports.report_4.name'),
    6: t('reports.report_6.name'),
    7: t('reports.report_7.name'),
    8: t('reports.report_8.name'),
  }

  return <BreadcrumbNavigation enableBackButton sectionName={`${reportNames[reportId]} ${t('actions.preview')}`} />
}

type ParamsReportType = {
  id: number
  term_id: number
  report_type: number
}

export default CorporateIncomeTaxReportPage
