import { LinkLabel } from 'aa_common/front-end/components'
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg'
import { BreadcrumbNavigation } from 'components/molecules'
import IncreaseDecreaseReport from 'components/organisms/accounting/IncreaseDecreaseReport'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { changeLocation } from 'store/app/actions'
import { cleanupTerms, fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsList } from 'store/settings/accountingPeriod/selectors'
import { cleanupLedgerSettings, fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectorLedgerSettingsList } from 'store/settings/ledgerSetting/selectors'

const IncreaseDecreaseReportPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isRedirectFromMonthlyClosing = location.pathname.includes('/accounting/monthly-closing')

  const handleCancel = () => {
    dispatch(changeLocation('/accounting/monthly-closing'))
  }

  const cancelLink = (
    <LinkLabel id="cancel-increase-decrease-report" onClick={handleCancel} style={{ paddingRight: '15px' }}>
      <CrossIcon />
    </LinkLabel>
  )

  const breadcrumb = (
    <BreadcrumbNavigation
      sectionName={i18n.t('pages.accounting.IncreaseDecreaseReport.page_title')}
      customBackElement={isRedirectFromMonthlyClosing ? cancelLink : undefined}
    />
  )

  const termsSelector = useSelector(selectTermsList, isEqual)
  const ledgersSelector = useSelector(selectorLedgerSettingsList, isEqual)

  const memoizedTermsData = useMemo(() => termsSelector.data, [termsSelector.data])
  const memoizedLedgers = useMemo(() => ledgersSelector.data, [ledgersSelector.data])

  useEffect(() => {
    dispatch(cleanupTerms())
    dispatch(cleanupLedgerSettings())

    dispatch(fetchTerms())
    dispatch(fetchMonthlyClosingCurrent())
    dispatch(fetchLedgerSettingsList())

    return () => {
      dispatch(cleanupTerms())
      dispatch(cleanupLedgerSettings())
    }
  }, [dispatch])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <IncreaseDecreaseReport terms={memoizedTermsData} ledgers={memoizedLedgers} />
    </MainTemplate>
  )
}

export default IncreaseDecreaseReportPage
