import Form from 'aa_common/front-end/components/form'
import { loadNs } from 'aa_common/front-end/i18n-config'
import Input from 'components/atoms/v2/Input'
import { RenderFormProps } from 'components/molecules/Form/index'
import { get } from 'lodash'
import React from 'react'

const t = loadNs(['common', 'components/organisms/settings/tag-form'])

const { Group, Header, Item, SubmitArea } = Form

const TagForm = ({ form }: { form: RenderFormProps }) => {
  const { loading, errors } = form
  return (
    <>
      <Group>
        <Header>{t('form_title')}</Header>
        <Item required label={t('name')}>
          <Input
            disabled={loading}
            name="name"
            placeholder={t('placeholder.under_200')}
            maxLength={200}
            control={form.control}
            error={get(errors, 'name.message')}
          />
        </Item>
      </Group>
      <SubmitArea formControl={form.control} loading={form.loading} requiredFields={[]} />
    </>
  )
}

export default TagForm
