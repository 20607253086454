import * as MasterDataApi from 'api/app/masterData'
import { AxiosResponse } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { INIT_APP_CHECK_PROGRESS, showErrorsAppCall } from 'store/app/actions'
import { parseError } from 'utils/ResponseHelper'

import { FETCH_MASTER_DATA_REQUEST, FETCH_MASTER_DATA_SUCCESS } from './actions'
import { parseDataMaster } from './helper'

export function* fetchMasterData() {
  try {
    const response: AxiosResponse = yield call(MasterDataApi.getAll)
    const parsedData = parseDataMaster(response.data.data)

    yield put({ type: FETCH_MASTER_DATA_SUCCESS, payload: parsedData })
    yield put({ type: INIT_APP_CHECK_PROGRESS })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_MASTER_DATA_REQUEST, fetchMasterData)
}
