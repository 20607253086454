import { Checkbox } from 'aa_common/front-end/antd'
import { getNs } from 'aa_common/front-end/i18n-config'
import { debounce, isEqual } from 'lodash'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionView } from 'store/asset/selectors'

const { t, useNsReady } = getNs(['components/organisms/assets/asset-list'])

export const HistoryCheckbox = ({ onChange }: HistoryCheckboxType) => {
  useNsReady()
  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)
  const isDetailMode = !!assetCollectionViewSelector.filters?.include_history

  const handleListModeChange = useCallback(
    debounce((event: any) => {
      onChange(event.checked)
    }, 200),
    [onChange]
  )

  return (
    <Checkbox name="mode" onChange={handleListModeChange} checked={isDetailMode}>
      <span>{t('show_fixed_asset_history')}</span>
    </Checkbox>
  )
}

type HistoryCheckboxType = {
  onChange: (detailMode: boolean) => void
}
