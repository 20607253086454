import { faPaperclip, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'aa_common/front-end/antd'
import { ConfirmModal } from 'aa_common/front-end/components'
import { validateFileSize } from 'aa_common/front-end/helpers'
import { message, Upload } from 'antd'
import { UploadChangeParam } from 'antd/es/upload/interface'
import { UploadFile } from 'antd/lib/upload/interface'
import { FILE_DOWNLOAD_PATH, FILE_UPLOAD_PATH, MAX_FILE_UPLOAD_SIZE } from 'constants/app'
import { CSRF_TOKEN_KEY } from 'constants/auth'
import { getIn, useFormikContext } from 'formik'
import i18n from 'i18n'
import { List as ImmuList } from 'immutable'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import uniqueId from 'lodash/uniqueId'
import React, { useEffect, useState } from 'react'

import { Item, List, Wrapper } from './styles'

type Props = {
  name: string
  mode?: 'view' | 'edit'
}

type Attachment = {
  id: number
  filename: string
  url: string
}

const UploadFileListField: React.FC<Props> = ({ name, mode = 'edit' }) => {
  const { values, setFieldValue } = useFormikContext()

  const [fileUploadedList, setFileUploadedList] = useState(getIn(values, name) || [])
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [selectedFile, setSelectedFile] = useState<Attachment | null>(null)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

  const fieldValue = getIn(values, name)
  const isEditMode = mode === 'edit'

  const handleBeforeUpload = (file: any) => {
    if (!validateFileSize(file, MAX_FILE_UPLOAD_SIZE)) {
      message.error(i18n.t('common.messages.over_file_size_limit', { name: file.name }))
      return false
    }

    return true
  }

  const handleChanged = ({ file, fileList }: UploadChangeParam<UploadFile<any>>) => {
    if (file.status === 'error') {
      message.error(i18n.t('components.UploadImageListField.upload_failure', { name: file.name }))
    }

    const newUpdatedFileList = ImmuList(fileList)
    const filteredFiles = newUpdatedFileList
      .filter(item => {
        return item.status === 'done' || item.status === 'success'
      })
      .toArray()

    const finishUploadedFiles = (fileUploadedList || []).map((file: any) => {
      const { id, filename, url } = file
      return { id, filename, url }
    })

    const recentUploadedFiles = filteredFiles?.map(file => {
      const { id, filename, url } = get(file, ['response', 'data']) || {}
      return { id, filename, url }
    })
    const newUploadedList = uniqBy([...finishUploadedFiles, ...recentUploadedFiles], 'id')

    setFileList(newUpdatedFileList.toArray())
    setFileUploadedList(newUploadedList)
    setFieldValue(name, newUploadedList)
  }

  const handleRemove = (file: any) => {
    setSelectedFile(file)
    setIsConfirmingDelete(true)

    return false
  }

  const handleCancelDelete = (): void => {
    setIsConfirmingDelete(false)
  }

  const handleDelete = (): void => {
    const newFiles = fileUploadedList.filter((item: any) => item.id !== selectedFile?.id)
    const newUpdatedFileList = ImmuList(fileList)
      .filter(item => item.response?.data?.id !== selectedFile?.id)
      .toArray()
    setFileList(newUpdatedFileList)
    setFileUploadedList(newFiles)
    setFieldValue(name, newFiles)
    setIsConfirmingDelete(false)
  }

  const handleDownload = (file: any): void => {
    window.open(`${FILE_DOWNLOAD_PATH}/${file.id}`, '_blank')
  }

  const headers: any = {
    'X-CSRF-Token': localStorage.getItem(CSRF_TOKEN_KEY),
  }

  const renderFileList = () => (
    <List>
      {fileUploadedList?.map((item: any) => (
        <Item key={uniqueId()}>
          <FontAwesomeIcon icon={faPaperclip} className="open-icon" onClick={() => handleDownload(item)} />
          <div className="content">{item.name || item.filename}</div>
          {isEditMode && (
            <FontAwesomeIcon icon={faTrashAlt} className="remove-icon" onClick={() => handleRemove(item)} />
          )}
        </Item>
      ))}
    </List>
  )

  useEffect(() => {
    setFileUploadedList(fieldValue)
  }, [fieldValue]) // eslint-disable-line

  return (
    <Wrapper>
      <Upload
        action={FILE_UPLOAD_PATH}
        fileList={fileList}
        onChange={handleChanged}
        multiple
        beforeUpload={handleBeforeUpload}
        showUploadList={false}
        headers={headers}
        withCredentials
      >
        {isEditMode && (
          <Button style={{ padding: '0 10px', width: 'auto' }} color="grey">
            {i18n.t('components.UploadFileListField.upload')}
          </Button>
        )}
      </Upload>
      {renderFileList()}
      <ConfirmModal
        message={i18n.t('common.messages.confirm_delete', { name: `${get(selectedFile, 'filename')}` })}
        visible={isConfirmingDelete}
        onOK={handleDelete}
        onCancel={handleCancelDelete}
      />
    </Wrapper>
  )
}

export default UploadFileListField
