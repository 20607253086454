import { checkSettingBelongToMonthlyClosing } from 'aa_common/front-end/helpers/monthly-closing-helper'
import { BasicForm } from 'components/molecules'
import { ASSET_CODE_SETTING_RULE_LENGTH_CODE, ASSET_CODE_SETTING_SCOPE_CODE } from 'constants/masterData'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectAssetCodeSettings } from 'store/masterData/selectors'
import {
  cleanupInitialNumberSetting,
  fetchInitialNumberSetting,
  saveInitialNumberSetting,
  setTermYearSelected,
} from 'store/settings/assetCode/actions'
import {
  selectAssetCodeAutoGenerateSettingListData,
  selectAssetCodeInitialSerialSettingData,
  selectAssetCodeInitialSerialSettingErrors,
} from 'store/settings/assetCode/selectors'

import { getSettingScopeText } from '../AssetCodeAutoGenerationView/helper'
import EachPatternForm from './EachPatternForm'
import { Wrapper } from './styles'
import WholeOfficeForm from './WholeOfficeForm'

const AssetCodeStartNumberForm: React.FC = () => {
  const dispatch = useDispatch()
  const assetCodeAutoGenerateSettingListData = useSelector(selectAssetCodeAutoGenerateSettingListData, isEqual)
  const assetCodeAutoGenerateSettingListErrors = useSelector(selectAssetCodeInitialSerialSettingErrors)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const initialNumberSettings = useSelector(selectAssetCodeInitialSerialSettingData, isEqual)
  const assetCodeMasterSettings = useSelector(selectAssetCodeSettings, isEqual)

  const currentSetting = useMemo(() => {
    if (!assetCodeAutoGenerateSettingListData || !currentMonthlyClosing) return null

    return assetCodeAutoGenerateSettingListData.find(setting =>
      checkSettingBelongToMonthlyClosing(setting, currentMonthlyClosing)
    )
  }, [assetCodeAutoGenerateSettingListData, currentMonthlyClosing])

  const handleSubmit = (values: any) => {
    const isSettingForEachPattern = currentSetting?.generating_scope === ASSET_CODE_SETTING_SCOPE_CODE.EACH_PATTERN
    const data = { ...values }

    if (!isSettingForEachPattern) {
      data.whole_office = data.initial_value
    }

    dispatch(
      saveInitialNumberSetting({
        id: currentSetting?.id,
        data,
      })
    )
  }

  const defaultFormValues = useMemo(() => {
    if (!initialNumberSettings || !currentSetting || !assetCodeMasterSettings) return null

    if (currentSetting.generating_scope === ASSET_CODE_SETTING_SCOPE_CODE.WHOLE_OFFICE) {
      return {
        ...initialNumberSettings,
        whole_office: initialNumberSettings.initial_value,
        whole_office_text: getSettingScopeText(assetCodeMasterSettings, ASSET_CODE_SETTING_SCOPE_CODE.WHOLE_OFFICE),
      }
    }
    return {
      asset_category_initial_values: (initialNumberSettings.items || []).map(assetCategoryItem => {
        return {
          ...assetCategoryItem,
          id: assetCategoryItem.asset_category_id,
          initial_value: assetCategoryItem.initial_value,
        }
      }),
    }
  }, [initialNumberSettings, currentSetting, assetCodeMasterSettings])

  useEffect(() => {
    if (currentSetting) {
      dispatch(fetchInitialNumberSetting(currentSetting.id))
    }
  }, [dispatch, currentSetting])

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupInitialNumberSetting())
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    dispatch(setTermYearSelected(currentMonthlyClosing?.from_term_month?.term_id))
  }, [currentMonthlyClosing]) // eslint-disable-line

  const lengthRuleNumber = currentSetting?.length_rule === ASSET_CODE_SETTING_RULE_LENGTH_CODE.FIVE_CHARACTERS ? 5 : 3
  return (
    <Wrapper>
      <BasicForm
        initialValues={defaultFormValues}
        onSubmit={handleSubmit}
        errors={assetCodeAutoGenerateSettingListErrors}
      >
        {currentSetting?.generating_scope === ASSET_CODE_SETTING_SCOPE_CODE.EACH_PATTERN ? (
          <EachPatternForm lengthRuleNumber={lengthRuleNumber} />
        ) : (
          <WholeOfficeForm lengthRuleNumber={lengthRuleNumber} />
        )}
      </BasicForm>
    </Wrapper>
  )
}

export default AssetCodeStartNumberForm
