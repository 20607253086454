import { DATE_FORMAT } from 'constants/app'
import i18n from 'i18n'
import { filter, get, uniq } from 'lodash'
import moment from 'moment'

interface BitemporalModel {
  valid_from: string
  valid_to: string | null
}

export const builtHistoryFormTitle = (data: BitemporalModel) => {
  const fromValue = data.valid_from ? moment(data.valid_from).format(DATE_FORMAT) : i18n.t('common.form_values.unset')
  const toValue = data.valid_to ? moment(data.valid_to).format(DATE_FORMAT) : i18n.t('common.form_values.unset')

  return `${fromValue} - ${toValue}`
}

export const findCorrectDataFromHistories = (histories: any[], currentItem: { biid: string; valid_date: string }) => {
  const filterHistories = filter(histories, history => history.biid === currentItem.biid)
  const selectedHistory = filterHistories.find(historyItem => {
    return (
      currentItem.valid_date >= historyItem.valid_from &&
      (historyItem.valid_to === null || currentItem.valid_date <= historyItem.valid_to)
    )
  })

  return selectedHistory
}

export const getActualBiids = (fixedAssetList: any[], biidKey: 'area_biid' | 'department_biid') => {
  const biids: string[] = []
  fixedAssetList.forEach((fixedAssetItem: any) => {
    biids.push(get(fixedAssetItem, biidKey))
    const hasIncludedChangeSituations = fixedAssetItem.change_situations && fixedAssetItem.change_situations.length > 0
    if (hasIncludedChangeSituations) {
      const changeSituations = [...fixedAssetItem.change_situations]
      changeSituations.forEach((changeSituationItem: any) => {
        biids.push(get(changeSituationItem, biidKey))
      })
    }
  })
  const result = uniq(biids).filter(item => item !== '')
  return result
}
