import find from 'lodash/find'
import last from 'lodash/last'
import { MonthlyClosing } from 'models/monthlyClosing'
import moment, { Moment } from 'moment'

import {
  ConvertDepreciationResultTermMonth,
  ConvertedDepreciationResultItem,
  ConvertedDepreciationResultTermMonthItem,
  DepreciationOfYearResult,
  DepreciationResultItem,
  DepreciationResults,
} from './model'

const hasDifferentDepreciationAmount = (termYear: DepreciationResultItem) => {
  return (
    termYear.book_value_of_tax_annual_depr !== 0 &&
    termYear.end_of_term_book_value !== termYear.book_value_of_tax_annual_depr
  )
}

const getLastDepreciationDate = (depreciationResults: DepreciationResults) => {
  let finishDepreciationDate = ''

  if (depreciationResults.data?.length > 0 && depreciationResults.terms?.length > 0) {
    const lastTermYear = last(depreciationResults.data)

    lastTermYear &&
      lastTermYear.months.forEach((month, index) => {
        const nextMonth = lastTermYear.months[index + 1]
        if (nextMonth && month.depreciation_amount > 0 && nextMonth.depreciation_amount === 0) {
          finishDepreciationDate = month.end_date
        }
      })

    let lastTermYearIndex = depreciationResults.data.length - 1
    if (finishDepreciationDate === '' && depreciationResults.data.length > 1) {
      let actualLastTermYear = { ...last(depreciationResults.data) }

      do {
        lastTermYearIndex -= 1
        actualLastTermYear = depreciationResults.data[lastTermYearIndex]

        // eslint-disable-next-line no-loop-func
        actualLastTermYear?.months?.forEach?.((month, index) => {
          const nextMonth = actualLastTermYear?.months?.[index + 1]
          if (nextMonth && month.depreciation_amount > 0 && nextMonth.depreciation_amount === 0) {
            finishDepreciationDate = month.end_date
          }
        })
      } while (lastTermYearIndex !== 0)
    }
  }

  return finishDepreciationDate
}

const getDividedAt = (dividedAts: string[], startDate: Moment, endDate: Moment) => {
  const dividedAtIndex =
    dividedAts &&
    dividedAts.findIndex((dividedAt: string) => moment(dividedAt).isBetween(startDate, endDate, 'date', '[]'))
  return dividedAtIndex > -1
    ? {
        isDivided: dividedAtIndex > -1,
        dividedAt: dividedAts[dividedAtIndex],
      }
    : {}
}

export const convertDepreciationResults = ({
  depreciationResults,
  currentMonthlyClosing,
  retiredAt,
  soldAt,
  usageStartedAt,
  acquiredAt,
  impairAt,
  isShowComparison,
  isDeclineBalanceMethods,
  dividedAts,
}: {
  depreciationResults: DepreciationResults
  currentMonthlyClosing: MonthlyClosing
  retiredAt: string | null
  soldAt: string | null
  usageStartedAt: string | null
  acquiredAt: string
  impairAt: string | null
  isShowComparison: boolean
  isDeclineBalanceMethods: boolean
  dividedAts: string[] | []
}) => {
  let assetHasBeenRetiredOrSold = false
  let completeDepreciation = false

  const results: ConvertedDepreciationResultItem[] = []
  if (depreciationResults.data?.length > 0 && depreciationResults.terms?.length > 0 && currentMonthlyClosing) {
    const finishDepreciationDate = getLastDepreciationDate(depreciationResults)

    depreciationResults.data.forEach(termYear => {
      const renderMonthsOfTerm = find(depreciationResults.terms, item => item.id === termYear.term_id)

      if (renderMonthsOfTerm) {
        const convertedRenderMonths = renderMonthsOfTerm.term_months.map(item => {
          const targetMonth = find(
            termYear.months,
            monthOfTargetTermYear => monthOfTargetTermYear.term_month_id === item.id
          )

          const converted = {
            ...item,
            hasBeenSoldOrRetired: assetHasBeenRetiredOrSold,
            hasCompletedDepreciation: completeDepreciation,
          }

          if (targetMonth?.is_retired_or_sold && !assetHasBeenRetiredOrSold) {
            assetHasBeenRetiredOrSold = true
          }

          const monthEndDate = moment(item.end_date)
          const lastDepreciationDate = moment(finishDepreciationDate)
          if (monthEndDate.isSame(lastDepreciationDate, 'date') && !completeDepreciation) {
            completeDepreciation = true
          }

          return converted
        }) as ConvertedDepreciationResultTermMonthItem[]

        const convertedDetailMonths = termYear.months.map(termMonth => {
          const targetTermMonth = find(convertedRenderMonths, item => item.id === termMonth.term_month_id)

          const current = moment(currentMonthlyClosing.from_term_month.start_date)
          const startDate = moment(targetTermMonth?.start_date)
          const endDate = moment(targetTermMonth?.end_date)
          const usageStartedAtDate = moment(usageStartedAt)
          const acquiredAtDate = moment(acquiredAt)

          const assetHasBeenAcquired =
            (acquiredAtDate.isBetween(startDate, endDate, 'date', '[]') &&
              usageStartedAtDate.isBetween(startDate, endDate, 'date', '[]') &&
              usageStartedAtDate.isBetween(acquiredAtDate, endDate, 'date', '[]')) ||
            startDate.isAfter(acquiredAtDate)
          const assetAcquiredButNotUsed =
            assetHasBeenAcquired &&
            startDate.isBetween(acquiredAtDate, usageStartedAtDate, 'date', '[]') &&
            endDate.isBetween(acquiredAtDate, usageStartedAtDate, 'date', '[]')

          const isNotDepreciationYet =
            startDate.isBefore(current, 'date') && (!assetHasBeenAcquired || assetAcquiredButNotUsed)
          const hasStartDepreciation = !isNotDepreciationYet
          const isActive =
            current.isBetween(startDate, endDate, 'date', '[]') &&
            !targetTermMonth?.hasBeenSoldOrRetired &&
            !targetTermMonth?.hasCompletedDepreciation
          const monthHasBeenAppliedDepreciation =
            hasStartDepreciation &&
            current.isAfter(startDate, 'date') &&
            current.isAfter(endDate, 'date') &&
            !targetTermMonth?.hasBeenSoldOrRetired &&
            !targetTermMonth?.hasCompletedDepreciation
          const dividedInfo = getDividedAt(dividedAts, startDate, endDate)
          return {
            ...termMonth,
            ...dividedInfo,
            id: `${termMonth.year}-${termMonth.term_month_id}`,
            retiredAt,
            soldAt,
            isActive,
            monthHasBeenAppliedDepreciation,
            hasBeenSoldOrRetired: targetTermMonth?.hasBeenSoldOrRetired,
            hasCompletedDepreciation: targetTermMonth?.hasCompletedDepreciation,
            impairAt,
          }
        }) as ConvertDepreciationResultTermMonth[]

        const months =
          isShowComparison && hasDifferentDepreciationAmount(termYear) && isDeclineBalanceMethods
            ? [
                ...convertedDetailMonths,
                {
                  accumulated_depreciation_amount: termYear.accumulated_recognized_depreciation_amt,
                  adjusted_accumulated_depreciation_amount: termYear.accumulated_recognized_depreciation_amt,
                  end_of_term_book_value: termYear.book_value_of_tax_annual_depr,
                } as DepreciationOfYearResult,
              ]
            : convertedDetailMonths

        results.push({
          ...termYear,
          id: termYear.term_id,
          months: months || [],
          renderMonths: convertedRenderMonths || [],
        })
      }
    })
  }

  return results
}
