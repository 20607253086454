/* eslint-disable jsx-a11y/anchor-is-valid */
import './tooltip.custom.scss'

import { yenWithoutSymbol } from 'aa_common/front-end/helpers/format-helper'
import { Tooltip } from 'antd'
import { ColumnType } from 'antd/es/table'
import i18n from 'i18n'
import { get } from 'lodash'
import {
  AssetCategoryObject,
  AssetObject,
  AssetTypeObject,
  DataTypes,
  DepreciationData,
  MoneyUnits,
} from 'models/depreciation'
import { TermMonth } from 'models/term'
import React from 'react'

import { Link } from './styles'

const parseMoney = (value: number, unit: MoneyUnits) => {
  if (unit === MoneyUnits.THOUSAND_YEN) {
    value = Math.floor(value / 1000)
  }

  return yenWithoutSymbol(value)
}

const createDefaultTableData = (columns: ColumnType<any>[]) => {
  const result: any = {}

  columns.forEach(column => {
    result[column.dataIndex as string] = yenWithoutSymbol(0)
  })

  return result
}

const parseDataForAssetType = (columns: ColumnType<any>[], data: AssetTypeObject[], unit: MoneyUnits) => {
  const result: any = []

  data.forEach((assetType: AssetTypeObject) => {
    const record: any = {
      ...createDefaultTableData(columns),
      key: 'asset-type',
      name: assetType.type,
      total_amount: parseMoney(assetType.total_amount, unit),
      type: DataTypes.ASSET_TYPE,
    }

    assetType.amount_months.forEach(amountMonth => {
      record[amountMonth.term_month_id.toString()] = parseMoney(amountMonth.amount, unit)
    })

    if (assetType.items) {
      record.children = parseDataForAssetCategory(columns, assetType.items, unit)
    }

    result.push(record)
  })

  return result
}

const parseDataForAssetCategory = (columns: ColumnType<any>[], data: AssetCategoryObject[], unit: MoneyUnits) => {
  const result: any = []

  data.forEach(assetCategory => {
    const record: any = {
      ...createDefaultTableData(columns),
      key: `asset-category-${assetCategory.asset_category_code}`,
      name: assetCategory.asset_category_name,
      total_amount: parseMoney(assetCategory.total_amount, unit),
      type: DataTypes.ASSET_CATEGORY,
    }

    assetCategory.amount_months.forEach(amountMonth => {
      record[amountMonth.term_month_id.toString()] = parseMoney(amountMonth.amount, unit)
    })

    if (assetCategory.items) {
      record.children = parseDataForAsset(columns, assetCategory.items, unit)
    }

    result.push(record)
  })

  return result
}

const parseDataForAsset = (columns: ColumnType<any>[], data: AssetObject[], unit: MoneyUnits) => {
  const result: any = []

  data.forEach(asset => {
    const record: any = {
      ...createDefaultTableData(columns),
      key: `asset-${asset.biid}`,
      biid: asset.biid,
      name: `${asset.code}-${asset.branch_code || ''} ${asset.name}`,
      service_life: asset.service_life,
      usage_started_at: asset.usage_started_at,
      depreciation_method: asset.depreciation_method,
      total_amount: parseMoney(asset.total_amount, unit),
      type: DataTypes.ASSET,
    }

    asset.amount_months.forEach(amountMonth => {
      record[amountMonth.term_month_id.toString()] = parseMoney(amountMonth.amount, unit)
    })

    result.push(record)
  })

  return result
}

const builtTooltipText = (asset: AssetObject) => {
  return (
    <div>
      <label>
        {i18n.t('components.DepreciationResultList.start_of_service')}:{' '}
        {asset.usage_started_at || i18n.t('common.form_values.unset')}
      </label>
      <label style={{ marginLeft: 22 }}>
        {i18n.t('components.DepreciationResultList.depreciation_method')}: {get(asset.depreciation_method, 'name_jp')}
      </label>
      <label style={{ marginLeft: 22 }}>
        {i18n.t('components.DepreciationResultList.service_life')}: {asset.service_life}
        {i18n.t('common.units.year')}
      </label>
    </div>
  )
}

export const buildMonthIds = (fromMonth: TermMonth, toMonth: TermMonth) => {
  const monthIds = []

  for (let i = fromMonth.id; i <= toMonth.id; i++) {
    monthIds.push(i)
  }

  return monthIds
}

export const buildColumns = (
  data: DepreciationData,
  onClickAssetName?: any,
  highlightMonths?: number[]
): ColumnType<any>[] => {
  const columns: ColumnType<any>[] = [
    {
      title: '',
      key: 'name',
      dataIndex: 'name',
      width: 280,
      fixed: 'left',
      render: function renderDetail(value: string, record: any) {
        if (record.type === DataTypes.ASSET) {
          return (
            <Tooltip title={builtTooltipText(record)} overlayClassName="app-tooltip">
              <Link onClick={() => onClickAssetName && onClickAssetName(record)}>{`${get(record, 'code') || ''} ${get(
                record,
                'name'
              )}`}</Link>
            </Tooltip>
          )
        }

        return value
      },
    },
  ]

  data.term_months.forEach(termMonth => {
    const { month } = termMonth

    columns.push({
      title: i18n.t('common.format.month', { month }),
      key: `col-${termMonth.id}`,
      dataIndex: `${termMonth.id}`,
      className: highlightMonths && highlightMonths.includes(termMonth.id) ? 'highlight' : '',
      width: 120,
    })
  })

  columns.push({
    title: i18n.t('common.form_values.total'),
    key: 'total_amount',
    dataIndex: 'total_amount',
    width: 120,
    fixed: 'right',
  })

  return columns
}

export const parseDataForReport = (data: DepreciationData, unit: MoneyUnits): any[] => {
  const columns = buildColumns(data)

  return parseDataForAssetType(columns, data.items, unit)
}
