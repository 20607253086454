/* eslint-disable react/react-in-jsx-scope */

import { loadNs } from 'aa_common/front-end/i18n-config'
import { ColumnsType } from 'antd/es/table'
import { OfficeUser } from 'models/officeUser'

const t = loadNs(['common', 'pages/settings/users'])

export const userTableColumns = (userInfo: any): ColumnsType<OfficeUser> => [
  {
    title: t('table.user_name'),
    width: 180,
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => {
      if (record.name === userInfo?.display_name) {
        return (
          <div style={{ display: 'flex' }}>
            {record.name}
            <span style={{ marginLeft: 5 }}>({t('label.its_me')})</span>
          </div>
        )
      }
      return <> {record.name} </>
    },
  },
  {
    title: t('table.email'),
    width: 'auto',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: t('table.role'),
    key: 'role',
    width: 180,
    render: (_, record) => {
      return record.roles?.map(item => item.name).join(', ')
    },
  },
]
