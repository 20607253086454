import { DATE_FORMAT_RFC, DEPRECIATION_METHOD_CODE } from 'aa_common/front-end/constants'
import { Term } from 'aa_common/front-end/models/term'
import moment from 'moment'

export const checkMemorandumValueForCalculate = (depreciationCode: number) => {
  return [
    DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE, // d.method 10
    DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE, // d.method 11
    DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE, // d.method 20
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250, // d.method 21
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200, // d.method 22
    DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE, // d.method 90
  ].includes(depreciationCode)
}

export const validateDividingDate = (
  acquired_at: string | null,
  dividingTime: moment.Moment,
  setTime: any,
  terms?: Term[],
  transitionAt?: string | null
) => {
  const momentAcquiredAt = transitionAt ? moment(transitionAt, DATE_FORMAT_RFC) : moment(acquired_at, DATE_FORMAT_RFC)
  const term = terms?.find(item => item.year === dividingTime?.year())
  const checkTime =
    moment(term?.start_date, DATE_FORMAT_RFC) <= momentAcquiredAt &&
    moment(term?.end_date, DATE_FORMAT_RFC) >= momentAcquiredAt
  if (checkTime) {
    if (!dividingTime.isSame(momentAcquiredAt)) {
      setTime(momentAcquiredAt)
    }
  } else {
    const fiscalYearItem = terms?.find(item => item.year === dividingTime?.year())
    if (fiscalYearItem && !dividingTime.isSame(moment(fiscalYearItem.start_date, DATE_FORMAT_RFC))) {
      setTime(moment(fiscalYearItem.start_date, DATE_FORMAT_RFC))
    }
  }
}

export const validateRangeDisableDividingTime = (current: moment.Moment, terms: Term[], acquired_at: string) => {
  const momentAcquireAt = moment(acquired_at, DATE_FORMAT_RFC)
  const termAcquire = terms.find(
    (item: Term) =>
      moment(item.start_date, DATE_FORMAT_RFC) <= momentAcquireAt &&
      moment(item.end_date, DATE_FORMAT_RFC) >= momentAcquireAt
  )
  return (
    (current && current < moment(termAcquire?.start_date, DATE_FORMAT_RFC)) ||
    (terms && current > moment(terms[terms.length - 1].end_date, DATE_FORMAT_RFC))
  )
}
