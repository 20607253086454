/* eslint-disable @typescript-eslint/no-shadow */
import { LoadingOutlined } from '@ant-design/icons'
import { faPaperclip, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinkLabel } from 'aa_common/front-end/components'
import { Spin } from 'antd'
import classNames from 'classnames'
import { FILE_DOWNLOAD_PATH } from 'constants/app'
import React from 'react'

import { UploadFileProps } from '../../../../../helpers/attachment-helper'
import { UploadItemWrapper } from './styles'

interface UploadItemProps {
  file: UploadFileProps
  actions: {
    download: () => void
    preview: () => void
    remove: () => void
  }
}

const UploadItem = ({ file, actions }: UploadItemProps) => {
  const handleDownload = (file: any): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    window.open(`${FILE_DOWNLOAD_PATH}/${file.uid}`, '_blank')
  }
  return (
    <UploadItemWrapper>
      <div className="label">
        <LinkLabel enableEllipsis disabled={file.status === 'uploading'} onClick={() => handleDownload(file)}>
          <span>
            <FontAwesomeIcon icon={faPaperclip} className="attachment-icon" />
            {file.name ?? file?.filename}
          </span>
        </LinkLabel>
      </div>
      <div className="actions">
        <FontAwesomeIcon
          icon={faTrashAlt}
          className={classNames('remove-icon', { hidden: file.status === 'uploading' })}
          onClick={actions.remove}
        />
        <Spin spinning={file.status === 'uploading'} indicator={<LoadingOutlined style={{ fontSize: 18 }} />} />
      </div>
    </UploadItemWrapper>
  )
}

export default UploadItem
