import { ACC_PLUS_JOURNAL_LINKAGE_STATUS } from 'constants/const'
import { MonthlyClosing } from 'models/monthlyClosing'

import { BaseAccountItem } from './accountItem'
import { TermMonth } from './term'

export type JournalCSVFormat = 'standard' | 'plus' | 'smb'

// Memo: refer to BE codes to know each enum
// https://github.com/moneyforwardvietnam/asset_accounting_backend/blob/develop/app/domain/valueobject/journal_event_type.go
export const JournalEventTypesMap = {
  '1': 'acquisition',
  '2': 'retirement',
  '3': 'sold',
  '4': 'depreciation',
  '5': 'impairment',
} as const

export const JOURNAL_EVENT_TYPE_NAMES = Object.values(JournalEventTypesMap)
export type JournalEventTypeName = (typeof JOURNAL_EVENT_TYPE_NAMES)[0]
export type JournalEventType = keyof typeof JournalEventTypesMap

export type JournalData = {
  id: number
  row_span: number
  journal_num: number
  recognized_at: string
  fixed_asset_biid: string | null
  debit_account_item_name: string
  debit_account_sub_item_name: string
  debit_department_name: string
  debit_business_partner_name: string
  debit_value?: number
  debit_excise_name: string
  credit_account_item_name: string
  credit_account_sub_item_name: string
  credit_department_name: string
  credit_business_partner_name: string
  credit_value?: number
  credit_excise_name: string
  remark: string
  journal_event_type?: JournalEventType
  slip_number?: number
  error_type?: number
  is_reversed_journal: boolean
  isError?: boolean
  hasSubmitResult?: boolean
  submit_response?: string
}

interface BaseJournalItem {
  id: number | null
  key_id: string | number
  from_term_month: TermMonth
  to_term_month: TermMonth
  from_term_month_id: number
  to_term_month_id: number
}

interface AccountItem extends BaseAccountItem {
  is_active?: boolean
}

export interface GeneralJournalSettingItem extends BaseJournalItem {
  automatic_journal_entry_accquisition_type: number
  create_journal_method: number
  depreciation_journal_cycle_type: number
  depreciation_journal_method: number
  depreciation_journal_type: number
  slip_date_type: number
  input_vat: number
  paid_excise_debit_account_item: AccountItem
  paid_excise_debit_account_item_id: number | null
  received_excise_debit_account_item: AccountItem
  received_excise_debit_account_item_id: number | null
  journal_linkage_status: string
}

export interface GeneralJournalSettingItemFormPayload {
  id?: number
  create_journal_method: number
  depreciation_journal_cycle_type: number
  depreciation_journal_method: number
  depreciation_journal_type: number
  slip_date_type: number
  paid_excise_debit_account_item: AccountItem
  paid_excise_debit_account_item_id: number | null
  received_excise_debit_account_item: AccountItem
  received_excise_debit_account_item_id: number | null
  term_year_period: number
  month_id: number
  journal_linkage_status: string
}

export interface GeneralJournalSettingItemSubmitPayload {
  create_journal_method: number
  depreciation_journal_cycle_type: number
  depreciation_journal_method: number
  depreciation_journal_type: number
  slip_date_type: number
  paid_excise_debit_account_item_id: number | null
  received_excise_debit_account_item_id: number | null
  from_term_month_id: number
  journal_linkage_status: string
}

export interface JournalCount {
  acquisition: number
  retirement: number
  sold: number
  depreciation: number
}

export interface JournalLinkageDetailData {
  data: ReadonlyArray<JournalData>
  termMonth: MonthlyClosing | null
  exportNumber: number
  totalFail: number
  summaryErrorType: string
  journalLinkageStatus: ACC_PLUS_JOURNAL_LINKAGE_STATUS | null
  loading?: boolean
}
