import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'aa_common/front-end/antd'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Divider } from 'antd'
import React, { useCallback } from 'react'

import MultiSelectPicker from '../../components/MultiSelectPicker'
import { CoreMultiSelectPickerItem } from '../../components/MultiSelectPicker/model'
import { BaseFormFilter } from '../../model'
import { MultiSelectFormWrapper } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet', 'common'])
interface AssetStatusFormProps extends BaseFormFilter {
  items: CoreMultiSelectPickerItem[]
  name: string
}

const AssetStatusForm = ({ items, name, formModule, setPopupShow, onFormSubmit }: AssetStatusFormProps) => {
  const hasDisableOptions = items.filter(item => item.disabled).length > 0

  const { handleSubmit, getValues } = formModule

  const handleFormSubmit = useCallback(() => handleSubmit(onFormSubmit, onFormSubmit)(), [handleSubmit, onFormSubmit])

  const handleClickSubmit = () => {
    const submitFormValues = {
      [name]: getValues(name),
    }
    formModule.setValue('submitFormValues', submitFormValues)
    setPopupShow(false)
    handleFormSubmit()
  }

  return (
    <MultiSelectFormWrapper hasDisableOptions={hasDisableOptions}>
      {hasDisableOptions && (
        <div className="tooltip-wrapper">
          <Tooltip
            overlayStyle={{ width: 300 }}
            placement="top"
            arrowPointAtCenter
            content={t('asset_status.select_only_today_message')}
          >
            <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 16, color: '7d8290', cursor: 'pointer' }} />
          </Tooltip>
        </div>
      )}
      <div style={{ padding: '0 5px' }}>
        <MultiSelectPicker items={items} name={name} listHeight={200} control={formModule.control} />
      </div>
      <Divider style={{ marginBottom: 0, marginTop: 5 }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 10px', paddingTop: 10 }}>
        <Button type="button" color="blue" onClick={handleClickSubmit}>
          {t('actions.ok')}
        </Button>
      </div>
    </MultiSelectFormWrapper>
  )
}

export default AssetStatusForm
