import React, { PropsWithChildren } from 'react'

import { DownloadIcon } from '../../icons/download-icon'
import { Label, Wrapper } from './styles'

type Props = {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: React.CSSProperties
}

export const DownloadLink: React.FC<PropsWithChildren<Props>> = ({ children, style, onClick }) => {
  return (
    <Wrapper onClick={onClick} style={style}>
      <DownloadIcon />
      <Label>{children}</Label>
    </Wrapper>
  )
}
