export const MFID_STATE_KEY = 'mfidState'
export const MFID_NONCE_KEY = 'mfidNonce'
export const CSRF_TOKEN_KEY = 'mfCSRFToken'
export const MFID_REDIRECT_URI_KEY = 'mfidRedirectUri'
export const LOGIN_FIRST_TIME_KEY = 'loginFirstTime'
export const SSO_TENANT_UID_KEY = 'ssoTenantUid'
export const SSO_REDIRECT_PATH_KEY = 'ssoRedirectPath'

export const BE_JWT_REFRESH_PATH = '/v1/sessions/refresh'
export const FE_SIGNIN_PATH = '/login'
export const FE_ERROR_403_PATH = '/errors/403'
