import { LedgerSetting, LedgerSettingsItem } from 'models/ledgerSetting'

export const selectorLedgerSettingsList = (
  state: any
): {
  loading: boolean
  data: LedgerSettingsItem[]
  errors: any
} => ({
  loading: state.getIn(['settings', 'ledgerSetting', 'list', 'loading']),
  data: state.getIn(['settings', 'ledgerSetting', 'list', 'data']) || [],
  errors: state.getIn(['settings', 'ledgerSetting', 'list', 'errors']),
})

export const selectLedgerSettingList = (state: any): LedgerSetting[] =>
  state.getIn(['settings', 'ledgerSetting', 'list', 'data'])

export const selectHasAccountantAndTaxLedger = (state: any): boolean =>
  state.getIn(['settings', 'ledgerSetting', 'hasAccountantAndTaxLedger'])
