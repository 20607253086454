import { ASSET_CHANGE_SITUATION_TYPES, AssetReport, calculatePaginationTotalPages, loadNs } from 'aa_common/front-end'
import { Checkbox, Radio, RadioChangeEvent } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { Pagination } from 'components/molecules'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMonthlyClosingAssetEvents } from 'store/accounting/monthlyClosing/actions'
import {
  selectMonthlyClosingAssetEventsInCurrent,
  selectMonthlyClosingAssetEventsInPast,
  selectMonthlyClosingCurrent,
} from 'store/accounting/monthlyClosing/selectors'

import { DividingEventView } from '../AssetEventListReport/DividingEventView'
import AcquisitionEventView from './AcquisitionEventView'
import ImpairmentEventView from './ImpairmentEventView'
import MovementEventView from './MovementEventView'
import RetirementEventView from './RetirementEventView'
import SaleEventView from './SaleEventView'
import { Box, BoxHeader, PagingWrapper, Wrapper } from './styles'
import SwitchEventView from './SwitchEventView'

type Props = {
  eventType: ASSET_CHANGE_SITUATION_TYPES
}

const DEFAULT_PAGE_SIZE = 20

const t = loadNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
const MonthlyClosingAssetEventList: React.FC<Props> = ({ eventType }) => {
  const dispatch = useDispatch()
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const assetEventsInCurrent = useSelector(selectMonthlyClosingAssetEventsInCurrent, isEqual)
  const assetEventsInPast = useSelector(selectMonthlyClosingAssetEventsInPast, isEqual)
  const [pageNum, setPageNum] = useState<number>(1)
  const [isNotLinked, setIsNotLinked] = useState<boolean | null>(null)
  const [assetEvents, setAssetEvents] = useState<{ data: AssetReport[]; total: number } | null>(null)
  const [isOccurredInThePast, setIsOccurredInThePast] = useState<boolean>(false)

  const handleChangePage = (value: number) => {
    setPageNum(value)
  }

  const renderedEventList = useMemo(() => {
    if (!assetEvents) return null

    switch (eventType) {
      case ASSET_CHANGE_SITUATION_TYPES.ACQUISITION:
        return <AcquisitionEventView assets={assetEvents.data} />
      case ASSET_CHANGE_SITUATION_TYPES.RETIREMENT:
        return <RetirementEventView assets={assetEvents.data} />
      case ASSET_CHANGE_SITUATION_TYPES.MOVEMENT:
        return <MovementEventView assets={assetEvents.data} />
      case ASSET_CHANGE_SITUATION_TYPES.SELL:
        return <SaleEventView assets={assetEvents.data} />
      case ASSET_CHANGE_SITUATION_TYPES.SWITCH:
        return <SwitchEventView assets={assetEvents.data} />
      case ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT:
        return <ImpairmentEventView assets={assetEvents.data} />
      case ASSET_CHANGE_SITUATION_TYPES.DIVIDING:
        return <DividingEventView assets={assetEvents.data} />
      default:
        return null
    }

    return null
  }, [assetEvents, eventType])

  const handleRadioChanged = (e: RadioChangeEvent) => {
    setIsOccurredInThePast(e.target.value)
  }

  const handleCheckboxChanged = (e: CheckboxChangeEvent) => {
    setIsNotLinked(e.target.checked ? true : null)
  }

  useEffect(() => {
    if (currentMonthlyClosing) {
      dispatch(
        fetchMonthlyClosingAssetEvents({
          monthly_closing_id: currentMonthlyClosing.id,
          change_situation_code: eventType,
          is_notlinked: isNotLinked,
          page_number: pageNum,
          page_size: DEFAULT_PAGE_SIZE,
        })
      )
    }
  }, [dispatch, currentMonthlyClosing, pageNum, eventType, isNotLinked])

  useEffect(() => {
    if (!assetEventsInCurrent) return

    if (isOccurredInThePast) setAssetEvents(assetEventsInPast)
    else setAssetEvents(assetEventsInCurrent)
  }, [assetEventsInCurrent, assetEventsInPast, isOccurredInThePast])

  return (
    <Wrapper>
      {assetEvents && (
        <PagingWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ minWidth: 330 }}>
              {eventType === ASSET_CHANGE_SITUATION_TYPES.ACQUISITION && (
                <Radio.Group onChange={handleRadioChanged} defaultValue={isOccurredInThePast}>
                  <Radio.Button value={false}>
                    {t('acquired_this_month', {
                      amount: assetEventsInCurrent.total,
                    })}
                  </Radio.Button>
                  <Radio.Button value>
                    {t('acquired_in_past', {
                      amount: assetEventsInPast.total,
                    })}
                  </Radio.Button>
                </Radio.Group>
              )}
            </div>
            <Checkbox onChange={handleCheckboxChanged} style={{ marginLeft: 20 }}>
              {t('only_unlink_journal')}
            </Checkbox>
          </div>
          <Pagination
            name="fixed-asset"
            totalPages={calculatePaginationTotalPages(assetEvents.total, DEFAULT_PAGE_SIZE) || 0}
            totalRecords={assetEvents.total || 0}
            onPageChanged={handleChangePage}
          />
        </PagingWrapper>
      )}
      <Box>
        <BoxHeader>
          <div className="status">{t('journal_linkage')}</div>
          <div className="photo">{t('photo')}</div>
          <div className="asset-code">{t('asset_code')}</div>
          <div className="asset-name">{t('asset_name')}</div>
        </BoxHeader>
        {renderedEventList}
      </Box>
    </Wrapper>
  )
}

export default MonthlyClosingAssetEventList
