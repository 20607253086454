import { Button } from 'aa_common/front-end/antd'
import { CONFIRM_TYPES, RightDrawer } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import Form from 'components/molecules/Form'
import useModal from 'hooks/useModalHook'
import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { submitImpairment } from 'store/asset/actions'
import { selectAssetDetailPage } from 'store/asset/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectorLedgerSettingsList } from 'store/settings/ledgerSetting/selectors'

import FormContent from './Form'
import { convertSubmitPayload } from './helper'
import { ModalClass } from './styles'
import SubmitButton from './SubmitButton'

const t = loadNs(['components/organisms/assets/asset-impairment', 'common'])

const { Footer } = Form
const defaultValues = {
  impaired_at: null,
  event_cause_id: null,
  event_attachments: [],
  event_photos: [],
  event_urls: [],
  impairment_method: null,
}

interface AssetImpairmentFormProps {
  errors?: any
  isShow: boolean
  setShowForm: (isShow: boolean) => void
}

const AssetImpairmentForm = ({ errors, isShow, setShowForm }: AssetImpairmentFormProps) => {
  const { loading: isLedgersLoading } = useSelector(selectorLedgerSettingsList, isEqual)
  const { asset, itemHistoryLoading } = useSelector(selectAssetDetailPage, isEqual)
  const { biid } = useParams<{ biid: string }>()

  const dispatch = useDispatch()

  const modal = useModal()

  useEffect(() => {
    isShow && dispatch(fetchLedgerSettingsList())
  }, [isShow, dispatch])
  const handleSubmit = (value: any) => {
    const onSubmit = () => {
      const payload = convertSubmitPayload(value)
      if (biid) {
        return Promise.resolve(dispatch(submitImpairment(biid, payload, true))).then(() => {
          setShowForm(false)
        })
      }
    }

    return Promise.resolve(
      !!asset.usage_started_at ||
        modal.confirm({
          title: t('no_usage_start_confirm_title'),
          message: t('no_usage_start_confirm_message'),
          type: CONFIRM_TYPES.CONFIRM,
        })
    ).then(isConfirmSubmit => {
      if (isConfirmSubmit) {
        return onSubmit()
      }
    })
  }

  return (
    <RightDrawer
      title={t('impairment')}
      className={ModalClass}
      visible={isShow}
      destroyOnClose
      onClose={() => setShowForm(false)}
      width={544}
    >
      <Form
        value={defaultValues}
        mode="onChange"
        errors={errors}
        loading={isLedgersLoading || itemHistoryLoading}
        onFormSubmit={handleSubmit}
      >
        <FormContent />
        <Footer>
          <div className="footer-form">
            <Button color="grey" onClick={() => setShowForm(false)}>
              {t('actions.cancel')}
            </Button>
            <SubmitButton>{t('actions.register')}</SubmitButton>
          </div>
        </Footer>
      </Form>
    </RightDrawer>
  )
}

export default AssetImpairmentForm
