import { LinkLabel } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import AccountingPeriodEditForm from 'components/organisms/settings/AccountingPeriodEditForm'
import AccountingPeriodForm from 'components/organisms/settings/AccountingPeriodForm'
import { MainTemplate } from 'components/templates'
import { get } from 'lodash'
import { FORM_MODES } from 'models/form'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { cleanupAsset, fetchAssetList } from 'store/asset/actions'
import {
  cleanupTermPeriods,
  cleanupTerms,
  createTermPeriodsRequest,
  fetchChangedTermPeriods,
  fetchTermPeriods,
  fetchTerms,
  generateTermPeriods,
  updateTermPeriodRequest,
} from 'store/settings/accountingPeriod/actions'

const t = loadNs(['common', 'components/organisms/main-menu'])

type Props = {
  terms: any[]
  term: any
  termPeriods: any
  errors?: any[]
  assets: any
  loading: boolean
  currentTermId: number | null
  fetchTerms: typeof fetchTerms
  fetchTermPeriods: typeof fetchTermPeriods
  fetchChangedTermPeriods: typeof fetchChangedTermPeriods
  generateTermPeriods: typeof generateTermPeriods
  createTermPeriodsRequest: typeof createTermPeriodsRequest
  cleanupTermPeriods: typeof cleanupTermPeriods
  cleanupTerms: typeof cleanupTerms
  fetchAssetList: typeof fetchAssetList
  cleanupAsset: typeof cleanupAsset
}

const AccountingPeriodPage: React.FC<Props> = ({
  terms,
  term,
  termPeriods,
  errors,
  assets,
  loading,
  currentTermId,
  fetchTerms,
  fetchTermPeriods,
  fetchChangedTermPeriods,
  generateTermPeriods,
  createTermPeriodsRequest,
  cleanupTermPeriods,
  cleanupTerms,
  fetchAssetList,
  cleanupAsset,
}) => {
  const [mode, setMode] = useState<FORM_MODES | null>(null)
  const [hasAssets, setHasAssets] = useState<boolean | null>(null)
  const dispatch = useDispatch()

  const handleFormValuesChanged = (values: any) => {
    if (get(values, 'start_date')) {
      generateTermPeriods(values)
    } else {
      termPeriods && cleanupTermPeriods()
    }
  }

  const handleSubmit = (values: any) => {
    mode === FORM_MODES.RESET ? createTermPeriodsRequest({ ...values, force: true }) : createTermPeriodsRequest(values)
  }

  const handleSubmitEdit = (values: any) => {
    const thunk = dispatch(updateTermPeriodRequest(values))
    Promise.resolve(thunk).then(() => handleCancel())
  }

  const handleChangeToResetMode = () => {
    setMode(FORM_MODES.RESET)
  }

  const handleCancel = () => {
    setMode(FORM_MODES.VIEW)
    currentTermId && fetchTermPeriods(currentTermId)
  }

  const handleSetMode = () => {
    setMode(FORM_MODES.EDIT)
  }

  const cancelLink = (
    <LinkLabel onClick={handleCancel} style={{ fontSize: 13, paddingRight: '15px' }}>
      {t('actions.cancel')}
    </LinkLabel>
  )

  const breadcrumb = (
    <BreadcrumbNavigation
      sectionName={t('setting.accounting_period')}
      customBackElement={[FORM_MODES.EDIT, FORM_MODES.RESET].includes(mode as FORM_MODES) ? cancelLink : undefined}
    />
  )

  const renderForm = () => {
    if (mode === null) return null

    if (mode === FORM_MODES.VIEW || mode === FORM_MODES.EDIT) {
      return (
        <AccountingPeriodEditForm
          terms={terms}
          termDetail={term}
          formMode={mode}
          errors={errors}
          hasAssets={hasAssets}
          loading={loading}
          onChangeModeToReset={handleChangeToResetMode}
          onSubmit={handleSubmitEdit}
          fetchTermPeriods={fetchTermPeriods}
          fetchChangedTermPeriods={fetchChangedTermPeriods}
          mode={mode}
          handleSetMode={handleSetMode}
        />
      )
    }
    return (
      <AccountingPeriodForm
        defaultFormValues={terms && terms[0]}
        values={termPeriods}
        errors={errors}
        loading={loading}
        isResetMode={mode === FORM_MODES.RESET}
        onValuesChanged={handleFormValuesChanged}
        onSubmit={handleSubmit}
      />
    )
  }

  useEffect(() => {
    if (!terms) {
      setMode(null)
      return
    }

    terms.length === 0 ? setMode(FORM_MODES.NEW) : setMode(FORM_MODES.VIEW)
  }, [terms]) // eslint-disable-line

  useEffect(() => {
    if (!assets) return

    const isHavingAssets = assets.length > 0
    setHasAssets(isHavingAssets)
  }, [assets])

  useEffect(() => {
    fetchTerms()
    fetchAssetList({}, 1, 1)

    return function cleanup() {
      cleanupTerms()
      cleanupAsset()
    }
  }, []) // eslint-disable-line

  return <MainTemplate breadcrumb={breadcrumb}>{renderForm()}</MainTemplate>
}

const mapStateToProps = (state: Readonly<any>) => ({
  terms: state.getIn(['settings', 'accountingPeriod', 'terms', 'data']),
  termPeriods: state.getIn(['settings', 'accountingPeriod', 'termPeriods', 'data']) || null,
  term: state.getIn(['settings', 'accountingPeriod', 'term', 'data']) || null,
  errors: state.getIn(['settings', 'accountingPeriod', 'termPeriods', 'errors']) || null,
  loading: state.getIn(['settings', 'accountingPeriod', 'termPeriods', 'loading']),
  currentTermId: state.getIn(['settings', 'accountingPeriod', 'currentTermId']),
  assets: state.getIn(['assets', 'list', 'data']) || null,
})

const mapDispatchToProps = {
  fetchTerms,
  fetchTermPeriods,
  fetchChangedTermPeriods,
  generateTermPeriods,
  createTermPeriodsRequest,
  fetchAssetList,
  cleanupTermPeriods,
  cleanupTerms,
  cleanupAsset,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingPeriodPage)
