import { JournalLinkageDetailData } from 'models/JournalData'

export const selectJournalList = (state: any) => {
  return {
    data: state.getIn(['accounting', 'journal', 'list', 'data']),
    errors: state.getIn(['accounting', 'journal', 'list', 'errors']),
    total: state.getIn(['accounting', 'journal', 'list', 'total']),
  }
}

export const selectJournalLinkage = (state: any): JournalLinkageDetailData => {
  return state.getIn(['accounting', 'journal', 'journalLinkageDetail'])
}

export const selectCountOfJournalEvents = (state: any) => state.getIn(['accounting', 'journal', 'countOfJournalEvents'])

export const selectorJournalSettingsForAssetCategoryList = (state: any) => {
  return {
    data: state.getIn(['accounting', 'journal', 'assetCategory', 'list', 'data']),
    loading: state.getIn(['accounting', 'journal', 'assetCategory', 'list', 'loading']),
  }
}

export const selectorSelectedAssetCategoryJournalSettingTerms = (state: any) => {
  return {
    data: state.getIn(['accounting', 'journal', 'assetCategory', 'selectedItem', 'data']),
    loading: state.getIn(['accounting', 'journal', 'assetCategory', 'selectedItem', 'loading']),
  }
}

export const selectorGeneralJournalSettingTerms = (state: any) => {
  return {
    data: state.getIn(['accounting', 'journal', 'generalSettings', 'terms', 'data']),
    loading: state.getIn(['accounting', 'journal', 'generalSettings', 'terms', 'loading']),
  }
}

export const selectJournalLinkageStatus = (state: any) => {
  return {
    data: state.getIn(['accounting', 'journal', 'journalLinkageStatus', 'data']),
    errors: state.getIn(['accounting', 'journal', 'journalLinkageStatus', 'errors']),
  }
}

export const selectSendJournalLoading = (state: any) => {
  return state.getIn(['accounting', 'journal', 'sendJournalLoading'])
}

export const selectSendJournalResult = (state: any) => {
  return state.getIn(['accounting', 'journal', 'journalLinkageSendResult'])
}
