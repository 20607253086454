import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { UserForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import { convertToUserFormPayload } from 'converters/settings/officeUser'
import { isEqual } from 'lodash'
import { FORM_MODES } from 'models/form'
import { UserFormPayload } from 'models/user'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  assignRolesOfficeUser,
  fetchOfficeUserItem,
  fetchUserRoleOptions,
  resetOfficeUser,
} from 'store/settings/officeUser/actions'
import { selectorOfficeUserItem, selectorOfficeUserRoleOptions } from 'store/settings/officeUser/selectors'

const t = loadNs(['pages/settings/users'])

const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('edit_user')} />

const UserEditPage = () => {
  const { id }: any = useParams()

  const dispatch = useDispatch()

  const roleOptionsSelector = useSelector(selectorOfficeUserRoleOptions, isEqual)
  const officeUserItemSelector = useSelector(selectorOfficeUserItem, isEqual)

  useEffect(() => {
    dispatch(fetchOfficeUserItem(id))
    dispatch(fetchUserRoleOptions())

    return () => {
      dispatch(resetOfficeUser())
    }
  }, [id]) // eslint-disable-line

  const handleSubmit = useCallback(
    (formValues: UserFormPayload) => {
      const { user_id, role_biids } = formValues
      const payload = { user_id, role_biids }
      dispatch(assignRolesOfficeUser(payload))
    },
    [dispatch]
  )

  const formValues = useMemo(() => convertToUserFormPayload(officeUserItemSelector.data), [officeUserItemSelector.data])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <UserForm
        values={formValues}
        errors={officeUserItemSelector.errors}
        onSubmit={handleSubmit}
        type={FORM_MODES.EDIT}
        roles={roleOptionsSelector.data}
        loading={roleOptionsSelector.loading || officeUserItemSelector.loading}
      />
    </MainTemplate>
  )
}

export default UserEditPage
