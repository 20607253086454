import { MasterDataService } from 'aa_common/front-end/open-api'
import { initAsyncSlice } from 'aa_common/front-end/zustand'

import { parseDataMaster } from '../store/masterData/helper'

const getMasterData = async () => {
  const data: any = await MasterDataService.masterDataList()
  const result = parseDataMaster(data.data)
  return {
    data: result,
  }
}
export const masterDataSlice = initAsyncSlice('share/master-data', {}, {}, getMasterData)
