import { ExciseSetting } from 'models/exciseSettings'

export const selectExciseSettingListData = (state: any): ExciseSetting[] => {
  return state.getIn(['settings', 'exciseSetting', 'list', 'data'])
}

export const selectExciseSettingData = (state: any): ExciseSetting => {
  return state.getIn(['settings', 'exciseSetting', 'item', 'data'])
}

export const selectExciseSettingLoadingState = (state: any) => {
  return state.getIn(['settings', 'exciseSetting', 'item', 'loading'])
}
