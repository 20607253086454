import { Term } from 'models/term'

import { TermsListSelector } from './select.model'

export const selectTermsList = (state: any): TermsListSelector => {
  return {
    data: state.getIn(['settings', 'accountingPeriod', 'terms', 'data']),
    loading: state.getIn(['settings', 'accountingPeriod', 'terms', 'loading']),
  }
}

export const selectFullTermsList = (state: any): TermsListSelector => {
  return {
    data: state.getIn(['settings', 'accountingPeriod', 'fullTerms', 'data']),
    loading: state.getIn(['settings', 'accountingPeriod', 'fullTerms', 'loading']),
  }
}

export const selectTermsData = (state: any): Term[] => {
  return state.getIn(['settings', 'accountingPeriod', 'terms', 'data'])
}

export const selectTermData = (state: any) => {
  return {
    data: state.getIn(['settings', 'accountingPeriod', 'term', 'data']),
    loading: state.getIn(['settings', 'accountingPeriod', 'term', 'loading']),
  }
}

export const selectCurrentTerm = (state: any): number => {
  return state.getIn(['settings', 'accountingPeriod', 'currentTermId'])
}
