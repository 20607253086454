import { FEATURES_ID } from 'aa_common/front-end/constants'
import { getCurrentJournalSettingsItem } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { LineAddNew } from 'components/atoms'
import useUserPermission from 'lib/hooks/useUserPermission'
import isEqual from 'lodash/isEqual'
import VirtualList from 'rc-virtual-list'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'

import ListItem from './ListItem'
import { ModalAutoJournalSettingListClass } from './styles'

interface ModalAutoJournalSettingsList {
  items: any[]
  visible: boolean
  isDetailMode: boolean
  isFormMode: boolean
  onAddNewClick: () => void
  onModifyClick: (item: any) => void
  onDeleteClick: (item: any) => void
}

const t = loadNs(['common'], 'actions')
const ModalAutoJournalSettingList = ({
  items,
  visible,
  isDetailMode,
  isFormMode,
  onAddNewClick,
  onModifyClick,
  onDeleteClick,
}: ModalAutoJournalSettingsList) => {
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const { permissions } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)
  const currentSettingItem = useMemo(() => {
    if (currentMonthlyClosing && items && items.length > 0) {
      const targetItem = getCurrentJournalSettingsItem(items, currentMonthlyClosing)
      return targetItem
    }
    return null
  }, [currentMonthlyClosing, items])

  return (
    <div style={{ marginTop: 10 }}>
      {isDetailMode && (
        <LineAddNew
          showTooltipMessage={!permissions.isCRU ? t('no_permission') : undefined}
          disabled={!permissions.isCRU || isFormMode}
          onClick={onAddNewClick}
        />
      )}
      <VirtualList
        data={items}
        className={ModalAutoJournalSettingListClass}
        style={{
          display: visible ? 'block' : 'none',
          minHeight: 500,
          marginTop: isDetailMode ? 5 : 0,
          maxHeight: 750,
          overflowY: 'auto',
          overflowAnchor: 'none',
          borderRadius: 4,
        }}
        itemHeight={30}
        itemKey="id"
      >
        {(item, _, props) => {
          return (
            <ListItem
              item={item}
              isDetailMode={isDetailMode}
              currentSettingItem={currentSettingItem}
              onModifyClick={onModifyClick}
              onDeleteClick={onDeleteClick}
              {...props}
            />
          )
        }}
      </VirtualList>
    </div>
  )
}

export default ModalAutoJournalSettingList
