import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Typography } from 'antd'
import { getIn, useFormikContext } from 'formik'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { loadNs } from '../../i18n-config'
import { Wrapper, WrapperReset } from './styles'

const { Text } = Typography
const t = loadNs('common')

export const UnderlineInput = ({
  name,
  placeholder,
  type = 'text',
  style,
  editable = false,
  isResetManualValue,
  onHandleClickOnReset,
  max,
  min = 0,
  className,
  ellipsis,
}: PropsWithChildren<Props>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { values, setFieldValue } = useFormikContext()
  const [mode, setMode] = useState<'view' | 'edit'>('view')
  const isViewMode = mode === 'view'
  const fieldValue = getValueWithDefault(getIn(values, name), type)
  const [value, setValue] = useState(fieldValue)
  const [previousValue, setPreviousValue] = useState(fieldValue)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = parseInt(e.target.value, 10)
    if (currentValue < min) {
      setValue(min)
    } else if (max && currentValue > max) {
      setValue(max)
    } else {
      setValue(getValueWithDefault(currentValue, type))
    }
  }

  const handleKeyPress = (event: any) => {
    event.key === 'Enter' && setMode('view')
  }

  const handleBlur = () => {
    setMode('view')

    if (previousValue === value) return

    const newValue = getValueWithDefault(value, type)

    setFieldValue(name, newValue)
    setPreviousValue(newValue)
  }

  const handleClickOnReset = () => {
    onHandleClickOnReset && onHandleClickOnReset()
  }

  useEffect(() => {
    if (!isViewMode && inputRef) {
      inputRef.current?.focus()
    }
  }, [mode, isViewMode])

  useEffect(() => {
    setValue(getValueWithDefault(fieldValue, type))
    setPreviousValue(fieldValue)
  }, [fieldValue]) // eslint-disable-line

  return (
    <Wrapper className={className}>
      {isViewMode || !editable ? (
        <Text className="value-view" ellipsis={ellipsis ? { tooltip: value } : false}>
          {value !== '' ? value : placeholder}
        </Text>
      ) : (
        <input
          value={value}
          name={name}
          onChange={handleChange}
          style={style}
          placeholder={placeholder}
          autoComplete="off"
          disabled={!editable || isViewMode}
          onBlur={handleBlur}
          ref={inputRef}
          onKeyPress={handleKeyPress}
          type={type}
        />
      )}
      {editable && isViewMode && (
        <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" onClick={() => setMode('edit')} />
      )}
      {isResetManualValue && (
        <WrapperReset>
          <span className="reset-label">{t('label.reset_manual_value')}</span>
          <Button className="btn-reset" type="link" onClick={handleClickOnReset}>
            {t('actions.reset')}
          </Button>
        </WrapperReset>
      )}
    </Wrapper>
  )
}

const getValueWithDefault = (value: string | number, type: 'number' | 'text', min?: number) => {
  const defaultValue = min || 0
  if (type === 'number') return value ? parseInt(value as string, 10) : defaultValue
  return value || ''
}

type Props = {
  name: string
  placeholder?: string
  type?: 'number' | 'text'
  style?: React.CSSProperties
  editable?: boolean
  isResetManualValue?: boolean
  onHandleClickOnReset?: () => void
  max?: number
  min?: number
  className?: string
  ellipsis?: boolean
}
