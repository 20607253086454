import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import AssetCategoryForm from 'components/organisms/settings/AssetCategoryForm'
import { MainTemplate } from 'components/templates'
import { FORM_MODES } from 'models/form'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cleanupAssetCategory, createAssetCategory } from 'store/settings/assetCategory/actions'

const t = loadNs(['pages/settings/asset-categories'])

const AssetCategoryNewPage = () => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('add')} />
  const dispatch = useDispatch()

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupAssetCategory())
    }
  }, []) // eslint-disable-line

  const handleSubmit = useCallback(
    (values: any) => {
      dispatch(createAssetCategory(values))
    },
    [dispatch]
  )

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <AssetCategoryForm onSubmit={handleSubmit} type={FORM_MODES.NEW} />
    </MainTemplate>
  )
}

export default AssetCategoryNewPage
