export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST'
export const FETCH_TAGS_REQUEST_AUTH = 'FETCH_TAGS_REQUEST_AUTH'
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS'
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE'

export const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST'
export const UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST'
export const SUBMIT_TAG_SUCCESS = 'SUBMIT_TAG_SUCCESS'
export const SUBMIT_TAG_FAILURE = 'SUBMIT_TAG_FAILURE'
export const HANDLE_SUBMIT_TAG_FAILURE = 'HANDLE_SUBMIT_TAG_FAILURE'

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE'

export const FETCH_TAG_REQUEST = 'FETCH_TAG_REQUEST'
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS'
export const FETCH_TAG_FAILURE = 'FETCH_TAG_FAILURE'

export const UPDATE_TAG_ORDER_REQUEST = 'UPDATE_TAG_ORDER_REQUEST'
export const UPDATE_TAG_ORDER_SUCCESS = 'UPDATE_TAG_ORDER_SUCCESS'
export const UPDATE_TAG_ORDER_FAILURE = 'UPDATE_TAG_ORDER_FAILURE'

export const CLEANUP_TAG = 'CLEANUP_TAG'
