import { ReactComponent as CheckCircleIcon } from 'assets/images/icons/check-circle.svg'
import { ReactComponent as ExclamationCircleIcon } from 'assets/images/icons/exclamation-circle.svg'
import { ReactComponent as RedirectLinkIcon } from 'assets/images/icons/link.svg'
import { AccountingPlusLink } from 'components/atoms'
import useAntMessageInstance from 'hooks/useAntMessageHook'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearAppMessage } from 'store/app/actions'
import { selectAppMessage } from 'store/app/selectors'

import { FailureIcon, LinkContent, SuccessIcon } from './styles'

const TopMessage = () => {
  const dispatch = useDispatch()
  const messageAnt = useAntMessageInstance()
  const appMessage = useSelector(selectAppMessage)
  const { type, content, path, label } = appMessage || {}
  const messageSuccessContent = content && (
    <>
      <label>{content}</label>
      {path && (
        <AccountingPlusLink path={path}>
          <LinkContent>
            <RedirectLinkIcon />
            {label}
          </LinkContent>
        </AccountingPlusLink>
      )}
    </>
  )

  useEffect(() => {
    if (content) {
      switch (type) {
        case 'success':
          messageAnt.success({
            content: messageSuccessContent,
            icon: <SuccessIcon component={CheckCircleIcon} />,
            onClose: dispatch(clearAppMessage()),
          })
          break
        case 'failure':
          messageAnt.error({
            content,
            icon: <FailureIcon component={ExclamationCircleIcon} />,
            onClose: dispatch(clearAppMessage()),
          })
          break
        default:
          break
      }
    }
  }, [clearAppMessage, content]) // eslint-disable-line

  return null
}

export default TopMessage
