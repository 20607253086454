import { BasicTable, CONFIRM_TYPES } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { NoDataImage } from 'components/atoms'
import { FEATURES_ID } from 'constants/const'
import useModal from 'hooks/useModalHook'
import useUserPermission from 'lib/hooks/useUserPermission'
import { get } from 'lodash'
import { Tag } from 'models/tag'
import React, { useCallback, useMemo } from 'react'

import { EmptyImage, Wrapper } from './styles'

const t = loadNs(['common', 'components/organisms/settings/tag-list'])

const getColumns = () => [{ field: 'name', text: t('name'), sortable: false }]
export const TAG_LIST_COLUMN_WIDTH = ['auto', 90]

type TagListProps = {
  tags: Tag[]
  loading?: boolean
  onDelete?: (item: Tag) => void
  onEdit?: (item: Tag) => void
  onRowFinishDragging?: (item: any) => void
}

const TagList = ({ tags, loading, onEdit, onDelete, onRowFinishDragging }: TagListProps) => {
  const modal = useModal()
  const { loading: permissionLoading, permissions: tagPermission } = useUserPermission(FEATURES_ID.MASTER_TAGS)

  const handleClickDelete = useCallback(
    (item: any) => {
      modal
        .confirm({
          title: '',
          message: t('messages.confirm_delete', { name: get(item, 'name') }),
          type: CONFIRM_TYPES.DELETE,
        })
        .then(isOKClick => {
          if (isOKClick) {
            onDelete && onDelete(item)
          }
        })
    },
    [onDelete]
  ) //eslint-disable-line

  const disableActions = useMemo(
    () => ({
      edit: () => !tagPermission.isEdit,
      delete: () => !tagPermission.isDelete,
    }),
    [tagPermission]
  )

  const showActionsTooltip = useMemo(
    () => ({
      edit: () => {
        return !tagPermission.isEdit ? t('actions.no_permission') : undefined
      },
      delete: () => {
        return !tagPermission.isDelete ? t('actions.no_permission') : undefined
      },
    }),
    [tagPermission]
  )

  return (
    <Wrapper>
      {tags && tags.length === 0 ? (
        <EmptyImage>
          <NoDataImage text={t('msg_no_data')} />
        </EmptyImage>
      ) : (
        <BasicTable
          columns={getColumns()}
          columnsWidth={TAG_LIST_COLUMN_WIDTH}
          data={tags}
          onEdit={onEdit}
          onDelete={handleClickDelete}
          disableDragRow={!tagPermission.isEdit || permissionLoading}
          draggableTooltipMessage={!tagPermission.isEdit ? t('actions.no_permission') : undefined}
          disableActions={disableActions}
          showActionsTooltip={showActionsTooltip}
          draggable
          loading={loading || permissionLoading}
          onRowFinishDragging={onRowFinishDragging}
        />
      )}
    </Wrapper>
  )
}

export default TagList
