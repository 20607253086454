import { UnderlineInput } from 'aa_common/front-end/antd'
import { UnborderCollapse } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { TooltipIcon } from 'components/atoms'
import { DEPRECIATION_METHOD_CODE } from 'constants/masterData'
import { useFormikContext } from 'formik'
import parser from 'html-react-parser'
import { get } from 'lodash'
import React, { useMemo } from 'react'
import { checkIsAfterDate, checkTheSameDate } from 'utils/DateTimeHelper'

import { Error, preLineStyle } from './styles'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form'])

const OLD_DEPRECIATION_METHOD_CODES = [
  DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE,
  DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE,
]

function isOldDMethod(dMethod: DEPRECIATION_METHOD_CODE) {
  return OLD_DEPRECIATION_METHOD_CODES.includes(dMethod)
}

function shouldRender(dMethod: DEPRECIATION_METHOD_CODE, usageStartedAt: string, transitionAt: string) {
  if (!transitionAt) return false
  if (!usageStartedAt) return false
  if (checkTheSameDate(usageStartedAt, transitionAt)) return false
  if (checkIsAfterDate(usageStartedAt, transitionAt)) return false

  return [
    ...OLD_DEPRECIATION_METHOD_CODES,
    DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD,
    DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
    DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
    DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT,
  ].includes(dMethod)
}

type Props = {
  name: string
  dMethodCode: any
  usagePeriodMonthsField: string
  usageStartedAt: string
  transitionAt: string
  serviceLifeField: string
  ledgerName: string
  disabled?: boolean
  isCopied?: boolean
}

const DepreciatedMonthsInput = ({
  name,
  dMethodCode,
  usagePeriodMonthsField,
  usageStartedAt,
  transitionAt,
  serviceLifeField,
  ledgerName,
  isCopied,
  disabled,
}: Props) => {
  const { values, errors, setFieldValue } = useFormikContext()

  const itemProps = useMemo(() => {
    return isOldDMethod(dMethodCode)
      ? {
          jsLabel: t('deprecated_months_60_of', { name: ledgerName }),
          label: (
            <>
              <label style={{ marginRight: 2 }}>{t('deprecated_months_60')}</label>
              <TooltipIcon overlayStyle={{ maxWidth: 800 }}>{parser(t('deprecated_months_60_tooltip'))}</TooltipIcon>
            </>
          ),
        }
      : {
          jsLabel: t('deprecated_months_of', { name: ledgerName }),
          label: (
            <>
              <label style={{ marginRight: 5 }}>{t('deprecated_months')}</label>
              <TooltipIcon overlayStyle={{ maxWidth: 600 }}>{parser(t('deprecated_months_tooltip'))}</TooltipIcon>
            </>
          ),
        }
  }, [dMethodCode, ledgerName])
  const maxValue = useMemo(() => {
    if (isOldDMethod(dMethodCode)) {
      return 60
    }
    if (dMethodCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD) {
      return 36
    }

    if (dMethodCode === DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT) {
      return get(values, serviceLifeField) * 12
    }

    return get(values, usagePeriodMonthsField)
  }, [dMethodCode, values, usagePeriodMonthsField, serviceLifeField])

  if (!shouldRender(dMethodCode, usageStartedAt, transitionAt)) return null

  return (
    <Item {...itemProps} style={preLineStyle} required>
      <UnderlineInput
        name={name}
        editable={!disabled && !isCopied}
        type="number"
        min={0}
        max={maxValue}
        isResetManualValue={!disabled && !isCopied && get(values, name) !== 0}
        onHandleClickOnReset={() => setFieldValue(name, 0)}
      />
      <Error>{get(errors, name)}</Error>
    </Item>
  )
}

export default DepreciatedMonthsInput
