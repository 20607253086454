import { Map } from 'immutable'

import { UnitActionTypes } from './action.model'
import {
  CHANGE_UNIT_POSITION_FAILURE,
  CHANGE_UNIT_POSITION_REQUEST,
  CHANGE_UNIT_POSITION_SUCCESS,
  CLEANUP_UNIT,
  CREATE_UNIT_REQUEST,
  DELETE_UNIT_FAILURE,
  DELETE_UNIT_REQUEST,
  DELETE_UNIT_SUCCESS,
  FETCH_UNIT_FAILURE,
  FETCH_UNIT_REQUEST,
  FETCH_UNIT_SUCCESS,
  FETCH_UNITS_FAILURE,
  FETCH_UNITS_REQUEST,
  FETCH_UNITS_REQUEST_AUTH,
  FETCH_UNITS_SUCCESS,
  SUBMIT_UNIT_FAILURE,
  SUBMIT_UNIT_SUCCESS,
  UPDATE_UNIT_REQUEST,
} from './constant'

const initialState = Map({
  list: Map({
    data: [],
    loading: false,
  }),
  item: Map({
    data: null,
    errors: null,
    loading: false,
  }),
  delete: Map({
    loading: false,
    errors: null,
  }),
})

export default (state = initialState, action: UnitActionTypes) => {
  switch (action.type) {
    case FETCH_UNITS_REQUEST:
    case FETCH_UNITS_REQUEST_AUTH:
      return state.setIn(['list', 'loading'], true)
    case FETCH_UNITS_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_UNITS_FAILURE:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], [])
    case FETCH_UNIT_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_UNIT_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'data'], action.payload)
    case FETCH_UNIT_FAILURE:
      return state.setIn(['item', 'loading'], false)
    case CREATE_UNIT_REQUEST:
    case UPDATE_UNIT_REQUEST:
      return state.setIn(['item', 'loading'], true).setIn(['item', 'errors'], null)
    case SUBMIT_UNIT_SUCCESS:
      return state.setIn(['item', 'loading'], false)
    case SUBMIT_UNIT_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], action.formErrors)
    case DELETE_UNIT_REQUEST:
      return state.setIn(['delete', 'loading'], true)
    case DELETE_UNIT_SUCCESS:
      return state.setIn(['delete', 'loading'], false)
    case DELETE_UNIT_FAILURE:
      return state.setIn(['delete', 'loading'], false).setIn(['delete', 'errors'], action.payload)
    case CHANGE_UNIT_POSITION_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case CHANGE_UNIT_POSITION_SUCCESS:
      return state.setIn(['list', 'loading'], false)
    case CHANGE_UNIT_POSITION_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case CLEANUP_UNIT:
      return initialState
    default:
      return state
  }
}
