import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import UnitSubmitForm from 'components/organisms/settings/UnitSubmitForm'
import { MainTemplate } from 'components/templates'
import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cleanupUnit, fetchUnitRequest, updateUnitRequest } from 'store/settings/unit/actions'

const t = loadNs(['pages/settings/units'])

type Props = {
  errors: Array<any>
  data: Record<string, any>
  loading: boolean
  cleanupUnit: () => void
  fetchUnitRequest: (id: number) => void
  updateUnitRequest: (id: number, data: any) => void
}

export const convertUnitEditFormPayload = (data: any) => {
  if (!data) {
    return null
  }

  return {
    name: data.name,
    link_memorandum_value_quantity: data.link_memorandum_value_quantity,
  }
}

const UnitEdit = ({ errors, data, loading, fetchUnitRequest, updateUnitRequest, cleanupUnit }: Props) => {
  const { id } = useParams<any>()

  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('edit')} />

  useEffect(() => {
    fetchUnitRequest(id)
    return cleanupUnit
  }, [id, fetchUnitRequest, cleanupUnit])

  const handleSubmit = (values: Record<string, any>) => {
    updateUnitRequest(id, values)
  }

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <UnitSubmitForm
        loading={loading}
        errors={errors}
        values={useMemo(() => convertUnitEditFormPayload(data), [data])}
        onSubmit={handleSubmit}
      />
    </MainTemplate>
  )
}

const mapStateToProps = (state: Readonly<any>) => ({
  data: state.getIn(['settings', 'unit', 'item', 'data']),
  errors: state.getIn(['settings', 'unit', 'item', 'errors']),
  loading: state.getIn(['settings', 'unit', 'item', 'loading']),
})

const mapDispatchToProps = {
  cleanupUnit,
  fetchUnitRequest,
  updateUnitRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitEdit)
