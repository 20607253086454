import { Button, messageSuccess, Spin } from 'aa_common/front-end/antd'
import { BottomAction, ErrorsList } from 'aa_common/front-end/components'
import { hasRetroactiveAssets } from 'aa_common/front-end/helpers'
import { getNs } from 'aa_common/front-end/i18n-config'
import { FixedAssetEventsService } from 'aa_common/front-end/open-api'
import { Table } from 'antd'
import useRetroactiveAssetModal from 'components/templates/modal/RetroactiveAssetModal'
import { isEmpty, isEqual } from 'lodash'
import { Asset } from 'models/asset'
import { IMPORT_EXPORT_EVENT_TYPES } from 'pages/assets/AssetListPage/import-export-event-type-selector'
import { importExportEventTypeSelectorSlice } from 'pages/assets/AssetListPage/store'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { CLEANUP_ASSET_IMPORT, CREATE_BULK_ASSET_LIST_REQUEST, setEditAssetItem } from 'store/assetImport/actions'
import { selectAssetImportListData, selectAssetImportListErrors } from 'store/assetImport/selectors'

import { useLongPolling } from '../../../../hooks/useLongPolling'
import { importBulkChangeAction, importBulkChangeSlice } from '../../assets/AssetImportForm/store'
import { columns, getDetailError, parseDataForTable } from './helper'
import { useImportTables } from './hooks'
import { NoteContainer, Wrapper } from './styles'

const { t, useNsReady } = getNs(['common', 'components/organisms/settings/setting-import-result-list'])
const SettingImportResultList: React.FC = () => {
  useNsReady()
  const dispatch = useDispatch()
  const importList = useSelector<any, Asset[]>(selectAssetImportListData, isEqual)
  const errorList = useSelector(selectAssetImportListErrors, isEqual)
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const store = importBulkChangeSlice.useSlice()
  const { importEventType } = importExportEventTypeSelectorSlice.useSlice()

  const { importBulkChangeType, data, errorBiids, errors } = store

  const bulkChangeData = useImportTables(importBulkChangeType, data?.events)
  const {
    location: { pathname, key: locationKey },
    push,
    goBack,
  } = useHistory()

  const confirmModal = useRetroactiveAssetModal()

  const handleRowClick = (record: any, index: number) => {
    dispatch(setEditAssetItem(importList[index]))
  }

  const onError = (errorBiids: string[]) => {
    importBulkChangeAction.setErrorBiids(errorBiids)
  }

  const onSuccess = () => {
    push('/assets')
    messageSuccess(t('messages.imported_bulk_change_success'))
  }

  const importCsvBulkchange = useLongPolling('importCsvBulkchange', { onError, onSuccess })
  const bulkChangeEventPolling = async () => {
    try {
      const res: any = await FixedAssetEventsService.eventsCreate({
        file_url: data?.upload_url,
        event_type_code: importBulkChangeType,
      })
      importCsvBulkchange.setJobId(res?.data?.id)
    } catch (e) {
      /* empty */
    }
  }

  const handleSubmit = () => {
    importBulkChangeAction.clearErrors()
    const createBulk = () => {
      importBulkChangeType ? bulkChangeEventPolling() : dispatch({ type: CREATE_BULK_ASSET_LIST_REQUEST })
    }

    if (!importBulkChangeType && hasRetroactiveAssets(importList, currentMonthlyClosing)) {
      // Open the retroactive asset warning modal
      confirmModal.open({
        onOKClick: () => createBulk(),
      })
    } else {
      // No retroactive asset in the list
      createBulk()
    }
  }

  const isEmptyImportList = isEmpty(importList)

  useEffect(() => {
    if (isEmptyImportList && !importBulkChangeType) {
      const previousUrl = pathname.substring(0, pathname.lastIndexOf('/'))
      // if can't go back we will using the previousUrl, eg: /assets/import/results -> /assets/import
      locationKey ? goBack() : push(previousUrl)
    }
    return function cleanup() {
      dispatch({ type: CLEANUP_ASSET_IMPORT })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    return () => {
      importBulkChangeAction.reset()
    }
  }, [])

  const bulkChangeImportDataSource = useMemo(() => {
    return bulkChangeData?.dataTable?.dataSource.map((item: any) => ({
      ...item,
      isError: errorBiids.includes(item.biid),
    }))
  }, [errorBiids])

  const { errorDetails, errorLines } = getDetailError(errors)

  const isImportForBulkChange = useMemo(() => {
    return importEventType !== null && importEventType !== IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER
  }, [importEventType])

  const hasError: boolean = !!(errorList || !!errorDetails.length)

  const isStickColumn = IMPORT_EXPORT_EVENT_TYPES.SALE || IMPORT_EXPORT_EVENT_TYPES.RETIREMENT

  return (
    <Spin loading={importCsvBulkchange.loading}>
      <Wrapper>
        <NoteContainer>{t(isImportForBulkChange ? 'bulk_change_import_notice_message' : 'notice_msg')}</NoteContainer>
        {hasError ? (
          <ErrorsList
            title={t('error_header')}
            errors={errorList || errorDetails}
            style={{ marginTop: 10, maxHeight: 150, overflowY: 'auto' }}
          />
        ) : null}
        <div>
          {isImportForBulkChange ? (
            <Table
              style={isStickColumn ? { marginTop: 10, width: 'calc(100vw - 180px)' } : { marginTop: 10 }}
              columns={bulkChangeData?.dataTable?.columns || []}
              dataSource={bulkChangeImportDataSource}
              pagination={false}
              scroll={isStickColumn ? { x: true } : {}}
              onRow={(record: any, rowIndex) => ({
                // rowIndex + 1 because the error line is 1-based
                className: record.isError || errorLines.includes((rowIndex as number) + 1) ? 'error' : '',
              })}
            />
          ) : (
            <Table
              style={{ marginTop: 10 }}
              columns={columns}
              dataSource={parseDataForTable(importList) || []}
              pagination={false}
              onRow={(record: any, rowIndex: any) => {
                return {
                  className: record.is_error ? 'error' : '',
                  onClick: () => {
                    handleRowClick(record, rowIndex)
                  }, // click row
                }
              }}
            />
          )}
        </div>
        <BottomAction id="submit-bottom" style={{ justifyContent: 'flex-end', background: 'rgba(255, 255, 255, 0.8)' }}>
          <Button disabled={hasError} data-testid="submit-import-btn" style={{ height: 42 }} onClick={handleSubmit}>
            {t('actions.register')}
          </Button>
        </BottomAction>
      </Wrapper>
    </Spin>
  )
}

export default SettingImportResultList
