import { QuestionCircleFilled } from '@ant-design/icons'
import { Tooltip as TooltipAnt } from 'antd'
import React, { CSSProperties } from 'react'

type Props = {
  overlayStyle?: React.CSSProperties
}

const TooltipIcon: React.FC<Props> = ({ children, overlayStyle }) => {
  return (
    <TooltipAnt
      title={children}
      placement="topLeft"
      arrowPointAtCenter
      overlayStyle={{ ...defaultOverlayStyle, ...overlayStyle }}
      overlayInnerStyle={overlayInnerStyle as CSSProperties}
    >
      <QuestionCircleFilled style={{ cursor: 'pointer' }} />
    </TooltipAnt>
  )
}

const defaultOverlayStyle = {
  fontSize: '13px',
}

const overlayInnerStyle = {
  whiteSpace: 'pre-line',
}

export default TooltipIcon
