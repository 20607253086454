import styled from '@emotion/styled'

import { GREY_BORDER_COLOR } from 'constants/styles'

export const Wrapper = styled.div`
  font-size: 13px;
`

export const Notice = styled.div`
  font-weight: 400;
  white-space: pre-wrap;
  margin-bottom: 15px;
`

export const BoxWrapper = styled.div`
  background-color: #ffffff;
  width: 495px;
  padding: 10px 15px;
  border: 1px solid ${GREY_BORDER_COLOR};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FieldLabel = styled.label`
  font-weight: 500;
`
