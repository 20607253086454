import { BasicForm } from 'components/molecules'
import { FORM_MODES } from 'models/form'
import { Term } from 'models/term'
import React from 'react'
import { fetchChangedTermPeriods, fetchTermPeriods } from 'store/settings/accountingPeriod/actions'

import Form from './Form'
import { Wrapper } from './style'

type Props = {
  terms: Term[]
  termDetail: Term
  loading?: boolean
  formMode: FORM_MODES
  errors: any
  hasAssets: boolean | null
  onChangeModeToReset: () => void
  onSubmit: (values: any) => void
  fetchTermPeriods: typeof fetchTermPeriods
  fetchChangedTermPeriods: typeof fetchChangedTermPeriods
  mode?: string
  handleSetMode?: () => void
}

const { SubmitButton } = BasicForm

const initialValues: any = {
  term_id: null,
  term_months: [],
  month_serial_number: null,
  lack_of_month_setting: 0,
  start_date: null,
  end_date: null,
}

const AccountingPeriodEditForm: React.FC<Props> = ({
  terms,
  termDetail,
  loading = false,
  formMode,
  errors,
  hasAssets,
  fetchTermPeriods,
  fetchChangedTermPeriods,
  onSubmit,
  onChangeModeToReset,
  mode,
  handleSetMode,
}) => {
  return (
    <Wrapper>
      <BasicForm
        initialValues={initialValues}
        errors={errors}
        onSubmit={onSubmit}
        loading={loading}
        preventSubmitOnEnter
      >
        <Form
          terms={terms}
          termDetail={termDetail}
          hasAssets={hasAssets}
          formMode={formMode}
          fetchTermPeriods={fetchTermPeriods}
          fetchChangedTermPeriods={fetchChangedTermPeriods}
          onChangeModeToReset={onChangeModeToReset}
          mode={mode}
          handleSetMode={handleSetMode}
        />
        {formMode === FORM_MODES.EDIT && <SubmitButton />}
      </BasicForm>
    </Wrapper>
  )
}

export default AccountingPeriodEditForm
