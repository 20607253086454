/* eslint-disable no-restricted-globals, no-restricted-syntax */
import {
  API_STATUS_CODE,
  Asset,
  AssetLedger,
  AssetSingleViewQueryParams,
  convertAttachmentsSubmitPayload,
  convertPhotosSubmitPayload,
  DATE_FORMAT_RFC,
  messageFn,
  Permission,
} from 'aa_common/front-end'
import { submitDividingAsset } from 'api/app/asset'
import { AssetDividingForm } from 'containers/assets'
import { AssetDividingFormProps } from 'containers/assets/dividing-form/schema'
import { get, isEqual } from 'lodash'
import { LedgerSettingsItem } from 'models/ledgerSetting'
import moment from 'moment'
import { RegisterType } from 'pages/assets/AssetDetailPage/schema'
import queryString from 'query-string'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAssetByValidAt } from 'store/asset/actions'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'
import { parseError, parseValidationError } from 'utils/ResponseHelper'

import { parseValueForm } from './helper'

interface AssetDividingProps {
  asset: Asset
  errors?: any
  causes: any[]
  isShow: boolean
  permissions: Permission
  onHandleReloadCauses?: () => void
  setShowForm: (isShow: boolean) => void
  setRegisterEvent: (registerType: string) => void
  setIsShowConfirmMessage: (value: boolean) => void
}

export const DividingForm = (props: AssetDividingProps) => {
  const { asset, setRegisterEvent, setIsShowConfirmMessage, setShowForm } = props
  const code = get(asset, 'code')
  const branchCode = get(asset, 'branch_code')

  const ledgerSettings = useMemo(() => {
    return asset.fixed_asset_ledgers?.map(function (item) {
      return item.ledger_setting
    }) as LedgerSettingsItem[]
  }, [asset.id])
  const dispatch = useDispatch()

  const getLedgersData = useCallback(() => {
    return asset?.fixed_asset_ledgers?.map(({ ledger_setting_id, depreciation_method_code }: AssetLedger) => ({
      ledger_setting_id,
      memorandum_value: 0,
      residual_amount: 0,
      depreciation_method_code,
    }))
  }, [asset])

  const defaultValues: AssetDividingFormProps = {
    divided_at: null,
    event_cause_id: null,
    divided_asset_code: `${code}-${branchCode}`,
    new_code: code,
    new_branch_code: '',
    divided_asset_name: get(asset, 'name'),
    new_asset_name: get(asset, 'name'),
    divided_asset_name_kana: get(asset, 'name_kana'),
    new_asset_name_kana: get(asset, 'name_kana'),
    divided_acquisition_cost: null,
    new_acquisition_cost: null,
    divided_excise_amount: null,
    new_excise_amount: null,
    divided_acquisition_cost_tax_included: null,
    new_acquisition_cost_tax_included: null,
    new_quantity: 0,
    divided_quantity: +get(asset, 'quantity'),
    new_memorandum_value: 0,
    divided_memorandum_value: get(asset, 'fixed_asset_ledgers[0].memorandum_value'),
    new_residual_amount: 0,
    divided_residual_amount: get(asset, 'fixed_asset_ledgers[0].residual_amount'),
    event_photos: null,
    event_attachments: null,
    event_urls: null,
    ledgers: getLedgersData(),
  }

  const terms = useSelector(selectTermsData, isEqual)
  const exciseSettingsByDateData = useSelector(getExciseSettingsByDateList, isEqual)
  const [errorResponse, setErrorResponse] = useState<any>({})
  const [valuesForm] = useState<AssetDividingFormProps>(defaultValues)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const exciseTaxMethod = get(exciseSettingsByDateData, 'data.tax_method')

  const handleSubmit = (values: AssetDividingFormProps) => {
    setIsLoading(true)
    const data = {
      divided_at: moment(values.divided_at).format(DATE_FORMAT_RFC),
      event_cause_id: values.event_cause_id,
      new_asset: {
        ...parseValueForm(
          ['code', 'branch_code', 'acquisition_cost', 'excise_amount', 'acquisition_cost_tax_included', 'quantity'],
          values,
          'new_'
        ),
        name: get(values, 'new_asset_name'),
        name_kana: get(values, 'new_asset_name_kana'),
        event_urls: values.event_urls,
        event_photos: values.event_photos ? convertPhotosSubmitPayload(values.event_photos) : null,
        event_attachments: values.event_attachments ? convertAttachmentsSubmitPayload(values.event_attachments) : null,
        ledgers: get(values, 'ledgers'),
      },
    }

    submitDividingAsset(String(asset.biid), data)
      .then(() => {
        setRegisterEvent(RegisterType.DIVIDED)
        const { valid_at } = queryString.parse(location.search) as AssetSingleViewQueryParams

        dispatch(fetchAssetByValidAt(String(asset.biid), valid_at, true, true))

        setIsShowConfirmMessage(true)
        setIsLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        const responseError = get(e, 'response')
        const errorFields = ['acquisition_cost_included_excise', 'new_asset_name_kana']

        if (responseError?.status === API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
          const error = parseValidationError(get(responseError, 'data.errors'), { isDividingEvent: true })

          for (const field of errorFields) {
            if (field in error) {
              messageFn().error(error[field])
            }
          }
          setErrorResponse(error)
        } else {
          const errors = parseError(e)?.errors
          errors.length > 0 &&
            errors.forEach((err: any) => {
              messageFn().error(err?.detail)
            })
        }
        setIsLoading(false)
      })
  }

  return (
    <AssetDividingForm
      {...props}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      defaultValues={valuesForm}
      ledgerSettings={ledgerSettings}
      errors={errorResponse}
      terms={terms}
      exciseTaxMethod={exciseTaxMethod}
    />
  )
}
