import { yen } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { AssetLedger } from 'aa_common/front-end/models/asset'
import { get } from 'lodash'
import React from 'react'

import { AmortizationTable } from '../styles'

interface ITableTemplate {
  ledger: AssetLedger | null
  depreciationRate: any
}
const t = loadNs(['components/organisms/assets/asset-detail'])

const TableTemplate = ({ ledger, depreciationRate }: ITableTemplate) => {
  return (
    <AmortizationTable>
      <tbody>
        <tr>
          <th>{t('depreciation_type')}</th>
          <td>
            <span>{get(ledger, 'depreciation_method.name_jp', 'ー')}</span>
          </td>

          <th>{t('residual_value')}</th>
          <td>
            <span>{ledger?.residual_amount ? yen(ledger?.residual_amount) : 'ー'}</span>
          </td>
        </tr>
        <tr>
          <th>{t('service_life')}</th>
          <td>
            <span>{`${t('service_life_amount', { amount: ledger?.service_life })}`}</span>
          </td>

          <th>{t('maximum_depreciation_amount')}</th>
          <td>
            <span>ー</span>
          </td>
        </tr>
        <tr>
          <th>{t('depreciation_rate')}</th>
          <td>
            <span>{`${depreciationRate || 'ー'}`}</span>
          </td>
          <th>{t('memorandum')}</th>
          <td>
            <span>{ledger?.memorandum_value ? yen(ledger?.memorandum_value) : 'ー'}</span>
          </td>
        </tr>
      </tbody>
    </AmortizationTable>
  )
}

export default TableTemplate
