import { Role } from 'models/role'

import { FetchUserRolesFailure, FetchUserRolesRequest, FetchUserRolesSuccess, UpdateRoleCall } from './action.model'
import {
  CLEANUP_ROLES,
  CREATE_NEW_ROLE_CALL,
  DELETE_ROLE_CALL,
  FETCH_ROLE_DETAIL,
  FETCH_ROLE_SAMPLE,
  FETCH_USER_ROLES_FAILURE,
  FETCH_USER_ROLES_REQUEST,
  FETCH_USER_ROLES_SUCCESS,
  HANDLE_FETCH_ROLE_DETAIL,
  SET_LOADING_ITEM,
  UPDATE_ROLE_CALL,
} from './constant'

export const fetchUserRoles = (): FetchUserRolesRequest => ({
  type: FETCH_USER_ROLES_REQUEST,
})

export const handleFetchUserRolesSuccess = (payload: Role[]): FetchUserRolesSuccess => ({
  type: FETCH_USER_ROLES_SUCCESS,
  payload,
})

export const handleFetchUserRolesFail = (): FetchUserRolesFailure => ({
  type: FETCH_USER_ROLES_FAILURE,
})

export const cleanupRoles = () => ({ type: CLEANUP_ROLES })

export const setLoadingItem = (payload: boolean) => ({
  type: SET_LOADING_ITEM,
  payload,
})

export const fetchRoleSample = () => ({
  type: FETCH_ROLE_SAMPLE,
})

export const fetchRoleDetail = (biid: string) => ({
  type: FETCH_ROLE_DETAIL,
  payload: biid,
})

export const handleFetchRoleDetail = (data: Role | null) => ({
  type: HANDLE_FETCH_ROLE_DETAIL,
  payload: data,
})

export const createNewRoleCall = (data: Role) => ({
  type: CREATE_NEW_ROLE_CALL,
  payload: data,
})

export const updateRoleCall = (biid: string, postData: Role): UpdateRoleCall => ({
  type: UPDATE_ROLE_CALL,
  payload: {
    biid,
    postData,
  },
})

export const deleteRoleCall = (biid: string) => ({
  type: DELETE_ROLE_CALL,
  payload: biid,
})
