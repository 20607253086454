import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { RenderFormProps } from 'components/molecules/Form'
import React from 'react'

import { Empty, List, ListItem } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet', 'common'])

interface ServiceLifeContentProps {
  formModule: RenderFormProps
  formState: any
  onFormSubmit: (event: any) => void
}

const ServiceLifeContent = ({ formModule, formState, onFormSubmit }: ServiceLifeContentProps) => {
  const { service_life } = formState
  const { handleSubmit } = formModule

  const handleFormSubmit = () => handleSubmit(onFormSubmit, onFormSubmit)()

  const handleClick = (name: string) => {
    formModule.setValue(name, null)
    const submitFormValues = {
      [name]: null,
    }
    formModule.setValue('submitFormValues', submitFormValues)
    handleFormSubmit()
  }

  return !service_life ? (
    <Empty>{t('empty')}</Empty>
  ) : (
    <List>
      {service_life && (
        <ListItem>
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ marginRight: 6, cursor: 'pointer' }}
            onClick={() => handleClick('service_life')}
          />
          <span>{`${service_life[0]} ${t('units.year')} ~ ${service_life[1]} ${t('units.year')}`}</span>
        </ListItem>
      )}
    </List>
  )
}

export default ServiceLifeContent
