import { loadNs } from 'aa_common/front-end/i18n-config'
import { forEach } from 'lodash'

const t = loadNs(['components/organisms/settings/cause-list'])

export const convertTypeToJPName = (masterData: any[], causes: any[]) => {
  const masterDataMap: any = {}
  forEach(masterData, item => {
    masterDataMap[item.code] = item.name_jp
  })

  return causes.map(item => {
    return {
      type_name: masterDataMap[item.type],
      ...item,
    }
  })
}

export const columns = () => {
  return [
    { field: 'code', text: t('code'), sortable: false },
    { field: 'type_name', text: t('type'), sortable: false },
    { field: 'content', text: t('content'), sortable: false },
    { field: 'search_key', text: t('search_key'), sortable: false },
  ]
}

export const CAUSE_LIST_COLUMNS_WIDTH = [120, 223, 120, 112]
