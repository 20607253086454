import { loadNs } from 'aa_common/front-end/i18n-config'
import { AssetLedger } from 'aa_common/front-end/models/asset'
import { get } from 'lodash'
import React from 'react'

import { AmortizationTable } from '../styles'

interface NoDepreciationTableProps {
  ledger: AssetLedger | null
}
const t = loadNs(['components/organisms/assets/asset-detail'])

const NoDepreciationTable = ({ ledger }: NoDepreciationTableProps) => {
  return (
    <AmortizationTable>
      <colgroup>
        <col style={{ width: 160 }} />
        <col style={{ width: 274 }} />
        <col style={{ width: 160 }} />
        <col style={{ width: 274 }} />
      </colgroup>

      <tbody>
        <tr>
          <th>{t('depreciation_type')}</th>
          <td colSpan={3}>
            <span>{get(ledger, 'depreciation_method.name_jp', 'ー')}</span>
          </td>
        </tr>
      </tbody>
    </AmortizationTable>
  )
}

export default NoDepreciationTable
