import { CORPORATE_INCOME_TAX } from 'aa_common/front-end/constants'
import get from 'lodash/get'

import { detailReportSlice } from '../../corporate-income-tax-report-container/store'
import { FormData } from '../common-report/form-data'
import { getPeriod, getReportGrid } from '../common-report/helper'
import { SvgFormWrapper } from '../common-report/styles'
import reportBackground from './background.svg'

export const ReportForm = () => {
  const cellWidth = 85
  const yCoordinate = 89
  const heightPerRow: number[] = [
    41, 41, 41, 41, 41, 41, 41, 52, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 48, 41, 41, 45,
  ]

  const xCoordinates: number[] = [326, 410, 497, 584, 670]

  const xCoordinatesPeriod: number[] = [420, 476, 500]
  const yCoordinatesPeriod: number[] = [44, 63]
  const reportData = detailReportSlice.useSlice(state => state?.data)
  const propsData = {
    reportData: getReportGrid(
      heightPerRow,
      cellWidth,
      xCoordinates,
      yCoordinate,
      reportData,
      CORPORATE_INCOME_TAX.TABLE_16_TYPE_7
    ),
    reportPeriods: getPeriod(reportData, xCoordinatesPeriod, yCoordinatesPeriod),
    companyName: { x: 584, y: 40, w: 170, h: 48, value: get(reportData, 'office_name', '') },
    reportBackground,
  }

  return (
    <SvgFormWrapper>
      <FormData {...propsData} />
    </SvgFormWrapper>
  )
}
