import {
  ASSET_EVENT_FIELD,
  AssetReport,
  formatDate,
  formatDateTime,
  identifyJournalStatusType,
  loadNs,
  objectToQueryParams,
  renderAssetFields,
  yen,
} from 'aa_common/front-end'
import { FileLink, NoImage, PhotoView, ReferenceLink } from 'components/atoms'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cleanupMonthlyClosingAssetEvents } from 'store/accounting/monthlyClosing/actions'
import { changeLocation } from 'store/app/actions'

import JournalStatus from './JournalStatus'
import { AssetDisplay, AssetDisplayDataRow, AssetDisplayHeader } from './styles'

type Props = {
  assets: AssetReport[]
}

const t = loadNs(['common', 'components/organisms/accounting/monthly-closing-asset-event-list'])
const renderAssetPhoto = (asset: AssetReport) => {
  const { thumbnail_url } = asset?.photos?.[0] || {}
  return !thumbnail_url ? <NoImage /> : <img src={thumbnail_url} alt="" />
}

const ImpairmentEventView = ({ assets }: Props) => {
  const dispatch = useDispatch()

  const handleAssetClick = useCallback(
    (asset: AssetReport) => {
      const params = objectToQueryParams({ valid_at: asset.impaired_at })
      dispatch(changeLocation(`/assets/${asset.biid}?${params}`))
    },
    [dispatch]
  )

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupMonthlyClosingAssetEvents())
    }
  }, [dispatch])

  return (
    <>
      {assets?.map(asset => (
        <AssetDisplay key={asset.id} onClick={() => handleAssetClick(asset)}>
          <AssetDisplayHeader>
            <div className="status">
              <JournalStatus type={identifyJournalStatusType(asset)} />
            </div>
            <div className="photo">{renderAssetPhoto(asset)}</div>
            <div className="asset-code">
              {asset.code}-{asset?.branch_code}
            </div>
            <div className="asset-name">{asset.name}</div>
          </AssetDisplayHeader>
          <AssetDisplayDataRow>
            <div className="short-label">{asset.impaired_at}</div>
            <div className="short-label">{t('impairment')}</div>
            <div className="label">{t('impaired_register_date')}</div>
            <div className="value">{formatDateTime(asset.created_at || '')}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('impairment_cause')}</div>
            <div className="value">{asset.event_cause?.content}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('acquisition_date')}</div>
            <div className="value">{formatDate(asset.acquired_at)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('service_start_date')}</div>
            <div className="value">{formatDate(asset.usage_started_at)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">
              {asset?.acquisition_cost_excluded_excise === asset?.acquisition_cost
                ? t('acquisition_cost_exclude')
                : t('acquisition_cost_include')}
            </div>
            <div className="value">{yen(asset.acquisition_cost)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('accumulated_depreciation')}</div>
            <div className="value">
              {renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.ACCUMULATED_DEPRECIATION)}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('impairment_amount')}</div>
            <div className="value">{yen(asset.impaired_amount)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('accumulated_impairment_amount')}</div>
            <div className="value">{yen(asset.accumulated_impaired_amount)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('impairment_remain_book_value')}</div>
            <div className="value">{yen(asset.remaining_book_value)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('department')}</div>
            <div className="value">{asset.department?.name}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('area')}</div>
            <div className="value">{asset.area?.name}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('photo')}</div>
            <div className="value">
              {asset?.event_photos?.map?.(photo => (
                <div key={photo.id}>
                  <PhotoView src={photo?.url} style={{ marginRight: 10 }} />
                </div>
              ))}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('attachment')}</div>
            <div className="value" style={{ display: 'block' }}>
              {asset?.event_attachments?.map?.(attachment => (
                <div key={attachment.id}>
                  <FileLink file={attachment} />
                </div>
              ))}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('reference_link')}</div>
            <div className="value" style={{ display: 'block' }}>
              {asset?.event_urls?.map?.((url: any) => (
                <div key={url}>
                  <ReferenceLink url={url} />
                </div>
              ))}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow className="last">
            <div className="label">{t('impair_by')}</div>
            <div className="value">{asset.user_info?.display_name}</div>
          </AssetDisplayDataRow>
        </AssetDisplay>
      ))}
    </>
  )
}

export default ImpairmentEventView
