import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { DatePicker as DatePickerAnt } from 'antd'
import { DATE_FORMAT, DATE_FORMAT_RFC } from 'constants/app'
import { getIn, useFormikContext } from 'formik'
import i18n from 'i18n'
import { get } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Pending, Wrapper } from './styles'

type Props = {
  name: string
  placeholder?: string
  dateFormat?: string
  style?: React.CSSProperties
  unsetable?: boolean
  allowClear?: boolean
  minDateField?: string
  maxDateField?: string
  wrapperStyle?: string
  disabled?: boolean
  afterChanged?: (value: Moment | null) => void
  className?: string
}

const FULL_YEAR_MONTH_DATE_FORMAT = 'YYYYMMDD'
const YEAR_MONTH_DATE_FORMAT = 'YYMMDD'
const MONTH_DATE_FORMAT = 'MMDD'
const DAY_FORMAT = 'DD'

const DatePicker: React.FC<Props> = ({
  name,
  placeholder,
  dateFormat = DATE_FORMAT,
  unsetable = false,
  style,
  allowClear = true,
  minDateField = null,
  maxDateField = null,
  disabled = false,
  wrapperStyle = '',
  afterChanged,
  className,
}) => {
  const { errors, values, setFieldValue } = useFormikContext()
  const [value, setValue] = useState<any>(null)
  const refContainer = useRef(null)
  const error = get(errors, name)
  const minDateValue = minDateField ? get(values, minDateField) : null
  const maxDateValue = maxDateField ? get(values, maxDateField) : null
  const fieldValue = getIn(values, name)

  const handleChanged = (newValue: Moment | null): void => {
    if (newValue === null) {
      setFieldValue(name, null)
    } else {
      setFieldValue(name, newValue.format(DATE_FORMAT_RFC))
    }

    afterChanged && afterChanged(newValue)
  }

  const handleEdit = () => {
    let currentDate = moment()

    if (minDateValue) {
      let minValue = minDateValue

      if (typeof minValue === 'string') minValue = moment(minValue)
      if (currentDate < minValue) currentDate = minValue
    }

    if (maxDateValue) {
      let maxValue = maxDateValue
      if (typeof maxValue === 'string') maxValue = moment(maxValue)
      if (currentDate > maxValue) currentDate = maxValue
    }

    setValue(currentDate)
    setFieldValue(name, currentDate.format(DATE_FORMAT_RFC))
  }

  const disabledDate = useCallback(
    (current: Moment) => {
      if (minDateValue) {
        let minValue = minDateValue
        if (typeof minValue === 'string') minValue = moment(minValue)
        if (current < minValue) return true
      }

      if (maxDateValue) {
        let maxValue = maxDateValue
        if (typeof maxValue === 'string') maxValue = moment(maxValue)
        return current.format(DATE_FORMAT_RFC) > maxValue.format(DATE_FORMAT_RFC)
      }

      return false
    },
    [minDateValue, maxDateValue]
  )

  useEffect(() => {
    if (fieldValue !== value) {
      if (fieldValue && typeof fieldValue !== 'string') {
        setFieldValue(name, fieldValue.format(DATE_FORMAT_RFC))
      }

      setValue(fieldValue)
    }
  }, [fieldValue]) // eslint-disable-line

  return useMemo(() => {
    return (
      <Wrapper ref={refContainer} className={wrapperStyle}>
        {(!unsetable || (unsetable && value)) && (
          <DatePickerAnt
            format={[dateFormat, FULL_YEAR_MONTH_DATE_FORMAT, YEAR_MONTH_DATE_FORMAT, MONTH_DATE_FORMAT, DAY_FORMAT]}
            placeholder={placeholder}
            onChange={handleChanged}
            value={typeof value === 'string' ? moment(value) : value}
            className={className || (error ? 'error' : undefined)}
            style={style}
            allowClear={allowClear}
            disabledDate={minDateField || maxDateField ? disabledDate : undefined}
            disabled={disabled}
            getPopupContainer={() => refContainer.current as any}
          />
        )}

        {unsetable && !value && (
          <Pending>
            {i18n.t('common.form_values.unset')}
            <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" onClick={handleEdit} />
          </Pending>
        )}

        {error && <CommonErrorMsg>{error}</CommonErrorMsg>}
      </Wrapper>
    )
  }, [value, error, minDateValue, maxDateValue, handleChanged]) // eslint-disable-line
}

export default DatePicker
