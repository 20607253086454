import { Button, UnderlineInput } from 'aa_common/front-end/antd'
import {
  RedirectToMasterPageLink,
  RightArrowIcon,
  UnborderCollapse,
  VerticalLine,
} from 'aa_common/front-end/components'
import { handlePermissionByFeatureId } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import * as assetCategoryApi from 'api/app/assetCategory'
import { Input } from 'components/atoms'
import { AutoCompleteSelect, DatePicker, MediaUploader } from 'components/molecules'
import { FEATURES_ID } from 'constants/const'
import { ENDPOINTS } from 'constants/endpoints'
import { useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { selectUserPermission } from 'store/session/selectors'

import { validateForm, ValueFields } from './helpers'
import { FormFields, LongItem } from './styles'

const t = loadNs(['components/organisms/assets/switch-category-form', 'common'])

const { Item, Actions } = UnborderCollapse
const RIGHT_ARROW_ICON_STYLE: React.CSSProperties = { marginLeft: 34, marginRight: 34, marginTop: 4 }

type Props = {
  causes: any[]
  assetCategories: any[]
  previousEvent?: any
  onClose?: (e: any) => void
}

const Form: React.FC<Props> = ({ assetCategories, causes, previousEvent, onClose }: Props) => {
  const { values, setFieldValue } = useFormikContext<ValueFields>()
  const [newAssetCategory, getAssetCategory] = useResource(assetCategoryApi.getAssetCategory)
  const masterData = useSelector(selectMasterData)
  const { data: permissions } = useSelector(selectUserPermission, isEqual)

  const handleAfterAssetCategoryChanged = (assetCategoryId: string | undefined) => {
    assetCategoryId && getAssetCategory(assetCategoryId)
  }

  useEffect(() => {
    if (newAssetCategory.data) {
      const {
        asset_type_for_corporate_tax,
        asset_type_for_depreciable_property_tax,
        asset_structure_for_corporate_tax,
        asset_detail_for_corporate_tax,
      } = newAssetCategory.data

      setFieldValue(
        'asset_type_for_depreciable_property_tax_code',
        get(asset_type_for_depreciable_property_tax, 'code')
      )
      setFieldValue('asset_type_for_corporate_tax_code', get(asset_type_for_corporate_tax, 'code'))
      setFieldValue('asset_structure_for_corporate_tax', asset_structure_for_corporate_tax)
      setFieldValue('asset_detail_for_corporate_tax', asset_detail_for_corporate_tax)
    }
  }, [setFieldValue, newAssetCategory])

  useEffect(() => {
    if (previousEvent) {
      setFieldValue('last_asset_category_name', get(previousEvent, ['asset_category', 'name']))
      setFieldValue(
        'last_asset_type_for_depreciable_property_tax_name',
        get(previousEvent, ['asset_type_for_depreciable_property_tax', 'name_jp'])
      )
      setFieldValue(
        'last_asset_type_for_corporate_tax_name',
        get(previousEvent, ['asset_type_for_corporate_tax', 'name_jp'])
      )
      setFieldValue('last_asset_structure_for_corporate_tax', get(previousEvent, ['asset_structure_for_corporate_tax']))
      setFieldValue('last_asset_detail_for_corporate_tax', get(previousEvent, ['asset_detail_for_corporate_tax']))
      setFieldValue('min_date', get(previousEvent, ['valid_from']))
    }
  }, [previousEvent]) // eslint-disable-line

  const isValidFields = validateForm(values)

  return (
    <>
      <FormFields>
        <Item label={t('switch_at')} required>
          <DatePicker name="switched_at" />
        </Item>
        <Item label={t('reason')} required>
          <AutoCompleteSelect
            name="event_cause_id"
            items={causes}
            valueField="id"
            textField="content"
            childrenNode={
              <RedirectToMasterPageLink
                permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_CAUSES, permissions)}
                urlName={ENDPOINTS.CAUSES}
                pageName={t('master.causes')}
              />
            }
          />
        </Item>
        <LongItem>
          <Item label={t('asset_category')} contentWidth={520} required>
            <UnderlineInput name="last_asset_category_name" style={{ width: 240 }} />
            <RightArrowIcon style={RIGHT_ARROW_ICON_STYLE} />
            <AutoCompleteSelect
              name="asset_category_id"
              items={assetCategories}
              valueField="id"
              textField="name"
              style={{ width: 240 }}
              afterChanged={handleAfterAssetCategoryChanged}
              childrenNode={
                <RedirectToMasterPageLink
                  permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_ASSET_CATEGORIES, permissions)}
                  urlName={ENDPOINTS.ASSET_CATEGORIES}
                  pageName={t('master.asset_categories')}
                />
              }
            />
          </Item>
        </LongItem>
        <LongItem>
          <Item label={t('asset_type_for_depreciable_property_tax')} contentWidth={520}>
            <UnderlineInput name="last_asset_type_for_depreciable_property_tax_name" style={{ width: 240 }} />
            <RightArrowIcon style={RIGHT_ARROW_ICON_STYLE} />
            <AutoCompleteSelect
              name="asset_type_for_depreciable_property_tax_code"
              items={get(masterData, 'asset_type_for_depreciable_property_tax')}
              valueField="code"
              textField="name_jp"
              style={{ width: 240 }}
            />
          </Item>
        </LongItem>
        <LongItem>
          <Item label={t('asset_type_for_corporate_tax')} contentWidth={520}>
            <UnderlineInput name="last_asset_type_for_corporate_tax_name" style={{ width: 240 }} />
            <VerticalLine style={{ position: 'absolute', top: 29, left: -146 }} />
            <RightArrowIcon style={RIGHT_ARROW_ICON_STYLE} />
            <AutoCompleteSelect
              name="asset_type_for_corporate_tax_code"
              items={get(masterData, 'asset_type_for_corporate_tax')}
              valueField="code"
              textField="name_jp"
              style={{ width: 240 }}
            />
          </Item>
        </LongItem>
        <LongItem>
          <Item label={t('asset_structure_for_corporate_tax')} contentWidth={560}>
            <UnderlineInput name="last_asset_structure_for_corporate_tax" style={{ width: 240 }} />
            <VerticalLine style={{ position: 'absolute', top: 29, left: -146 }} />
            <RightArrowIcon style={RIGHT_ARROW_ICON_STYLE} />
            <Input name="asset_structure_for_corporate_tax" style={{ width: 240 }} />
          </Item>
        </LongItem>
        <LongItem>
          <Item label={t('asset_detail_for_corporate_tax')} contentWidth={560}>
            <UnderlineInput name="last_asset_detail_for_corporate_tax" style={{ width: 240 }} />
            <RightArrowIcon style={{ marginLeft: 34, marginRight: 34, marginTop: 4 }} />
            <Input name="asset_detail_for_corporate_tax" style={{ width: 240 }} />
          </Item>
        </LongItem>
        <MediaUploader />
      </FormFields>
      <Actions>
        <Button type="submit" color="grey" onClick={onClose}>
          {t('actions.cancel')}
        </Button>
        <Button type="submit" disabled={!isValidFields}>
          {t('actions.register')}
        </Button>
      </Actions>
    </>
  )
}

export default Form
