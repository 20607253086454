import { CORPORATE_INCOME_TAX } from 'aa_common/front-end/constants'
import { PresenterCorporateTaxDataResponse, ReportsService } from 'aa_common/front-end/open-api'
import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { Pagination } from 'aa_common/front-end/zustand/helper'

import { REPORT_TYPE } from '../corporate-income-tax/components/filter'

const initData: PresenterCorporateTaxDataResponse[] | undefined = []
const initFilters: {
  termId?: number
  reportTemplateId?: number
  pagination?: Pagination
  assetTypeCodes?: number[]
  assetCategoryIds?: number[]
  reportType?: number
  currentAssetTypeCodes?: string
  currentAssetCategoryIds?: string
} = {}

const fetchData: FilterFunction<typeof initData, typeof initFilters> = async filters => {
  const { assetTypeCodes, assetCategoryIds, reportType, reportTemplateId } = filters || {}
  let totalFilters = filters
  const isGetByAssetType = (reportType && +reportType === REPORT_TYPE.BY_ASSET_TYPE) as boolean
  const isGetByAssetCategory = reportType && +reportType === REPORT_TYPE.BY_ASSET_CATEGORY
  const isReportNo8 = +reportTemplateId! === CORPORATE_INCOME_TAX.TABLE_16_TYPE_8

  if (((isGetByAssetType && !assetTypeCodes) || (isGetByAssetCategory && !assetCategoryIds)) && !isReportNo8) {
    const filterData = await getAssetTypesAndAssetCategories(filters)
    totalFilters = { ...filters, ...filterData }
    detailReportSlice.setFilters(totalFilters)
  }

  const data = await getReportData(
    {
      ...totalFilters,
    },
    isGetByAssetType,
    isReportNo8
  )

  return {
    data,
  }
}

const getReportData = async (
  filters?: typeof initFilters,
  isGetByAssetType?: boolean,
  isReportNo8?: boolean
): Promise<PresenterCorporateTaxDataResponse[]> => {
  const { termId, reportTemplateId, reportType } = filters || {}
  const { currentAssetTypeCodes, currentAssetCategoryIds, sumIds } = getAssetTypesAndCategoryIds(filters)

  if (
    !termId ||
    !reportTemplateId ||
    !reportType ||
    (!currentAssetTypeCodes && !currentAssetCategoryIds && !isReportNo8)
  )
    return []

  return (
    (
      await ReportsService.corporateTaxDataList({
        term_id: termId,
        report_template_id: reportTemplateId,
        report_type: reportType,
        ...(isGetByAssetType
          ? { asset_type_codes: currentAssetTypeCodes }
          : { asset_category_ids: currentAssetCategoryIds }),
        ...(sumIds && { sum_ids: sumIds }),
      })
    ).data || []
  )
}

const getAssetTypesAndCategoryIds = (
  filters?: typeof initFilters
): { currentAssetTypeCodes?: string; currentAssetCategoryIds?: string; sumIds?: string } => {
  const { pagination, assetTypeCodes, assetCategoryIds, reportType } = filters || {}
  const isGetByAssetType = reportType && +reportType === REPORT_TYPE.BY_ASSET_TYPE
  if (!pagination || !pagination.current || !pagination.total) return {}
  const { current, pageSize, total } = pagination

  const startIndex = (current - 1) * pageSize
  const endIndex = startIndex + pageSize
  const currentAssetTypeCodes = assetTypeCodes?.slice(startIndex, endIndex).join(',')
  const currentAssetCategoryIds = assetCategoryIds?.slice(startIndex, endIndex).join(',')
  const sumIds = isGetByAssetType ? assetTypeCodes?.join(',') : assetCategoryIds?.join(',')
  const isLastPage = current === Math.ceil(total / pageSize)

  detailReportSlice.setFilters(prevFilter => ({ ...prevFilter, currentAssetTypeCodes, currentAssetCategoryIds }))

  return { currentAssetTypeCodes, currentAssetCategoryIds, ...(isLastPage && { sumIds }) }
}

const getAssetTypesAndAssetCategories = async (filters?: typeof initFilters) => {
  const { termId, reportTemplateId, reportType, pagination: _pagination } = filters || {}
  const isGetByAssetType = reportType && +reportType === REPORT_TYPE.BY_ASSET_TYPE

  if (!termId && !reportTemplateId) return {}
  const params = {
    term_id: termId as number,
    report_template_id: reportTemplateId as number,
  }

  const responseData = (
    isGetByAssetType
      ? await ReportsService.corporateTaxAssetTypesList(params)
      : await ReportsService.corporateTaxAssetCategoriesList(params)
  )?.data

  const data = responseData?.map((item: any) => (isGetByAssetType ? item : item?.id))
  const pageSize = _pagination ? _pagination.pageSize : 4
  const current = responseData?.length ? 1 : 0

  const pagination: Pagination = {
    current,
    pageSize,
    total: responseData?.length,
  }

  return { pagination, ...(isGetByAssetType ? { assetTypeCodes: data } : { assetCategoryIds: data }) }
}

export const detailReportSlice = initAsyncSlice('CITS/detail-report', initData, initFilters, fetchData)
