import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from 'constants/pagination'
import { Map } from 'immutable'

import { SubmitDestinationActionTypes } from './action.model'
import {
  CLEANUP_SUBMIT_DESTINATION,
  CREATE_SUBMIT_DESTINATION_REQUEST,
  DELETE_SUBMIT_DESTINATION_FAILURE,
  DELETE_SUBMIT_DESTINATION_REQUEST,
  DELETE_SUBMIT_DESTINATION_SUCCESS,
  EXPORT_SUBMIT_DESTINATION_FAILURE,
  EXPORT_SUBMIT_DESTINATION_REQUEST,
  EXPORT_SUBMIT_DESTINATION_SUCCESS,
  FETCH_SUBMIT_DESTINATION_REQUEST,
  FETCH_SUBMIT_DESTINATION_SUCCESS,
  FETCH_SUBMIT_DESTINATIONS_REQUEST,
  FETCH_SUBMIT_DESTINATIONS_REQUEST_AUTH,
  FETCH_SUBMIT_DESTINATIONS_SUCCESS,
  IMPORT_SUBMIT_DESTINATION_FAILURE,
  IMPORT_SUBMIT_DESTINATION_REQUEST,
  IMPORT_SUBMIT_DESTINATION_SUCCESS,
  SUBMIT_SUBMIT_DESTINATION_FAILURE,
  SUBMIT_SUBMIT_DESTINATION_SUCCESS,
  UPDATE_SUBMIT_DESTINATION_REQUEST,
} from './constant'

export const initialState = Map({
  list: Map({
    data: [],
    loading: false,
    filters: null,
    sort: null,
    pageNumber: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE,
    totalRecords: 0,
  }),
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
  import: Map({
    loading: false,
    error: {
      message: '',
      defails: [],
    },
  }),
})

export default (state = initialState, action: SubmitDestinationActionTypes) => {
  switch (action.type) {
    case FETCH_SUBMIT_DESTINATIONS_REQUEST:
    case FETCH_SUBMIT_DESTINATIONS_REQUEST_AUTH:
      return state
        .setIn(['list', 'loading'], true)
        .setIn(['list', 'pageNumber'], action.payload.pageNumber)
        .setIn(['list', 'pageSize'], action.payload.pageSize)
        .setIn(['list', 'filters'], action.payload.filters)
        .setIn(['list', 'sort'], action.payload.sort)
    case FETCH_SUBMIT_DESTINATIONS_SUCCESS:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'data'], action.payload.data)
        .setIn(['list', 'totalRecords'], action.payload.totalRecords)
    case FETCH_SUBMIT_DESTINATION_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_SUBMIT_DESTINATION_SUCCESS:
      return state
        .setIn(['item', 'loading'], false)
        .setIn(['item', 'data'], action.payload.data)
        .setIn(['list', 'totalRecords'], action.payload.totalRecords)
    case CREATE_SUBMIT_DESTINATION_REQUEST:
    case UPDATE_SUBMIT_DESTINATION_REQUEST:
      return state.setIn(['item', 'loading'], true).setIn(['item', 'errors'], null)
    case SUBMIT_SUBMIT_DESTINATION_SUCCESS:
      return state.setIn(['item', 'loading'], false)
    case SUBMIT_SUBMIT_DESTINATION_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], action.formErrors)
    case DELETE_SUBMIT_DESTINATION_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case DELETE_SUBMIT_DESTINATION_FAILURE:
    case DELETE_SUBMIT_DESTINATION_SUCCESS:
      return state.setIn(['list', 'loading'], false)
    case IMPORT_SUBMIT_DESTINATION_REQUEST:
      return state.setIn(['import', 'loading'], true)
    case IMPORT_SUBMIT_DESTINATION_SUCCESS:
      return state
        .setIn(['import', 'loading'], false)
        .setIn(['import', 'error'], initialState.getIn(['import', 'error']))
    case IMPORT_SUBMIT_DESTINATION_FAILURE:
      return state.setIn(['import', 'loading'], false).setIn(['import', 'error'], action.payload)
    case CLEANUP_SUBMIT_DESTINATION:
      return initialState
    case EXPORT_SUBMIT_DESTINATION_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case EXPORT_SUBMIT_DESTINATION_SUCCESS:
    case EXPORT_SUBMIT_DESTINATION_FAILURE:
      return state.setIn(['list', 'loading'], false)
    default:
      return state
  }
}
