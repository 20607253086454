import { CSVCharset } from 'models/csvCharset'

import { DeleteAreaRequest, FetchAreasRequest } from './action.model'
import {
  CLEANUP_AREA,
  CLEAR_AREA_ITEM_ERRORS,
  CREATE_AREA_REQUEST,
  DELETE_AREA_BY_ID_REQUEST,
  DELETE_AREA_REQUEST,
  EXPORT_AREA_REQUEST,
  FETCH_AREA_BY_BIID_REQUEST,
  FETCH_AREA_REQUEST,
  FETCH_AREAS_FAILURE,
  FETCH_AREAS_REQUEST,
  FETCH_AREAS_REQUEST_AUTH,
  FETCH_AREAS_SUCCESS,
  IMPORT_AREA_REQUEST,
  INSERT_AREA_REQUEST,
  PARTIAL_CLEANUP_AREA,
  UPDATE_AREA_REQUEST,
} from './constant'
import { AreaSearchPayload, DeleteAreaPayload } from './model'

export const fetchAreas = (payload: AreaSearchPayload = {}): FetchAreasRequest => ({
  type: FETCH_AREAS_REQUEST,
  payload,
})

export const fetchAreasAuth = (payload: AreaSearchPayload = {}) => ({
  type: FETCH_AREAS_REQUEST_AUTH,
  payload,
})

export const fetchAreasSuccess = (payload: any) => ({
  type: FETCH_AREAS_SUCCESS,
  payload,
})

export const fetchAreasFailure = () => ({
  type: FETCH_AREAS_FAILURE,
})

export const fetchArea = (id: number) => {
  return {
    type: FETCH_AREA_REQUEST,
    id,
  }
}

export const fetchByBiid = (biid: string) => {
  return {
    type: FETCH_AREA_BY_BIID_REQUEST,
    biid,
  }
}

export const createArea = (payload: any) => {
  return {
    type: CREATE_AREA_REQUEST,
    payload,
  }
}

export const insertArea = (payload: any, callback?: any) => {
  return {
    type: INSERT_AREA_REQUEST,
    payload,
    callback,
  }
}

export const updateArea = (id: number, payload: any, isLatest: boolean, callback?: any) => {
  return {
    type: UPDATE_AREA_REQUEST,
    id,
    payload,
    isLatest,
    callback,
  }
}

export const clearAreaItemErrors = () => {
  return {
    type: CLEAR_AREA_ITEM_ERRORS,
  }
}

export const deleteArea = (payload: DeleteAreaPayload): DeleteAreaRequest => {
  return {
    type: DELETE_AREA_REQUEST,
    payload,
  }
}

export const deleteAreaById = (payload: any) => {
  return {
    type: DELETE_AREA_BY_ID_REQUEST,
    payload,
  }
}

export const importAreas = (charset: CSVCharset, file: any) => {
  return {
    type: IMPORT_AREA_REQUEST,
    payload: {
      charset,
      file,
    },
    meta: { thunk: true },
  }
}
export type ImportAreasAction = ReturnType<typeof importAreas>

export const exportAreas = (charset: CSVCharset, filters: any) => {
  return {
    type: EXPORT_AREA_REQUEST,
    payload: {
      charset,
      filters,
    },
  }
}
export type ExportAreasAction = ReturnType<typeof exportAreas>

export const cleanupArea = () => {
  return {
    type: CLEANUP_AREA,
  }
}

export const partialCleanupArea = () => {
  return {
    type: PARTIAL_CLEANUP_AREA,
  }
}
