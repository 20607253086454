import i18n from 'i18n'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { ENDPOINTS, Permission } from '../../constants'
import { LinkLabel } from '../link-label'
import { Wrapper } from './styles'

interface Props {
  urlName: string
  pageName: string
  isDisabledReload?: boolean
  onHandleReload?: () => void
  permissions?: Permission
}

export const RedirectToMasterPageLink = ({
  urlName,
  pageName,
  onHandleReload,
  isDisabledReload,
  permissions,
}: Props) => {
  const history = useHistory()
  return (
    <Wrapper>
      <LinkLabel
        onClick={() => history?.push(`/${ENDPOINTS.MASTER}/${urlName}/new`)}
        disabled={!permissions?.isEdit}
        showTooltipMessage={!permissions?.isEdit ? i18n.t('common.actions.no_permission') : undefined}
        className="link"
      >
        {i18n.t('components.AutoCompleteSelect.add_new_item_link', {
          itemName: pageName,
        })}
      </LinkLabel>
      <LinkLabel className="link" disabled={isDisabledReload} onClick={onHandleReload}>
        {i18n.t('components.AutoCompleteSelect.reload_options', {
          itemName: pageName,
        })}
      </LinkLabel>
    </Wrapper>
  )
}
