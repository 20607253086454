import { BasicModal } from 'aa_common/front-end/components'
import { useModalState } from 'aa_common/front-end/hooks/useModalState'
import { loadNs } from 'aa_common/front-end/i18n-config'
import BasicForm, { RenderFormProps } from 'components/molecules/Form'
import React, { useEffect, useRef } from 'react'

import Form, { AssetCodeFormProp } from './Form'
import { Wrapper } from './styles'

const t = loadNs(['common'])
export type { AssetCodeFormProp } from './Form'

const AssetCodeAutoGenerationFormModal = React.forwardRef<any>(function AssetCodeAutoGenerationFormModal(_, ref) {
  const { title, isShow, isLoading, initialValues, errors, handleOnClose, customProps } =
    useModalState<AssetCodeFormProp>(ref)

  const formRef = useRef<RenderFormProps>(null)
  useEffect(() => {
    if (isShow) {
      // delay a bit to let React have time to set ref to `formRef.current`
      Promise.resolve().then(() => {
        if (initialValues && formRef.current) {
          formRef.current.reset(initialValues)
        }
      })
    }
  }, [isShow, initialValues])

  return (
    <Wrapper>
      <BasicModal
        title={title || t('actions.edit')}
        isShow={isShow}
        onCancel={() => handleOnClose()}
        width={760}
        destroyOnClose
      >
        <BasicForm
          ref={formRef}
          value={initialValues || {}}
          errors={errors}
          loading={isLoading}
          onFormSubmit={handleOnClose}
        >
          <Form allowSelectTerm={customProps.allowSelectTerm} />
        </BasicForm>
      </BasicModal>
    </Wrapper>
  )
})

export default AssetCodeAutoGenerationFormModal
