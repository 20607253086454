/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-shadow */
import { PlusOutlined } from '@ant-design/icons'
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { message, Upload } from 'antd'
import { UploadChangeParam } from 'antd/es/upload/interface'
import { HttpRequestHeader, UploadFile } from 'antd/lib/upload/interface'
import { IMAGE_UPLOAD_PATH, MAX_IMAGE_UPLOAD_SIZE } from 'constants/app'
import { CSRF_TOKEN_KEY } from 'constants/auth'
import i18n from 'i18n'
import get from 'lodash/get'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Spin } from '../../../antd'
import { validateFileSize } from '../../../helpers'
import { BasicModal, ConfirmModal } from '../../modals'
import { ICoreUploadImage } from './model'
import { EditIcon, PreviewImage, RemoveIcon, Thumbnail, ThumbnailImage, WrapperClass } from './styles'

// eslint-disable-next-line react/prop-types
const CoreUploadImage = ({ mode = 'edit', value = [], onChange }: ICoreUploadImage) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [deletedItem, setDeletedItem] = useState<UploadFile | null>(null)
  const [previewImage, setPreviewImage] = useState<UploadFile | null>(null)
  const isEditMode = mode === 'edit'

  useEffect(() => {
    if (value && value.length > 0) {
      setFileList(value)
    }
  }, [value])

  const handleBeforeUpload = (image: UploadFile) => {
    if (!validateFileSize(image as any, MAX_IMAGE_UPLOAD_SIZE)) {
      message.error(i18n.t('common.messages.over_file_size_limit', { name: image.name }))
      image.status = 'error'
      return false
    }
    return true
  }

  const handleOnChange = ({ file, fileList }: UploadChangeParam<UploadFile<any>>) => {
    if (file.status === 'error') {
      message.error(i18n.t('components.UploadImageListField.upload_failure', { name: file.name }))
    }

    const updatedFileList = fileList
      .filter(item => item.status !== 'error')
      .map(item => {
        const responseData = item.response?.data
        return {
          ...item,
          fileName: responseData ? responseData.filename : item.fileName,
          uid: responseData ? responseData.id : item.uid,
          url: responseData ? responseData.url : item.url,
          thumbUrl: responseData ? responseData.thumbnail_url : item.thumbUrl,
        }
      })

    onChange?.(updatedFileList)
    setFileList(updatedFileList)
  }

  const handlePreviewClick = useCallback((image: UploadFile) => {
    setPreviewImage(image)
  }, [])

  const handleRemoveClick = useCallback((image: UploadFile) => {
    setDeletedItem(image)
    return false
  }, [])

  const handleConfirmDelete = () => {
    const updatedFileList = fileList.filter(item => item.uid !== deletedItem?.uid)

    setDeletedItem(null)
    onChange?.(updatedFileList)
    setFileList(updatedFileList)
  }

  const handleCancelDelete = () => setDeletedItem(null)

  const handleClosePreviewModal = () => setPreviewImage(null)

  const ImageListItem = useCallback(
    ({
      imageItem,
      isEditMode,
      loading,
      remove,
      preview,
    }: {
      imageItem: UploadFile
      loading?: boolean
      isEditMode: boolean
      remove: () => void
      preview: () => void
    }) => {
      const imageName = imageItem.name || ''
      const imageSrc = get(imageItem.response, 'data.thumbnail_url') || imageItem.url || imageItem.thumbUrl

      return (
        <Thumbnail key={imageItem.uid}>
          <Spin loading={loading} size="default">
            {isEditMode && !loading && (
              <EditIcon className="edit-icon" onClick={preview}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </EditIcon>
            )}
            {isEditMode && !loading && (
              <RemoveIcon className="remove-icon" onClick={remove}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </RemoveIcon>
            )}
            <ThumbnailImage url={imageSrc} className="image-item" onClick={() => !isEditMode && preview()}>
              <img alt={imageName} src={imageSrc} />
            </ThumbnailImage>
          </Spin>
        </Thumbnail>
      )
    },
    []
  )

  const requestHeader: HttpRequestHeader = useMemo(
    () => ({
      'X-CSRF-Token': localStorage.getItem(CSRF_TOKEN_KEY) as string,
    }),
    []
  )

  return (
    <>
      <Upload
        action={IMAGE_UPLOAD_PATH}
        fileList={fileList}
        className={WrapperClass}
        listType="picture-card"
        beforeUpload={handleBeforeUpload}
        onChange={handleOnChange}
        accept="image/*"
        multiple
        itemRender={(_, file, __, actions) => {
          return (
            <ImageListItem
              loading={file.status === 'uploading'}
              imageItem={file}
              isEditMode={isEditMode}
              remove={actions.remove}
              preview={actions.preview}
            />
          )
        }}
        headers={requestHeader}
        withCredentials
        onPreview={handlePreviewClick}
        onRemove={handleRemoveClick}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>{i18n.t('components.UploadImageListField.upload')}</div>
        </div>
      </Upload>
      <BasicModal isShow={!!previewImage} title="&nbsp;" width={390} onCancel={handleClosePreviewModal}>
        <PreviewImage>
          <img
            alt={get(previewImage, 'name') || ''}
            src={get(previewImage?.response, 'data.url') || previewImage?.url || previewImage?.thumbUrl}
          />
        </PreviewImage>
      </BasicModal>
      <ConfirmModal
        message={i18n.t('common.messages.confirm_delete', { name: `${get(deletedItem, 'name', '')}` })}
        visible={!!deletedItem}
        onOK={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </>
  )
}

export default CoreUploadImage
