import { ACTION } from './constant'

export const fetchAssetEventsReport = (
  termMonthId: number,
  changeSituationCode: number,
  pageNumber = 1,
  pageSize: number
) => ({
  type: ACTION.FETCH_ASSET_EVENTS_REPORT,
  payload: {
    termMonthId,
    changeSituationCode,
    pageNumber,
    pageSize,
  },
})

export const handleFetchAssetEventsReport = (payload: any) => ({
  type: ACTION.HANDLE_FETCH_ASSET_EVENTS_REPORT,
  payload,
})

export const fetchSummaryFixedAssetEvents = (payload: number) => ({
  type: ACTION.FETCH_SUMMARY_FIXED_ASSET_EVENTS,
  payload,
})

export const handleFetchSummaryFixedAssetEvents = (payload: any[]) => ({
  type: ACTION.HANDLE_FETCH_SUMMARY_FIXED_ASSET_EVENTS,
  payload,
})
