import * as assetApi from 'api/app/asset'
import * as operationLogApi from 'api/app/operationLog'
import { AxiosResponse } from 'axios'
import { convertOperationLogs } from 'converters/internalControl/operationLog'
import { get } from 'lodash'
import { Asset } from 'models/asset'
import { call, put, takeLatest } from 'redux-saga/effects'
import { showErrorsAppCall } from 'store/app/actions'
import { parseError } from 'utils/ResponseHelper'

import {
  FetchOperationLogItemFailure,
  FetchOperationLogItemRequest,
  FetchOperationLogItemSuccess,
  FetchOperationLogsFailure,
  FetchOperationLogsRequest,
  FetchOperationLogsSuccess,
} from './action.model'
import {
  FETCH_OPERATION_LOG_ITEM_FAILURE,
  FETCH_OPERATION_LOG_ITEM_REQUEST,
  FETCH_OPERATION_LOG_ITEM_SUCCESS,
  FETCH_OPERATION_LOGS_FAILURE,
  FETCH_OPERATION_LOGS_REQUEST,
  FETCH_OPERATION_LOGS_SUCCESS,
} from './constant'

export function* fetchOperationLogs({ params, pageNumber, pageSize }: FetchOperationLogsRequest) {
  try {
    const response: AxiosResponse = yield call(operationLogApi.get, params, pageNumber, pageSize)
    const operationLogs = convertOperationLogs(get(response, 'data.data'))
    const totalRecords = get(response, 'data.meta.total')

    yield put<FetchOperationLogsSuccess>({
      type: FETCH_OPERATION_LOGS_SUCCESS,
      payload: {
        logs: operationLogs,
        totalRecords,
      },
    })
  } catch (error) {
    yield put<FetchOperationLogsFailure>({ type: FETCH_OPERATION_LOGS_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchOperationLogItem({ payload }: FetchOperationLogItemRequest) {
  try {
    const response: AxiosResponse = yield call(assetApi.getById, payload.biid, payload.id, true)

    yield put<FetchOperationLogItemSuccess>({
      type: FETCH_OPERATION_LOG_ITEM_SUCCESS,
      payload: get(response, 'data.data') as Asset,
    })
  } catch (error) {
    yield put<FetchOperationLogItemFailure>({ type: FETCH_OPERATION_LOG_ITEM_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher(): Generator<any> {
  yield takeLatest(FETCH_OPERATION_LOGS_REQUEST, fetchOperationLogs)
  yield takeLatest(FETCH_OPERATION_LOG_ITEM_REQUEST, fetchOperationLogItem)
}
