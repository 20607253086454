import { faChevronLeft, faChevronRight, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { isEqual, pick } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionFormValues, selectSettingsData } from 'store/asset/selectors'
import { selectMasterData } from 'store/masterData/selectors'

import FilterForm from './FilterForm'
import { getFixedAssetFilterPayload } from './helper'
import { AssetCollectionFilterEvent } from './model'
import { AssetFilterFacetCollapseWrapper, AssetFilterFacetSider, AssetFilterFacetWrapper } from './styles'

const t = loadNs(['common'])

interface AssetFilterFacet {
  filterDate: string
  onSubmit: (event: AssetCollectionFilterEvent) => void
  onFilterReset: (event: AssetCollectionFilterEvent) => void
}

const pickFields = [
  'keyword',
  'depreciation_method',
  'asset_status',
  'acquire_category',
  'acquire_destination_name',
  'memo',
  'name',
  'name_kana',
  'pre_submit_number',
  'asset_type_for_corporate_tax',
  'asset_type_for_depreciable_property_tax',
  'quantity',
  'asset_category',
  'area',
  'tag',
  'department',
  'acquisition_cost',
  'acquired_at',
  'usage_started_at',
  'service_life',
  'code',
  'branch_code',
]

const AssetFilterFacetNew = ({ filterDate, onSubmit, onFilterReset }: AssetFilterFacet) => {
  const [siderCollapse, setSiderCollapse] = useState(false)

  const masterData = useSelector(selectMasterData, isEqual)
  const settingsData = useSelector(selectSettingsData, isEqual)
  const assetCollectionViewFormValues = useSelector(selectAssetsCollectionFormValues, isEqual)

  const filterValue = useMemo(() => assetCollectionViewFormValues, [assetCollectionViewFormValues])

  const handleCollapse = () => setSiderCollapse(prevValue => !prevValue)

  const handleOnSubmit = (formValues: any, isReset?: boolean) => {
    const filterForm = pick(formValues, pickFields)
    const payload: AssetCollectionFilterEvent = {
      formValues,
      filtersPayload: getFixedAssetFilterPayload(filterForm),
    }
    setTimeout(() => {
      isReset ? onFilterReset(payload) : onSubmit(payload)
    }, 100)
  }

  const TriggerTemplate = () => {
    return (
      <div style={{ borderTop: '1px solid #D4D8DD' }} onClick={handleCollapse}>
        <div style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          <div>
            {siderCollapse ? (
              <FontAwesomeIcon icon={faChevronRight} style={{ verticalAlign: 'middle', fontSize: 18 }} />
            ) : (
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ verticalAlign: 'middle', marginRight: 10, fontSize: 18 }}
              />
            )}
            {!siderCollapse && (
              <span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>{t('actions.close')}</span>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <AssetFilterFacetSider
      trigger={<TriggerTemplate />}
      width={230}
      collapsedWidth={40}
      collapsed={siderCollapse}
      collapsible
    >
      <AssetFilterFacetWrapper width={230}>
        <FilterForm
          value={filterValue}
          filterDate={filterDate}
          masterData={masterData}
          settingsData={settingsData}
          onSubmit={handleOnSubmit}
        />
      </AssetFilterFacetWrapper>
      <AssetFilterFacetCollapseWrapper>
        <div className="collapse-item">
          <FontAwesomeIcon style={{ fontSize: 16 }} icon={faSearch} onClick={handleCollapse} />
        </div>
        <div className="collapse-item">
          <FontAwesomeIcon style={{ fontSize: 16 }} icon={faFilter} onClick={handleCollapse} />
        </div>
      </AssetFilterFacetCollapseWrapper>
    </AssetFilterFacetSider>
  )
}

export default AssetFilterFacetNew
