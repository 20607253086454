import { ReportsService } from 'aa_common/front-end/open-api'
import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'

import { Report } from './model'

const initData: { reports?: Report[] } = {}

const initFilter: {
  currentTermId?: number
  reportType?: number
} = {}

const loadHistoryCount: FilterFunction<typeof initData, typeof initFilter> = async filters => {
  const term_id = filters?.currentTermId

  if (!term_id) return {}
  const response = (await ReportsService.corporateTaxHistoryCountList({ term_id }))?.data

  const mapCount: Record<string, number> = response?.reduce((acc: Record<string, number>, cur) => {
    return { ...acc, ...(cur.report_template_id && { [cur.report_template_id]: cur.count }) }
  }, {})

  let reports = reportSlice.getState().data?.reports as Report[]
  if (Array.isArray(response)) {
    reports = reports?.map((report: Report) => ({
      ...report,
      count: mapCount[report.id],
    }))
  }

  return { data: { reports } }
}

// CITS = corporate income tax slice
export const reportSlice = initAsyncSlice<typeof initData, typeof initFilter>(
  'CITS/reports',
  initData,
  initFilter,
  loadHistoryCount
)
