/* eslint-disable prefer-destructuring */
import { get } from 'lodash'
import { AccountItem, AccountSubItem } from 'models/accountItem'

export const parsedDataAccountItems = (accountItems: AccountItem[]) => {
  return accountItems.map((accountItem: AccountItem, index) => {
    accountItem.index = index
    accountItem.unique_id = accountItem.id
    accountItem.excise_id = accountItem.excise?.id

    if (accountItem.account_sub_items) {
      accountItem.account_sub_items.forEach((subAccountItem: AccountSubItem, index) => {
        subAccountItem.index = index
        subAccountItem.unique_id = subAccountItem.id
        subAccountItem.excise_id = accountItem.excise?.id
        subAccountItem.account_item_unique_id = accountItem.id
      })
    } else {
      accountItem.account_sub_items = []
    }

    return accountItem
  })
}

export const cleanupData = (accountItems: AccountItem[]) => {
  const newData: AccountItem[] = []

  if (!accountItems) {
    return []
  }

  accountItems.forEach((accountItem: AccountItem) => {
    if (!accountItem.id && accountItem.deleted) return

    const newAccountItem: AccountItem = {
      ...accountItem,
      account_sub_items: [],
      index: newData.length,
    }

    accountItem.account_sub_items.forEach((subAccountItem: AccountSubItem) => {
      if (!subAccountItem.id && subAccountItem.deleted) return

      const newAccountSubItem = {
        ...subAccountItem,
        index: newAccountItem.account_sub_items.length,
      }

      newAccountItem.account_sub_items.push(newAccountSubItem)
    })

    newData.push(newAccountItem)
  })

  return newData
}

export const parseErrors = (errors: any) => {
  const result: any = {}
  let itemIndex
  let subItemIndex: number
  let itemField
  let subItemField: string

  errors?.forEach((error: any) => {
    const errorPointer = get(error, ['source', 'pointer'])
    const errorDetail = get(error, ['detail'])
    const parts = errorPointer.split('/')

    itemIndex = parts[2]
    itemField = parts[3]

    if (parts.length === 6) {
      subItemIndex = parts[4]
      subItemField = parts[5]
      result[`[${itemIndex}].${itemField}[${subItemIndex}].${subItemField}`] = errorDetail
    } else {
      result[`[${itemIndex}].${itemField}`] = errorDetail
    }
  })

  return result
}
