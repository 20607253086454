/* eslint-disable react/display-name */
import { ColumnsType } from 'antd/es/table'
import { DATETIME_NO_DOT_FORMAT } from 'constants/app'
import i18n from 'i18n'
import { find } from 'lodash'
import { IMasterData } from 'models/masterData'
import { OperationLog } from 'models/operationLog'
import moment from 'moment'
import React from 'react'

export const logTableColumns = (masterData: IMasterData): ColumnsType<OperationLog> => [
  {
    title: i18n.t('pages.internalControl.operationLog.table.created_at'),
    key: 'created_at',
    dataIndex: 'createdAt',
    width: 150,
    render: value => {
      return <span>{moment(value).format(DATETIME_NO_DOT_FORMAT)}</span>
    },
  },
  {
    title: i18n.t('pages.internalControl.operationLog.table.operation_type'),
    key: 'operation_type',
    dataIndex: 'type',
    width: 100,
    render: value => {
      const renderedText = find(masterData.operation_type, item => item.code === value)?.name_jp
      return <span>{renderedText}</span>
    },
  },
  {
    title: i18n.t('pages.internalControl.operationLog.table.operation_target'),
    key: 'operation_target',
    dataIndex: 'target',
    width: 120,
    render: value => {
      const renderedText = find(masterData.operation_target, item => item.code === value)?.name_jp
      return <span>{renderedText}</span>
    },
  },
  {
    title: i18n.t('pages.internalControl.operationLog.table.operator'),
    key: 'operator',
    dataIndex: ['user', 'display_name'],
    width: 120,
  },
  {
    title: i18n.t('pages.internalControl.operationLog.table.asset_code'),
    key: 'asset_code',
    width: 120,
    render: (_, record) => {
      return <span>{`${record.asset.code}-${record.asset.branch_code}`}</span>
    },
  },
  {
    title: i18n.t('pages.internalControl.operationLog.table.asset_name'),
    key: 'asset_name',
    dataIndex: ['asset', 'name'],
    width: 'auto',
  },
]
