/* eslint-disable react/no-unstable-nested-components */
import { faChevronRight, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse as CollapseAnt } from 'antd'
import classNames from 'classnames'
import React from 'react'

import EditGroup from './EditGroup'
import Item from './Item'
import { Actions, Wrapper } from './styles'
import ViewGroup from './ViewGroup'

const { Panel } = CollapseAnt

type Props = {
  mode?: 'view' | 'edit'
  title?: string
  onEdit?: () => void
  children?: React.ReactNode
}

interface CollapseProps extends React.FC<Props> {
  Item: typeof Item
  ViewGroup: typeof ViewGroup
  EditGroup: typeof EditGroup
  Actions: typeof Actions
}

const Collapse: CollapseProps = ({ children, mode = 'view', title, onEdit }: Props) => {
  return (
    <Wrapper>
      <CollapseAnt
        defaultActiveKey={['1']}
        expandIconPosition="right"
        expandIcon={panelProps => {
          return (
            <div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className={classNames('collapse-icon', { active: panelProps.isActive })}
              />
            </div>
          )
        }}
      >
        <Panel header={title} key="1">
          <div className="content">{children}</div>
          {mode === 'view' && (
            <div className="action">
              <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" onClick={onEdit} />
            </div>
          )}
        </Panel>
      </CollapseAnt>
    </Wrapper>
  )
}

Collapse.Item = Item
Collapse.ViewGroup = ViewGroup
Collapse.EditGroup = EditGroup
Collapse.Actions = Actions

export default Collapse
