import {
  exportMonthlyClosingIncreaseDecreaseReportURL,
  getIncreaseDecreaseReportMonthlyClosing,
} from 'api/app/monthlyClosing'
import * as reportApi from 'api/app/report'
import { AxiosResponse } from 'axios'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { setAppMessage, showErrorsAppCall } from 'store/app/actions'
import { parseError } from 'utils/ResponseHelper'

import {
  ExportIncreaseDecreaseReportRequest,
  FetchIncreaseDecreaseReportFailure,
  FetchIncreaseDecreaseReportRequest,
  FetchIncreaseDecreaseReportSuccess,
} from './action.model'
import {
  EXPORT_INCREASE_DECREASE_REPORT_FAILURE,
  EXPORT_INCREASE_DECREASE_REPORT_REQUEST,
  EXPORT_INCREASE_DECREASE_REPORT_SUCCESS,
  FETCH_INCREASE_DECREASE_REPORT_FAILURE,
  FETCH_INCREASE_DECREASE_REPORT_REQUEST,
  FETCH_INCREASE_DECREASE_REPORT_SUCCESS,
} from './constant'

export function* fetchIncreaseDecreaseReport({ payload, isInMonthlyClosing }: FetchIncreaseDecreaseReportRequest) {
  try {
    const result: AxiosResponse = isInMonthlyClosing
      ? yield call(getIncreaseDecreaseReportMonthlyClosing, payload)
      : yield call(reportApi.getIncreaseDecreaseReport, payload)
    yield put<FetchIncreaseDecreaseReportSuccess>({
      type: FETCH_INCREASE_DECREASE_REPORT_SUCCESS,
      payload: get(result, 'data.data'),
    })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
    yield put<FetchIncreaseDecreaseReportFailure>({
      type: FETCH_INCREASE_DECREASE_REPORT_FAILURE,
    })
  }
}

export function* exportIncreaseDecreaseReport({ payload, isInMonthlyClosing }: ExportIncreaseDecreaseReportRequest) {
  try {
    const linkExport = isInMonthlyClosing
      ? exportMonthlyClosingIncreaseDecreaseReportURL(payload)
      : reportApi.exportIncreaseDecreaseReportURL(payload)
    yield window.open(linkExport, '_blank')
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      })
    )
    yield put({ type: EXPORT_INCREASE_DECREASE_REPORT_SUCCESS })
  } catch (error) {
    yield put({ type: EXPORT_INCREASE_DECREASE_REPORT_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_INCREASE_DECREASE_REPORT_REQUEST, fetchIncreaseDecreaseReport)
  yield takeLatest(EXPORT_INCREASE_DECREASE_REPORT_REQUEST, exportIncreaseDecreaseReport)
}
