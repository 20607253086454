import { BasicForm } from 'components/molecules'
import { YEAR_FORMAT } from 'constants/app'
import { ACCOUNTING_PERIOD_TERM_MONTH_TYPES } from 'constants/masterData'
import { get } from 'lodash'
import { Term } from 'models/term'
import moment from 'moment'
import React, { useEffect } from 'react'

import Form from './Form'
import { determineDefaultTermMonthDate } from './helpers'
import { Wrapper } from './style'
import TermList from './TermList'

type Props = {
  loading?: boolean
  errors?: any[]
  values?: any
  defaultFormValues: Term | null
  isResetMode?: boolean
  onValuesChanged: (values: any) => void
  onSubmit: (values: any) => void
}

const { SubmitButton } = BasicForm

const defaultInitialValues: any = {
  default_term_month_date: ACCOUNTING_PERIOD_TERM_MONTH_TYPES.BASE_ON_START_DATE,
  year: parseInt(moment().format(YEAR_FORMAT), 10),
  period: 1,
  lack_of_month_setting: 0,
  start_date: null,
  end_date: null,
}

const AccountingPeriodForm: React.FC<Props> = ({
  values,
  errors,
  loading = false,
  isResetMode = false,
  defaultFormValues,
  onValuesChanged,
  onSubmit,
}) => {
  const renderForm = () => {
    let initialValues = defaultInitialValues

    if (isResetMode && defaultFormValues) {
      initialValues = {
        ...defaultFormValues,
        default_term_month_date: determineDefaultTermMonthDate(defaultFormValues?.start_date),
      }
    }

    return (
      <BasicForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        errors={errors}
        loading={loading}
        preventSubmitOnEnter
      >
        <Form onValuesChanged={onValuesChanged} />
        <TermList data={get(values, 'term_months') || []} style={{ marginTop: 10 }} />
        <SubmitButton />
      </BasicForm>
    )
  }

  useEffect(() => {
    if (isResetMode && defaultFormValues) {
      onValuesChanged({
        ...defaultFormValues,
        default_term_month_date: determineDefaultTermMonthDate(defaultFormValues?.start_date),
      })
    }
  }, []) // eslint-disable-line

  return <Wrapper>{renderForm()}</Wrapper>
}

export default AccountingPeriodForm
