import React, { useEffect, useLayoutEffect, useRef } from 'react'

import { Wrapper } from './styles'

type Props = {
  id: string
  containerId?: string
  additionWidth?: number
  style?: React.CSSProperties
}

export const BottomAction: React.FC<Props> = ({ children, id, containerId, additionWidth, style }) => {
  const observer = useRef<ResizeObserver>(
    new ResizeObserver(() => {
      const containerWidth = document.getElementById(containerId || 'app-main-body')?.offsetWidth || 0
      const bottomSubmitEl = document.getElementById(id) as HTMLElement
      // TODO: Please keep the old code for backward compability if there occurs any issues;
      // bottomSubmitEl.style.width = containerWidth + 15 + (additionWidth || 0) + 'px';
      bottomSubmitEl.style.width = `${containerWidth + (additionWidth || 0)}px`
    })
  ).current

  useLayoutEffect(() => {
    let containter = document.getElementById('app-main-body')
    if (containerId) containter = document.getElementById(containerId)
    observer.observe(containter as HTMLElement)
  }, [observer, containerId, id])

  useEffect(() => {
    return function cleanup() {
      observer.disconnect()
    }
  }, [observer])

  return (
    <Wrapper id={id} style={style}>
      {children}
    </Wrapper>
  )
}
