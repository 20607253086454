import get from 'lodash/get'

import { API_STATUS_CODE } from '../constants'
import { getNs } from '../i18n-config'

const { t } = getNs('common')

const getFieldName = (error: any): string => {
  const pointer = get(error, 'source.pointer', '') as string
  const parts = pointer.split('/')

  switch (parts.length) {
    case 2:
    case 3:
      if (parts[1] === 'acquisition_cost') {
        return 'acquisition_cost_input'
      }
      return parts[1]

    case 4:
      return `${parts[1]}[${parts[2]}].${parts[3]}`

    default:
      return ''
  }
}

export const parseValidationError = (errors: any): any => {
  const result: any = {}
  let field = ''

  if (Array.isArray(errors)) {
    errors.forEach(error => {
      field = getFieldName(error)
      if (field) {
        result[field] = error.detail
      }
    })
  }

  return result
}

export const calculatePaginationTotalPages = (totalRecords: number, pageSize: number) =>
  Math.ceil(totalRecords / pageSize) || 0

export const parseImportErrors = (response: any): any => {
  const { meta, errors } = response || {}

  const result: any = { message: '', details: [] }
  result.message = meta?.message
  errors?.forEach((error: any) => {
    result.details.push(error.detail)
  })

  return result
}

export const getFileNameFromHeader = (header: any): string => {
  const contentDisposition = header['content-disposition']
  const fileName = contentDisposition.split('=')[1]

  return fileName
}

export const parseResponse = (res: any) => {
  return {
    data: res?.data,
  }
}

export const parseError = (err: any) => {
  let errors = []
  switch (err?.response?.status) {
    case API_STATUS_CODE.FORBIDDEN:
      errors = [
        {
          detail: err?.response?.data?.meta?.message || t('messages.no_permissions_error_occured'),
        },
      ]
      break
    default:
      errors = err?.response?.data?.errors || [
        {
          detail: err?.response?.statusText || t('messages.unexpected_error_occured'),
        },
      ]
      break
  }
  return {
    errors,
  }
}

export const getErrorMsg = (error: Types.ExtAxiosError) =>
  error?.response?.data?.errors?.[0]?.detail || error?.response?.statusText || error?.response?.status.toString()
