import { AccountItem } from 'models/accountItem'

export const selectAccountItemList = (state: any): AccountItem[] => {
  return state.getIn(['settings', 'accountItem', 'list', 'data'])
}

export const selectAccountItemLoading = (state: any): AccountItem[] => {
  return state.getIn(['settings', 'accountItem', 'list', 'loading'])
}

export const selectAccountItemList2 = (state: any): { loading: boolean; data: AccountItem[] } => {
  return {
    loading: state.getIn(['settings', 'accountItem', 'list', 'loading']),
    data: state.getIn(['settings', 'accountItem', 'list', 'data']),
  }
}

export const selectEditingItem = (state: any) => {
  return state.getIn(['settings', 'accountItem', 'editingItem'])
}

export const selectNewAccountItems = (state: any) => {
  return state.getIn(['settings', 'accountItem', 'newItems'])
}

export const selectIsResetForm = (state: any) => {
  return state.getIn(['settings', 'accountItem', 'isResetForm'])
}

export const selectErrors = (state: any) => {
  return state.getIn(['settings', 'accountItem', 'errors'])
}

export const selectImportErrors = (state: any) => {
  return state.getIn(['settings', 'accountItem', 'import', 'error'])
}

export const selectImportLoading = (state: any) => {
  return state.getIn(['settings', 'accountItem', 'import', 'loading'])
}
