import { yen } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { combinedKeys } from 'aa_common/front-end/shared/helpers'
import { ColumnsType, ColumnType } from 'antd/es/table'
import { ASSET_STATUSES } from 'constants/masterData'
import { get } from 'lodash'
import { LedgerSetting, LedgerSettingsItem } from 'models/ledgerSetting'
import moment from 'moment'
import React from 'react'
import { isAccountingJournalTarget } from 'utils/LedgerHelper'

import { DepreciationMethodTemplate, ServiceLifeTemplate } from '../components/tables/columnTemplates'
import { TitleStyles as TitleTemplate } from '../styles'

const t = loadNs(['pages/assets'])

export const renderDefaultSort = (sorter: any, fieldKey: string): 'ascend' | 'descend' | undefined => {
  const sortedColumn = get(sorter, 'columnKey')
  const configurableOrder = sortedColumn === fieldKey ? get(sorter, 'order') : undefined
  if (fieldKey === combinedKeys(['code', 'branch_code'])) {
    return sorter ? configurableOrder : 'descend'
  }
  return sorter && configurableOrder
}

const DetailDepreciationMethodTemplate = (amount: number, record: any) =>
  !record.isParent && DepreciationMethodTemplate(amount)

const DetailServiceLifeTemplate = (amount: number, record: any) => !record.isParent && ServiceLifeTemplate(amount)

const MoneyTemplate = (amount: string | number) => <span>{yen(amount)}</span>

export const generateColumns = (sorter: any, ledgerSettings: LedgerSetting[], isDetailMode?: boolean) => {
  return (ledgerSettings || []).reduce((fixedAssetColumns, item, index) => {
    const ledgerId = item.id
    const dMethodColumn: ColumnType<any> = {
      title: <TitleTemplate>{t('table.field_name.depreciation_method', { ledger: item.name })}</TitleTemplate>,
      dataIndex: ['fixed_asset_ledgers', `${index}`, 'depreciation_method', 'name_jp'],
      key: `depreciation_method|${ledgerId}`,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, `depreciation_method|${ledgerId}`),
      width: 110,
      render: isDetailMode ? DetailDepreciationMethodTemplate : DepreciationMethodTemplate,
    }
    const serviceLifeColumn: ColumnType<any> = {
      title: <TitleTemplate>{t('table.field_name.service_life', { ledger: item.name })}</TitleTemplate>,
      dataIndex: ['fixed_asset_ledgers', `${index}`, 'service_life'],
      key: `service_life|${ledgerId}`,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, `service_life|${ledgerId}`),
      width: 110,
      render: isDetailMode ? DetailServiceLifeTemplate : ServiceLifeTemplate,
    }
    const dAmountColumn: ColumnType<any> = {
      title: <TitleTemplate>{t('table.field_name.depreciation_amount', { ledger: item.name })}</TitleTemplate>,
      dataIndex: ['fixed_asset_costs', `${index}`, 'depreciation_amount'],
      key: `depreciation_amount|${ledgerId}`,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, `depreciation_amount|${ledgerId}`),
      width: 110,
      className: 'asset-table-money',
      render: MoneyTemplate,
    }
    const aCBookColumn: ColumnType<any> = {
      title: (
        <TitleTemplate>{t('table.field_name.accounting_current_book_value', { ledger: item.name })}</TitleTemplate>
      ),
      dataIndex: ['fixed_asset_costs', `${index}`, 'accounting_current_book_value'],
      key: `accounting_current_book_value|${ledgerId}`,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, `accounting_current_book_value|${ledgerId}`),
      width: 110,
      className: 'asset-table-money',
      render: MoneyTemplate,
    }
    const aDAmountColumn: ColumnType<any> = {
      title: (
        <TitleTemplate>{t('table.field_name.accumulated_depreciation_amount', { ledger: item.name })}</TitleTemplate>
      ),
      dataIndex: ['fixed_asset_costs', `${index}`, 'accumulated_depreciation_amount'],
      key: `accumulated_depreciation_amount|${ledgerId}`,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, `accumulated_depreciation_amount|${ledgerId}`),
      width: 140,
      className: 'asset-table-money',
      render: MoneyTemplate,
    }
    const impairmentLossAmountColumn: ColumnType<any> = {
      title: (
        <span style={{ whiteSpace: 'pre-line' }}>
          {t('table.field_name.impairment_loss_amount', { ledger: item.name })}
        </span>
      ),
      dataIndex: ['fixed_asset_ledgers', `${index}`, 'impaired_amount'],
      key: `impaired_amount|${ledgerId}`,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, `impaired_amount|${ledgerId}`),
      width: 140,
      className: 'asset-table-money',
      render: MoneyTemplate,
    }
    const impairmentAccumulatedAmountColumn: ColumnType<any> = {
      title: (
        <span style={{ whiteSpace: 'pre-line' }}>
          {t('table.field_name.impairment_accumulated_amount', { ledger: item.name })}
        </span>
      ),
      dataIndex: ['fixed_asset_ledgers', `${index}`, 'accumulated_impaired_amount'],
      key: `accumulated_impaired_amount|${ledgerId}`,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, `accumulated_impaired_amount|${ledgerId}`),
      width: 140,
      className: 'asset-table-money',
      render: MoneyTemplate,
    }

    fixedAssetColumns.push(dMethodColumn)
    fixedAssetColumns.push(serviceLifeColumn)
    fixedAssetColumns.push(dAmountColumn)
    fixedAssetColumns.push(aCBookColumn)
    fixedAssetColumns.push(aDAmountColumn)
    if (isAccountingJournalTarget(item as LedgerSettingsItem)) {
      fixedAssetColumns.push(impairmentLossAmountColumn)
      fixedAssetColumns.push(impairmentAccumulatedAmountColumn)
    }
    return fixedAssetColumns
  }, [] as ColumnsType<any>)
}

export const validateDeletable = (item: any, monthlyClosingCurrent: any) => {
  const assetStatus = item?.asset_status
  const startUsageAt = item?.usage_started_at
  const transitionAt = item?.transition_at
  const monthlyClosingBeginningDate = monthlyClosingCurrent?.from_term_month?.start_date
  const retiredOrSold = assetStatus?.code === ASSET_STATUSES.RETIRED_OR_SOLD
  const acquiredBeforeUsedCompare = assetStatus?.code === ASSET_STATUSES.ACQUIRED_BEFORE_USE
  const transitionAtAfterMonthlyClosing = moment(transitionAt).isSameOrAfter(moment(monthlyClosingBeginningDate))
  const startUsageAtAfterTransitionAt = moment(startUsageAt).isAfter(moment(transitionAt))
  const startUsageAtAfterMonthlyClosing = moment(startUsageAt).isSameOrAfter(moment(monthlyClosingBeginningDate))

  if (retiredOrSold || !!item?.divided_at) {
    return false
  }

  if (
    acquiredBeforeUsedCompare ||
    (transitionAt && !startUsageAt) ||
    (transitionAt && transitionAtAfterMonthlyClosing) ||
    (transitionAt && startUsageAtAfterTransitionAt && startUsageAtAfterMonthlyClosing)
  ) {
    return true
  }

  return false
}
