import { CSVCharset } from 'models/csvCharset'
import { GeneralJournalSettingItemSubmitPayload, JournalLinkageDetailData } from 'models/JournalData'

import {
  DeleteAssetCategoryJournalSettingsItemRequest,
  DeleteGeneralJournalSettingsTermRequest,
  ExportJournalLinkageRequest,
  FetchAssetCategoryTermsJournalSettingsRequest,
  SaveGeneralJournalSettingsItemRequest,
  SaveJournalItemSettingsRequest,
  UpdateGeneralJournalSettingsItemRequest,
  UpdateJournalItemSettingsRequest,
} from './action.model'
import {
  CLEANUP_JOURNAL_SETTINGS,
  CLEAR_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS,
  CLEAR_SEND_JOURNAL_RESULT,
  DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_REQUEST,
  DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST,
  EXPORT_JOURNAL_LINKAGE_REQUEST,
  EXPORT_JOURNAL_REQUEST,
  FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_REQUEST,
  FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST,
  FETCH_COUNT_OF_JOURNAL_EVENTS,
  FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_REQUEST,
  FETCH_JOURNAL_LINKAGE_DETAIL_FAILURE,
  FETCH_JOURNAL_LINKAGE_DETAIL_REQUEST,
  FETCH_JOURNAL_LINKAGE_DETAIL_SUCCESS,
  FETCH_JOURNAL_LINKAGE_STATUS_REQUEST,
  FETCH_JOURNALS_FAILURE,
  FETCH_JOURNALS_REPORT_REQUEST,
  FETCH_JOURNALS_REQUEST,
  FETCH_JOURNALS_SUCCESS,
  HANDLE_FETCH_COUNT_OF_JOURNAL_EVENTS,
  SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST,
  SAVE_JOURNAL_ITEM_SETTINGS_REQUEST,
  SEND_JOURNAL_FAIL_PARTIAL,
  SEND_JOURNAL_REQUEST,
  SET_TOTAL_JOURNALS,
  UPDATE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST,
  UPDATE_JOURNAL_ITEM_SETTINGS_REQUEST,
} from './constant'

export const fetchJournalsReport = (params: any, pageNumber: number, pageSize: number) => ({
  type: FETCH_JOURNALS_REPORT_REQUEST,
  payload: {
    params,
    pageNumber,
    pageSize,
  },
})

export const fetchCountOfJournalEvents = (params: any) => ({
  type: FETCH_COUNT_OF_JOURNAL_EVENTS,
  payload: params,
})

export const handleFetchCountOfJournalEvents = (payload: any) => ({
  type: HANDLE_FETCH_COUNT_OF_JOURNAL_EVENTS,
  payload,
})

export const setTotalJournals = (total: number) => ({
  type: SET_TOTAL_JOURNALS,
  payload: total,
})

export const fetchJournals = () => ({
  type: FETCH_JOURNALS_REQUEST,
})

export const fetchJournalLinkageDetail = (linkageHistoryId: string) =>
  ({
    type: FETCH_JOURNAL_LINKAGE_DETAIL_REQUEST,
    linkageHistoryId,
  } as const)

export const fetchJournalsSuccess = (payload: any) => ({
  type: FETCH_JOURNALS_SUCCESS,
  payload,
})

export const fetchJournalsFails = (payload: any) => ({
  type: FETCH_JOURNALS_FAILURE,
  payload,
})

export const fetchJournalLinkageDetailSuccess = (payload: JournalLinkageDetailData) =>
  ({
    type: FETCH_JOURNAL_LINKAGE_DETAIL_SUCCESS,
    payload,
  } as const)

export const fetchJournalLinkageDetailFails = () =>
  ({
    type: FETCH_JOURNAL_LINKAGE_DETAIL_FAILURE,
  } as const)

export const fetchAssetCategoryJournalSettingsList = () => {
  return { type: FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST }
}

export const fetchJournalGeneralSettingsTerms = () => {
  return { type: FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_REQUEST }
}

export const fetchAssetCategoryJournalSettingsTerms = (
  assetCategoryId: string | number
): FetchAssetCategoryTermsJournalSettingsRequest => {
  return { type: FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_REQUEST, assetCategoryId }
}

export const deleteAssetCategoryJournalSettingsTerm = (
  deletedId: string,
  assetCategoryId: number
): DeleteAssetCategoryJournalSettingsItemRequest => {
  return { type: DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_REQUEST, deletedId, assetCategoryId }
}

export const deleteGeneralJournalSettingsTerm = (deletedId: number): DeleteGeneralJournalSettingsTermRequest => {
  return { type: DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST, deletedId }
}

export const saveJournalItemSettings = (payload: any): SaveJournalItemSettingsRequest => {
  return {
    type: SAVE_JOURNAL_ITEM_SETTINGS_REQUEST,
    payload,
    meta: {
      thunk: true,
    },
  }
}

export const updateJournalItemSettings = (id: any, payload: any): UpdateJournalItemSettingsRequest => {
  return {
    type: UPDATE_JOURNAL_ITEM_SETTINGS_REQUEST,
    payload,
    id,
    meta: {
      thunk: true,
    },
  }
}

export const saveGeneralJournalSettingsItem = (
  payload: GeneralJournalSettingItemSubmitPayload
): SaveGeneralJournalSettingsItemRequest => {
  return {
    type: SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST,
    payload,
    meta: { thunk: true },
  }
}

export const updateGeneralJournalSettingsItem = (
  id: string | number,
  payload: GeneralJournalSettingItemSubmitPayload
): UpdateGeneralJournalSettingsItemRequest => {
  return {
    type: UPDATE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST,
    id,
    payload,
    meta: { thunk: true },
  }
}

export const clearSelectedAssetCategoryTerms = () => {
  return { type: CLEAR_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS }
}

export const cleanupJournalSettings = () => {
  return { type: CLEANUP_JOURNAL_SETTINGS }
}

export const exportJournals = (format: string, charset: string) => {
  return { type: EXPORT_JOURNAL_REQUEST, payload: { format, charset } }
}

export const fetchJournalLinkageStatus = () => {
  return { type: FETCH_JOURNAL_LINKAGE_STATUS_REQUEST }
}

export const sendJournal = () => {
  return {
    type: SEND_JOURNAL_REQUEST,
  }
}

export const sendJournalFailPartial = (payload: any) => {
  return {
    type: SEND_JOURNAL_FAIL_PARTIAL,
    payload,
  }
}

export const exportJournalsLinkage = (linkage_history_id: string, charset: CSVCharset): ExportJournalLinkageRequest => {
  return { type: EXPORT_JOURNAL_LINKAGE_REQUEST, payload: { charset, linkage_history_id } }
}

export const clearJournalResult = () => {
  return {
    type: CLEAR_SEND_JOURNAL_RESULT,
  }
}
