import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'aa_common/front-end/antd'
import { LinkLabel } from 'aa_common/front-end/components'
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg'
import { BreadcrumbNavigation } from 'components/molecules'
import LedgerSettingsTable from 'components/organisms/depreciation/LedgerSettingsTable'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import { LedgerSettingsItem } from 'models/ledgerSetting'
import queryString from 'query-string'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { changeLocation } from 'store/app/actions'
import { cleanupLedgerSettings, fetchLedgerSettingsListAuth } from 'store/settings/ledgerSetting/actions'
import { selectorLedgerSettingsList } from 'store/settings/ledgerSetting/selectors'

type LedgerSettingsViewSearchQuery = {
  backUrl: string
}

const LedgerSettingsViewPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const handleEditClick = useCallback(() => {
    dispatch(changeLocation('/setting/ledgers/edit'))
  }, [dispatch])

  const ledgerSettingListSelector = useSelector(selectorLedgerSettingsList, isEqual)

  const memoizedLedgerSettingsList = useMemo(() => {
    return (
      ledgerSettingListSelector.data &&
      (ledgerSettingListSelector.data.map(item => ({
        ...item,
        key_id: item.key_id || item.id,
      })) as LedgerSettingsItem[])
    )
  }, [ledgerSettingListSelector.data])

  useEffect(() => {
    dispatch(fetchLedgerSettingsListAuth())

    return () => {
      dispatch(cleanupLedgerSettings())
    }
  }, [dispatch])

  const breadcrumb = useMemo(() => {
    const params = queryString.parse(location.search) as LedgerSettingsViewSearchQuery
    const hasBackUrl = !!params.backUrl

    const handleCancel = () => {
      params.backUrl && dispatch(changeLocation(params.backUrl))
    }

    const cancelLink = (
      <LinkLabel onClick={handleCancel} style={{ paddingRight: '15px' }}>
        <CrossIcon />
      </LinkLabel>
    )

    const renderLedgerSettingTitle = () => (
      <div style={{ display: 'flex' }}>
        {i18n.t('components.LedgerSettings.title')}
        <Tooltip content={i18n.t('components.LedgerSettings.tooltip.title')}>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            style={{ fontSize: 18, marginTop: 7, marginLeft: 3, cursor: 'pointer' }}
          />
        </Tooltip>
      </div>
    )

    return (
      <BreadcrumbNavigation
        sectionName={renderLedgerSettingTitle()}
        customBackElement={hasBackUrl ? cancelLink : undefined}
      />
    )
  }, [location, dispatch])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <LedgerSettingsTable
        readonly
        data={memoizedLedgerSettingsList}
        loading={ledgerSettingListSelector.loading}
        onEdit={handleEditClick}
      />
    </MainTemplate>
  )
}

export default LedgerSettingsViewPage
