import styled from '@emotion/styled/macro'
import { BLACK_COLOR, GREY_BORDER_COLOR, LIGHT_BLUE_COLOR } from 'constants/styles'

export const SliderMonthWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 242px;

  .jslider-pointer {
    position: absolute;
    top: 18px;
    width: 0;
    height: 0;
    border-width: 0 5px 8px 5px;
    border-style: solid;
    border-color: transparent transparent #4a7ee2 transparent;
    cursor: pointer;
  }

  .jslider-month-selector {
    padding-bottom: 15px;
    margin-bottom: -20px;

    table {
      border-spacing: 0;
      border-radius: 3px;
      border-collapse: separate;
      border: 1px solid ${GREY_BORDER_COLOR};
      background-color: white;

      tr {
        td {
          width: 33px;
          height: 24px;
          color: ${BLACK_COLOR};
          cursor: pointer;
          text-align: center;
          vertical-align: middle;

          &.selected {
            background: ${LIGHT_BLUE_COLOR};
          }
          &:first-of-type {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
          &:last-of-type {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
          & + td {
            border-left: 1px solid ${GREY_BORDER_COLOR};
          }
        }
      }
    }
  }
`
