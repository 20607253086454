import { AutoCompleteSelect } from 'components/molecules'
import { Excise } from 'models/excise'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectExciseList } from 'store/settings/excise/selectors'

import { ExciseSelectWrapper } from './styles'

type Props = {
  name: string
  placeholder?: string
  style?: React.CSSProperties
  isDisabled?: boolean
  afterChanged?: (value?: string) => void
  className?: string
  allowClear?: boolean
}

const ExciseSelect: React.FC<Props> = ({
  name,
  placeholder,
  style,
  isDisabled,
  afterChanged,
  className,
  allowClear = false,
}) => {
  const exciseList = useSelector<any, Excise[]>(selectExciseList) || []

  return (
    <ExciseSelectWrapper>
      <AutoCompleteSelect
        style={style}
        name={name}
        placeholder={placeholder}
        items={exciseList}
        valueField="id"
        textField="short_name"
        allowClear={allowClear}
        isShowError={false}
        disabled={isDisabled}
        afterChanged={afterChanged}
        className={className}
      />
    </ExciseSelectWrapper>
  )
}

export default ExciseSelect
