import type { AxiosResponse, AxiosResponseTransformer } from 'axios'
import chardet from 'chardet'
import contentDisposition from 'content-disposition'
import { saveAs } from 'file-saver'

import { getNs } from '../i18n-config'
import { CSVCharset, SupportedTextCharset } from '../models/csvCharset'
import { RequestParams } from '../open-api/gen/http-client'

const { t } = getNs('common')

export const validateFileSize = (file: File, maxSize: number): boolean => {
  const sizeMB = file.size / 1024 / 1024

  if (sizeMB > maxSize) {
    return false
  }

  return true
}

/**
 * Download file by axios response to handle error response in React application.
 * @param response [AxiosResponse]
 */
export const downloadFileByResponse = (response: AxiosResponse): void => {
  const blob = new Blob([response.data], { type: response.headers['content-type'] })
  const filename: string | undefined = contentDisposition.parse(response.headers['content-disposition']).parameters
    ?.filename
  saveAs(blob, filename)
}

// TODO utf8 vs utf8bom
const inferEncoding = (file: File) => {
  const first5Mb = file.slice(0, 1048 * 5)
  return Promise.resolve(first5Mb.arrayBuffer()).then(buffer => {
    const charset = chardet.detect(new Uint8Array(buffer))
    return charset ? charset.toLowerCase() : charset
  })
}

const errorMsgs = t('messages.import_file_format_error_desc', { returnObjects: true })
export const isSupportedCharset = (file: File) => {
  return inferEncoding(file).then(charset => {
    const isSupported = !!charset && SupportedTextCharset.includes(charset as CSVCharset)
    return {
      isSupported,
      notSupportedMsg: isSupported ? null : errorMsgs,
      charset,
    }
  })
}

const downloadMiddleware: AxiosResponseTransformer = (data: any, headers: any) => {
  const fileName = contentDisposition.parse(headers['content-disposition']).parameters?.filename
  const type = headers['content-type']
  const blob = new Blob([data], { type })
  saveAs(blob, fileName)
  return { data }
}

export const downloadRequestParams: RequestParams = {
  format: 'blob',
  transformResponse: downloadMiddleware,
}
