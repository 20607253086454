import { yen } from 'aa_common/front-end/helpers/format-helper'
import { loadNs } from 'aa_common/front-end/i18n-config'
import * as depreciationRatesApi from 'api/app/depreciationRates'
import useResource from 'lib/hooks/useResource'
import { get } from 'lodash'
import { AssetLedger } from 'models/asset'
import React, { useEffect, useState } from 'react'

import { AmortizationTable } from '../styles'

const t = loadNs(['components/organisms/assets/asset-detail'])

interface INewDecliningBalanceMethodTable {
  ledger: AssetLedger | null
  depreciationRate: any
}

const NewDecliningBalanceMethodTable = ({ ledger, depreciationRate }: INewDecliningBalanceMethodTable) => {
  const [depreciationRates, getDepreciationRates] = useResource(depreciationRatesApi.get)
  const [renderDepreciationRate, setDepreciationRate] = useState<any>(null)

  useEffect(() => {
    if (ledger) {
      getDepreciationRates({ depreciation_method: ledger.depreciation_method.code, service_life: ledger.service_life })
    }
  }, [ledger]) // eslint-disable-line

  useEffect(() => {
    if (depreciationRates.data?.length > 0) {
      const depreciationRateData = get(depreciationRates, 'data[0]')
      setDepreciationRate(depreciationRateData)
    }
  }, [depreciationRates])

  return (
    <AmortizationTable>
      <tbody>
        <tr>
          <th>{t('depreciation_type')}</th>
          <td>
            <span>{get(ledger, 'depreciation_method.name_jp', 'ー')}</span>
          </td>

          <th>{t('residual_value')}</th>
          <td>
            <span>{ledger?.residual_amount ? yen(ledger?.residual_amount) : 'ー'}</span>
          </td>
        </tr>
        <tr>
          <th>{t('service_life')}</th>
          <td>
            <span>{`${t('service_life_amount', { amount: ledger?.service_life })}`}</span>
          </td>

          <th>{t('maximum_depreciation_amount')}</th>
          <td>
            <span>ー</span>
          </td>
        </tr>
        <tr>
          <th>{t('depreciation_rate')}</th>
          <td>
            <span>{`${depreciationRate || 'ー'}`}</span>
          </td>
          <th>{t('memorandum')}</th>
          <td>
            <span>{ledger?.memorandum_value ? yen(ledger?.memorandum_value) : 'ー'}</span>
          </td>
        </tr>
        <tr>
          <th>{t('guarantee_rate')}</th>
          <td colSpan={3}>
            <span>{get(renderDepreciationRate, 'guarantee_rate', 'ー')}</span>
          </td>
        </tr>
        <tr>
          <th>{t('revised_depreciation_rate')}</th>
          <td colSpan={3}>
            <span>{get(renderDepreciationRate, 'revised_depreciation_rate', 'ー')}</span>
          </td>
        </tr>
      </tbody>
    </AmortizationTable>
  )
}

export default NewDecliningBalanceMethodTable
