import { ACCOUNTING_PERIOD_TERM_MONTH_TYPES } from 'constants/masterData'
import moment from 'moment'

export const determineDefaultTermMonthDate = (startDate: string) => {
  const startDateMoment = moment(startDate)

  return startDateMoment.date() + 1 <= 14
    ? ACCOUNTING_PERIOD_TERM_MONTH_TYPES.BASE_ON_START_DATE
    : ACCOUNTING_PERIOD_TERM_MONTH_TYPES.BASE_ON_END_DATE
}
