import Form from 'components/molecules/Form'
import React, { useEffect, useState } from 'react'

import renderUnitForm from './Form'
import { Wrapper } from './style'

type Props = {
  loading: boolean
  errors: Array<any> | undefined
  values?: any
  onSubmit: (values: any) => void
}

const initialValues = {
  name: null,
  link_memorandum_value_quantity: false,
}

const UnitSubmitForm: React.FC<Props> = ({ errors = undefined, loading = false, values, onSubmit }) => {
  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    setFormValues(values)
  }, [setFormValues, values])

  return (
    <Wrapper>
      <Form value={formValues} errors={errors} onFormSubmit={onSubmit} loading={loading}>
        {renderUnitForm}
      </Form>
    </Wrapper>
  )
}

export default UnitSubmitForm
