import { Button, Spin } from 'aa_common/front-end/antd'
import { LinkLabel } from 'aa_common/front-end/components'
import { removeCookieByName } from 'aa_common/front-end/helpers'
import { getNs } from 'aa_common/front-end/i18n-config'
import { SessionTemplate } from 'components/templates'
import { LOGIN_FIRST_TIME_KEY, SSO_REDIRECT_PATH_KEY, SSO_TENANT_UID_KEY } from 'constants/auth'
import parser from 'html-react-parser'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { redirectUrl } from 'store/app/actions'
import { selectFlagInvalidOffice } from 'store/session/selectors'

import { buildMFIDAuthURL } from '../helper'
import { Content, ErrorWrapper, Title, Wrapper } from './styles'

const REACT_APP_INQUIRY_LINK = process.env.REACT_APP_INQUIRY_LINK as string

const { t, useNsReady } = getNs(['pages/user/login-page'])

const LoginPage: React.FC = () => {
  useNsReady()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const params = new URLSearchParams(useLocation().search)
  const isFirstTime = params.get('firstTime') === 'true'
  const flagInvalidOffice = useSelector(selectFlagInvalidOffice, isEqual)

  const handleLogin = () => {
    const mfidUrl = buildMFIDAuthURL()

    setLoading(true)
    dispatch(redirectUrl(mfidUrl))
  }

  const handleClickFirstTime = () => {
    dispatch(redirectUrl(REACT_APP_INQUIRY_LINK))
  }

  useEffect(() => {
    if (isFirstTime) {
      sessionStorage.setItem(LOGIN_FIRST_TIME_KEY, 'true')
    } else {
      sessionStorage.removeItem(LOGIN_FIRST_TIME_KEY)
    }
  }, [isFirstTime])

  useEffect(() => {
    sessionStorage.removeItem(SSO_TENANT_UID_KEY)
    sessionStorage.removeItem(SSO_REDIRECT_PATH_KEY)
    removeCookieByName('is_agent_login')
  }, []) // eslint-disable-line

  return (
    <SessionTemplate>
      <Spin loading={loading}>
        {flagInvalidOffice && (
          <ErrorWrapper>
            {t('error_message')}
            {t('help_center_link1')}
            <a href={REACT_APP_INQUIRY_LINK}>{t('help_link')}</a>
            {t('help_center_link2')}
          </ErrorWrapper>
        )}
        <Wrapper>
          <Title>{isFirstTime ? t('first_time_title') : t('title')}</Title>
          <Content>{parser(t('content'))}</Content>
          <Button onClick={handleLogin} data-testid="login-btn">
            {isFirstTime ? t('first_time_mfid_login') : t('mfid_login')}
          </Button>
          {!isFirstTime && (
            <LinkLabel style={{ display: 'block', marginTop: 5 }} onClick={handleClickFirstTime}>
              {t('first_time_login')}
            </LinkLabel>
          )}
        </Wrapper>
      </Spin>
    </SessionTemplate>
  )
}

export default LoginPage
