import { getNs } from '../i18n-config'
import { Term, TermMonth } from '../models'
import { formatMonth } from './format-helper'

const { t } = getNs('common')

export function getNextTermId(id: number, terms: Term[] = []) {
  const idx = terms.findIndex(term => term.id === id)
  return terms[idx + 1]?.id || id
}

export const generateTermsOptions = (terms: Term[] = [], disableToTermId?: number) => {
  const termsList = terms ?? []
  const i18nText = t('units.year_1')

  if (disableToTermId) {
    const idx = termsList.findIndex(term => term.id === disableToTermId)
    return termsList.map(({ id, year }, i) => ({
      value: id,
      text: `${year}${i18nText}`,
      disabled: idx > i,
    }))
  }

  return termsList.map(({ id, year }) => ({
    value: id,
    text: `${year}${i18nText}`,
    disabled: false,
  }))
}

export const generateAccountPeriods = (accountPeriods: Term[]) => {
  return accountPeriods?.map((item: Term) => ({
    value: item.id,
    label: t('format.term_year', { year: item.year }),
  }))
}

export const generateTermMonths = (termMonth: TermMonth[]) => {
  return termMonth?.map((item: TermMonth) => ({
    value: item.id,
    label: t('format.term_month', { month: formatMonth(item.month) }),
  }))
}
