import { Asset, AssetReport, AttachmentType, PhotoType } from 'aa_common/front-end/models'
import { CheckboxChangeEventTarget } from 'antd/es/checkbox/Checkbox'
import { FileLink, NoImage, PhotoView, ReferenceLink } from 'components/atoms'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import { setSelectRows } from 'pages/accounting/AssetEventListReportPage/store'

export const renderAssetPhoto = (asset: AssetReport | Asset) => {
  const isEmpty = !asset.photos || asset.photos.length === 0
  return isEmpty ? <NoImage /> : <img src={get(asset, 'photos[0].thumbnail_url')} alt="" />
}

export const renderPhotoList = (photos: PhotoType[] = []) => {
  return photos?.map((photo: PhotoType) => (
    <div key={uniqueId()}>
      <PhotoView src={get(photo, 'url')} style={{ marginRight: 10 }} />
    </div>
  ))
}

export const renderAttachmentList = (attachments: AttachmentType[], isDividingEvent: boolean) => {
  return attachments?.map((attachment: AttachmentType) => (
    <div key={uniqueId()}>
      <FileLink file={attachment} {...(isDividingEvent && { iconColor: '#000', style: { color: '#000' } })} />
    </div>
  ))
}

export const renderUrlList = (urls: string[], isDividingEvent: boolean) => {
  return urls?.map((url: string) => (
    <div key={uniqueId()}>
      <ReferenceLink url={url} {...(isDividingEvent && { iconColor: '#000' })} />
    </div>
  ))
}

export const handleSelectedItem = ({ name: id, checked: isSelected }: CheckboxChangeEventTarget) => {
  if (id) {
    setSelectRows([{ id, isSelected }])
  }
}
