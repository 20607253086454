import { useModalState } from 'aa_common/front-end/hooks/useModalState'
import { message } from 'antd'
import Input from 'components/atoms/v2/Input'
import i18n from 'i18n'
import { get } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import { isValidUrl } from '../../../../../helpers'
import { NewBasicModal as BasicModal } from '../../../../modals'
import { CustomModalClass } from './styles'

// eslint-disable-next-line no-restricted-globals
const EditLinkFormModal = React.forwardRef<any, { name: string; placeholder?: string }>(function EditLinkFormModal(
  { name, placeholder },
  ref
) {
  const { isShow, initialValues, handleOnClose } = useModalState<any>(ref)

  const [textValue, setTextValue] = useState('')
  const valueRef = useRef<string>('')

  useEffect(() => {
    valueRef.current = textValue
  }, [textValue])

  useEffect(() => {
    if (initialValues) {
      const url = get(initialValues, 'url', '')
      setTextValue(url)
    }
  }, [initialValues])

  useEffect(() => {
    !isShow && setTextValue('')
  }, [isShow])

  const handleValidate = () => {
    const text = textValue.trim()
    if (!text) return false
    const isValid = isValidUrl(text)

    if (!isValid) {
      message.error(i18n.t('common.messages.invalid_url', { url: text }))
    }

    return isValid
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const isValid = handleValidate()
      if (isValid) {
        const index = get(initialValues, 'index', null)
        handleOnClose({ index, url: textValue.trim() })
      }
    }
  }

  const handleOnBlur = () => handleValidate()

  return (
    <BasicModal
      isShow={isShow}
      className={CustomModalClass}
      title="&nbsp;"
      width={600}
      onCancel={() => {
        setTimeout(() => {
          const previousUrl = get(initialValues, 'url', '')
          const index = get(initialValues, 'index', '')
          const currentUrl = valueRef.current.trim()
          const isDifferent = previousUrl !== currentUrl && isValidUrl(currentUrl)
          handleOnClose(isDifferent ? { index, url: currentUrl } : undefined)
        }, 100)
      }}
    >
      <div style={{ padding: 10 }}>
        <Input
          name={`${name}-edit`}
          placeholder={placeholder}
          value={textValue}
          onChange={event => setTextValue(event.target.value)}
          onBlur={handleOnBlur}
          onKeyDown={handleOnKeyDown}
        />
      </div>
    </BasicModal>
  )
})

export default EditLinkFormModal
