import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Radio } from 'antd'
import i18n from 'i18n'
import React from 'react'

import { Tooltip } from '../../antd'
import { CSVCharset } from '../../models'

interface CharsetItem {
  label: React.ReactElement
  value: CSVCharset
}

const renderLabel = (item: string, tooltip: string, tooltipOverlayInnerStyle?: React.CSSProperties) => (
  <div style={{ display: 'flex' }}>
    {item}
    <Tooltip content={tooltip} overlayInnerStyle={tooltipOverlayInnerStyle}>
      <FontAwesomeIcon
        icon={faQuestionCircle}
        style={{ fontSize: 18, marginTop: 3, cursor: 'pointer', marginLeft: 5 }}
      />
    </Tooltip>
  </div>
)

type Props = {
  defaultCharset?: CSVCharset
  handleChange?: (charset: CSVCharset) => void
  style?: React.CSSProperties
  isShortCharsetLabel?: boolean
  tooltipOverlayInnerStyle?: React.CSSProperties
}

export const CSVCharsetRadios = ({
  defaultCharset = 'utf-8',
  handleChange,
  style,
  isShortCharsetLabel,
  tooltipOverlayInnerStyle,
}: Props) => {
  const items: CharsetItem[] = [
    {
      label: renderLabel(
        isShortCharsetLabel ? 'UTF-8' : 'UTF-8文字コード',
        i18n.t('components.ImportModal.tooltip.utf8'),
        tooltipOverlayInnerStyle
      ),
      value: 'utf-8',
    },
    {
      label: renderLabel(
        isShortCharsetLabel ? 'Shift-jis' : 'Shift-jis文字コード',
        i18n.t('components.ImportModal.tooltip.shift_jis'),
        tooltipOverlayInnerStyle
      ),
      value: 'shift_jis',
    },
  ]

  const onChange = (e: any) => {
    handleChange && handleChange(e.target.value)
  }

  return (
    <Radio.Group style={style} onChange={onChange} defaultValue={defaultCharset}>
      {items?.map(field => (
        <Radio value={field.value} key={field.value}>
          {field.label}
        </Radio>
      ))}
    </Radio.Group>
  )
}
