import { loadNs } from 'aa_common/front-end/i18n-config'
import { JournalItemSettingTable } from 'components/templates'
import get from 'lodash/get'
import React from 'react'

import { BaseTableViewProps, renderAccountNameFieldValue } from './helper'

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const SaleSettingTableView = ({ item }: BaseTableViewProps) => {
  return (
    <JournalItemSettingTable title={t('sale_settings_table')}>
      <tbody>
        <tr>
          <th className="section-name">{t('accumulated')}</th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_debit_accumulated_depreciation_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_debit_accumulated_depreciation_account_sub_item.name'))}
          </td>

          <th className="section-name">{t('asset_category')}</th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_credit_asset_category_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_credit_asset_category_account_sub_item.name'))}
          </td>
        </tr>
        <tr>
          <th className="section-name">{t('accumulated_impairment_loss')}</th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_debit_accumulated_impairment_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_debit_accumulated_impairment_account_sub_item.name'))}
          </td>

          <th className="section-name" />
          <td colSpan={2} />
        </tr>
        <tr>
          <th className="section-name">
            {t('income_subject')}
            <br />({t('income_subject_noted')})
          </th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_debit_revenue_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_debit_revenue_account_sub_item.name'))}
          </td>
          <th className="section-name">
            {t('payment_category')}
            <br />({t('disposal_cost')})
          </th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_credit_payment_category_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_credit_payment_category_account_sub_item.name'))}
          </td>
        </tr>
        <tr>
          <th className="section-name">{t('sale_loss')}</th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_debit_loss_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_debit_loss_account_sub_item.name'))}
          </td>

          <th className="section-name">{t('sale_gain')}</th>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_credit_gain_account_item.name'))}
          </td>
          <td className="view-table-cell">
            {renderAccountNameFieldValue(get(item, 'sale_credit_gain_account_sub_item.name'))}
          </td>
        </tr>
      </tbody>
    </JournalItemSettingTable>
  )
}

export default SaleSettingTableView
