import { AssignRolesOfficeUserPayload, InviteUserPayload } from 'models/officeUser'

import base from './base'

export const list = () => {
  return base.get('/v1/office_users')
}

export const getCreatableOfficeUsers = () => {
  return base.get('/v1/creatable_office_users')
}

export const getUserById = (id: number) => {
  return base.get(`/v1/office_users/${id}`)
}

export const assignRolesForUser = (payload: AssignRolesOfficeUserPayload) => {
  return base.post('/v1/office_users/assign_roles', payload)
}

export const inviteUser = (payload: InviteUserPayload) => {
  return base.post('/v1/office_users/invite', payload)
}

export const deleteOfficeUserById = (id: number) => {
  return base.delete(`/v1/office_users/${id}`)
}
