import './styles.scss'

import { getAssetPayload, parseDataForForm } from 'aa_common/front-end/helpers/asset-helper'
import { Drawer } from 'antd'
import * as assetApi from 'api/app/asset'
import { AssetForm } from 'components/organisms'
import useResource from 'lib/hooks/useResource'
import { isEqual } from 'lodash'
import { Asset } from 'models/asset'
import { FORM_MODES } from 'models/form'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setEditAssetItem, setImportAssetList } from 'store/assetImport/actions'
import { selectAssetImportItemData, selectAssetImportListData } from 'store/assetImport/selectors'
import { parseValidationError } from 'utils/ResponseHelper'

const SettingFormDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState<boolean>(false)
  const settingItem = useSelector<any, Asset>(selectAssetImportItemData, isEqual)
  const settingList = useSelector<any, Asset[]>(selectAssetImportListData, isEqual)
  const [validateAssetRes, validateAsset] = useResource(assetApi.create)
  const [formErrors, setFormErrors] = useState()

  const handleClose = () => {
    dispatch(setEditAssetItem(null))
    setFormErrors(undefined)
  }

  const handleSubmit = (values: any) => {
    validateAsset({ ...getAssetPayload(values), dry_run: true })
  }

  useLayoutEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'auto'
  }, [visible])

  useEffect(() => {
    setVisible(!!settingItem)
  }, [settingItem])

  useEffect(() => {
    if (validateAssetRes.error) {
      setFormErrors(parseValidationError(validateAssetRes.error))
    } else if (validateAssetRes.data) {
      const newSettingList = [...settingList]

      newSettingList[settingItem.index as number] = {
        ...parseDataForForm(validateAssetRes.data),
        index: settingItem.index,
      } as Asset
      dispatch(setImportAssetList(newSettingList))
      dispatch(setEditAssetItem(null))
      setFormErrors(undefined)
    }
  }, [validateAssetRes]) // eslint-disable-line

  return (
    <Drawer
      title=""
      placement="right"
      closable
      onClose={handleClose}
      visible={visible}
      className="asset-import-right-drawer"
      width={1060}
      destroyOnClose
    >
      <div id="asset-edit-import-form">
        {settingItem && (
          <AssetForm
            initialAssetData={settingItem}
            errors={formErrors}
            formMode={FORM_MODES.IMPORT}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </Drawer>
  )
}

export default SettingFormDrawer
