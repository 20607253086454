import { AutoCompleteSelect as Select, Button } from 'aa_common/front-end/antd'
import { ColorLabel, VerticalLine } from 'aa_common/front-end/components'
import { generateTermsOptions } from 'aa_common/front-end/helpers'
import { useFirstNotNullValue } from 'aa_common/front-end/hooks'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { RoundedMinusIcon, RoundedPlusIcon } from 'components/atoms'
import {
  ASSET_CODE_AUTO_GENERATE_SETTING_METHOD,
  ASSET_CODE_SETTING_RULE_TYPE_CODE,
  ASSET_CODE_SETTING_SCOPE_CODE,
} from 'constants/masterData'
import { GREY_BORDER_COLOR } from 'constants/styles'
import { isEqual } from 'lodash'
import { SettingRule } from 'models/assetCode'
import React, { ComponentProps, useEffect, useMemo } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'

import { displayAssetCodeFormatByRules } from '../AssetCodeAutoGenerationView/helper'
import { parseLengthRuleValues } from './helper'
import RulesSelection from './RulesSelection'
import { FormFooter, Row, RulesWrapper } from './styles'

const t = loadNs([
  'common',
  'components/organisms/settings/asset-code-auto-generation-form-modal',
  'components/organisms/settings/asset-code-auto-generation-view',
])

const { EACH_PATTERN } = ASSET_CODE_SETTING_SCOPE_CODE
const { ASSET_CATEGORY } = ASSET_CODE_SETTING_RULE_TYPE_CODE
const { AUTO_NUMBER } = ASSET_CODE_AUTO_GENERATE_SETTING_METHOD
const RULES_FIELD_NAME = 'rules'

const AutoCompleteSelect = (props: ComponentProps<typeof Select>) => {
  const { control } = useFormContext()
  return <Select control={control} allowClear={false} style={{ width: 240 }} {...props} />
}

export interface AssetCodeFormProp {
  allowSelectTerm: boolean
}

const Form = ({ allowSelectTerm }: AssetCodeFormProp) => {
  const terms = useSelector(selectTermsData, isEqual)
  const assetCodeSettings = useSelector(selectMasterData)?.asset_code_settings

  const { getValues } = useFormContext()
  const {
    fields: rulesFields,
    append: appendRule,
    remove: removeRule,
    prepend: prependRule,
  } = useFieldArray({ name: RULES_FIELD_NAME })
  const generatingScope = useWatch({ name: 'generating_scope' })

  useEffect(() => {
    const [firstRule] = getValues(RULES_FIELD_NAME) || []
    if (generatingScope === ASSET_CATEGORY && firstRule?.field !== ASSET_CATEGORY) {
      prependRule({ field: ASSET_CATEGORY, value: 3 })
    }
  }, [generatingScope, getValues, prependRule])

  const rules = useWatch({ name: RULES_FIELD_NAME, defaultValue: [] as SettingRule[] })
  const values = useWatch({ name: ['generating_method', 'generating_scope', 'length_rule'] })

  const fromTermId = useFirstNotNullValue(useWatch<number>({ name: 'from_term_id' }))
  const termList = useMemo(() => {
    return generateTermsOptions(terms, fromTermId)
  }, [terms, fromTermId])

  return (
    <>
      <Row style={{ alignItems: 'center' }}>
        <div className="label">{t('setting_method')}</div>
        <div>
          <AutoCompleteSelect
            name="generating_method"
            items={assetCodeSettings.auto_generating_serial_number_setting_method.values}
            textField="name_jp"
            valueField="code"
          />
        </div>
      </Row>
      {values.generating_method === AUTO_NUMBER && (
        <>
          <Row style={{ alignItems: 'center' }}>
            <div className="label">{t('setting_scope')}</div>
            <div>
              <AutoCompleteSelect
                name="generating_scope"
                items={assetCodeSettings.auto_generating_serial_number_setting_scope.values}
                textField="name_jp"
                valueField="code"
              />
            </div>
          </Row>
          <Row>
            <div className="label" style={{ marginTop: 4 }}>
              {t('setting_rules')}
            </div>
            <RulesWrapper>
              {rulesFields.map((rule, index) => (
                <div key={rule.id} className="container">
                  <RulesSelection name={`rules[${index}]`} defaultValue={{ ...rule }} rules={rules} />
                  {values?.generating_scope === EACH_PATTERN && rule.field === ASSET_CATEGORY ? (
                    <RoundedMinusIcon style={{ marginLeft: 5, cursor: 'not-allowed' }} color="#D4D8DD" />
                  ) : (
                    <RoundedMinusIcon style={{ marginLeft: 5 }} onClick={() => removeRule(index)} />
                  )}
                  <VerticalLine height={30} color={GREY_BORDER_COLOR} />
                </div>
              ))}
              <div className="container">
                <RoundedPlusIcon
                  color="#3B7DE9"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    appendRule({})
                  }}
                />
                <VerticalLine height={30} color={GREY_BORDER_COLOR} />
                <AutoCompleteSelect
                  name="length_rule"
                  items={parseLengthRuleValues(
                    assetCodeSettings,
                    assetCodeSettings.auto_generating_serial_number_setting_length.values
                  )}
                  textField="text"
                  valueField="code"
                  style={{ width: 200 }}
                />
              </div>
            </RulesWrapper>
          </Row>
          <Row>
            <div className="label" />
            <div>
              <ColorLabel color="disabled">
                {t('preview')}
                {displayAssetCodeFormatByRules(assetCodeSettings, rules, values?.length_rule)}
              </ColorLabel>
            </div>
          </Row>
        </>
      )}
      <FormFooter>
        <label>{t('edit_start_year')}</label>
        <AutoCompleteSelect
          name="from_term_id"
          textField="text"
          valueField="value"
          items={termList}
          disabled={!allowSelectTerm}
          style={{ width: 110 }}
        />
        <Button type="submit" size="custom">
          {t('actions.save')}
        </Button>
      </FormFooter>
    </>
  )
}

export default Form
