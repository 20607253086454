import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from 'antd'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { selectCurrentOffice, selectUserInfo } from 'store/session/selectors'

import { Tooltip } from '../../antd'
import {
  URL_AA_SUPPORT,
  URL_ANNOUNCEMENT_REGARDING_EXTERNAL_SENDING_TOOL,
  URL_PRIVACY_POLICY,
  URL_TERMS_OF_SERVICE,
  URL_ZENDECK,
  ZENDECK_SERVICE_NAME,
  ZENDECK_TICKET_FORM_ID,
} from '../../constants/app'
import { ACLinkStyled, FaqWrapperClass } from './styles'

type FaqProps = {
  isShowFullOptions?: boolean
}

export const Faq = ({ isShowFullOptions = false }: FaqProps) => {
  const { data: userInfo } = useSelector(selectUserInfo, isEqual)
  const { data: currentOffice } = useSelector(selectCurrentOffice, isEqual)

  const zendeckQueryParams = {
    identification_code: currentOffice?.identification_code,
    mail: userInfo?.email,
    office_type_id: `${currentOffice?.id}`,
    service_name: ZENDECK_SERVICE_NAME,
    ticket_form_id: ZENDECK_TICKET_FORM_ID,
    user_identification_code: userInfo?.identification_code,
  }

  const links = [
    { text: 'support_site', href: URL_AA_SUPPORT, isShow: true },
    {
      text: 'inquiry_form',
      href: `${URL_ZENDECK}?${new URLSearchParams(zendeckQueryParams).toString()}`,
      isShow: true,
    },
    { text: 'terms_of_service', href: URL_TERMS_OF_SERVICE, isShow: isShowFullOptions },
    { text: 'privacy_policy', href: URL_PRIVACY_POLICY, isShow: isShowFullOptions },
    {
      text: 'announcement_regarding_external_sending_tool',
      href: URL_ANNOUNCEMENT_REGARDING_EXTERNAL_SENDING_TOOL,
      isShow: isShowFullOptions,
    },
  ]

  return (
    <Popover
      overlayClassName={FaqWrapperClass}
      placement="bottomRight"
      arrowPointAtCenter
      trigger="click"
      content={
        <div>
          {links.map(link => {
            if (!link.isShow) return null
            return (
              <ACLinkStyled key={link.text} href={link.href} target="_blank" rel="noreferrer">
                {i18n.t(`components.MainHeader.faq.${link.text}`)}
              </ACLinkStyled>
            )
          })}
        </div>
      }
    >
      <span className="header-icon">
        <Tooltip
          arrowPointAtCenter
          placement="bottom"
          overlayStyle={{ pointerEvents: 'none' }}
          content={i18n.t('components.MainHeader.faq.tooltip')}
        >
          <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
        </Tooltip>
      </span>
    </Popover>
  )
}
