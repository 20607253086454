/* eslint-disable react/display-name */
import { ComplexTable } from 'aa_common/front-end/antd'
import { ColumnType } from 'antd/es/table'
import { isEqual } from 'lodash'
import { OfficeUser } from 'models/officeUser'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { getUserInfoSelector } from 'store/session/selectors'

import { userTableColumns } from './settings'
import { Wrapper } from './styles'
import UserListActions from './UserListActions'

interface IUserList {
  data: OfficeUser[]
  onItemDeleteClick: (item: OfficeUser) => void
}

const UserList = ({ data, onItemDeleteClick }: IUserList) => {
  const dispatch = useDispatch()
  const userInfo = useSelector(getUserInfoSelector, isEqual)

  const columns = useMemo(() => userTableColumns(userInfo), [userInfo])

  const handleEditClick = useCallback(
    (item: OfficeUser) => {
      dispatch(changeLocation(`/setting/users/${item.id}/edit`))
    },
    [dispatch]
  )

  const handleDeleteClick = useCallback(
    (item: OfficeUser) => {
      onItemDeleteClick && onItemDeleteClick(item)
    },
    [onItemDeleteClick]
  )

  const actions = useMemo<ColumnType<OfficeUser>>(() => {
    return {
      width: 100,
      key: 'action',
      fixed: 'right',
      render: (_, record) => {
        const isYourself = record.name === userInfo?.display_name
        return (
          <UserListActions
            item={record}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            isYourSelf={isYourself}
          />
        )
      },
    }
  }, [handleEditClick, handleDeleteClick, userInfo])

  const memoizedData = useMemo(() => {
    return data || []
  }, [data])

  return (
    <Wrapper>
      <ComplexTable
        data={memoizedData}
        tableLayout="auto"
        columns={columns}
        actions={actions}
        rowKey="id"
        enableSelectRows={false}
      />
    </Wrapper>
  )
}

export default React.memo(UserList)
