import { AutoCompleteSelect, Button } from 'aa_common/front-end/antd'
import { ColorLabel, LinkLabel } from 'aa_common/front-end/components'
import {
  checkSettingBelongToMonthlyClosing,
  checkTermBelongToTermSpan,
  generateTermsOptions,
  getNextTermId,
} from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { LineAddNew } from 'components/atoms'
import { FEATURES_ID } from 'constants/const'
import { ASSET_CODE_AUTO_GENERATE_SETTING_METHOD } from 'constants/masterData'
import useUserPermission from 'lib/hooks/useUserPermission'
import { AutoGenerateSetting } from 'models/assetCode'
import { Term } from 'models/term'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'
import { selectAssetCodeSettings } from 'store/masterData/selectors'
import { setTermYearSelected } from 'store/settings/assetCode/actions'
import { selectAssetCodeAutoGenerateSettingListData, selectTermYearSelected } from 'store/settings/assetCode/selectors'

import {
  displayAssetCodeFormatByRules,
  displayFulltextAssetCodeFormatByRules,
  displayPeriod,
  getSettingMethodText,
  getSettingScopeText,
  renderOtherSettingsCount,
} from './helper'
import { LastRow, Row, Table, TopInfo, Wrapper, WrapperActions } from './styles'

const t = loadNs(['common', 'components/organisms/settings/asset-code-auto-generation-view'])

type CallbackFn = (setting: AutoGenerateSetting) => void
type Props = {
  terms: Term[]
  onClickAdd: CallbackFn
  onClickTopEdit: CallbackFn
  onClickEdit: CallbackFn
  onClickDelete: CallbackFn
}

const showNoPermissionMessage = (permission: boolean) => (!permission ? t('actions.no_permission') : undefined)

const AssetCodeAutoGenerationView: React.FC<Props> = ({
  terms,
  onClickAdd,
  onClickTopEdit,
  onClickEdit,
  onClickDelete,
}) => {
  const dispatch = useDispatch()
  const { permissions } = useUserPermission(FEATURES_ID.SETTING_AUTO_GEN_SERIAL_NUMBER)

  const assetCodeAutoGenerateSettingListData = useSelector(selectAssetCodeAutoGenerateSettingListData)
  const assetCodeMasterSettings = useSelector(selectAssetCodeSettings)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent)
  const termYearSelected = useSelector(selectTermYearSelected)

  const [isShowFullYears, setIsShowFullYears] = useState<boolean>(false)

  const handleCheckAllYears = (e: CheckboxChangeEvent) => {
    setIsShowFullYears(e.target.checked)
  }

  const handleTermYearChange = (termYear: number) => {
    dispatch(setTermYearSelected(termYear))
  }

  const handleClickStartNumberSetting = () => {
    dispatch(changeLocation('/setting/asset-codes/start-number'))
  }

  const termsItems = useMemo(() => generateTermsOptions(terms), [terms])

  useEffect(() => {
    if (!termYearSelected && termsItems.length) {
      handleTermYearChange(currentMonthlyClosing?.from_term_month?.term_id)
    }
  }, [termsItems, currentMonthlyClosing, termYearSelected]) // eslint-disable-line

  const settingList = useMemo(() => {
    if (assetCodeAutoGenerateSettingListData) {
      if (isShowFullYears) {
        return assetCodeAutoGenerateSettingListData.sort((a, b) => b.from_term_id - a.from_term_id)
      }

      if (terms && termYearSelected) {
        const term = terms.find(term => term.id === termYearSelected)
        return assetCodeAutoGenerateSettingListData.filter(setting => checkTermBelongToTermSpan(setting, term))
      }

      if (currentMonthlyClosing) {
        return assetCodeAutoGenerateSettingListData.filter(setting =>
          checkSettingBelongToMonthlyClosing(setting, currentMonthlyClosing)
        )
      }
    }

    return []
  }, [isShowFullYears, assetCodeAutoGenerateSettingListData, currentMonthlyClosing, termYearSelected, terms])

  return (
    <Wrapper>
      <TopInfo>
        <div>
          <AutoCompleteSelect
            textField="text"
            valueField="value"
            name="term_year_filter"
            allowClear={false}
            items={termsItems}
            disabled={!termsItems.length || isShowFullYears}
            value={termYearSelected}
            onChange={handleTermYearChange}
          />
          <ColorLabel color={isShowFullYears ? 'disabled' : 'normal'}>
            {t('display_at', {
              year: currentMonthlyClosing?.from_term_month?.year,
            })}
          </ColorLabel>
          <Checkbox style={{ marginLeft: 20 }} onChange={handleCheckAllYears}>
            <span>{t('display_all')}</span>
            <ColorLabel color={isShowFullYears ? 'disabled' : 'normal'}>
              {renderOtherSettingsCount(assetCodeAutoGenerateSettingListData, currentMonthlyClosing)}
            </ColorLabel>
          </Checkbox>
        </div>
        <Button
          color="grey"
          size="custom"
          showTooltipMessage={!isShowFullYears ? showNoPermissionMessage(permissions.isCRU) : undefined}
          style={{ width: 74 }}
          onClick={() => onClickTopEdit({ ...settingList[0] })}
          disabled={isShowFullYears || !permissions.isCRU || !!settingList[0]?.to_term}
        >
          {t('actions.edit')}
        </Button>
      </TopInfo>
      {isShowFullYears && (
        <LineAddNew
          showTooltipMessage={!permissions.isCRU ? t('actions.no_permission') : undefined}
          disabled={!permissions.isCRU}
          onClick={() => {
            onClickAdd({
              ...settingList[0],
              from_term_id: getNextTermId(settingList[0]?.from_term_id, terms),
            })
          }}
        />
      )}
      {settingList.map((settingItem: AutoGenerateSetting, index) => {
        return (
          <div key={settingItem?.id}>
            <Table>
              <Row>
                <div className="label">{t('validity_period')}</div>
                <div style={{ position: 'relative', display: 'flex' }}>
                  <div>{displayPeriod(settingItem)}</div>
                  {checkSettingBelongToMonthlyClosing(settingItem, currentMonthlyClosing) && (
                    <span
                      style={{
                        color: '#666',
                        marginLeft: '5px',
                      }}
                    >
                      {t('the_processing_year_is_in_this_period')}
                    </span>
                  )}
                </div>
              </Row>
              <Row>
                <div className="label">{t('setting_method')}</div>
                <div>{getSettingMethodText(assetCodeMasterSettings, settingItem.generating_method)}</div>
              </Row>
              {settingItem.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER && (
                <>
                  <Row>
                    <div className="label">{t('setting_scope')}</div>
                    <div style={{ width: 130 }}>
                      {getSettingScopeText(assetCodeMasterSettings, settingItem.generating_scope)}
                    </div>
                    {!isShowFullYears && (
                      <LinkLabel
                        showTooltipMessage={showNoPermissionMessage(permissions.isCRU)}
                        disabled={!permissions.isCRU}
                        onClick={handleClickStartNumberSetting}
                      >
                        {t('start_number_setting')}
                      </LinkLabel>
                    )}
                  </Row>
                  <Row className="last">
                    <div className="label">{t('setting_rules')}</div>
                    <div style={{ width: 675 }}>
                      <div>
                        {displayFulltextAssetCodeFormatByRules(
                          assetCodeMasterSettings,
                          settingItem.rules,
                          settingItem.length_rule
                        )}
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <ColorLabel color="disabled">
                          {`${t('preview')}${displayAssetCodeFormatByRules(
                            assetCodeMasterSettings,
                            settingItem.rules,
                            settingItem.length_rule
                          )}`}
                        </ColorLabel>
                      </div>
                    </div>
                  </Row>
                </>
              )}
              {isShowFullYears && (
                <LastRow>
                  <WrapperActions>
                    {index === 0 && settingList.length > 1 && (
                      <LinkLabel
                        showTooltipMessage={showNoPermissionMessage(permissions.isDelete)}
                        disabled={!permissions.isDelete}
                        type="danger"
                        onClick={() => onClickDelete(settingItem)}
                      >
                        {t('delete')}
                      </LinkLabel>
                    )}
                    <LinkLabel
                      showTooltipMessage={showNoPermissionMessage(permissions.isEdit)}
                      disabled={!permissions.isEdit}
                      onClick={() => onClickEdit(settingItem)}
                    >
                      {t('edit')}
                    </LinkLabel>
                  </WrapperActions>
                </LastRow>
              )}
            </Table>
          </div>
        )
      })}
    </Wrapper>
  )
}

export default React.memo(AssetCodeAutoGenerationView)
