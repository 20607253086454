import { Tooltip } from 'aa_common/front-end/antd'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { ColumnType } from 'antd/es/table'
import { AccountItem, AccountSubItem } from 'models/accountItem'
import React from 'react'

import { StyledCheckbox } from './styles'

const t = loadNs(['components/organisms/settings/account-item-list'])

const renderColumnContent = (name: string, checked: boolean) => {
  return (
    <Tooltip content={t('tooltip_title')} placement="top" color="black" mouseLeaveDelay={0}>
      <span style={{ cursor: 'not-allowed' }}>
        <StyledCheckbox name={name} disabled checked={checked} style={{ pointerEvents: 'none' }} />
      </span>
    </Tooltip>
  )
}

export const getColumns = (): ColumnType<any>[] => [
  {
    title: t('status'),
    key: 'account_items_status',
    align: 'center',
    width: 48,
    render(accountItem: any) {
      if (accountItem?.children) {
        return renderColumnContent(accountItem?.name, accountItem?.is_active)
      }

      return null
    },
  },
  {
    title: t('account_item_code'),
    dataIndex: 'code',
    width: 148,
  },
  {
    title: t('account_name'),
    dataIndex: 'name',
    width: 148,
  },
  {
    title: t('excise'),
    dataIndex: 'excise_label',
    width: 148,
  },
  {
    title: t('search_key'),
    dataIndex: 'search_key',
    width: 148,
  },
  {
    title: t('status'),
    key: 'account_sub_items',
    width: 48,
    className: 'border-left',
    align: 'center',
    render(data: any) {
      if (data.is_account_sub_item) {
        return renderColumnContent(data?.account_sub_item_name, data?.account_sub_item_is_active)
      }

      return null
    },
  },
  {
    title: t('account_sub_item_code'),
    dataIndex: 'account_sub_item_code',
    width: 148,
  },
  {
    title: t('account_item_name'),
    dataIndex: 'account_sub_item_name',
    width: 148,
  },
  {
    title: t('excise'),
    dataIndex: 'account_sub_item_excise_label',
    width: 148,
  },
  {
    title: t('search_key'),
    dataIndex: 'account_sub_item_search_key',
    width: 148,
  },
]

const buildAccountSubItems = (accountSubItems: AccountSubItem[]): any => {
  if (!accountSubItems) return []

  return accountSubItems.map(accountSubItem => {
    return {
      key: accountSubItem.unique_id,
      is_account_sub_item: true,
      account_sub_item_code: accountSubItem.code,
      account_sub_item_is_active: accountSubItem.is_active,
      account_sub_item_name: accountSubItem.name,
      account_sub_item_excise: accountSubItem.excise,
      account_sub_item_search_key: accountSubItem.search_key,
      account_sub_item_excise_label: accountSubItem?.excise?.long_name,
    }
  })
}

export const buildDataForTable = (data: AccountItem[]): any => {
  return data.map(accountItem => {
    return {
      ...accountItem,
      key: accountItem.unique_id,
      excise_label: accountItem?.excise?.long_name,
      children: buildAccountSubItems(accountItem.account_sub_items),
    }
  })
}
