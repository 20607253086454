import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { AccountItemList } from 'components/organisms'
import { ListPageTemplate } from 'components/templates'
import React from 'react'

const t = loadNs(['components/organisms/main-menu'])

const AccountItemListPage: React.FC = () => {
  const breadcrumb = <BreadcrumbNavigation sectionName={t('master.account_item')} />
  return (
    <ListPageTemplate breadcrumb={breadcrumb} maxWidth="100%">
      <AccountItemList />
    </ListPageTemplate>
  )
}

export default AccountItemListPage
