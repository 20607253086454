import styled from '@emotion/styled'
import { Checkbox } from 'aa_common/front-end/antd'
import { getNs } from 'aa_common/front-end/i18n-config'
import { isEqual } from 'lodash'
import { FixedAssetListItem } from 'models/asset'
import { assetEventListReportSlice, assetEventListReportStore } from 'pages/accounting/AssetEventListReportPage/store'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectAssetEventsReport } from 'store/accounting/assetEventsReport/selectors'

import { useGetSelectedEventOnPage } from './hooks'

const { t, useNsReady } = getNs(['pages/assets'])

export const CollectiveCheckbox = ({ eventType }: { eventType?: number }) => {
  useNsReady()
  const assetReportCollectionSelectorData = useSelector(selectAssetEventsReport, isEqual)?.data
  const rowSelected = assetEventListReportSlice.useSlice(state => state.data?.selectedItem)
  const amountOfSelectedAssets = rowSelected
    ? Object.keys(rowSelected)?.filter((key: string) => rowSelected[key]).length
    : 0
  // Get the list of biid in current list page
  const numberOfSelectedRowOnPage = useGetSelectedEventOnPage(eventType).selectedEventIds?.length

  const isIndeterminate =
    numberOfSelectedRowOnPage > 0 && numberOfSelectedRowOnPage < assetReportCollectionSelectorData.data?.length
  const isCheckedAll = !isIndeterminate && numberOfSelectedRowOnPage > 0

  const handleCollectiveChange = () => {
    // Select/unselect all rows
    assetReportCollectionSelectorData.data?.forEach((item: FixedAssetListItem) => {
      assetEventListReportStore.setSelectRows([{ id: item?.id, isSelected: !(isIndeterminate || isCheckedAll) }])
    })
  }

  return (
    <CheckboxWrapper data-testid="collective-checkbox">
      <Checkbox
        className={isCheckedAll ? '' : 'uncheck-collective'}
        name="collective"
        onChange={handleCollectiveChange}
        checked={isCheckedAll}
        indeterminateValue={isIndeterminate}
      >
        {amountOfSelectedAssets > 0 && (
          <span>{t('selectedItems', { numberOfSelectedRow: amountOfSelectedAssets })}</span>
        )}
      </Checkbox>
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled.div`
  display: inline-block;
  float: left;

  label,
  label > span,
  div {
    display: inline-block;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    height: 2px !important;
  }
  .uncheck-collective {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: transparent;
      border: 1px solid #d9d9d9;
    }
  }
`
