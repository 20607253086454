import { DEPRECIATION_METHOD_CODE } from 'aa_common/front-end/constants'
import {
  formatDate,
  formatDateTime,
  identifyJournalStatusType,
  objectToQueryParams,
  yen,
} from 'aa_common/front-end/helpers'
import { Asset } from 'aa_common/front-end/models'
import { useTaxMethodExciseAsset } from 'components/organisms/assets/hooks/useTaxMethodExciseAsset'
import i18n from 'i18n'
import get from 'lodash/get'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'

import { renderAssetPhoto } from './helpers'
import { JournalStatus } from './JournalStatus'
import { Media } from './Media'
import { Row } from './Row'
import { AssetDisplay, AssetDisplayDataRow, AssetDisplayHeader } from './styles'

export const DividingEventView = ({ assets }: Props) => {
  const dispatch = useDispatch()

  const handleAssetClick = (asset: Asset) => {
    if (!asset.deleted_at) {
      const paramObj = {
        valid_at: asset?.divided_at ? asset?.divided_at : asset.acquired_at,
      }
      const params = `?${objectToQueryParams(paramObj)}`

      dispatch(changeLocation(`/assets/${asset.biid || ''}${params}`))
    }
  }

  if (!assets) return null
  return (
    <>
      {assets.map(asset => {
        return <AssetInformation key={asset?.id} asset={asset} onAssetClick={handleAssetClick} />
      })}
    </>
  )
}

const AssetInformation = ({ asset, onAssetClick }: AssetInformationProps) => {
  const { isIncludedTax } = useTaxMethodExciseAsset()
  const isDeleted = !!asset.deleted_at
  const termMonthId = asset?.term_month?.month || null
  const dMethodOfFirstLedger = get(asset, 'fixed_asset_ledgers[0].depreciation_method_code')

  const getLabel = (key: string) => i18n.t(`components.AssetEventsReport.${key}`)

  const getValue = (name: string, isValueOfLedger: boolean = false) => {
    const fieldName = isValueOfLedger ? `fixed_asset_ledgers[0].${name}` : name
    return get(asset, fieldName)
  }

  const renderMemorandumValueAndResidualAmountFields = () => {
    const isShowResidualAmount = [
      DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
      DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
    ].includes(dMethodOfFirstLedger)

    const isShowMemorandumValue = [
      DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE,
      DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE,
      DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE,
      DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250,
      DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200,
    ].includes(dMethodOfFirstLedger)

    const isHiddenBothMemorandumValueAndResidualAmount = [
      DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT,
      DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
    ].includes(dMethodOfFirstLedger)

    if (isHiddenBothMemorandumValueAndResidualAmount) return null

    if (isShowMemorandumValue) {
      return <Row label={getLabel('memorandum_value')} value={yen(getValue('memorandum_value', true))} />
    }

    if (isShowResidualAmount) {
      return <Row label={getLabel('residual_amount')} value={yen(getValue('residual_amount', true))} />
    }
  }

  return (
    <AssetDisplay onClick={() => onAssetClick(asset)}>
      <AssetDisplayHeader>
        <div className="status">
          {!isDeleted && <JournalStatus type={identifyJournalStatusType(asset)} termMonthId={termMonthId} />}
        </div>
        <div className="photo">{renderAssetPhoto(asset)}</div>
        <div className="asset-code">{`${asset.code}-${asset.branch_code}`}</div>
        <div className="asset-name">{asset.name}</div>
      </AssetDisplayHeader>
      <AssetDisplayDataRow>
        <div className="status no-bottom-border">
          {isDeleted && <JournalStatus type={identifyJournalStatusType(asset)} termMonthId={termMonthId} />}
        </div>
        <div className="empty-space no-bottom-border" />
        <div className="short-label">{asset.valid_from}</div>
        <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
        <div className="label text-color-666">{getLabel('registered_at')}</div>
        <div className="value text-color-666">{formatDateTime(asset.created_at as string)}</div>
      </AssetDisplayDataRow>
      <Row label={getLabel('divided_at')} value={formatDate(getValue('divided_at')) as string} />
      <Row label={getLabel('cause_of_divide')} value={getValue('event_cause.content')} />
      <Row
        label={getLabel('standard_date_to_show_book_value')}
        value={formatDate(getValue('dividing_book_value_standard_date', true)) as string}
      />
      <Row label={getLabel('book_value_when_dividing')} value={yen(getValue('dividing_book_value', true))} />
      <Row
        label={getLabel('acquisition_cost')}
        value={yen(getValue(isIncludedTax ? 'acquisition_cost_included_excise' : 'acquisition_cost_excluded_excise'))}
      />
      {!isIncludedTax && (
        <>
          <Row label={getLabel('excise_amount')} value={yen(getValue('excise_value'))} />
          <Row
            label={getLabel('acquisition_included_excise_amount')}
            value={yen(getValue('acquisition_cost_included_excise'))}
          />
        </>
      )}
      <Row label={getLabel('quantity')} value={getValue('quantity')} />
      {renderMemorandumValueAndResidualAmountFields()}
      <Media asset={asset} />
      <Row label={getLabel('created_by')} value={getValue('user_info.display_name')} />
    </AssetDisplay>
  )
}

type Props = {
  assets: Asset[]
}

type AssetInformationProps = {
  asset: Asset
  onAssetClick: (asset: Asset) => void
}
