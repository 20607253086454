import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { UserForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import { FORM_MODES } from 'models/form'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCreatableUsers,
  fetchUserRoleOptions,
  inviteUser,
  resetOfficeUser,
} from 'store/settings/officeUser/actions'
import {
  selectorCreatableOfficeUsers,
  selectorOfficeUserItem,
  selectorOfficeUserRoleOptions,
} from 'store/settings/officeUser/selectors'

const t = loadNs(['pages/settings/users'])

const UserNewPage = () => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('add_user')} />
  const creatableUsersSelector = useSelector(selectorCreatableOfficeUsers, isEqual)
  const officeUserItemSelector = useSelector(selectorOfficeUserItem, isEqual)
  const rolesSelector = useSelector(selectorOfficeUserRoleOptions, isEqual)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCreatableUsers())
    dispatch(fetchUserRoleOptions())

    return () => {
      dispatch(resetOfficeUser())
    }
  }, []) // eslint-disable-line

  const memoizedRoles = useMemo(() => rolesSelector.data, [rolesSelector.data])
  const memoizedErrors = useMemo(() => officeUserItemSelector.errors, [officeUserItemSelector.errors])
  const memoizedCreatableUsers = useMemo(() => creatableUsersSelector.data, [creatableUsersSelector.data])

  const handleSubmit = useCallback(
    (formValues: any) => {
      const { id, role_biids } = formValues
      const payload = { id, role_biids }
      dispatch(inviteUser(payload))
    },
    [dispatch]
  )

  const isLoading = creatableUsersSelector.loading || rolesSelector.loading || officeUserItemSelector.loading

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <UserForm
        errors={memoizedErrors}
        creatableUsers={memoizedCreatableUsers}
        onSubmit={handleSubmit}
        type={FORM_MODES.NEW}
        roles={memoizedRoles}
        loading={isLoading}
      />
    </MainTemplate>
  )
}

export default UserNewPage
