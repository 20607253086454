import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { LINK_COLOR } from 'constants/styles'

export const Wrapper = styled.div`
  max-width: 1200px;
`

export const ErrorArea = styled.div`
  margin-top: 26px;
`

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    min-width: 330px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1 0%;
  }

  .display-all-checkbox {
    border-left: 1px solid #d4d8dd;
    padding-left: 15px;

    &:first-of-type {
      margin-left: 5px;
    }
  }
`

export const EmptyImage = styled.div`
  margin-top: 60px;
  text-align: center;
`

export const SearchFilterDatePickerClass = css`
  max-width: 170px;
  width: 100%;

  .ant-picker .ant-picker-input > input {
    text-align: left;
  }
`

export const Link = styled.label`
  color: ${LINK_COLOR};
  cursor: pointer;
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  margin: 10px 0;
`
