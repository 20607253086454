import React from 'react'
import { Controller } from 'react-hook-form'

import { IUploadImage } from './model'
import CoreUploadImage from './UploadImage'

const UploadImage = ({ name, mode = 'edit', value = [], onChange, control }: IUploadImage) => {
  // *** Form UploadImage ***
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ onChange: controllerOnChange, value, name }) => {
          return (
            <CoreUploadImage
              value={value}
              name={name}
              mode={mode}
              onChange={fileList => {
                controllerOnChange(fileList)
                onChange && onChange(fileList)
              }}
            />
          )
        }}
      />
    )
  }

  // *** regular UploadImage ***
  return <CoreUploadImage value={value} name={name} mode={mode} onChange={onChange} />
}

export default React.memo(UploadImage)
