import { formatMonthDay, loadNs, yenWithoutSymbolCheckNull } from 'aa_common/front-end'
import { Tooltip } from 'antd'
import { get } from 'lodash'
import React from 'react'

import JournalActions from './JournalActions'

const t = loadNs(['pages/accounting/journal-list', 'components/organisms/accounting/journal-list'])
const renderContent = (row: any, field1: string, field2: string) => {
  return (
    <>
      {get(row, field1)}
      <br />
      {get(row, field2)}
      <br />
    </>
  )
}

const renderTextTooltip = ({
  valueExcludedExcise,
  calculatedExciseValue,
  manualExciseValue,
  valueIncludedExcise,
}: any) => {
  let text = ''
  if (valueExcludedExcise) text += `${t('tooltip_value_excluded_excise', { valueExcludedExcise })} `
  if (calculatedExciseValue) text += `${t('tooltip_calculated_excise_value', { calculatedExciseValue })} `
  if (manualExciseValue) text += `${t('tooltip_manual_excise_value', { manualExciseValue })} `
  if (valueIncludedExcise) text += t('tooltip_value_included_excise', { valueIncludedExcise })

  return text
}

const getRowClassName = (journalNum: number, className?: string) => {
  return journalNum % 2 === 0 ? className || '' : 'odd-num-journal'
}

const renderTitle = (key1: string, key2: string) => (
  <>
    {t(key1)}
    <br />
    {t(key2)}
  </>
)

const renderValueExcise = (isIncluded: boolean, key: string, className?: string) => {
  return {
    title: renderTitle('value', 'excise'),
    key: `${key}_value_excise`,
    className: 'border-left',
    render: (_: any, row: any) => {
      return {
        children: (
          <>
            {isIncluded || !row[`${key}_excise_id`] ? (
              <span>{yenWithoutSymbolCheckNull(row[`${key}_value`])}</span>
            ) : (
              <Tooltip
                overlayStyle={{ maxWidth: 500 }}
                placement="top"
                title={renderTextTooltip({
                  valueExcludedExcise: yenWithoutSymbolCheckNull(row[`${key}_value_excluded_excise`]),
                  calculatedExciseValue: yenWithoutSymbolCheckNull(row[`${key}_calculated_excise_value`]),
                  manualExciseValue: yenWithoutSymbolCheckNull(row[`${key}_manual_excise_value`]),
                  valueIncludedExcise: yenWithoutSymbolCheckNull(row[`${key}_value_included_excise`]),
                })}
              >
                <span>{yenWithoutSymbolCheckNull(row[`${key}_value`])}</span>
              </Tooltip>
            )}
            <br />
            <span>{row[`${key}_excise_name`]}</span>
            <br />
          </>
        ),
        props: {
          style: { minWidth: 120, textAlign: 'right' },
          className: getRowClassName(row.journal_num, className),
        },
      }
    },
  }
}

export const genColumns = ({
  isIncluded,
  lastKey,
  className,
}: {
  isIncluded: boolean
  lastKey: string
  className?: string
}) => [
  {
    title: t('number_order'),
    dataIndex: 'slip_number',
    key: 'slip_number',
    className: 'padding-5 align-center',
    width: '50px',
    render: (value: string, row: any) => {
      return {
        children: value,
        props: {
          rowSpan: row.row_span,
          className: getRowClassName(row.journal_num, className),
          style: { padding: '8px 5px', textAlign: 'center', verticalAlign: 'top' },
        },
      }
    },
  },
  {
    title: t('recognized_at'),
    dataIndex: 'recognized_at',
    key: 'recognized_at',
    className: 'padding-5 align-center border-left',
    width: '50px',
    render: (value: string, row: any) => {
      return {
        children: formatMonthDay(value),
        props: {
          rowSpan: row.row_span,
          className: getRowClassName(row.journal_num, className),
          style: { padding: '8px 5px', textAlign: 'center', verticalAlign: 'top' },
        },
      }
    },
  },
  {
    title: renderTitle('debit_account_item', 'account_sub_item'),
    key: 'debit_account_item',
    className: 'border-double-left',
    render: (_: any, row: any) => {
      return {
        children: renderContent(row, 'debit_account_item_name', 'debit_account_sub_item_name'),
        props: {
          style: { minWidth: 120 },
          className: getRowClassName(row.journal_num, className),
        },
      }
    },
  },
  {
    title: renderTitle('department', 'business_partner'),
    key: 'debit_department_business_partner',
    className: 'border-left',
    render: function renderFunc(_: any, row: any) {
      return {
        children: renderContent(row, 'debit_department_name', 'debit_business_partner_name'),
        props: {
          style: { minWidth: 120 },
          className: row.journal_num % 2 === 0 ? className || '' : 'odd-num-journal',
        },
      }
    },
  },
  renderValueExcise(isIncluded, 'debit', className),
  {
    title: renderTitle('credit_account_item', 'account_sub_item'),
    key: 'credit_account_item',
    className: 'border-double-left',
    render: (_: any, row: any) => {
      return {
        children: renderContent(row, 'credit_account_item_name', 'credit_account_sub_item_name'),
        props: {
          style: { minWidth: 120 },
          className: getRowClassName(row.journal_num, className),
        },
      }
    },
  },
  {
    title: renderTitle('department', 'business_partner'),
    key: 'credit_department_business_partner',
    className: 'border-left',
    render: (_: any, row: any) => {
      return {
        children: renderContent(row, 'credit_department_name', 'credit_business_partner_name'),
        props: {
          style: { minWidth: 120 },
          className: getRowClassName(row.journal_num, className),
        },
      }
    },
  },
  renderValueExcise(isIncluded, 'credit', className),
  {
    title: t('remark'),
    dataIndex: 'remark',
    className: 'border-double-left',
    key: 'remark',
    render: (value: string, row: any) => {
      return {
        children: value,
        props: {
          rowSpan: row.row_span,
          className: getRowClassName(row.journal_num, className),
          style: { minWidth: 120, padding: '8px 5px', verticalAlign: 'top' },
        },
      }
    },
  },
  {
    key: lastKey,
    className: 'border-left journal-actions',
    width: '24px',
    render: (_: any, row: any) => {
      return {
        children: <JournalActions journalRecord={row} />,
        props: {
          rowSpan: row.row_span,
          className: getRowClassName(row.journal_num, className),
        },
      }
    },
  },
]
