import { LinkLabel } from 'aa_common/front-end/components'
import { formatMonth, formatYear } from 'aa_common/front-end/helpers'
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg'
import { NoDataImage } from 'components/atoms'
import { BreadcrumbNavigation } from 'components/molecules'
import { MonthlyClosingAssetEventList } from 'components/organisms'
import TabPageTemplate from 'components/templates/TabPageTemplate'
import { TabPageTemplateSettings } from 'components/templates/TabPageTemplate/model'
import { ASSET_CHANGE_SITUATION_TYPES } from 'constants/masterData'
import useActiveTab from 'hooks/useActiveTabHook'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchMonthlyClosingCurrent,
  fetchMonthlyClosingSummaryAssetEvents,
} from 'store/accounting/monthlyClosing/actions'
import {
  selectMonthlyClosingCurrent,
  selectMonthlyClosingSummaryAssetEvents,
} from 'store/accounting/monthlyClosing/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { NoDataWrapper } from './styles'

const { RedirectLink } = LinkLabel

const MonthlyClosingAssetEventListPage = () => {
  const dispatch = useDispatch()
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const summaryAssetEvents = useSelector(selectMonthlyClosingSummaryAssetEvents, isEqual)
  const ledgerSettings = useSelector(selectLedgerSettingList, isEqual)

  const breadcrumb = useMemo(() => {
    const cancelLink = (
      <RedirectLink redirectTo="/accounting/monthly-closing" style={{ paddingRight: '15px' }}>
        <CrossIcon />
      </RedirectLink>
    )

    return (
      <BreadcrumbNavigation
        sectionName={i18n.t('components.MonthlyClosingAssetEventList.title', {
          year: formatYear(currentMonthlyClosing?.from_term_month?.term?.year),
          month: formatMonth(currentMonthlyClosing?.from_term_month?.month),
        })}
        customBackElement={cancelLink}
      />
    )
  }, [currentMonthlyClosing])

  const { activeTab, handleOnTabChange } = useActiveTab()

  const tabs = useMemo(() => {
    if (!summaryAssetEvents) return []

    const assetEventTabsConfig = [
      {
        amount: summaryAssetEvents.acquire_amount,
        eventKey: 'acquisition',
        eventType: ASSET_CHANGE_SITUATION_TYPES.ACQUISITION,
      },
      {
        amount: summaryAssetEvents.retire_amount,
        eventKey: 'retirement',
        eventType: ASSET_CHANGE_SITUATION_TYPES.RETIREMENT,
      },
      { amount: summaryAssetEvents.sell_amount, eventKey: 'sale', eventType: ASSET_CHANGE_SITUATION_TYPES.SELL },
      {
        amount: summaryAssetEvents.move_amount,
        eventKey: 'movement',
        eventType: ASSET_CHANGE_SITUATION_TYPES.MOVEMENT,
      },
      {
        amount: summaryAssetEvents.impair_amount,
        eventKey: 'impairment',
        eventType: ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT,
      },
      {
        amount: summaryAssetEvents.dividing,
        eventKey: 'dividing',
        eventType: ASSET_CHANGE_SITUATION_TYPES.DIVIDING,
      },
    ]

    return assetEventTabsConfig.map(assetEventTabConfig => {
      const t1 = i18n.t<string>(`components.MonthlyClosingAssetEventList.${assetEventTabConfig.eventKey}`)
      const t2 = i18n.t<string>('common.units.item')
      return {
        children:
          currentMonthlyClosing && assetEventTabConfig.amount === 0 ? (
            <NoDataWrapper>
              <NoDataImage
                imageHeight={200}
                text={i18n.t('components.MonthlyClosingAssetEventList.message_no_data', {
                  event: i18n.t(`components.MonthlyClosingAssetEventList.${assetEventTabConfig.eventKey}`),
                })}
              />
            </NoDataWrapper>
          ) : (
            <MonthlyClosingAssetEventList eventType={assetEventTabConfig.eventType} />
          ),
        key: assetEventTabConfig.eventKey,
        label: ` ${t1}（ ${assetEventTabConfig.amount}${t2} ）`,
      }
    })
  }, [summaryAssetEvents, currentMonthlyClosing])

  const tabSettings: TabPageTemplateSettings = {
    activeKey: activeTab,
    onTabChange: handleOnTabChange,
    tabs,
    destroyInactiveTabPane: true,
  }

  useEffect(() => {
    if (currentMonthlyClosing) {
      dispatch(fetchMonthlyClosingSummaryAssetEvents(currentMonthlyClosing.id))
    }
  }, [currentMonthlyClosing, dispatch])

  useEffect(() => {
    !currentMonthlyClosing && dispatch(fetchMonthlyClosingCurrent())
    !ledgerSettings && dispatch(fetchLedgerSettingsList())
  }, []) // eslint-disable-line

  return <TabPageTemplate tabsSettings={tabSettings} breadcrumb={breadcrumb} />
}

export default MonthlyClosingAssetEventListPage
