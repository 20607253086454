import styled from '@emotion/styled'

type MultiSelectFormWrapperProps = {
  hasDisableOptions: boolean
}
export const MultiSelectFormWrapper = styled.div`
  width: 240px;
  padding: ${(props: MultiSelectFormWrapperProps) => (props.hasDisableOptions ? '5px 0 10px' : '10px 0')};

  .tooltip-wrapper {
    float: right;
    padding: 0 10px 5px;
  }

  .ant-select-dropdown {
    box-shadow: none;
  }

  .ant-select-multiple .ant-select-selector {
    border-radius: 4px;
    padding: 0px;
    font-size: 13px;
  }

  .ant-select-selection-item {
    display: none;
    + .ant-select-selection-search {
      margin-left: 6px;
    }
  }

  .ant-select-item-option {
    flex-direction: row-reverse;
    .ant-select-item-option-state {
      margin-right: 5px;
    }
  }
`
