import { parseError, parseResponse } from 'utils/ResponseHelper'

import base from './base'

export const getExciseSettings = () => {
  return base
    .get('/v1/excise_settings')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const getExciseSettingsAuth = () => {
  return base
    .get('/v1/excise_settings/get')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const createExciseSetting = (data: any) => {
  return base.post('/v1/excise_settings', data)
}

export const deleteExciseSetting = (id: number | string) => {
  return base.delete(`/v1/excise_settings/${id || ''}`)
}

export const getExciseSettingById = (id: number) => {
  return base.get(`/v1/excise_settings/${id}`)
}

export const updateExciseSetting = (id: number, data: any) => {
  return base.post(`/v1/excise_settings/${id}/save`, data)
}
