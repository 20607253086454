import { loadNs } from 'aa_common/front-end/i18n-config'
import { Input, RegexInput } from 'components/atoms'
import { AutoCompleteSelect, FilterPanel } from 'components/molecules'
import React, { forwardRef } from 'react'

const t = loadNs(['components/organisms/settings/department-filter'])

const { Row, Field, Label } = FilterPanel

const initialValues = {
  code: '',
  name: '',
  prepress_category: '',
  search_key: '',
}

type DepartmentFilterProps = {
  loading?: boolean
  prepressCategories: any[]
  onSubmit?: (values: any) => void
}

const DepartmentFilter = forwardRef<HTMLFormElement, DepartmentFilterProps>(function DepartmentFilter(
  { prepressCategories, onSubmit, loading },
  ref
) {
  return (
    <FilterPanel initialValues={initialValues} onSubmit={onSubmit} loading={loading} formRef={ref}>
      <Row>
        <Field>
          <Label>{t('code')}</Label>
          <RegexInput name="code" maxLength={10} type="code" disabled={loading} />
        </Field>
        <Field>
          <Label>{t('name')}</Label>
          <Input name="name" disabled={loading} maxLength={200} />
        </Field>
        <Field>
          <Label>{t('prepress_category')}</Label>
          <AutoCompleteSelect
            name="prepress_category_code"
            items={prepressCategories}
            valueField="code"
            disabled={loading}
            textField="name_jp"
          />
        </Field>
        <Field>
          <Label>{t('search_key')}</Label>
          <Input name="search_key" maxLength={20} disabled={loading} />
        </Field>
      </Row>
    </FilterPanel>
  )
})

export default React.memo(DepartmentFilter)
