import { getPastFutureRecordCount } from 'aa_common/front-end/helpers/monthly-closing-helper'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { get } from 'lodash'
import { Excise } from 'models/excise'
import { ExciseSetting } from 'models/exciseSettings'
import { ExciseSettings } from 'models/masterData'
import { MonthlyClosing } from 'models/monthlyClosing'

const t = loadNs(['components/organisms/settings/excise-setting-view'])

export const getTaxMethodText = (exciseSetting: ExciseSettings, value: number) => {
  const taxMethods = exciseSetting?.tax_method?.values || []

  const selectedValue = taxMethods.find(taxMethod => taxMethod.code === value)
  return get(selectedValue, 'name_jp', '')
}

export const getExecuteText = (exciseSetting: ExciseSettings, value: number) => {
  const exText =
    exciseSetting?.automatic_journal_entry_accquisition_type?.values ||
    exciseSetting?.input_tax_when_register_fixed_asset?.values ||
    []

  const selectedValue = exText.find(text => text.code === value)
  return get(selectedValue, 'name_jp', '')
}

export const getInputTaxMethod = (exciseSetting: ExciseSettings, value: number) => {
  const inputMethods = exciseSetting?.tax_input_format?.values || []

  const selectedValue = inputMethods.find(inputFormat => inputFormat.code === value)
  return get(selectedValue, 'name_jp', '')
}

export const getRoundingMethodText = (exciseSetting: ExciseSettings, value: number) => {
  const roundingMethods = exciseSetting?.rounding_method?.values || []

  const selectedValue = roundingMethods.find(roundingMethod => roundingMethod.code === value)
  return get(selectedValue, 'name_jp', '')
}

export const getEditableText = (exciseSetting: ExciseSettings, value: any) => {
  let selectedValue = null

  const settingMethods = exciseSetting?.can_edit_tax?.values || []

  selectedValue = settingMethods.find(settingMethod => settingMethod.code === value)

  return get(selectedValue, 'name_jp', '')
}

export const getExciseText = (exciseSetting: Excise[], value: any) => {
  const rateText = (exciseRate: any) => {
    return ` ${exciseRate.reduced_flg !== 0 ? '(軽)' : ''}${exciseRate.rate * 100}%`
  }

  const simpleName = (simpleExcise: any) => {
    return ` ${simpleExcise.name ? simpleExcise.name.replace('第', '') : ''}`
  }

  const selectedValue = exciseSetting.find(excise => excise.id === value)

  if (selectedValue)
    return `${selectedValue?.excise_label?.abbreviation}${
      selectedValue?.excise_rate?.rate ? rateText(selectedValue.excise_rate) : ''
    }${selectedValue?.simple_excise ? simpleName(selectedValue.simple_excise) : ''}`
}

export const displayPeriod = (setting: ExciseSetting) => {
  if (setting.to_term) {
    if (setting.from_term?.year === setting.to_term.year) return t('year_format', { year: setting.from_term?.year })
    return t('from_to_year_format', {
      from_year: setting.from_term?.year,
      to_year: setting.to_term.year,
    })
  }
  return t('from_year_format', { from_year: setting.from_term?.year })
}

export const renderOtherSettingsCount = (settings: ExciseSetting[], monthlyClosing: MonthlyClosing) => {
  const count = getPastFutureRecordCount(settings, monthlyClosing)
  return count && t('other_settings_count', count)
}
