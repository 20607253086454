import { Input as AntInput, InputProps } from 'antd'
import classnames from 'classnames'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { yen, yenStringToNumber } from '../../helpers'
import { ErrorMessage } from '../error-message/error-message.component'
import { StyledWrapper } from './styles'

export const TextField = ({
  name,
  className,
  underline = false,
  readOnly = false,
  required = false,
  allowPrefix = false,
  defaultValue = '',
  ...rest
}: Props) => {
  const { setValue, errors } = useFormContext()
  const classNames = classnames(className, { underline, error: name && errors[name] })
  const formatter = (value: string | number) => {
    return allowPrefix ? yen(value) : value
  }
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e?.target as HTMLInputElement

    if (name) {
      if (!allowPrefix) return setValue(name, value)
      return setValue(name, yenStringToNumber(value))
    }
  }

  if (!name) {
    return (
      <StyledWrapper>
        <AntInput className={classNames} value={formatter(defaultValue)} readOnly={readOnly} {...rest} />
      </StyledWrapper>
    )
  }
  return (
    <Controller
      name={name}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ value, onBlur, onChange: controllerOnChange }) => (
        <StyledWrapper>
          <AntInput
            name={name}
            className={classNames}
            value={formatter(value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleOnChange(e)
              controllerOnChange(e)
            }}
            onBlur={onBlur}
            readOnly={readOnly}
            {...rest}
          />
          <ErrorMessage errors={errors} name={name} />
        </StyledWrapper>
      )}
    />
  )
}

type Props = InputProps & {
  name?: string
  underline?: boolean
  allowPrefix?: boolean
  required?: boolean
  defaultValue?: number | string
}
