export const FETCH_UNITS_REQUEST = 'FETCH_UNITS_REQUEST'
export const FETCH_UNITS_REQUEST_AUTH = 'FETCH_UNITS_REQUEST_AUTH'
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS'
export const FETCH_UNITS_FAILURE = 'FETCH_UNITS_FAILURE'

export const CREATE_UNIT_REQUEST = 'CREATE_UNIT_REQUEST'
export const UPDATE_UNIT_REQUEST = 'UPDATE_UNIT_REQUEST'
export const SUBMIT_UNIT_SUCCESS = 'SUBMIT_UNIT_SUCCESS'
export const SUBMIT_UNIT_FAILURE = 'SUBMIT_UNIT_FAILURE'
export const HANDLE_SUBMIT_UNIT_FAILURE = 'HANDLE_SUBMIT_UNIT_FAILURE'

export const FETCH_UNIT_REQUEST = 'FETCH_UNIT_REQUEST'
export const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS'
export const FETCH_UNIT_FAILURE = 'FETCH_UNIT_FAILURE'

export const DELETE_UNIT_REQUEST = 'DELETE_UNIT_REQUEST'
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS'
export const DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE'
export const CHANGE_UNIT_POSITION_REQUEST = 'CHANGE_UNIT_POSITION_REQUEST'
export const CHANGE_UNIT_POSITION_SUCCESS = 'CHANGE_UNIT_POSITION_SUCCESS'
export const CHANGE_UNIT_POSITION_FAILURE = 'CHANGE_UNIT_POSITION_FAILURE'
export const CLEANUP_UNIT = 'CLEANUP_UNIT'
