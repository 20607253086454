import { formatMonth } from 'aa_common/front-end/helpers'
import { ModalRef } from 'aa_common/front-end/hooks'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { FORM_MODES } from 'aa_common/front-end/models'
import Form, { RenderFormProps } from 'components/molecules/Form'
import { ItemWrapper } from 'components/organisms/accounting/ModalAutoJournalSettingList/ListItem/styles'
import { first, get, isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectorSelectedAssetCategoryJournalSettingTerms } from 'store/accounting/journal/selectors'
import { selectAccountItemList2 } from 'store/settings/accountItem/selectors'

import CopyAssetCategoriesAutoJournalModal from '../CopyAssetAutoJournalModal'
import { initialFormValues } from './const'
import { convertFormPayload, convertSubmitPayload } from './helper'
import { StyledWrapper } from './styles'
import SubmitArea from './SubmitArea'
import {
  DepreciationSettingTable,
  GetSettingTable,
  ImpairmentSettingTable,
  RetirementSettingTable,
  SaleSettingTable,
} from './tables'

interface JournalItemSettingFormProps {
  editItem?: any
  selectedAssetCategory: any
  formMode: FORM_MODES
  isDetailMode: boolean
  nextSettings: any
  onCancelClick: () => void
  onFormSubmit: (values: any, id?: number) => any
}

const t = loadNs(['common', 'components/organisms/accounting/auto-journal-setting-tab'])
const JournalItemSettingForm = ({
  editItem,
  selectedAssetCategory,
  isDetailMode,
  nextSettings,
  formMode,
  onCancelClick,
  onFormSubmit,
}: JournalItemSettingFormProps) => {
  const [formValue, setFormValues] = useState(initialFormValues)
  const formRef = useRef<RenderFormProps>(null)
  const copyModalRef = useRef<ModalRef<any>>(null)
  const [title, setRenderTitle] = useState<string>('')
  const accountItemSelector = useSelector(selectAccountItemList2, isEqual)
  const { data: journalItems } = useSelector(selectorSelectedAssetCategoryJournalSettingTerms, isEqual)

  const latestSettings = useMemo(() => first(journalItems), [journalItems])
  const accountItems = useMemo(
    () => accountItemSelector?.data?.filter(accountItem => accountItem?.is_active) || [],
    [accountItemSelector.data]
  )

  const isFormMode = formMode === FORM_MODES.NEW || formMode === FORM_MODES.EDIT

  useEffect(() => {
    switch (formMode) {
      case FORM_MODES.NEW: {
        if (nextSettings) {
          const latestSettingsPayload = convertFormPayload(latestSettings)
          setFormValues({
            ...initialFormValues,
            ...latestSettingsPayload,
            term_year_period: nextSettings.term_id,
            month_id: nextSettings.id,
          })
        }
        break
      }
      case FORM_MODES.EDIT: {
        editItem && setFormValues(convertFormPayload(editItem))
        break
      }
      default:
        break
    }
  }, [editItem, nextSettings, latestSettings, formMode])

  const renderValidFrom = () => {
    const formValues = formRef.current?.getValues()

    if (editItem && get(formValues, 'month_id') === editItem.from_term_month.id) {
      const validFrom = `${t('valid_period')}: ${t('format.term_year_month', {
        year: editItem.from_term_month.term.year,
        month: formatMonth(editItem.from_term_month.month),
      })}`
      if (validFrom) {
        if (editItem.to_term_month && editItem.from_term_month.id !== editItem.to_term_month.id) {
          const validTo = t('format.term_year_month', {
            year: editItem.to_term_month.term.year,
            month: formatMonth(editItem.to_term_month.month),
          })
          return `${validFrom}〜${validTo}`
        }
        if (editItem.to_term_month && editItem.from_term_month.id === editItem.to_term_month.id) {
          return validFrom
        }
        return `${validFrom}〜`
      }
    }

    if (isFormMode && title) {
      return `${t('valid_period')}: ${title}〜`
    }
    return ''
  }

  const openCopyModal = useCallback(() => {
    copyModalRef.current?.open(
      {
        title: null,
        initialValues: null,
        onValuesCb: () => null,
      },
      {}
    )
  }, [])

  const handleFormSubmit = (formValues: ReturnType<typeof convertFormPayload>) => {
    const payload = convertSubmitPayload(formValues, selectedAssetCategory)
    return onFormSubmit(payload, editItem?.id)
  }

  return isFormMode ? (
    <Form ref={formRef} value={formValue} onFormSubmit={handleFormSubmit}>
      <StyledWrapper>
        <ItemWrapper>
          <div className="header">
            <span className="period">{renderValidFrom()}</span>
          </div>
          <div className="body">
            <GetSettingTable accountItems={accountItems} />
            <DepreciationSettingTable accountItems={accountItems} />
            <ImpairmentSettingTable accountItems={accountItems} />
            <RetirementSettingTable accountItems={accountItems} />
            <SaleSettingTable accountItems={accountItems} />
          </div>
        </ItemWrapper>
        <SubmitArea
          nextSettings={nextSettings}
          isDetailMode={isDetailMode}
          item={editItem}
          formMode={formMode}
          onCancelClick={onCancelClick}
          onCopyClick={openCopyModal}
          setRenderTitle={setRenderTitle}
        />
        <CopyAssetCategoriesAutoJournalModal ref={copyModalRef} selectedAssetCategory={selectedAssetCategory} />
      </StyledWrapper>
    </Form>
  ) : null
}

export default JournalItemSettingForm
