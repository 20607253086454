import { Term } from 'models/term'

import { MonthlyClosing } from '../../../models/monthlyClosing'

export interface TermSpan {
  from_term?: Term
  to_term?: Term
}
export const checkSettingBelongToMonthlyClosing = (setting: TermSpan, monthlyClosing: MonthlyClosing) => {
  if (!monthlyClosing) return false

  const currentMonthlyClosingStartDate = monthlyClosing?.from_term_month?.start_date || ''
  const startDateOfSetting = setting?.from_term?.start_date || ''
  const endDateOfSetting = setting?.to_term?.end_date || ''
  return (
    currentMonthlyClosingStartDate >= startDateOfSetting &&
    (!endDateOfSetting || currentMonthlyClosingStartDate <= endDateOfSetting)
  )
}

export const getPastFutureRecordCount = (settings: TermSpan[], monthlyClosing: MonthlyClosing) => {
  if (!settings || !monthlyClosing) return ''
  if (settings.length === 1) return { future_count: 0, past_count: 0 }

  const future_count = settings.findIndex(setting => checkSettingBelongToMonthlyClosing(setting, monthlyClosing))
  if (future_count === -1) return ''

  return {
    future_count,
    past_count: settings.length - future_count - 1,
  }
}

export const checkTermBelongToTermSpan = (termSpan: TermSpan, term?: Term) => {
  const { start_date } = termSpan.from_term || {}
  const { end_date } = termSpan.to_term || {}

  return term && start_date && term.start_date >= start_date && (!end_date || term.end_date <= end_date)
}
