import { objectToQueryParams } from 'aa_common/front-end/helpers/request-helper'
import { BE_BASE_URL } from 'constants/app'

import base from './base'

export const getResults = (payload: any) => {
  return base.get('/v1/depreciation_results/aggregate', {
    params: payload,
  })
}

export const exportDepreciationResultURL = (payload: any) => {
  const params = objectToQueryParams(payload)
  return `${BE_BASE_URL}/v1/export/depreciation_results?${params}`
}
