import { get } from 'lodash'

export interface ValueFields {
  moved_at: null
  event_cause_id: null
  area_biid: null
  department_biid: null
  event_photos: any
  event_attachments: any
  event_urls: any
}

export const validateForm = (values: ValueFields) => {
  const { moved_at, event_cause_id } = values

  return moved_at && event_cause_id
}

interface AssetMovementSubmitPayload {
  id?: number | string
  area_biid: string
  department_biid: string
  event_attachments: any[]
  event_cause_id: number
  event_photos: any[]
  event_urls: string[]
  moved_at: string
}

export const convertSubmitPayload = (values: any): AssetMovementSubmitPayload => {
  const result = {
    id: get(values, 'id', undefined),
    area_biid: get(values, 'area_biid.value', null),
    department_biid: get(values, 'department_biid.value', null),
    event_cause_id: get(values, 'event_cause_id', null),
    event_attachments: get(values, 'event_attachments', []),
    event_photos: get(values, 'event_photos', []),
    event_urls: get(values, 'event_urls', []),
    moved_at: get(values, 'moved_at', null),
  }

  return result
}
export const validateAreaDepartment = (values: any) => {
  const area_biid = get(values, 'area_biid.value', null)
  const department_biid = get(values, 'department_biid.value', null)
  return !area_biid && !department_biid
}
