import {
  PresenterOneOfficeResponse,
  PresenterOneOfficeUserResponse,
} from 'aa_common/front-end/open-api/gen/data-contracts'
import { initSlice } from 'aa_common/front-end/zustand/init-slice'

type SessionType = {
  currentOffice?: PresenterOneOfficeResponse
  currentUser?: PresenterOneOfficeUserResponse
}

const initData: SessionType = {}

export const sessionsSlice = initSlice('share/sessions', initData)
