import { CSRF_TOKEN_KEY, FE_SIGNIN_PATH } from 'constants/auth'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { fetchCurrentUserRequest } from 'store/session/actions'
import { getCurrentUserSelector } from 'store/session/selectors'

const RouteWithoutOffice: React.FC<RouteProps> = ({ children, ...rest }) => {
  const currentUser = useSelector(getCurrentUserSelector)
  const dispatch = useDispatch()
  const csrfToken = localStorage.getItem(CSRF_TOKEN_KEY)

  useEffect(() => {
    if (!currentUser) {
      dispatch(fetchCurrentUserRequest())
    }
  }, []) // eslint-disable-line

  if (csrfToken) {
    return (
      <Route
        {...rest}
        render={() => {
          if (currentUser) {
            return children
          }
        }}
      />
    )
  }
  return <Route {...rest} render={() => <Redirect to={FE_SIGNIN_PATH} />} />
}

export default RouteWithoutOffice
