import { faExternalLinkAlt, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BasicModal, ConfirmModal } from 'aa_common/front-end/components'
import { isValidUrl } from 'aa_common/front-end/helpers/format-helper'
import { Input, message } from 'antd'
import { getIn, useFormikContext } from 'formik'
import i18n from 'i18n'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { EditForm, LinkItem, LinkList, Wrapper } from './styles'

type Props = {
  name: string
  placeholder?: string
  maxLength?: number
  mode?: 'view' | 'edit'
}

const convertStringArrayToLinkList = (stringArray: string[]) => {
  if (!stringArray) return []

  return stringArray.map((stringItem: string, index) => {
    return {
      id: index,
      value: stringItem,
    }
  })
}

const convertLinkListToStringArray = (listList: any[]) => listList.map((item: any) => item.value)

const LinkListField: React.FC<Props> = ({ name, maxLength = 200, placeholder, mode = 'edit' }) => {
  const { values, setFieldValue, errors, setErrors } = useFormikContext()
  const [linkList, setLinkList] = useState(convertStringArrayToLinkList(getIn(values, name) || []))
  const [linkValue, setLinkValue] = useState('')
  const [selectedLink, setSelectedLink] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

  const fieldValue = getIn(values, name)
  const isEditMode = mode === 'edit'

  const handleEditLink = (link: any) => {
    setSelectedLink(link)
    setIsEdit(true)
    setErrors('')
  }

  const handleOpenLink = (link: any) => {
    window.open(link.value, '_blank')
  }

  const handleRemoveLink = (link: any) => {
    setSelectedLink(link)
    setIsConfirmingDelete(true)
    setErrors('')
  }

  const onInputChange = (e: any) => {
    setLinkValue(e.target.value)
    setErrors('')
  }

  const handlePressEnterOrBlur = (e: any) => {
    e.preventDefault()
    if (!linkValue) return
    if (!isValidUrl(linkValue)) {
      message.error(i18n.t('common.messages.invalid_url', { url: linkValue }))
      return false
    }

    const newLinkList = [...linkList, { id: moment().valueOf(), value: linkValue }]
    setLinkList(newLinkList)
    setFieldValue(name, convertLinkListToStringArray(newLinkList))
    setLinkValue('')
  }

  const handleCancelDelete = (): void => {
    setIsConfirmingDelete(false)
  }

  const handleDelete = (): void => {
    const newLinkList = linkList.filter((item: any) => item.id !== (selectedLink as any).id)
    setLinkList(newLinkList)
    setFieldValue(name, convertLinkListToStringArray(newLinkList))
    setIsConfirmingDelete(false)
  }

  const handleCloseEditModal = () => {
    const index = linkList.findIndex((item: any) => item.id === (selectedLink as any).id)
    linkList.splice(index, 1, selectedLink as any)
    setLinkList([...linkList])
    setFieldValue(name, convertLinkListToStringArray(linkList))
    setIsEdit(false)
  }

  const handleLinkChanged = (e: any): void => {
    setSelectedLink({ ...(selectedLink as any), value: e.target.value })
  }

  const renderLinkList = () => (
    <LinkList style={{ marginTop: '10px' }}>
      {get(errors, name) && <span style={{ color: '#dc3545' }}>{i18n.t('common.messages.url_invalid')}</span>}
      {linkList.map((item: any) => (
        <LinkItem key={uniqueId()}>
          <FontAwesomeIcon icon={faExternalLinkAlt} className="open-icon" onClick={() => handleOpenLink(item)} />
          {item.value}
          {isEditMode && (
            <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" onClick={() => handleEditLink(item)} />
          )}
          {isEditMode && (
            <FontAwesomeIcon icon={faTrashAlt} className="remove-icon" onClick={() => handleRemoveLink(item)} />
          )}
        </LinkItem>
      ))}
    </LinkList>
  )

  useEffect(() => {
    setLinkList(convertStringArrayToLinkList(fieldValue))
  }, [fieldValue]) // eslint-disable-line

  return (
    <Wrapper>
      {isEditMode && (
        <Input
          placeholder={placeholder}
          value={linkValue}
          maxLength={maxLength}
          onChange={onInputChange}
          onPressEnter={handlePressEnterOrBlur}
          onBlur={handlePressEnterOrBlur}
        />
      )}
      {renderLinkList()}
      {isEditMode && (
        <BasicModal isShow={isEdit} title="&nbsp;" width={600} onCancel={handleCloseEditModal}>
          <EditForm>
            <Input
              value={(selectedLink as any).value}
              onChange={handleLinkChanged}
              maxLength={maxLength}
              onPressEnter={handleCloseEditModal}
            />
          </EditForm>
        </BasicModal>
      )}
      <ConfirmModal
        message={i18n.t('common.messages.confirm_delete', { name: `${(selectedLink as any).value}` })}
        visible={isConfirmingDelete}
        onOK={handleDelete}
        onCancel={handleCancelDelete}
      />
    </Wrapper>
  )
}

export default LinkListField
