import * as tempDatatApi from 'api/app/tempData'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import { call, put, takeLatest } from 'redux-saga/effects'
import { showErrorsAppCall } from 'store/app/actions'
import { parseError } from 'utils/ResponseHelper'

import { CreateTempDataRequest, FetchTempDataRequest } from './action.model'
import {
  CREATE_TEMP_DATA_FAILURE,
  CREATE_TEMP_DATA_REQUEST,
  CREATE_TEMP_DATA_SUCCESS,
  FETCH_TEMP_DATA_FAILURE,
  FETCH_TEMP_DATA_REQUEST,
  FETCH_TEMP_DATA_SUCCESS,
} from './actions'

export function* fetchTempData({ dataType, extraKey }: FetchTempDataRequest) {
  try {
    const response: AxiosResponse = yield call(tempDatatApi.get, dataType, extraKey)
    yield put({ type: FETCH_TEMP_DATA_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put({ type: FETCH_TEMP_DATA_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createTempData({ dataType, data, extraKey }: CreateTempDataRequest) {
  try {
    yield call(tempDatatApi.create, dataType, data, extraKey)
    yield put({
      type: CREATE_TEMP_DATA_SUCCESS,
      savedAt: moment(),
    })
  } catch (error) {
    yield put({ type: CREATE_TEMP_DATA_FAILURE })
  }
}

export default function* actionWatcher(): Generator<any> {
  yield takeLatest(FETCH_TEMP_DATA_REQUEST, fetchTempData)
  yield takeLatest(CREATE_TEMP_DATA_REQUEST, createTempData)
}
