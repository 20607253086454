import { ASSET_CHANGE_SITUATION_TYPES } from 'aa_common/front-end/constants'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { NoDataImage } from 'components/atoms'
import { BreadcrumbNavigation } from 'components/molecules'
import { AssetEventListReport } from 'components/organisms'
import TabPageTemplate from 'components/templates/TabPageTemplate'
import { TabPageTemplateSettings } from 'components/templates/TabPageTemplate/model'
import useActiveTab from 'hooks/useActiveTabHook'
import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSummaryFixedAssetEvents } from 'store/accounting/assetEventsReport/actions'
import { selectSummaryFixedAssetEvents } from 'store/accounting/assetEventsReport/selectors'
import { parseSummaryAssetEvents } from 'utils/EventHelper'
import { useShallow } from 'zustand/react/shallow'

import { assetEventListReportSlice, EventKeys, resetStore, setDetailMode, setTermMonthId } from './store'
import { NoDataWrapper } from './styles'

const t = loadNs([
  'components/organisms/accounting/monthly-closing-asset-event-list',
  'common',
  'components/organisms/main-menu',
])
const AssetEventListReportPage = () => {
  const dispatch = useDispatch()

  const sliceEventReport = assetEventListReportSlice.useSlice(useShallow(state => state.data))
  const summaryAssetEvents = useSelector(selectSummaryFixedAssetEvents, isEqual)

  const breadcrumb = <BreadcrumbNavigation sectionName={t('depreciation.asset_events_report')} />

  useEffect(() => {
    sliceEventReport?.termMonthId && dispatch(fetchSummaryFixedAssetEvents(sliceEventReport?.termMonthId))
  }, [dispatch, sliceEventReport?.termMonthId])

  const { activeTab, handleOnTabChange } = useActiveTab()
  const tabs = () => {
    if (!summaryAssetEvents) return []
    const summaryConverted = parseSummaryAssetEvents(summaryAssetEvents)

    const assetEventTabsConfig = [
      {
        amount: summaryConverted.acquire_amount,
        eventKey: EventKeys.Acquisition,
        eventType: ASSET_CHANGE_SITUATION_TYPES.ACQUISITION,
      },
      {
        amount: summaryConverted.retire_amount,
        eventKey: EventKeys.Retirement,
        eventType: ASSET_CHANGE_SITUATION_TYPES.RETIREMENT,
      },
      { amount: summaryConverted.sell_amount, eventKey: EventKeys.Sale, eventType: ASSET_CHANGE_SITUATION_TYPES.SELL },
      {
        amount: summaryConverted.move_amount,
        eventKey: EventKeys.Movement,
        eventType: ASSET_CHANGE_SITUATION_TYPES.MOVEMENT,
      },
      {
        amount: summaryConverted.impair_amount,
        eventKey: EventKeys.Impairment,
        eventType: ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT,
      },
      {
        amount: summaryConverted.dividing,
        eventKey: EventKeys.Dividing,
        eventType: ASSET_CHANGE_SITUATION_TYPES.DIVIDING,
      },
    ]

    return assetEventTabsConfig.map(assetEventTabConfig => {
      const t1 = t(assetEventTabConfig.eventKey)
      const t2 = t('units.item')
      return {
        children:
          assetEventTabConfig.amount === 0 ? (
            <NoDataWrapper>
              <NoDataImage
                imageHeight={200}
                text={t('message_no_data', {
                  event: t(assetEventTabConfig.eventKey),
                })}
              />
            </NoDataWrapper>
          ) : (
            <AssetEventListReport eventType={assetEventTabConfig.eventType} />
          ),
        key: assetEventTabConfig.eventKey,
        label: `${t1}（${assetEventTabConfig.amount || 0}${t2}）`,
      }
    })
  }

  const tabSettings: TabPageTemplateSettings = {
    activeKey: activeTab,
    onTabChange: key => {
      handleOnTabChange(key)
      setDetailMode(true)
      resetStore()
    },
    tabs: tabs(),
    destroyInactiveTabPane: true,
  }

  const onChangeTermSelection = (termMonthId: number) => {
    setTermMonthId(termMonthId)
    setDetailMode(true)
  }

  return (
    <TabPageTemplate
      isShowTermSelection
      tabsSettings={tabSettings}
      breadcrumb={breadcrumb}
      type="card"
      onChangeTermSelection={onChangeTermSelection}
    />
  )
}

export default AssetEventListReportPage
