/* eslint-disable import/no-named-default */
import { AutoCompleteSelect, Button } from 'aa_common/front-end/antd'
import { formatMonth } from 'aa_common/front-end/helpers/format-helper'
import { default as BasicForm } from 'components/molecules/Form'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import { TermMonth } from 'models/term'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsList } from 'store/settings/accountingPeriod/selectors'

import { Wrapper } from './styles'

type Props = {
  isReselectingTerm: boolean
  handleClickReselectingTerm: () => void
  reselectTerms: TermMonth[]
  monthlyClosingCurrent: any
}

const { Group } = BasicForm

export const Form = ({
  isReselectingTerm: isClickSelectTerm,
  handleClickReselectingTerm: handleClickSelectTerm,
  reselectTerms,
  monthlyClosingCurrent,
}: Props) => {
  const { control } = useFormContext()
  const dispatch = useDispatch()
  const terms = useSelector(selectTermsList, isEqual)?.data
  const term_selection = useWatch({ name: 'term_selection' })
  const convertedReselectTerms = useMemo(() => {
    return (reselectTerms || []).map(item => {
      return {
        textLabel: `${i18n.t('components.MonthlyClosingView.terms_selection', {
          year: item?.term?.year,
          month: formatMonth(item.month),
        })}`,
        ...item,
      }
    })
  }, [reselectTerms])

  useEffect(() => {
    !terms && dispatch(fetchTerms())
  }, [dispatch, terms])

  const isDisableButton = useMemo(() => {
    if (terms?.length > 0 && reselectTerms?.length > 0) {
      const firstTerm = terms[0].start_date
      const lastReselectTerm = reselectTerms[reselectTerms.length - 1].start_date

      return !moment(lastReselectTerm).isSameOrAfter(moment(firstTerm))
    }

    return true
  }, [terms, reselectTerms])

  const month = formatMonth(monthlyClosingCurrent?.from_term_month?.month)
  const year = monthlyClosingCurrent?.from_term_month?.term?.year

  return (
    <Wrapper>
      {!isClickSelectTerm ? (
        <>
          <div>{i18n.t('components.MonthlyClosingView.monthly_closing_start', { year, month })}</div>
          <div style={{ whiteSpace: 'break-spaces' }}>
            {i18n.t('components.MonthlyClosingView.monthly_closing_warning', { year, month })}
          </div>
          <Button
            type="button"
            size="short"
            color="grey"
            disabled={isDisableButton}
            onClick={() => handleClickSelectTerm()}
          >
            {i18n.t('components.MonthlyClosingView.select_term')}
          </Button>
        </>
      ) : (
        <Group borderLess>
          <div style={{ marginBottom: 5 }}>{i18n.t('components.MonthlyClosingView.select_term_title')}</div>
          <div style={{ marginBottom: 10 }}>{i18n.t('components.MonthlyClosingView.select_term_content')}</div>
          <AutoCompleteSelect
            name="term_selection"
            textField="textLabel"
            valueField="id"
            items={convertedReselectTerms}
            className="terms-reselect"
            control={control}
            allowClear={false}
          />
          <Button
            type="button"
            size="custom"
            color="grey"
            style={{ marginRight: 10 }}
            onClick={() => handleClickSelectTerm()}
            className="cancel_terms"
          >
            {i18n.t('common.actions.cancel')}
          </Button>
          <Button type="submit" size="custom" color="blue" disabled={!term_selection} className="submit_terms">
            {i18n.t('common.actions.ok')}
          </Button>
        </Group>
      )}
    </Wrapper>
  )
}
