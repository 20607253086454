/* eslint-disable react/display-name */
import { formatDate, yen } from 'aa_common/front-end/helpers'
import instance, { loadNs } from 'aa_common/front-end/i18n-config'
import { combinedKeys } from 'aa_common/front-end/shared/helpers'
import { ColumnsType } from 'antd/es/table'
import PlaceholderImage from 'assets/images/image-placeholder.jpg'
import TagPopup from 'components/organisms/assets/AssetList/components/TagPopup'
import { ASSET_STATUSES } from 'constants/masterData'
import { Permission } from 'constants/permissions'
import { find, get, isEqual } from 'lodash'
import { LedgerSetting } from 'models/ledgerSetting'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'

import CommentPopup from '../components/CommentPopup'
import { AssetCodeTemplate, TextHighlightTemplate } from '../components/tables/columnTemplates'
import { FixedAssetTableImage } from '../styles'
import { generateColumns, renderDefaultSort } from './helper'

const t = loadNs(['pages/assets'])

const AssetCode = (data: any, record: any, searchWords: string[]) => {
  if (record.isParent) {
    return <AssetCodeTemplate data={data} searchWords={searchWords} />
  }

  return {
    children: null,
    props: {
      colSpan: 0,
    },
  }
}

const NameRender = (data: any, record: any, searchWords: string[]) => {
  if (record.isParent) {
    return <TextHighlightTemplate data={data} searchWords={searchWords} />
  }

  return {
    children: null,
    props: {
      colSpan: 0,
    },
  }
}

const CommentTemplate = ({ item, permissions }: { item: any; permissions: Permission }) => {
  if (item.isParent) {
    return <CommentPopup item={item} permissions={permissions} />
  }
  return null
}

const DateTemplate = (date: string) => <span>{`${date ? formatDate(date) : ''}`}</span>
const MoneyTemplate = (amount: string | number, record: any) => {
  if (!record.isParent) {
    return <span>{yen(amount)}</span>
  }

  return null
}
const StatusTemplate = ({ data, filterAt }: { data: any; filterAt: string }) => {
  const masterData = useSelector(selectMasterData, isEqual)
  const validAt = moment(filterAt)

  const currentEvent = find(data?.change_situations, event => {
    const validFromDate = moment(event.valid_from)
    const validToDate = moment(event.valid_to)
    return (
      validAt.isBetween(validFromDate, validToDate, 'date', '[]') || (validAt.isAfter(validFromDate) && !event.valid_to)
    )
  })

  if (data.isParent && currentEvent) {
    let renderText = ''
    const assetAcquiredAt = moment(currentEvent.acquired_at)
    const assetStatus = currentEvent.asset_status
    const isToBeAcquired = validAt.isBefore(assetAcquiredAt)
    if (isToBeAcquired) {
      const toBeAcquired = find(masterData.asset_status, item => item.code === ASSET_STATUSES.TO_BE_ACQUIRED)
      renderText = (instance.language === 'ja' ? toBeAcquired?.name_jp : toBeAcquired?.name_en) || ''
    } else {
      renderText = (instance.language === 'ja' ? assetStatus?.name_jp : assetStatus?.name_en) || ''
    }

    return <span>{renderText}</span>
  }

  return null
}

const renderEventInfo = (record: any) => {
  return (
    <table className="render-event-info" style={{ width: '100%', minWidth: '100%', tableLayout: 'fixed' }}>
      <colgroup>
        <col style={{ width: 80, minWidth: 80 }} />
        <col style={{ width: 100, minWidth: 100 }} />
        <col style={{ width: 170, minWidth: 170 }} />
      </colgroup>
      <tbody>
        <tr>
          <td>
            <span>{formatDate(get(record, 'valid_from'))}</span>
          </td>
          <td>
            <span style={{ fontWeight: 'bold' }}>{get(record, ['change_situation', 'name_jp'])}</span>
          </td>
          <td>
            <span>{get(record, ['event_cause', 'content'])}</span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const TableImage = (photos: any[], record: any) => {
  if (record.isParent) {
    const hasImage = photos && photos.length > 0
    return (
      <div style={{ display: 'flex' }}>
        <FixedAssetTableImage
          preview={false}
          src={hasImage ? photos[0].thumbnail_url : ''}
          fallback={PlaceholderImage}
        />
      </div>
    )
  }

  const obj = {
    children: renderEventInfo(record),
    props: {
      colSpan: 3,
      className: 'ant-table-cell-fix-left-last',
    },
  }

  return obj
}

export const parseDataForPattern2 = (data: any[]): any[] => {
  const result = data.map(dataItem => {
    return {
      id: dataItem.id,
      key: `${dataItem.id}`,
      biid: dataItem.biid,
      photos: dataItem.photos,
      code: dataItem.code,
      branch_code: dataItem.branch_code,
      name: dataItem.name,
      isParent: true,
      children: dataItem.change_situations
        ? dataItem.change_situations.map((item: any) => {
            return {
              ...item,
              key: `${dataItem.id}-${item.id}`,
              parentId: dataItem.id,
              isHighlight: dataItem.isHighlight,
            }
          })
        : null,
      ...dataItem,
    }
  })

  return result
}

export const fixedAssetTablePattern2Columns = ({
  ledgerSettings,
  filterAt,
  searchWords,
  permissions,
  sorter,
}: {
  searchWords: string[]
  filterAt: string
  ledgerSettings: LedgerSetting[]
  sorter: any
  activePopup?: any
  permissions: Permission
}): ColumnsType<any> => {
  return [
    {
      title: <div style={{ textAlign: 'center' }}>{t('table.field_name.photo')}</div>,
      width: 60,
      dataIndex: 'photos',
      key: 'photos',
      fixed: 'left',
      className: 'fixed-asset-photo',
      render: TableImage,
    },
    {
      title: t('table.field_name.asset_code'),
      width: 110,
      key: combinedKeys(['code', 'branch_code']),
      fixed: 'left',
      sorter: true,
      defaultSortOrder: renderDefaultSort(sorter, combinedKeys(['code', 'branch_code'])),
      showSorterTooltip: false,
      render: (data, record) => AssetCode(data, record, searchWords),
    },
    {
      title: t('table.field_name.name'),
      dataIndex: 'name',
      key: 'name',
      width: 170,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'name'),
      fixed: 'left',
      render: (data, record) => NameRender(data, record, searchWords),
    },
    {
      title: t('table.field_name.acquired_at'),
      dataIndex: 'acquired_at',
      key: 'acquired_at',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'acquired_at'),
      width: 110,
      render: DateTemplate,
    },
    {
      title: <span style={{ whiteSpace: 'pre-line' }}>{t('table.field_name.used_at')}</span>,
      dataIndex: 'usage_started_at',
      key: 'usage_started_at',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'usage_started_at'),
      width: 110,
      render: DateTemplate,
    },
    {
      title: t('table.field_name.acquisition_cost'),
      key: 'acquisition_cost',
      showSorterTooltip: false,
      width: 110,
      className: 'asset-table-money',
      render: (record: any) => {
        if (!record.isParent) {
          const isExcluded = record.is_excluded
          return MoneyTemplate(isExcluded ? record.acquisition_cost_excluded_excise : record.acquisition_cost, record)
        }

        return null
      },
    },
    {
      title: t('table.field_name.asset_category'),
      dataIndex: ['asset_category', 'name'],
      key: 'asset_category',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'asset_category'),
      width: 110,
    },
    {
      title: t('table.field_name.department'),
      dataIndex: ['department', 'name'],
      key: 'department',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'department'),
      width: 110,
    },
    {
      title: t('table.field_name.area'),
      dataIndex: ['area', 'name'],
      key: 'area',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'area'),
      width: 140,
    },
    ...generateColumns(sorter, ledgerSettings, true),
    {
      title: t('table.field_name.asset_status'),
      key: 'asset_status',
      sorter: false,
      width: 130,
      render: data => <StatusTemplate data={data} filterAt={filterAt} />,
    },
    {
      title: t('table.field_name.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'created_at'),
      width: 110,
      render: DateTemplate,
    },
    {
      title: t('table.field_name.created_by'),
      dataIndex: ['user_info', 'display_name'],
      key: 'created_by',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'created_by'),
      width: 110,
    },
    {
      sorter: false,
      width: 35,
      fixed: 'right',
      align: 'center',
      className: 'col-fixed-right',
      render: ({ tags }: any, record) => (record.isParent ? <TagPopup tags={tags} /> : null),
      onCell: record => ({
        onClick: event => record.isParent && event.stopPropagation(),
      }),
    },
    {
      sorter: false,
      width: 50,
      fixed: 'right',
      render: (item: any) => <CommentTemplate item={item} permissions={permissions} />,
      onCell: record => ({
        onClick: event => record.isParent && event.stopPropagation(),
      }),
    },
  ]
}
