import {
  ASSET_EVENT_FIELD,
  ASSET_ITEM_STATUS_PARAM_TYPE,
  AssetReport,
  formatDate,
  formatDateTime,
  identifyJournalStatusType,
  loadNs,
  objectToQueryParams,
  renderAssetFields,
  yen,
} from 'aa_common/front-end'
import { FileLink, NoImage, PhotoView, ReferenceLink } from 'components/atoms'
import get from 'lodash/get'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cleanupMonthlyClosingAssetEvents } from 'store/accounting/monthlyClosing/actions'
import { changeLocation } from 'store/app/actions'

import JournalStatus from './JournalStatus'
import { AssetDisplay, AssetDisplayDataRow, AssetDisplayHeader } from './styles'

type Props = {
  assets: AssetReport[]
}

const t = loadNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
const RetirementEventView: React.FC<Props> = ({ assets }) => {
  const dispatch = useDispatch()

  const renderAssetPhoto = useCallback((asset: AssetReport) => {
    const isEmpty = !asset.photos || asset.photos.length === 0

    return isEmpty ? <NoImage /> : <img src={get(asset, 'photos[0].thumbnail_url')} alt="" />
  }, [])

  const handleAssetClick = (asset: AssetReport) => {
    const paramObj = {
      valid_at: asset.retired_at,
      type: ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD,
    }
    const params = `?${objectToQueryParams(paramObj)}`
    dispatch(changeLocation(`/assets/${asset.biid}${params}`))
  }

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupMonthlyClosingAssetEvents())
    }
  }, []) // eslint-disable-line

  return (
    <>
      {assets?.map(asset => (
        <AssetDisplay key={asset?.biid} onClick={() => handleAssetClick(asset)}>
          <AssetDisplayHeader>
            <div className="status">
              <JournalStatus type={identifyJournalStatusType(asset)} />
            </div>
            <div className="photo">{renderAssetPhoto(asset)}</div>
            <div className="asset-code">{`${asset.code}-${asset.branch_code}`}</div>
            <div className="asset-name">{asset.name}</div>
          </AssetDisplayHeader>
          <AssetDisplayDataRow>
            <div className="short-label">{asset.valid_from}</div>
            <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
            <div className="label text-color-666">{t('retire_at')}</div>
            <div className="value text-color-666">{formatDateTime(asset.created_at as string)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('retire_reason')}</div>
            <div className="value">{get(asset.event_cause, 'content')}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('acquisition_date')}</div>
            <div className="value">{formatDate(asset.acquired_at as string)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('service_start_date')}</div>
            <div className="value">{formatDate(asset.usage_started_at as string)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">
              {get(asset, 'acquisition_cost_excluded_excise') === get(asset, 'acquisition_cost')
                ? t('acquisition_cost_exclude')
                : t('acquisition_cost_include')}
            </div>
            <div className="value">{yen(asset.acquisition_cost)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('accumulated_depreciation')}</div>
            <div className="value">
              {renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.ACCUMULATED_DEPRECIATION)}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('accumulated_impairment')}</div>
            <div className="value">{yen(asset.accumulated_impaired_amount)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('disposal_cost')}</div>
            <div className="value">{yen(asset.disposal_cost)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('loss_on_retirement')}</div>
            <div className="value">{renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.LOSS_AND_GAIN)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('department')}</div>
            <div className="value">{get(asset.department, 'name')}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('area')}</div>
            <div className="value">{get(asset.area, 'name')}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('photo')}</div>
            <div className="value">
              {asset?.event_photos?.map((photo: any) => (
                <div key={photo?.id}>
                  <PhotoView src={get(photo, 'url')} style={{ marginRight: 10 }} />
                </div>
              ))}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('attachment')}</div>
            <div className="value" style={{ display: 'block' }}>
              {asset?.event_attachments?.map((attachment: any) => (
                <div key={attachment?.id}>
                  <FileLink file={attachment} />
                </div>
              ))}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('reference_link')}</div>
            <div className="value" style={{ display: 'block' }}>
              {asset?.event_urls?.map((url: any) => (
                <div key={url}>
                  <ReferenceLink url={url} />
                </div>
              ))}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow className="last">
            <div className="label">{t('retire_by')}</div>
            <div className="value">{get(asset.user_info, 'display_name')}</div>
          </AssetDisplayDataRow>
        </AssetDisplay>
      ))}
    </>
  )
}

export default RetirementEventView
