import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'

import { BoldLabel, FilterField } from '../styles'
import styles from './form-filter.module.scss'
import { IFilterTemplate } from './model'

const FormFilterTemplate = ({ name, label, form, type, handleSubmit, renderContent, renderForm }: IFilterTemplate) => {
  const [isModalShow, setModalShow] = useState(false)
  const [isPopupShow, setPopupShow] = useState(false)

  const onVisibleChange = useCallback(
    (event: boolean) => {
      setPopupShow(event)
    },
    [setPopupShow]
  )

  const handleModalOnClick = useCallback(() => setModalShow(true), [setModalShow])

  const renderFormTemplate = () => {
    switch (type) {
      case 'popup':
        return (
          <Popover
            visible={isPopupShow}
            onVisibleChange={onVisibleChange}
            overlayClassName={styles['form-popover']}
            placement="rightTop"
            content={
              <div className={classNames(`${name}-popover`)}>
                {renderForm({
                  form,
                  isPopupShow,
                  setPopupShow,
                  isModalShow,
                  showModal: setModalShow,
                  onSubmit: handleSubmit,
                })}
              </div>
            }
            trigger="click"
          >
            <div className="label-wrapper">
              <BoldLabel>{label}</BoldLabel>
              <div style={{ marginLeft: 'auto' }}>
                <FontAwesomeIcon icon={faChevronRight} className="filter-chevron" />
              </div>
            </div>
          </Popover>
        )
      case 'modal':
        return (
          <>
            {renderForm({
              form,
              isPopupShow,
              setPopupShow,
              isModalShow,
              showModal: setModalShow,
              onSubmit: handleSubmit,
            })}
            <div className="label-wrapper" onClick={handleModalOnClick}>
              <BoldLabel>{label}</BoldLabel>
              <div style={{ marginLeft: 'auto' }}>
                <FontAwesomeIcon icon={faChevronRight} className="filter-chevron" />
              </div>
            </div>
          </>
        )
      default:
        break
    }
  }

  return (
    <FilterField className="form-filter-field">
      {renderFormTemplate()}
      {renderContent({ form, isPopupShow, setPopupShow, isModalShow, showModal: setModalShow, onSubmit: handleSubmit })}
    </FilterField>
  )
}

export default FormFilterTemplate
