import styled from '@emotion/styled'

export const Wrapper = styled.div`
  margin-top: 10px;

  th {
    text-align: left;
    padding-left: 5px;
  }
`

export const EmptyImage = styled.div`
  margin-top: 60px;
  text-align: center;
`
