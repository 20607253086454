import { AccountItem } from 'models/accountItem'
import { CSVCharset } from 'models/csvCharset'

export const FETCH_ACCOUNT_ITEMS_REQUEST = 'FETCH_ACCOUNT_ITEMS_REQUEST'
export const FETCH_ACCOUNT_ITEMS_REQUEST_AUTH = 'FETCH_ACCOUNT_ITEMS_REQUEST_AUTH'
export const FETCH_ACCOUNT_ITEMS_SUCCESS = 'FETCH_ACCOUNT_ITEMS_SUCCESS'
export const FETCH_ACCOUNT_ITEMS_FAILURE = 'FETCH_ACCOUNT_ITEMS_FAILURE'

export const CHANGE_ACCOUNT_ITEMS_VALUES = 'CHANGE_ACCOUNT_ITEMS_VALUES'

export const UPDATE_ACCOUNT_ITEMS_REQUEST = 'UPDATE_ACCOUNT_ITEMS_REQUEST'
export const UPDATE_ACCOUNT_ITEMS_SUCCESS = 'UPDATE_ACCOUNT_ITEMS_SUCCESS'
export const UPDATE_ACCOUNT_ITEMS_FAILURE = 'UPDATE_ACCOUNT_ITEMS_FAILURE'

export const SET_EDITING_ACCOUNT_ITEM = 'SET_EDITING_ACCOUNT_ITEM'
export const SET_NEW_ACCOUNT_ITEMS = 'SET_NEW_ACCOUNT_ITEMS'
export const SET_IS_RESET_FORM = 'SET_IS_RESET_FORM'
export const SET_ERRORS = 'SET_ERRORS'

export const IMPORT_ACCOUNT_ITEMS_REQUEST = 'IMPORT_ACCOUNT_ITEMS_REQUEST'
export const IMPORT_ACCOUNT_ITEMS_SUCCESS = 'IMPORT_ACCOUNT_ITEMS_SUCCESS'
export const IMPORT_ACCOUNT_ITEMS_FAILURE = 'IMPORT_ACCOUNT_ITEMS_FAILURE'

export const EXPORT_ACCOUNT_ITEMS_REQUEST = 'EXPORT_ACCOUNT_ITEMS_REQUEST'
export const EXPORT_ACCOUNT_ITEMS_SUCCESS = 'EXPORT_ACCOUNT_ITEMS_SUCCESS'
export const EXPORT_ACCOUNT_ITEMS_FAILURE = 'EXPORT_ACCOUNT_ITEMS_FAILURE'

export const CLEANUP_ACCOUNT_ITEMS = 'CLEANUP_ACCOUNT_ITEMS'

export const fetchAccountItems = () => ({
  type: FETCH_ACCOUNT_ITEMS_REQUEST,
})

export const fetchAccountItemsAuth = () => ({
  type: FETCH_ACCOUNT_ITEMS_REQUEST_AUTH,
})

export const fetchAccountItemsSuccess = (payload: any) => ({
  type: FETCH_ACCOUNT_ITEMS_SUCCESS,
  payload,
})

export const fetchAccountItemsFailure = () => ({
  type: FETCH_ACCOUNT_ITEMS_FAILURE,
})

export const changeAccountItemsValues = (newValues: any) => ({
  type: CHANGE_ACCOUNT_ITEMS_VALUES,
  payload: newValues,
})

export const updateAccountItems = () => ({
  type: UPDATE_ACCOUNT_ITEMS_REQUEST,
})

export const setEditingAccountItem = (editingItem: any) => ({
  type: SET_EDITING_ACCOUNT_ITEM,
  payload: editingItem,
})

export const setNewAccountItems = (accountItems: AccountItem[] | null) => ({
  type: SET_NEW_ACCOUNT_ITEMS,
  payload: accountItems,
})

export const setIsResetForm = (isReset: boolean) => ({
  type: SET_IS_RESET_FORM,
  payload: isReset,
})

export const setErrors = (payload: any) => ({
  type: SET_ERRORS,
  payload,
})

export const importAccountItems = (charset: CSVCharset, file: any) => {
  return {
    type: IMPORT_ACCOUNT_ITEMS_REQUEST,
    payload: charset,
    file,
  }
}

export const exportAccountItems = (charset: CSVCharset) => {
  return {
    type: EXPORT_ACCOUNT_ITEMS_REQUEST,
    charset,
  }
}

export const cleanupAccountItems = () => ({
  type: CLEANUP_ACCOUNT_ITEMS,
})
