/* eslint-disable @typescript-eslint/no-shadow */
import { ModalRef } from 'aa_common/front-end/hooks/useModalState'
import { message } from 'antd'
import Input from 'components/atoms/v2/Input'
import useModal from 'hooks/useModalHook'
import i18n from 'i18n'
import { List } from 'immutable'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { isValidUrl } from '../../../helpers'
import { CONFIRM_TYPES } from '../../modals'
import EditLinkFormModal from './components/EditModal'
import LinkList from './components/LinkList'
import { BaseLinkListFieldProps } from './model'

const CoreLinkListField = ({ value, placeholder, name, onChange }: BaseLinkListFieldProps) => {
  const [textValue, setTextValue] = useState('')
  const [linkList, setLinkList] = useState<string[]>([])
  const modal = useModal()
  const editModalRef = useRef<ModalRef<any>>(null)
  const linkListRef = useRef<any>(null)

  useEffect(() => {
    setLinkList(value || [])
  }, [value])

  const handleDeleteClick = useCallback(
    ({ index }: any) => {
      const selectedLink = linkList[index]
      modal
        .confirm({
          title: '',
          message: i18n.t('common.messages.confirm_delete', { name: selectedLink }),
          type: CONFIRM_TYPES.DELETE,
        })
        .then(isOKClick => {
          if (isOKClick) {
            const newValue = List(linkList).remove(index).toArray()
            setLinkList(newValue)
            onChange?.(newValue)
          }
        })
    },
    [linkList] // eslint-disable-line
  )

  const handleEditClick = useCallback(
    ({ index, url }) => {
      editModalRef.current?.open(
        {
          title: null,
          initialValues: { index, url },
          onValuesCb: values => {
            const { index, url: newUrl } = values
            const newValue = List(linkList).set(index, newUrl).toArray()
            setLinkList(newValue)
            onChange?.(newValue)
          },
        },
        {}
      )
    },
    [linkList] // eslint-disable-line
  )

  const handleValidate = (e: any) => {
    e.preventDefault()
    const text = textValue.trim()
    if (!text) return

    if (!isValidUrl(text)) {
      message.error(i18n.t('common.messages.invalid_url', { url: text }))
      return false
    }

    const updatedLinks = [...linkList]
    updatedLinks.push(textValue)
    onChange?.(updatedLinks)
    setLinkList(updatedLinks)
    setTextValue('')
    setTimeout(() => {
      linkListRef.current.scrollToItem({ index: updatedLinks.length - 1, align: 'end' })
    }, 10)
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleValidate(event)
    }
  }

  const handleOnBlur = (event: any) => handleValidate(event)

  return (
    <div className="aa-link-list-field">
      <Input
        name={name}
        value={textValue}
        placeholder={placeholder}
        onChange={event => setTextValue(event.target.value)}
        onBlur={handleOnBlur}
        onKeyDown={handleOnKeyDown}
      />
      {linkList?.length > 0 && (
        <LinkList ref={linkListRef} items={linkList} onDeleteClick={handleDeleteClick} onEditClick={handleEditClick} />
      )}
      <EditLinkFormModal name={name} placeholder={placeholder} ref={editModalRef} />
    </div>
  )
}

export default CoreLinkListField
