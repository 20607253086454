import { removeCookieByName } from 'aa_common/front-end/helpers/cookie-helper'
import axios from 'axios'
import history from 'browserHistory'
import { API_ERROR_META_CODE, API_STATUS_CODE } from 'constants/apiCode'
import { BE_BASE_URL } from 'constants/app'
import { BE_JWT_REFRESH_PATH, CSRF_TOKEN_KEY, FE_SIGNIN_PATH } from 'constants/auth'
import { get } from 'lodash'

const csrfIgnoreMethods = ['get', 'head', 'options']

axios.defaults.baseURL = BE_BASE_URL
axios.defaults.withCredentials = true

// ===== The codes below is for JWT refresh =====
let isRefreshing = false
let retryingFunc: (newCSRFToken: string) => typeof Promise
let retryingFuncs: Array<typeof retryingFunc> = []

const handleErrors = (errorResponse: any) => {
  const { status } = errorResponse
  const metaCode = get(errorResponse, 'data.meta.code')

  switch (status) {
    case API_STATUS_CODE.SERVICE_UNAVAILABLE: {
      history.push('/errors/503')
      break
    }
    case API_STATUS_CODE.SERVER_ERROR: {
      history.push('/errors/500')
      break
    }
    case API_STATUS_CODE.FORBIDDEN: {
      if (metaCode === API_ERROR_META_CODE.TERM_OF_USE_NOT_CONFIRMED) {
        window && window.location.replace('/term-of-use')
      }
      break
    }
    case API_STATUS_CODE.UNAUTHORIZED: {
      if (metaCode === API_ERROR_META_CODE.OFFICE_NOT_SELECTED) {
        window && window.location.replace('/offices/select')
      }
      if (metaCode === API_ERROR_META_CODE.OFFICE_EXPIRED) {
        history.push('/errors/40103')
      }
      break
    }
    default:
      break
  }
  return errorResponse
}

const isAuthError = (errorResponse: any): boolean => {
  if (
    errorResponse &&
    errorResponse.status === 401 &&
    errorResponse.data.meta.code === API_ERROR_META_CODE.UNAUTHORIZED &&
    errorResponse.config.url !== BE_JWT_REFRESH_PATH
  ) {
    localStorage.removeItem(CSRF_TOKEN_KEY)
    return true
  }

  return false
}

function onAccessTokenFetched(csrfToken: string): void {
  retryingFuncs.forEach(retryFunc => retryFunc(csrfToken))
}

function addRetryingFunc(retryFunc: any): void {
  retryingFuncs.push(retryFunc)
}

const handleAuthError = (errorResponse: any): Promise<any> => {
  try {
    const retryOriginalRequest = new Promise(resolve => {
      addRetryingFunc((csrfToken: string) => {
        errorResponse.config.headers['X-CSRF-Token'] = csrfToken
        resolve(axios(errorResponse.config))
      })
    })

    // When there are many asynchronous events failing due to the expired token.
    // Only one event should refresh token and retry all of events
    if (!isRefreshing) {
      isRefreshing = true
      axios
        .post(BE_JWT_REFRESH_PATH)
        .then(response => {
          const newCSRFToken = response.data.data.csrf_token
          localStorage.setItem(CSRF_TOKEN_KEY, newCSRFToken)
          onAccessTokenFetched(newCSRFToken)
        })
        .catch(error => {
          history.push(FE_SIGNIN_PATH)
          return Promise.reject(error)
        })
        .finally(() => {
          isRefreshing = false
          retryingFuncs = []
          removeCookieByName('is_agent_login')
        })
    }
    return retryOriginalRequest
  } catch (err) {
    return Promise.reject(err)
  }
}

// ===== The codes above are for JWT refresh =====

const initialize = () => {
  axios.interceptors.request.use(
    config => {
      if (config.method && !csrfIgnoreMethods.includes(config.method)) {
        const headers = {
          'X-CSRF-Token': localStorage.getItem(CSRF_TOKEN_KEY) || '',
        }

        config.headers = headers
      }
      return config
    },
    error => {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  axios.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    error => {
      const { response } = error

      if (isAuthError(response)) {
        return handleAuthError(response)
      }
      handleErrors(response)

      return Promise.reject(error)
    }
  )
}

initialize()

export default axios
