import i18n from 'i18n'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { cleanupAssetCollectionList } from 'store/asset/actions'
import { handleLogout } from 'store/session/actions'
import { selectCurrentOffice, selectUserInfo } from 'store/session/selectors'

import { Tooltip } from '../../antd'
import { ReactComponent as AALogo } from '../../assets/icons/aa-logo.svg'
import { ReactComponent as MenuIcon } from '../../assets/icons/menu-mfc-list.svg'
import { ReactComponent as MfcLogo } from '../../assets/icons/mfc-logo.svg'
import { formatDate, formatMonth } from '../../helpers'
import { useCheckAgentLogin } from '../../hooks'
import { DropdownToggle } from '../dropdown-toggle'
import { ConfirmModal } from '../modals'
import { AgentLoginHeader } from './agent-login-header'
import { Faq } from './faq'
import { Notification } from './notification'
import { OfficeDropdown } from './office-dropdown'
import {
  Divide,
  DropdownMenuStyled,
  Item,
  LastLinkStyled,
  LeftContent,
  LinkStyled,
  RightContent,
  Wrapper,
} from './styles'

const REACT_APP_EDIT_EMAIL = process.env.REACT_APP_EDIT_EMAIL as string
const REACT_APP_EDIT_PASSWORD = process.env.REACT_APP_EDIT_PASSWORD as string

const profileKeys = {
  logOut: 'log-out',
  changeEmail: 'change-email',
  changePassword: 'change-password',
}
const profileItems = [
  {
    key: profileKeys.changeEmail,
    text: (
      <LinkStyled href={REACT_APP_EDIT_EMAIL} target="_blank" rel="noopener noreferrer">
        {i18n.t('components.MainHeader.user.edit_email')}
      </LinkStyled>
    ),
  },
  {
    key: profileKeys.changePassword,
    text: (
      <LinkStyled href={REACT_APP_EDIT_PASSWORD} target="_blank" rel="noopener noreferrer">
        {i18n.t('components.MainHeader.user.edit_password')}
      </LinkStyled>
    ),
  },
  {
    key: profileKeys.logOut,
    text: i18n.t('components.MainHeader.user.log_out'),
  },
]

const serviceItems = [
  {
    key: 'accounting_plus',
    text: (
      <LinkStyled
        href="https://enterprise-accounting.moneyforward.com/session/new"
        target="_blank"
        rel="noopener noreferrer"
      >
        {i18n.t('components.MainHeader.services.accounting_plus')}
      </LinkStyled>
    ),
  },
  {
    key: 'expense',
    text: (
      <LinkStyled href="https://expense.moneyforward.com/" target="_blank" rel="noopener noreferrer">
        {i18n.t('components.MainHeader.services.expense')}
      </LinkStyled>
    ),
  },
  {
    key: 'dept_payment',
    text: (
      <LinkStyled href="https://biz.moneyforward.com/payable/" target="_blank" rel="noopener noreferrer">
        {i18n.t('components.MainHeader.services.dept_payment')}
      </LinkStyled>
    ),
  },
  {
    key: 'claims',
    text: (
      <LinkStyled href="https://biz.moneyforward.com/receivable/" target="_blank" rel="noopener noreferrer">
        {i18n.t('components.MainHeader.services.claims')}
      </LinkStyled>
    ),
  },
  {
    key: 'cloud_series',
    text: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MfcLogo />
        <LastLinkStyled href="https://biz.moneyforward.com/" target="_blank" rel="noopener noreferrer">
          {i18n.t('components.MainHeader.services.cloud_series')}
        </LastLinkStyled>
      </div>
    ),
  },
]

export const MainHeader = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const userInfoSelector = useSelector(selectUserInfo, isEqual)
  const currentOfficeSelector = useSelector(selectCurrentOffice, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const isAgentLogin = useCheckAgentLogin()

  const [isConfirmAgentLogout, setIsConfirmAgentLogout] = useState(false)

  const isLoading = userInfoSelector.loading || currentOfficeSelector.loading
  const renderMenuIcon = () => (
    <div className="header-icon">
      <Tooltip
        overlayStyle={{ pointerEvents: 'none' }}
        tooltipStyles={{ height: 25, cursor: 'pointer' }}
        content={i18n.t('components.MainHeader.services.tooltip')}
      >
        <MenuIcon width="24px" height="25px" style={{ position: 'relative', bottom: 8 }} />
      </Tooltip>
    </div>
  )

  const handleUserSelect = useCallback(
    (item: any) => {
      if (item.key === profileKeys.logOut) {
        dispatch(handleLogout())
      }
    },
    [dispatch]
  )

  const handleLogoClick = () => {
    dispatch(cleanupAssetCollectionList())
    history.push('/')
  }

  useEffect(() => {
    !currentMonthlyClosing && dispatch(fetchMonthlyClosingCurrent())
  }, [dispatch, currentMonthlyClosing])

  const renderCurrentMonthlyClosing = useMemo(() => {
    if (currentMonthlyClosing) {
      const currentTerm = i18n.t('common.format.term_year_month', {
        year: currentMonthlyClosing?.from_term_month?.term.year,
        month: formatMonth(currentMonthlyClosing?.from_term_month?.month),
      })

      const from_date = formatDate(currentMonthlyClosing?.from_term_month?.start_date)
      const to_date = formatDate(currentMonthlyClosing?.to_term_month?.end_date)

      const renderText = i18n.t('components.MainHeader.current_monthly_closing', {
        term_year_month: currentTerm,
        from_date,
        to_date,
      })
      return <span className="current-monthly-closing">{renderText}</span>
    }
  }, [currentMonthlyClosing])

  const onClickAgentLogout = () => {
    setIsConfirmAgentLogout(true)
  }

  const handleAgentLogout = () => {
    setIsConfirmAgentLogout(false)
    dispatch(handleLogout())
  }

  const handleCancelAgentLogout = () => {
    setIsConfirmAgentLogout(false)
  }

  return (
    <Wrapper>
      {isAgentLogin && (
        <>
          <AgentLoginHeader onSubmit={onClickAgentLogout} />
          <ConfirmModal
            title={i18n.t('components.AgentLoginHeader.title_confirm_agent_logout')}
            message={i18n.t('components.AgentLoginHeader.message_confirm_agent_logout', currentOfficeSelector?.data)}
            okText={i18n.t('components.AgentLoginHeader.confirm_agent_logout')}
            type={1}
            visible={isConfirmAgentLogout}
            onOK={handleAgentLogout}
            onCancel={handleCancelAgentLogout}
          />
        </>
      )}
      <LeftContent>
        <DropdownToggle
          name="services"
          label={renderMenuIcon()}
          items={serviceItems}
          onSelectedItem={handleUserSelect}
          faSortDownOption={false}
          dropdownMenuStyle={DropdownMenuStyled}
          loading={isLoading}
        />
        <Divide />
        <AALogo height="21px" style={{ cursor: 'pointer' }} onClick={handleLogoClick} />
      </LeftContent>
      <RightContent>
        <Item>
          <Notification />
        </Item>
        <Item>
          <Faq isShowFullOptions />
        </Item>
        <Item>{renderCurrentMonthlyClosing}</Item>
        <Item>
          <OfficeDropdown />
        </Item>
        <Item>
          <DropdownToggle
            name="user"
            label={get(userInfoSelector.data, 'display_name', '')}
            loading={isLoading}
            items={profileItems}
            onSelectedItem={handleUserSelect}
            dropdownMenuStyle={{ textAlign: 'center', position: 'fixed', top: 40 }}
          />
        </Item>
      </RightContent>
    </Wrapper>
  )
}

export * from './faq'
export * from './notification'
export * from './office-dropdown'
