import { Button, Tooltip } from 'aa_common/front-end/antd'
import { ColorLabel } from 'aa_common/front-end/components'
import { useAssetCodePreview } from 'aa_common/front-end/hooks'
import { getNs } from 'aa_common/front-end/i18n-config'
import { AssetCategoriesService } from 'aa_common/front-end/open-api'
import { NumberInput } from 'components/atoms'
import { getIn, useFormikContext } from 'formik'
import React, { useEffect } from 'react'

import { Row, SubmitWrapper, Table } from './styles'

const { t, useNsReady } = getNs(['common', 'components/organisms/settings/asset-code-start-number-page'])

type Props = {
  lengthRuleNumber: number
}

const Form: React.FC<Props> = ({ lengthRuleNumber }) => {
  useNsReady()
  const { values, setFieldValue } = useFormikContext()
  const { getAssetCodePreview, isIncludeAcquisitionDate } = useAssetCodePreview()
  const wholeOffice = getIn(values, 'whole_office')

  const fetchAssetCategory = async () => {
    const response = await AssetCategoriesService.assetCategoriesList({
      'page[number]': 1,
      'page[size]': 1, // Only get the first item to generate the code for the asset code preview
    })
    setFieldValue('asset_category_code', response?.data[0]?.code ?? '')
  }

  useEffect(() => {
    fetchAssetCategory()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const assetCodePreview = getAssetCodePreview(values, lengthRuleNumber)

  return (
    <>
      {wholeOffice !== null && (
        <Table>
          <Row className="last">
            <div className="label">{getIn(values, 'whole_office_text')}</div>
            <div className="content">
              {getIn(values, 'is_editable') ? (
                <NumberInput name="initial_value" style={{ width: 100 }} maxLength={lengthRuleNumber} />
              ) : (
                <Tooltip content={t('disabled_tooltip_msg')} placement="top">
                  <NumberInput name="whole_office" style={{ width: 100 }} disabled />
                </Tooltip>
              )}
              <ColorLabel color="disabled" style={{ marginLeft: 10, marginTop: 5 }}>
                {t('code_sample', { code: assetCodePreview })}
                {isIncludeAcquisitionDate && t('incase_registering_today')}
              </ColorLabel>
            </div>
          </Row>
        </Table>
      )}
      <SubmitWrapper>
        <Button type="submit" color="blue" size="custom" style={{ width: 70 }}>
          {t('actions.save')}
        </Button>
      </SubmitWrapper>
    </>
  )
}

export default Form
