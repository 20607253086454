import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from 'constants/pagination'
import { Map } from 'immutable'

import { DepartmentActionTypes } from './action.model'
import {
  CLEANUP_DEPARTMENT,
  CLEAR_DEPARTMENT_ITEM_ERRORS,
  CREATE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_BY_ID_FAILURE,
  DELETE_DEPARTMENT_BY_ID_REQUEST,
  DELETE_DEPARTMENT_BY_ID_SUCCESS,
  EXPORT_DEPARTMENT_FAILURE,
  EXPORT_DEPARTMENT_REQUEST,
  EXPORT_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENT_BY_BIID_REQUEST,
  FETCH_DEPARTMENT_BY_BIID_SUCCESS,
  FETCH_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENTS_FAILURE,
  FETCH_DEPARTMENTS_ON_CLOUD_REQUEST,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_REQUEST_AUTH,
  FETCH_DEPARTMENTS_SUCCESS,
  HANDLE_FETCH_DEPARTMENTS_ON_CLOUD,
  IMPORT_DEPARTMENT_FAILURE,
  IMPORT_DEPARTMENT_REQUEST,
  IMPORT_DEPARTMENT_SUCCESS,
  INSERT_DEPARTMENT_REQUEST,
  PARTIAL_CLEANUP_DEPARTMENT,
  SUBMIT_DEPARTMENT_FAILURE,
  SUBMIT_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_REQUEST,
} from './constant'

export const initialState = Map({
  list: Map({
    data: [],
    loading: false,
    filters: null,
    pageNumber: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: ['-code'],
    totalRecords: 0,
  }),
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
  import: Map({
    loading: false,
    error: {
      message: '',
      details: [],
    },
  }),
  export: {
    loading: false,
  },
  departmentOnCloud: Map({
    list: [],
    loading: false,
  }),
})

export default (state = initialState, action: DepartmentActionTypes) => {
  switch (action.type) {
    case FETCH_DEPARTMENTS_REQUEST:
    case FETCH_DEPARTMENTS_REQUEST_AUTH:
      return state
        .setIn(['list', 'loading'], true)
        .setIn(['list', 'filters'], action.payload.filters)
        .setIn(['list', 'pageNumber'], action.payload.pageNumber)
        .setIn(['list', 'pageSize'], action.payload.pageSize)
        .setIn(['list', 'sort'], action.payload.sort)
    case FETCH_DEPARTMENTS_SUCCESS:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'data'], action.payload.data)
        .setIn(['list', 'totalRecords'], action.payload.totalRecords)
    case FETCH_DEPARTMENTS_FAILURE:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], []).setIn(['list', 'totalRecords'], 0)
    case FETCH_DEPARTMENTS_ON_CLOUD_REQUEST:
      return state.setIn(['departmentOnCloud', 'loading'], true)
    case HANDLE_FETCH_DEPARTMENTS_ON_CLOUD:
      return state.setIn(['departmentOnCloud', 'list'], action.payload).setIn(['departmentOnCloud', 'loading'], false)
    case FETCH_DEPARTMENT_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_DEPARTMENT_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'data'], action.payload)
    case FETCH_DEPARTMENT_BY_BIID_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_DEPARTMENT_BY_BIID_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'data'], action.payload)
    case CREATE_DEPARTMENT_REQUEST:
    case INSERT_DEPARTMENT_REQUEST:
    case UPDATE_DEPARTMENT_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case SUBMIT_DEPARTMENT_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], null)
    case SUBMIT_DEPARTMENT_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], action.payload)
    case DELETE_DEPARTMENT_BY_ID_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case DELETE_DEPARTMENT_BY_ID_SUCCESS:
    case DELETE_DEPARTMENT_BY_ID_FAILURE:
      return state.setIn(['item', 'loading'], false)
    case CLEAR_DEPARTMENT_ITEM_ERRORS:
      return state.setIn(['item', 'errors'], null)
    case IMPORT_DEPARTMENT_REQUEST:
      return state.setIn(['import', 'loading'], true)
    case IMPORT_DEPARTMENT_SUCCESS:
      return state
        .setIn(['import', 'loading'], false)
        .setIn(['import', 'error'], initialState.getIn(['import', 'error']))
    case IMPORT_DEPARTMENT_FAILURE:
      return state.setIn(['import', 'loading'], false).setIn(['import', 'error'], action.payload)
    case EXPORT_DEPARTMENT_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case EXPORT_DEPARTMENT_SUCCESS:
    case EXPORT_DEPARTMENT_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case PARTIAL_CLEANUP_DEPARTMENT:
      return initialState.setIn(['list'], state.getIn(['list']))
    case CLEANUP_DEPARTMENT:
      return initialState
    default:
      return state
  }
}
