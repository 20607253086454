import { loadNs } from 'aa_common/front-end/i18n-config'
import { Map } from 'immutable'
import { get } from 'lodash'
import { TableColumn } from 'models/sorting'

const t = loadNs(['components/organisms/settings/unit-list'])

export const displayLinkMemorandumValueQuantity = (link_memorandum_value_quantity: boolean): string => {
  const result = link_memorandum_value_quantity
    ? t('memorandum_value_quantity_options.interlock')
    : t('memorandum_value_quantity_options.not_linked')
  return result
}

export const columns = (): TableColumn[] => [
  { field: 'name', text: t('name'), sortable: false },
  {
    field: 'link_memorandum_value_quantity',
    text: t('link_memorandum_value_quantity'),
    sortable: false,
  },
]

export const UNIT_LIST_COLUMNS_WIDTH = [160, 'auto', 90]

export const convertDataToDisplay = (data: any) => {
  if (!data || !data.length) return []

  return data.map((dataItem: any) => {
    const displayItem = {
      id: get(dataItem, 'id'),
      name: get(dataItem, 'name'),
      is_default: get(dataItem, 'is_default'),
    }

    let immutableDisplayItem = Map(displayItem)

    columns()?.forEach(({ field }: { field: string }) => {
      switch (field) {
        case 'link_memorandum_value_quantity': {
          const linkMemorandumValueQuantity = get(dataItem, 'link_memorandum_value_quantity')
          immutableDisplayItem = immutableDisplayItem.set(
            field,
            displayLinkMemorandumValueQuantity(linkMemorandumValueQuantity)
          )
          break
        }
        default: {
          const renderData = get(dataItem, field)
          immutableDisplayItem = immutableDisplayItem.set(field, renderData)
        }
      }
    })

    return immutableDisplayItem.toObject()
  })
}
