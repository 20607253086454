import styled from '@emotion/styled'

export const Wrapper = styled.div`
  margin-top: 10px;

  th {
    text-align: left;
    padding-left: 5px;
  }
`

export const ErrorArea = styled.div`
  margin-top: 26px;
`

export const ActionArea = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 15px;
  margin-top: 15px;

  button {
    margin-left: 10px;
  }
`

export const EmptyImage = styled.div`
  margin-top: 60px;
  text-align: center;
`
