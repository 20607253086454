import {
  buildPaginationParams,
  buildQueryFilterParams,
  objectToQueryParams,
} from 'aa_common/front-end/helpers/request-helper'
import { BE_BASE_URL } from 'constants/app'
import { DEFAULT_PAGE_SIZE } from 'constants/pagination'
import { AssetEventSearchParams, MONTHLY_CLOSING_STATUSES } from 'models/monthlyClosing'
import { parseResponse } from 'utils/ResponseHelper'

import base from './base'
import { IExportIncreaseDecreaseReport, IFetchIncreaseDecreaseReport } from './model/report'

export const getCurrent = () => {
  return base.get('/v1/monthly_closing/current')
}

export const getSummaryAssetEvents = (monthlyClosingId: number) => {
  return base.get(`/v1/monthly_closing/summary_fixed_asset_events/${monthlyClosingId}`)
}

export const getClosedMonlyClosingOfSetting = (monthClosingSettingId: number) => {
  const filterParams = buildQueryFilterParams({
    monthly_closing_setting_id: monthClosingSettingId,
    status: MONTHLY_CLOSING_STATUSES.CLOSED,
  })

  return base.get('/v1/monthly_closing', {
    params: { ...filterParams },
  })
}

export const getNextSetting = (monthlyClosingId: number) => {
  return base.get(`/v1/monthly_closing_settings/${monthlyClosingId}/next`)
}

export const getAssetEvents = (
  { page_number = 1, page_size = 2, ...others }: AssetEventSearchParams,
  is_occurred_in_the_past = false
) => {
  const params = {
    ...others,
    is_occurred_in_the_past,
    ...buildPaginationParams(page_number, page_size),
  }

  return base.get('/v1/monthly_closing/fixed_asset_events', { params })
}

export const updateSetting = (monthlyClosingId: number, data: any) => {
  return base.put(`/v1/monthly_closing_settings/${monthlyClosingId}`, data)
}

export const submitCloseMonthlyClosing = () => {
  return base.put('/v1/monthly_closing/current')
}

export const getJournals = () => {
  return base
    .get('/v1/monthly_closing/journals')
    .then(res => parseResponse(res))
    .catch(err => err?.response?.data || {})
}

export const getExportJournalHistories = (pageNumber?: number) =>
  base.get('/v1/journals/export_journal_histories', {
    params: {
      ...buildPaginationParams(pageNumber, DEFAULT_PAGE_SIZE),
    },
  })

export const getSendingJournalHistories = (pageNumber?: number) =>
  base.get('/v1/journals/journal_linkage_histories', {
    params: {
      ...buildPaginationParams(pageNumber, DEFAULT_PAGE_SIZE),
    },
  })

export const getIncreaseDecreaseReportMonthlyClosing: IFetchIncreaseDecreaseReport = payload => {
  return base.get('/v1/monthly_closing/increase_decrease_cost', {
    params: payload,
  })
}

export const getDepreciationResultReportMonthlyClosing = (payload: any) => {
  return base.get('/v1/monthly_closing/depreciation_results/aggregate', {
    params: payload,
  })
}

export const exportMonthlyClosingIncreaseDecreaseReportURL: IExportIncreaseDecreaseReport = payload => {
  const params = objectToQueryParams(payload)
  return `${BE_BASE_URL}/v1/export/monthly_closing/increase_decrease_cost?${params}`
}

export const exportMonthlyClosingDepreciationResultURL = (payload: any) => {
  const params = objectToQueryParams(payload)
  return `${BE_BASE_URL}/v1/export/monthly_closing/depreciation_results?${params}`
}

export const getMonthlyClosingReselectTerms = () => {
  return base.get('/v1/monthly_closing/reselect/get_term_month')
}

export const submitMonthlyClosingReselectTerm = (term_month_id: any) => {
  return base.put('/v1/monthly_closing/reselect', { term_month_id })
}

export const reopenMonthlyClosing = (dry_run?: boolean) => {
  return base.post('/v1/monthly_closing/reopen', { dry_run })
}

export const getMonthlyClosingHistory = () => {
  return base.get('/v1/monthly_closing/history')
}
