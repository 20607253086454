import { handlePermissionByFeatureId } from 'aa_common/front-end/helpers'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUserPermission } from 'store/session/selectors'

const useUserPermission = (featureId: number) => {
  const userPermissions = useSelector(selectUserPermission, isEqual)

  return useMemo(() => {
    const permissions = handlePermissionByFeatureId(featureId, userPermissions.data)

    return {
      loading: userPermissions.loading,
      permissions,
    }
  }, [userPermissions, featureId])
}

export default useUserPermission
