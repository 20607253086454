import { loadNs } from 'aa_common/front-end/i18n-config'
import { ASSET_CODE_SETTING_RULE_TYPE_CODE } from 'constants/masterData'
import { SettingRule } from 'models/assetCode'
import { AssetCodeSettingRule, AssetCodeSettings } from 'models/masterData'

const t = loadNs(['components/organisms/settings/asset-code-auto-generation-view'])

export const getDefaultRules = (assetCodeSettingRules: AssetCodeSettingRule[]) => {
  const defaultRules: SettingRule[] = []

  if (!assetCodeSettingRules) return []

  const assetCategoryRule = assetCodeSettingRules.find(
    assetCodeSettingRule => assetCodeSettingRule.code === ASSET_CODE_SETTING_RULE_TYPE_CODE.ASSET_CATEGORY
  )
  const symbolRule = assetCodeSettingRules.find(
    assetCodeSettingRule => assetCodeSettingRule.code === ASSET_CODE_SETTING_RULE_TYPE_CODE.SYMBOL
  )

  if (assetCategoryRule) {
    defaultRules.push({
      field: ASSET_CODE_SETTING_RULE_TYPE_CODE.ASSET_CATEGORY,
      value: assetCategoryRule.values[0].code,
      value_text: assetCategoryRule.values[0].name_jp,
      unique_key: ASSET_CODE_SETTING_RULE_TYPE_CODE.ASSET_CATEGORY,
    })
  }

  if (symbolRule) {
    defaultRules.push({
      field: ASSET_CODE_SETTING_RULE_TYPE_CODE.SYMBOL,
      value: symbolRule.values[0].code,
      value_text: symbolRule.values[0].name_jp,
      unique_key: ASSET_CODE_SETTING_RULE_TYPE_CODE.SYMBOL,
    })
  }

  return defaultRules
}

export const parseRules = (assetCodeSettingRules: AssetCodeSettingRule[], rules: SettingRule[]) => {
  if (!assetCodeSettingRules || !rules) return []

  return rules.map((rule, index) => {
    const assetCodeSettingRule = assetCodeSettingRules.find(
      assetCodeSettingRule => assetCodeSettingRule.code === rule.field
    )

    if (assetCodeSettingRule?.code === ASSET_CODE_SETTING_RULE_TYPE_CODE.FIXED_VALUE) {
      return {
        ...rule,
        unique_key: `${assetCodeSettingRule?.code}-${index}`,
      }
    }
    return {
      ...rule,
      value_text: displayValueTextOfRule(assetCodeSettingRules, rule.field, rule.value as number),
      unique_key: `${assetCodeSettingRule?.code}-${index}`,
    }
  })
}

export const displayValueTextOfRule = (
  masterDataRules: AssetCodeSettingRule[],
  ruleCode: number,
  ruleValue: number
) => {
  let result = ''

  if (!masterDataRules) return ''

  const selectedMasterDataRule = masterDataRules.find(masterDataRule => masterDataRule.code === ruleCode)

  if (selectedMasterDataRule) result = selectedMasterDataRule?.name_jp

  const selectedRuleValue = selectedMasterDataRule?.values.find(dataRule => dataRule.code === ruleValue)

  if (selectedRuleValue) result += `（${selectedRuleValue.name_jp}）`

  return result
}

export const parseLengthRuleValues = (assetCodeSetting: AssetCodeSettings, lengthValues: any[]) => {
  return lengthValues.map((lengthValue: any) => {
    return {
      ...lengthValue,
      text: `${t('serial_number')}（${lengthValue.name_jp}）`,
    }
  })
}
