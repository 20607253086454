import { faCaretDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Col, Row, Select } from 'antd'
import { RenderFormProps } from 'components/molecules/Form'
import { ASSET_CODE_FIELD, nameField } from 'components/organisms/assets/AssetForm/const'
import { List } from 'immutable'
import { find, sortBy, uniqBy } from 'lodash'
import { IFilterFacetSettingsData } from 'models/asset'
import { IMasterData } from 'models/masterData'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { OTHER_CONDITION_FILTERS } from './const'
import { OtherConditionFilterOption } from './model'
import RenderFilterTemplate from './RenderFilterTemplate'
import { RemoveFilterStyle, RowStyle, SelectStyle } from './styles'

const { Option } = Select

const t = loadNs(['components/organisms/assets/asset-filter-facet'])

interface AdvancedFilterTemplate {
  index: number
  field: any
  masterData: IMasterData
  settingsData: IFilterFacetSettingsData
  formModule: RenderFormProps
  remainingFilters: OtherConditionFilterOption[]
  onFilterChanged: (index: number, filter?: string, lastFilter?: string) => void
  onFilterRemoved: (index: number, filter?: string) => void
}

const FilterTemplate = ({
  index,
  field,
  masterData,
  settingsData,
  remainingFilters,
  formModule,
  onFilterChanged,
  onFilterRemoved,
}: AdvancedFilterTemplate) => {
  const [fieldType, setFieldType] = useState<string | undefined>()

  const selectRefContainer = useRef(null)

  useEffect(() => {
    setFieldType(field ? field.fieldKey : undefined)
  }, [field])

  const [renderRemainingFilters, setRenderRemainingFilters] = useState<OtherConditionFilterOption[] | null>(null)

  useEffect(() => {
    if (fieldType) {
      const selectItem = find(OTHER_CONDITION_FILTERS, item => item.value === fieldType)
      if (selectItem) {
        const actual = List<OtherConditionFilterOption>(remainingFilters).push(selectItem).toArray()
        setRenderRemainingFilters(uniqBy(sortRemainingFilters(actual), 'value'))
      }
    } else {
      setRenderRemainingFilters(uniqBy(sortRemainingFilters(remainingFilters), 'value'))
    }
  }, [remainingFilters, fieldType])

  const handleFilterTypeChanged = (filter: any) => {
    setFieldType(prev => {
      if (prev !== filter) {
        onFilterChanged(index, filter, prev)
      } else {
        onFilterChanged(index, filter)
      }
      return filter
    })
  }

  const sortRemainingFilters = (remainingFilters: OtherConditionFilterOption[]) => {
    const assetName = find(remainingFilters, item => item.value === nameField.text)
    const assetCode = find(remainingFilters, item => item.value === ASSET_CODE_FIELD)

    const remaining = remainingFilters.filter(item => item.value !== nameField.text && item.value !== ASSET_CODE_FIELD)
    const sorted = sortBy(remaining, 'value')

    assetName && sorted.unshift(assetName)
    assetCode && sorted.unshift(assetCode)

    return sorted
  }

  const handleRemoveFilter = useCallback(() => {
    onFilterRemoved(index, fieldType)
  }, [fieldType, onFilterRemoved, index])

  return (
    <Row ref={selectRefContainer} className={RowStyle} gutter={[16, 16]}>
      <Col span={8}>
        <Select
          className={SelectStyle}
          value={fieldType}
          suffixIcon={<FontAwesomeIcon style={{ color: '#7C8291' }} icon={faCaretDown} />}
          dropdownMatchSelectWidth
          placeholder={t('otherFilterConditions.condition_item')}
          onChange={handleFilterTypeChanged}
          getPopupContainer={() => selectRefContainer.current as any}
        >
          {renderRemainingFilters &&
            renderRemainingFilters.map(({ value, label }) => {
              return (
                <Option key={value} value={value}>
                  {label}
                </Option>
              )
            })}
        </Select>
      </Col>
      <Col span={15}>
        <RenderFilterTemplate
          masterData={masterData}
          settingsData={settingsData}
          formModule={formModule}
          field={fieldType}
        />
      </Col>
      <Col span={1} style={{ alignSelf: 'center' }}>
        <FontAwesomeIcon className={RemoveFilterStyle} icon={faTimesCircle} onClick={handleRemoveFilter} />
      </Col>
    </Row>
  )
}

export default FilterTemplate
