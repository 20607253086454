/* eslint-disable no-unsafe-optional-chaining */
import { faCircleCheck, faCircleExclamation, faExternalLink, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BasicModal, CONFIRM_TYPES, ConfirmModal } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Button, Progress, Typography } from 'antd'
import * as recalculateApi from 'api/app/recalculate'
import { BE_WS_URL, URL_AA_SUPPORT } from 'constants/app'
import { RECALCULATE_STATUS } from 'constants/const'
import { useWebSocket } from 'hooks/useWebSocket'
import useResource from 'lib/hooks/useResource'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAppMessage } from 'store/app/actions'

import {
  BottomSection,
  ButtonStyled,
  ContentWrapper,
  NoteSection,
  ProgressBarWrapper,
  ResultContent,
  ResultSection,
  TopSection,
  Wrapper,
} from './styles'

const t = loadNs(['pages/settings/recalculate'])

const ReCalculateView = () => {
  const dispatch = useDispatch()
  const [isShowConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const { recalculateData, setRecalculateData } = useWebSocket(BE_WS_URL)
  const [{ isLoading }, triggerRecalculate] = useResource(recalculateApi.trigger)
  const [{ data: recalculateHistory }, getRecalculateHistory] = useResource(recalculateApi.getHistory)

  const timeRecalculate = new Date(get(recalculateHistory, '[0].created_at')).toLocaleString('en-ZA').replace(',', '')
  const successCountRecalculate = get(recalculateHistory, '[0].success_count')
  const totalRecalculate = get(recalculateHistory, '[0].total')
  const failedCountRecalculate = get(recalculateHistory, '[0].failed_count')
  const recalculateStatus = get(recalculateHistory, '[0].status')
  const isRecalculateDone = successCountRecalculate === totalRecalculate

  useEffect(() => {
    getRecalculateHistory()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (recalculateData?.status === RECALCULATE_STATUS.SUCCESS && isRecalculateDone) {
      dispatch(
        setAppMessage({
          type: 'success',
          content: t('success_recalculate_message'),
        })
      )

      getRecalculateHistory()
    }
  }, [recalculateData]) //eslint-disable-line

  const handleShowConfirmModal = () => setShowConfirmModal(true)

  const handleClickCancelBtn = () => setShowConfirmModal(false)

  const handleClickRecalculateBtn = () => {
    setShowConfirmModal(false)
    triggerRecalculate()
  }

  const getProgressPercent = () => {
    if (!recalculateData?.total && !recalculateData?.success_count) {
      return
    }

    return (recalculateData?.success_count / recalculateData?.total) * 100
  }

  const handleHiddenModalError = () => {
    recalculateData && setRecalculateData({ ...recalculateData, status: RECALCULATE_STATUS.ERROR })
    getRecalculateHistory()
  }

  const listNotes = [t('text_note_1'), t('text_note_2'), t('text_note_3'), t('text_note_4'), t('text_note_5')]

  const progressSetting = {
    percent: getProgressPercent() || 0,
    showInfo: false,
    strokeColor: '#3B7DE9',
    trailColor: '#EFF1F4',
    strokeWidth: 4,
    style: { marginTop: 4 },
  }

  const isShowProgressBar = [recalculateStatus, recalculateData?.status].includes(RECALCULATE_STATUS.PROCESSING)

  const isRecalculateHasError = recalculateData?.total !== recalculateData?.success_count
  const isDisableRecalculateBtn =
    get(recalculateHistory, '[0].disable_recalculate') &&
    recalculateStatus === RECALCULATE_STATUS.SUCCESS &&
    isRecalculateDone

  return (
    <Wrapper>
      <ContentWrapper>
        <TopSection>
          <p>{t('text_description_1')}</p>
          <p>
            {t('text_description_2')}{' '}
            <Typography.Link href={`${URL_AA_SUPPORT}guide`} target="_blank">
              {t('detail')} <FontAwesomeIcon icon={faExternalLink} />
            </Typography.Link>
          </p>
        </TopSection>
        <NoteSection>
          <p>{t('note_title')}</p>
          <ul>
            {listNotes.map(note => (
              <li key={note}>{note}</li>
            ))}
          </ul>
        </NoteSection>
        {!!recalculateHistory?.length && recalculateStatus === RECALCULATE_STATUS.SUCCESS && (
          <ResultSection>
            <p>{t('result_title')}</p>
            <ResultContent>
              {isRecalculateDone ? (
                <FontAwesomeIcon icon={faCircleCheck} color="#3B7DE9" />
              ) : (
                <FontAwesomeIcon icon={faCircleExclamation} color="#EC4949" />
              )}
              <span>{timeRecalculate}</span>
              <span>{t('result_unit', { successCountRecalculate, totalRecalculate })}</span>
              {!isRecalculateDone && <span>{t('result_text', { failedCountRecalculate })}</span>}
            </ResultContent>
          </ResultSection>
        )}
      </ContentWrapper>
      <BottomSection>
        {isShowProgressBar ? (
          <ProgressBarWrapper>
            <p>{t('progress_description_text_1')}</p>
            <p>{t('progress_description_text_2')}</p>
            <Progress {...progressSetting} />
            <span>
              {`${recalculateData?.success_count ? recalculateData?.success_count : 0}`}/
              {`${recalculateData?.total ? recalculateData?.total : 0}`}
              {t('case')}
            </span>
          </ProgressBarWrapper>
        ) : (
          <Button
            type="primary"
            onClick={handleShowConfirmModal}
            loading={isLoading}
            disabled={isDisableRecalculateBtn}
          >
            {t('recalculate')}
          </Button>
        )}

        {isRecalculateHasError && recalculateData?.status === RECALCULATE_STATUS.SUCCESS && (
          <BasicModal
            isShow={isRecalculateHasError && recalculateData?.status === RECALCULATE_STATUS.SUCCESS}
            title={t('error_recalculate_title')}
            width={540}
            onCancel={handleHiddenModalError}
            footer={<ButtonStyled onClick={handleHiddenModalError}>{t('cancel_text')}</ButtonStyled>}
          >
            <p>
              {t('error_recalculate_message_line1_text1')}{' '}
              <b>
                {recalculateData?.total - recalculateData?.success_count}
                {t('case')}
              </b>{' '}
              {t('error_recalculate_message_line1_text2')}
            </p>{' '}
            <br />
            <p>{t('error_recalculate_message_line2')}</p>
          </BasicModal>
        )}

        <ConfirmModal
          type={CONFIRM_TYPES.CONFIRM}
          visible={!!isShowConfirmModal}
          closable
          title={t('recalculate_title')}
          message={t('modal_text')}
          okText={t('recalculate')}
          cancelText={t('cancel_text')}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          onOK={handleClickRecalculateBtn}
          onCancel={handleClickCancelBtn}
          cancelStyle={{ minWidth: '70px' }}
        />
      </BottomSection>
    </Wrapper>
  )
}

export default ReCalculateView
