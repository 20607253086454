import { ColumnState, SortOptions, TableColumn } from 'models/sorting'

export const convertTableSortPayload = (columns: ColumnState[]) => {
  const sorts: string[] = []
  ;(columns || []).forEach(column => {
    if (column.sortType !== '') {
      switch (column.sortType) {
        case 'asc':
          sorts.push(`${column.field}`)
          break
        case 'desc':
          sorts.push(`-${column.field}`)
          break
        default:
          break
      }
    }
  })

  return sorts
}

export function calcInitColumnsState(columns: TableColumn[], defaultSortOpts?: string[]) {
  const sortOptions = (defaultSortOpts || []).reduce((options, opt) => {
    const key = opt.startsWith('-') ? opt.slice(1) : opt
    options[key] = key === opt ? 'asc' : 'desc'
    return options
  }, {} as SortOptions)

  return columns.map(
    item =>
      ({
        ...item,
        sortType: sortOptions[item.field],
      } as ColumnState)
  )
}
