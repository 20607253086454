import { Button, Spin } from 'aa_common/front-end/antd'
import { Formik } from 'formik'
import i18n from 'i18n'
import React, { useEffect, useState } from 'react'

import Divider from './Divider'
import Form from './Form'
import Item from './Item'
import ItemView from './ItemView'
import { Footer, Group, Header, Wrapper } from './styles'

type Props = {
  children: any
  errors?: any
  initialValues: any
  loading?: boolean
  preventSubmitOnEnter?: boolean
  onSubmit: (values: any) => void
}

const SubmitButton = ({ loading, isDisableBtn }: { loading?: boolean; isDisableBtn?: boolean }) => {
  return (
    <Footer>
      <Button type="submit" data-testid="submit-btn" loading={loading} disabled={isDisableBtn}>
        {i18n.t('components.BasicForm.register')}
      </Button>
    </Footer>
  )
}

interface BasicFormProps extends React.FC<Props> {
  Divider: typeof Divider
  Footer: typeof Footer
  Group: typeof Group
  Header: typeof Header
  Item: typeof Item
  ItemView: typeof ItemView
  SubmitButton: typeof SubmitButton
}

const BasicForm: BasicFormProps = ({
  loading = false,
  preventSubmitOnEnter = false,
  children,
  errors,
  initialValues,
  onSubmit,
}: Props) => {
  const [formActions, setFormActions] = useState<any>(null)

  useEffect(() => {
    if (formActions) {
      formActions.setErrors(errors)
      formActions.setTouched(errors)
    }
  }, [errors, formActions, onSubmit])

  const handleInit = (actions: any) => {
    setFormActions(actions)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (preventSubmitOnEnter && event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return (
    initialValues && (
      <Spin loading={loading}>
        <Wrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={null}
            validateOnChange={false}
            validateOnBlur={false}
            errors={null}
            onSubmit={onSubmit}
          >
            <Form onInit={handleInit} onKeyPress={handleKeyPress}>
              {children}
            </Form>
          </Formik>
        </Wrapper>
      </Spin>
    )
  )
}

BasicForm.Divider = Divider
BasicForm.Footer = Footer
BasicForm.Group = Group
BasicForm.Header = Header
BasicForm.Item = Item
BasicForm.ItemView = ItemView
BasicForm.SubmitButton = SubmitButton

export default BasicForm
