import { CONFIRM_TYPES, RightDrawer } from 'aa_common/front-end/components'
import { updateObjectValues } from 'aa_common/front-end/helpers'
import { getNs } from 'aa_common/front-end/i18n-config'
import { BasicForm } from 'components/molecules'
import { DATE_FORMAT_RFC } from 'constants/app'
import { CAUSE_TYPES } from 'constants/masterData'
import useModal from 'hooks/useModalHook'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { Asset } from 'models/asset'
import { FORM_MODES } from 'models/form'
import moment from 'moment'
import { BulkChangeEvent, rowSelectSliceAssetList } from 'pages/assets/AssetListPage/store'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionView } from 'store/asset/selectors'
import { AreaDataItem } from 'store/settings/area/model'
import { DepartmentItem } from 'store/settings/department/model'

import { useGetSelectedItemOnPage } from '../AssetList/components/AssetListTopTemplate/hooks'
import Form, { AREA_DEPARTMENT_EMPTY, ErrorCustom } from './Form'
import { convertSubmitPayload, validateAreaDepartment } from './helpers'

const { t, useNsReady } = getNs('components/organisms/assets/asset-movement-form')

type Props = {
  errors?: any
  causes: any[]
  isShow?: boolean
  loading?: boolean
  latestEvent: any
  data?: any
  formMode?: FORM_MODES.NEW | FORM_MODES.EDIT
  onClose?: (e: any) => void
  onSubmit: (data: any) => void
  onHandleReloadCauses?: () => void
}

const initialDefaultValues = {
  moved_at: null,
  event_cause_id: null,
  area_biid: null,
  department_biid: null,
  event_photos: [],
  event_attachments: [],
  event_urls: [],
}

const AssetMovementForm: React.FC<Props> = ({
  isShow = false,
  loading = false,
  formMode = FORM_MODES.NEW,
  errors,
  causes,
  latestEvent,
  data,
  onClose,
  onSubmit,
  onHandleReloadCauses,
}) => {
  useNsReady()
  const [visible, setVisible] = useState(isShow)
  const movementCauses = useMemo(() => causes.filter(cause => get(cause, 'type') === CAUSE_TYPES.MOVEMENT), [causes])
  const [initialValues, setInitialValues] = useState<any>(null)
  const { bulkChangeEvent } = rowSelectSliceAssetList.useSlice(state => ({
    bulkChangeEvent: state?.data?.bulkChangeEvent,
  }))
  const [errorCustoms, setErrorCustoms] = useState<ErrorCustom>()

  const isBulkChangeMovement = bulkChangeEvent === BulkChangeEvent.MOVEMENT
  const biidOfSelectedItems = useGetSelectedItemOnPage().selectedIds

  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)

  const modal = useModal()

  const handleSubmit = (values: any) => {
    if (validateAreaDepartment(values)) {
      setErrorCustoms({
        type: AREA_DEPARTMENT_EMPTY,
        message: t('area_department_error_message'),
      })
      return
    }
    setErrorCustoms(undefined)
    const { usage_started_at } = latestEvent || {}

    const hasAssetNotStartUsage = !!biidOfSelectedItems.find((biid: string) => {
      return !!assetCollectionViewSelector.assets.find(
        (asset: Asset) => asset.biid === biid && asset.usage_started_at === null
      )
    })

    Promise.resolve(
      (!isBulkChangeMovement && !!usage_started_at) ||
        (isBulkChangeMovement && !hasAssetNotStartUsage) ||
        modal.confirm({
          title: t('confirm_title'),
          message: t('confirm_message'),
          type: CONFIRM_TYPES.CONFIRM,
        })
    ).then(isOKClick => {
      if (isOKClick) {
        const payload = convertSubmitPayload(values)
        onSubmit(payload)
      }
    })
  }

  const getDefaultMoveAt = () => {
    const validFrom = get(latestEvent, 'valid_from')
    const today = moment().format(DATE_FORMAT_RFC)

    if (validFrom > today) {
      return validFrom
    }

    return today
  }

  useEffect(() => {
    setVisible(isShow)

    if (isShow) {
      const defaultMoveAt = getDefaultMoveAt()

      if (formMode === FORM_MODES.NEW) {
        setInitialValues({
          ...initialDefaultValues,
          min_date: get(latestEvent, 'valid_from'),
          moved_at: defaultMoveAt,
          area_name: get(latestEvent, ['area', 'name']),
          department_name: get(latestEvent, ['department', 'name']),
          submit_destination_name: get(latestEvent, ['area', 'submit_destination', 'name']),
        })
      } else if (data) {
        const objectValues = updateObjectValues(initialDefaultValues, data)
        const area = get(latestEvent, 'area') as AreaDataItem
        const department = get(latestEvent, 'department') as DepartmentItem

        setInitialValues({
          ...objectValues,
          id: data.id,
          biid: data.biid,
          event_cause_id: get(data, ['event_cause', 'id']),
          area_biid: area
            ? {
                value: area.biid,
                label: area.name,
              }
            : null,
          new_submit_destination_name: get(latestEvent, ['area', 'submit_destination', 'name'], ''),
          department_biid: department
            ? {
                value: department.biid,
                label: department.name,
              }
            : null,
          min_date: get(latestEvent, 'valid_from'),
          moved_at: get(data, 'valid_from'),
        })
      }
    }
  }, [isShow, data]) // eslint-disable-line

  return (
    <RightDrawer
      title={isBulkChangeMovement ? t('movement_amount_title', { amount: biidOfSelectedItems.length }) : t('movement')}
      visible={visible}
      onClose={onClose}
      width={isBulkChangeMovement ? 500 : 794}
    >
      <BasicForm initialValues={initialValues} onSubmit={handleSubmit} errors={errors} loading={loading}>
        <Form
          causes={movementCauses}
          formMode={formMode}
          onClose={onClose}
          onHandleReloadCauses={onHandleReloadCauses}
          errorCustoms={errorCustoms}
        />
      </BasicForm>
    </RightDrawer>
  )
}

export default AssetMovementForm
