import { Map } from 'immutable'

import { TagActionTypes } from './action.model'
import {
  CLEANUP_TAG,
  CREATE_TAG_REQUEST,
  FETCH_TAG_REQUEST,
  FETCH_TAG_SUCCESS,
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_REQUEST_AUTH,
  FETCH_TAGS_SUCCESS,
  SUBMIT_TAG_FAILURE,
  SUBMIT_TAG_SUCCESS,
  UPDATE_TAG_ORDER_FAILURE,
  UPDATE_TAG_ORDER_REQUEST,
  UPDATE_TAG_ORDER_SUCCESS,
  UPDATE_TAG_REQUEST,
} from './constant'

const initialState = Map({
  list: Map({
    data: [],
    loading: false,
  }),
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
})

export default (state = initialState, action: TagActionTypes) => {
  switch (action.type) {
    case FETCH_TAGS_REQUEST:
    case FETCH_TAGS_REQUEST_AUTH:
      return state.setIn(['list', 'loading'], true)
    case FETCH_TAGS_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_TAGS_FAILURE:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'errors'], action.payload)
        .setIn(['list', 'data'], null)
    case FETCH_TAG_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_TAG_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'data'], action.payload)
    case CREATE_TAG_REQUEST:
    case UPDATE_TAG_REQUEST:
      return state.setIn(['item', 'loading'], true).setIn(['item', 'errors'], null)
    case SUBMIT_TAG_SUCCESS:
      return state.setIn(['item', 'loading'], false)
    case SUBMIT_TAG_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], action.formErrors)
    case UPDATE_TAG_ORDER_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case UPDATE_TAG_ORDER_SUCCESS:
    case UPDATE_TAG_ORDER_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case CLEANUP_TAG:
      return initialState
    default:
      return state
  }
}
