import { loadNs } from 'aa_common/front-end/i18n-config'
import { ConfigProvider } from 'antd'
import { Role } from 'models/role'
import React from 'react'

import { ActionButton } from './styles'

const t = loadNs(['common'])

interface RoleListActionListProps {
  item: Role
  onEditClick: (item: Role) => void
  onDeleteClick: (item: Role) => void
}

const ActionList = ({ item, onEditClick, onDeleteClick }: RoleListActionListProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ConfigProvider autoInsertSpaceInButton={false}>
        <ActionButton disabled={!item.editable} className="edit" onClick={() => onEditClick(item)}>
          {t('actions.edit')}
        </ActionButton>
        <ActionButton disabled={!item.deletable} className="delete" onClick={() => onDeleteClick(item)}>
          {t('actions.cancel_1')}
        </ActionButton>
      </ConfigProvider>
    </div>
  )
}

export default ActionList
