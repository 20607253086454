import { Button } from 'aa_common/front-end/antd'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Divider } from 'antd'
import React, { useCallback } from 'react'

import MultiSelectPicker from '../../components/MultiSelectPicker'
import { CoreMultiSelectPickerItem } from '../../components/MultiSelectPicker/model'
import { BaseFormFilter } from '../../model'
import { MultiSelectFormWrapper } from './styles'

const t = loadNs(['common'])

interface MultiSelectFormProps extends BaseFormFilter {
  items: CoreMultiSelectPickerItem[]
  name: string
}

const MultiSelectForm = ({ items, name, formModule, setPopupShow, onFormSubmit }: MultiSelectFormProps) => {
  const { handleSubmit, getValues } = formModule

  const handleFormSubmit = useCallback(() => handleSubmit(onFormSubmit, onFormSubmit)(), [handleSubmit, onFormSubmit])

  const handleClickSubmit = () => {
    const submitFormValues = {
      [name]: getValues(name),
    }
    formModule.setValue('submitFormValues', submitFormValues)
    setPopupShow(false)
    handleFormSubmit()
  }

  return (
    <MultiSelectFormWrapper>
      <div style={{ padding: '0 5px' }}>
        <MultiSelectPicker items={items} name={name} listHeight={200} control={formModule.control} />
      </div>
      <Divider style={{ marginBottom: 0, marginTop: 5 }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 10px', paddingTop: 10 }}>
        <Button type="button" color="blue" onClick={handleClickSubmit}>
          {t('actions.ok')}
        </Button>
      </div>
    </MultiSelectFormWrapper>
  )
}

export default MultiSelectForm
