import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yen } from 'aa_common/front-end/helpers/format-helper'
import { loadNs } from 'aa_common/front-end/i18n-config'
import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { BeginningBookValueWrapper } from './styles'

const t = loadNs(['components/organisms/assets/asset-form'])

type Mode = 'view' | 'edit'
type Props = {
  placeholder?: string
  value: number
  hasManualValue: boolean
  maxValue: number
  editable?: boolean
  onChange?: (newValue: number) => void
  onClickResetValue?: () => void
  labelClassName?: string
}

const BeginningBookValue: React.FC<Props> = ({
  placeholder,
  maxValue,
  value,
  hasManualValue,
  editable = true,
  onChange,
  onClickResetValue,
  labelClassName,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [mode, setMode] = useState<Mode>('view')
  const isViewMode = mode === 'view'
  const [currentValue, setCurrentValue] = useState<number>(value || 0)

  const handleKeyPress = (event: any) => {
    event.key === 'Enter' && setMode('view')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value
    const moneyRegex = /^[0-9-]*$/

    if (newValue === '') setCurrentValue(0)
    else if (moneyRegex.test(newValue)) {
      const newNumberValue = parseInt(newValue, 10)
      if (newNumberValue <= maxValue) setCurrentValue(newNumberValue)
    }
  }

  const handleBlur = () => {
    onChange && onChange(currentValue)
    setMode('view')
  }

  const renderActionOnViewMode = () => {
    if (hasManualValue)
      return (
        <label className="reset-link" onClick={onClickResetValue}>
          {t('reset_manual_input')}
        </label>
      )

    return <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" onClick={() => setMode('edit')} />
  }

  useEffect(() => {
    if (!isViewMode && inputRef) {
      inputRef.current!.focus()
    }
  }, [mode, isViewMode])

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <BeginningBookValueWrapper>
      {isViewMode || !editable ? (
        <label className={classnames('value-view', labelClassName)} style={{ textAlign: 'right' }}>
          {yen(currentValue)}
        </label>
      ) : (
        <input
          value={currentValue}
          onChange={handleChange}
          placeholder={placeholder}
          autoComplete="off"
          disabled={isViewMode}
          onBlur={handleBlur}
          ref={inputRef}
          type="number"
          onKeyPress={handleKeyPress}
        />
      )}
      {isViewMode && editable && renderActionOnViewMode()}
    </BeginningBookValueWrapper>
  )
}

export default BeginningBookValue
