import styled from '@emotion/styled'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutoCompleteSelect, Tooltip } from 'aa_common/front-end/antd'
import { getNs } from 'aa_common/front-end/i18n-config'
import { CSSProperties, useEffect } from 'react'

import { importExportEventTypeSelectorSlice, ImportExportEventTypeSelectorState } from './store'

const { t, useNsReady } = getNs(['pages/assets', 'components/organisms/assets/asset-import-form'])

export const IMPORT_EXPORT_EVENT_TYPES = {
  FIXED_ASSET_LEDGER: 10,
  START_USAGE: 20,
  MOVEMENT: 30,
  RETIREMENT: 40,
  SALE: 50,
  IMPAIRMENT: 60,
  ASSET_TYPE_MODIFICATION: 70,
}

const items = [
  { type: IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER, text: t('fixed_asset_ledger') },
  { type: IMPORT_EXPORT_EVENT_TYPES.START_USAGE, text: t('start_usage') },
  { type: IMPORT_EXPORT_EVENT_TYPES.MOVEMENT, text: t('movement') },
  { type: IMPORT_EXPORT_EVENT_TYPES.RETIREMENT, text: t('retirement') },
  { type: IMPORT_EXPORT_EVENT_TYPES.SALE, text: t('sale') },
  { type: IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT, text: t('impairment') },
  { type: IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION, text: t('asset_type_modification') },
]

export const ImportExportEventTypeSelector = ({ name, tooltip, onChange, tooltipOverlayInnerStyle }: Props) => {
  useNsReady()
  const value = importExportEventTypeSelectorSlice.useSlice(
    state => state[name as keyof ImportExportEventTypeSelectorState]
  )

  useEffect(() => {
    importExportEventTypeSelectorSlice.setState(state => ({
      ...state,
      [name]: IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER,
    }))
  }, [])

  return (
    <StyledWrapper>
      <label>
        {t('format')}
        <Tooltip
          content={tooltip ?? t('download_template_tooltip_content')}
          overlayInnerStyle={tooltipOverlayInnerStyle}
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            style={{ fontSize: 18, marginTop: 3, cursor: 'pointer', marginLeft: 5 }}
          />
        </Tooltip>
      </label>
      <AutoCompleteSelect
        name={name}
        valueField="type"
        textField="text"
        items={items}
        value={value}
        onChange={onChange}
      />
    </StyledWrapper>
  )
}

type Props = {
  name: string
  tooltip?: string
  onChange: (value: number) => void
  tooltipOverlayInnerStyle?: CSSProperties
}

const StyledWrapper = styled.div`
  width: 240px;
  margin-bottom: 10px;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    font-weight: 500;
  }
`
