import { Button } from 'aa_common/front-end/antd'
import {
  checkRetroActiveFixedAsset,
  hasEventOccurred,
  isRetroactiveEvent,
} from 'aa_common/front-end/helpers/asset-helper'
import { handlePermissionByFeatureId } from 'aa_common/front-end/helpers/permission-helper'
import * as assetApi from 'api/app/asset'
import { BreadcrumbNavigation, DropdownMenuButton } from 'components/molecules'
import { DropdownMenuSettings } from 'components/molecules/DropdownMenuButton'
import {
  AssetDetail,
  AssetEventSellForm,
  AssetImpairmentForm,
  AssetModificationForm,
  AssetMovementForm,
  AssetRetirementForm,
  AssetUsageStartedForm,
  DividingForm,
} from 'components/organisms'
import { MainTemplate } from 'components/templates'
import useRetrospectiveAssetModal from 'components/templates/modal/RetroactiveAssetModal'
import { DATE_FORMAT, DATE_FORMAT_RFC } from 'constants/app'
import { FEATURES_ID } from 'constants/const'
import { ASSET_CHANGE_SITUATION_TYPES } from 'constants/masterData'
import i18n from 'i18n'
import useResource from 'lib/hooks/useResource'
import { get, isEqual } from 'lodash'
import { ASSET_ITEM_STATUS_PARAM_TYPE, AssetSingleViewQueryParams } from 'models/asset'
import { FORM_MODES } from 'models/form'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'
import {
  cleanupAssetItem,
  cleanupAssetItemByValidAt,
  clearAssetEventErrors,
  createAssetEventMovement,
  createAssetEventRetirement,
  createAssetEventSell,
  createAssetEventUsage,
  fetchAssetByValidAt,
  submitModifiedAssetType,
  updateAssetEventMovement,
  updateAssetEventRetirement,
  updateAssetEventSell,
  updateAssetEventUsage,
} from 'store/asset/actions'
import { selectAssetDetailPage, selectAssetItemByValidAt, selectEventHistories } from 'store/asset/selectors'
import { selectUserPermission } from 'store/session/selectors'
import { fetchCauseList } from 'store/settings/cause/actions'

import { disableReasonTooltip, getImpairmentMenuSettingMemo, isInapplicableForImpairment } from './helpers'
import { RegisterType } from './schema'
import { ContainerClass, Section, TopMessage } from './styles'

function getAssetSingleViewQueryParams(searchQuery: string) {
  const { valid_at, type = '' } = queryString.parse(searchQuery) as AssetSingleViewQueryParams
  const validAt = moment(valid_at).isValid() ? moment(valid_at) : moment()

  return {
    type,
    validAt,
    valid_at: validAt.format(DATE_FORMAT_RFC),
  }
}

const AssetDetailPage = () => {
  const { biid } = useParams<{ biid: string }>()
  const dispatch = useDispatch()
  const location = useLocation()
  const confirmModal = useRetrospectiveAssetModal()
  const [registerEvent, setRegisterEvent] = useState('')
  const [isShowImpairmentForm, setShowImpairmentForm] = useState(false)

  const [isShowUsageStartedForm, setIsShowUsageStartedForm] = useState<boolean>(false)
  const [isShowRetirementForm, setIsShowRetirementForm] = useState<boolean>(false)
  const [isShowMovementForm, setIsShowMovementForm] = useState<boolean>(false)
  const [isShowUpdateUsageStartedForm, setIsShowUpdateUsageStartedForm] = useState<boolean>(false)
  const [isShowUpdateRetirementForm, setIsShowUpdateRetirementForm] = useState<boolean>(false)
  const [isShowUpdateMovementForm, setIsShowUpdateMovementForm] = useState<boolean>(false)
  const [isShowSellForm, setIsShowSellForm] = useState<boolean>(false)
  const [isShowUpdateSellForm, setIsShowUpdateSellForm] = useState<boolean>(false)
  const [isShowTypeModifyForm, setIsShowTypeModifyForm] = useState(false)
  const [currentAssetEvent, setCurrentAssetEvent] = useState<any>(null)
  const [isShowDividingForm, setIsShowDividingForm] = useState<boolean>(false)

  const [responseAssetEvent, getCurrentAssetEvent] = useResource(assetApi.getById)
  const [, validateUsagedAt] = useResource(assetApi.createUsage)
  const [isShowConfirmMessage, setIsShowConfirmMessage] = React.useState(false)

  const { asset, itemHistoryLoading, itemHistoryErrors, causes, eventHistory } = useSelector(
    selectAssetDetailPage,
    isEqual
  )

  const assetItemByValidAtSelector = useSelector(selectAssetItemByValidAt, isEqual)
  const { data: userPermissions } = useSelector(selectUserPermission)
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const eventHistories = useSelector(selectEventHistories, isEqual)
  const hasDividingEvent = hasEventOccurred(eventHistories, ASSET_CHANGE_SITUATION_TYPES.DIVIDING)

  const fixedAssetPermissions = handlePermissionByFeatureId(FEATURES_ID.FIXED_ASSETS, userPermissions)
  const startUsagePermissions = handlePermissionByFeatureId(FEATURES_ID.START_USAGE, userPermissions)
  const movementPermissions = handlePermissionByFeatureId(FEATURES_ID.MOVEMENT, userPermissions)
  const retirePermissions = handlePermissionByFeatureId(FEATURES_ID.RETIRE, userPermissions)
  const salePermissions = handlePermissionByFeatureId(FEATURES_ID.SALE, userPermissions)
  const commentPermissions = handlePermissionByFeatureId(FEATURES_ID.COMMENT, userPermissions)
  const impairmentPermissions = handlePermissionByFeatureId(FEATURES_ID.IMPAIRMENT, userPermissions)
  const typeModifyPermissions = handlePermissionByFeatureId(FEATURES_ID.TYPE_MODIFICATION, userPermissions)
  const dividingPermissions = handlePermissionByFeatureId(FEATURES_ID.DIVIDING, userPermissions)
  const isRetiredOrSold: boolean = !!get(asset, 'retired_at') || !!get(asset, 'sold_at')

  const dataByValidAt = useMemo(() => {
    return assetItemByValidAtSelector.data || asset
  }, [asset, assetItemByValidAtSelector.data])

  const breadcrumb = useMemo(() => {
    const pageName = `（${get(dataByValidAt, 'code') || ''}-${get(dataByValidAt, 'branch_code') || ''}）${
      get(dataByValidAt, 'name') || ''
    }`

    return <BreadcrumbNavigation enableBackButton sectionName={pageName} />
  }, [dataByValidAt])
  const isDividingAble = useMemo(() => {
    const eventList = eventHistory?.list
    if (asset.acquisition_cost_excluded_excise) {
      if (asset.acquisition_cost_excluded_excise <= 1) return false
    } else if (asset.acquisition_cost <= 1) return false
    if (eventList && eventList.length > 0) {
      const dividingEvent = eventList.find(
        event => event?.change_situation?.code === ASSET_CHANGE_SITUATION_TYPES.DIVIDING
      )
      const isDestinationFA = dividingEvent?.dividing_source_fixed_asset
      if (isRetiredOrSold || isDestinationFA) {
        return false
      }
    }
    return true
  }, [asset, asset?.quantity, eventHistory])

  const isShowingEventForm =
    isShowUsageStartedForm ||
    isShowRetirementForm ||
    isShowSellForm ||
    isShowMovementForm ||
    isShowUpdateUsageStartedForm ||
    isShowUpdateRetirementForm ||
    isShowUpdateMovementForm ||
    isShowImpairmentForm ||
    isShowDividingForm

  const dropdownMenuSettings: DropdownMenuSettings[] = useMemo(
    () => [
      {
        label: i18n.t('components.AssetUsageStartedForm.usage_started'),
        onClick: () => startUsagePermissions.isCreate && setIsShowUsageStartedForm(true),
        disabled: !!get(asset, 'usage_started_at') || isRetiredOrSold || !startUsagePermissions.isCreate,
        tooltip: startUsagePermissions.isCreate ? undefined : disableReasonTooltip,
      },
      {
        label: i18n.t('components.AssetMovementForm.movement'),
        onClick: () => movementPermissions.isCreate && setIsShowMovementForm(true),
        disabled: isRetiredOrSold || !movementPermissions.isCreate,
        tooltip: movementPermissions.isCreate ? undefined : disableReasonTooltip,
      },
      {
        label: i18n.t('components.AssetRetirementForm.retirement'),
        onClick: () => setIsShowRetirementForm(true),
        disabled: isRetiredOrSold || !retirePermissions.isCreate,
        tooltip: retirePermissions.isCreate ? undefined : disableReasonTooltip,
      },
      {
        label: i18n.t('components.AssetEventSellForm.sale'),
        onClick: () => setIsShowSellForm(true),
        disabled: isRetiredOrSold || !salePermissions.isCreate,
        tooltip: salePermissions.isCreate ? undefined : disableReasonTooltip,
      },
      ...(!isInapplicableForImpairment(asset)
        ? [
            {
              label: i18n.t('components.AssetImpairment.impairment'),
              onClick: () => setShowImpairmentForm(true),
              ...getImpairmentMenuSettingMemo(impairmentPermissions?.isCreate, asset, isRetiredOrSold),
            },
          ]
        : []),
      ...[
        {
          label: i18n.t('components.AssetDividing.dividing'),
          onClick: () => setIsShowDividingForm(true),
          disabled: !isDividingAble || !(dividingPermissions.isCRU || dividingPermissions.isCRUD),
          tooltip: dividingPermissions.isCRU || dividingPermissions.isCRUD ? undefined : disableReasonTooltip,
        },
      ],
      {
        label: i18n.t('components.AssetTypeModifyForm.modify_type'),
        onClick: () => setIsShowTypeModifyForm(true),
        disabled: !typeModifyPermissions.isCreate,
        tooltip: typeModifyPermissions.isCreate ? undefined : disableReasonTooltip,
      },
    ],
    [asset, impairmentPermissions, isRetiredOrSold, isDividingAble, dividingPermissions]
  )

  const handleEditClick = () => {
    dispatch(changeLocation(`/assets/${get(asset, 'biid')}/edit`))
  }

  const handleCreateEventUsage = (data: any) => {
    validateUsagedAt(biid, { ...data, dry_run: true })
      .then((result: any) => {
        return checkRetroActiveFixedAsset({
          res: result,
          assetEvent: data,
          currentMonthlyClosing,
          confirmModal,
        })
      })
      .then((isOK: boolean) => {
        isOK && dispatch(createAssetEventUsage(biid, data, hasDividingEvent))
      })
  }

  const handleCreateEventRetirement = (data: any) => {
    dispatch(createAssetEventRetirement(get(asset, 'biid') as string, data, hasDividingEvent))
  }

  const handleCreateEventMovement = (data: any) => {
    dispatch(createAssetEventMovement(get(asset, 'biid') as string, data, hasDividingEvent))
  }

  const handleUpdateEventUsage = async (data: any) => {
    const shouldUpdate = await (!isRetroactiveEvent(data, currentMonthlyClosing) || confirmModal.open({}))
    if (!shouldUpdate) return
    dispatch(updateAssetEventUsage(get(asset, 'biid') as string, get(data, 'id'), data, hasDividingEvent))
  }

  const handleUpdateEventRetirement = (data: any) => {
    dispatch(updateAssetEventRetirement(get(asset, 'biid') as string, get(data, 'id'), data, hasDividingEvent))
  }

  const handleUpdateEventMovement = (data: any) => {
    dispatch(updateAssetEventMovement(get(asset, 'biid') as string, get(data, 'id'), data, hasDividingEvent))
  }

  const handleCreateEventSell = (data: any) => {
    dispatch(createAssetEventSell(get(asset, 'biid') as string, data, hasDividingEvent))
  }

  const handleUpdateEventSell = (data: any) => {
    const assetBiid = get(asset, 'biid') as string
    const assetId = get(data, 'id')

    dispatch(updateAssetEventSell(assetBiid, assetId, data, hasDividingEvent))
  }

  const handleShowUpdateEvent = (eventData: any) => {
    if (!eventData) return

    getCurrentAssetEvent(eventData.biid, eventData.id)
  }

  const handleCloseForm = () => {
    isShowUsageStartedForm && setIsShowUsageStartedForm(false)
    isShowRetirementForm && setIsShowRetirementForm(false)
    isShowMovementForm && setIsShowMovementForm(false)
    isShowUpdateUsageStartedForm && setIsShowUpdateUsageStartedForm(false)
    isShowUpdateRetirementForm && setIsShowUpdateRetirementForm(false)
    isShowUpdateMovementForm && setIsShowUpdateMovementForm(false)
    isShowSellForm && setIsShowSellForm(false)
    isShowUpdateSellForm && setIsShowUpdateSellForm(false)
    dispatch(clearAssetEventErrors())
  }

  const handleReloadCauses = () => {
    dispatch(fetchCauseList())
  }

  useEffect(() => {
    const { valid_at, type: assetItemStatus } = queryString.parse(location.search) as AssetSingleViewQueryParams
    switch (assetItemStatus) {
      case ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED:
      case ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD: {
        dispatch(fetchAssetByValidAt(biid, valid_at, false, true))
        break
      }
      default:
        dispatch(fetchAssetByValidAt(biid, valid_at, true, true))
    }

    dispatch(fetchCauseList())
    dispatch(fetchMonthlyClosingCurrent())

    return function cleanup() {
      dispatch(cleanupAssetItem())
      dispatch(cleanupAssetItemByValidAt())
    }
  }, []) // eslint-disable-line

  // Reset page when data of asset changed
  useEffect(() => {
    handleCloseForm()
  }, [asset]) // eslint-disable-line

  useEffect(() => {
    if (responseAssetEvent.data) {
      setCurrentAssetEvent(responseAssetEvent.data)

      switch (get(responseAssetEvent.data, ['change_situation', 'code'])) {
        case ASSET_CHANGE_SITUATION_TYPES.USAGE_STARTED:
          setIsShowUpdateUsageStartedForm(true)
          break
        case ASSET_CHANGE_SITUATION_TYPES.RETIREMENT:
          setIsShowUpdateRetirementForm(true)
          break
        case ASSET_CHANGE_SITUATION_TYPES.MOVEMENT:
          setIsShowUpdateMovementForm(true)
          break
        case ASSET_CHANGE_SITUATION_TYPES.SELL:
          setIsShowUpdateSellForm(true)
          break
        default:
          break
      }
    }
  }, [responseAssetEvent])

  const renderDisplayDateMessage = (valid_at: moment.Moment, type?: string) => {
    if (!valid_at) return null

    const isToBeAcquired = type === ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED
    const filterDate = moment(isToBeAcquired ? dataByValidAt?.acquired_at : valid_at)
    const context = !isToBeAcquired && moment().isSame(filterDate, 'date') ? 'today' : ''
    return i18n.t('components.AssetDetail.filter_at_fixed_asset', {
      context,
      filterDate: filterDate.format(DATE_FORMAT),
    })
  }

  const renderTopMessage = () => {
    const { validAt, type } = getAssetSingleViewQueryParams(location.search)
    const acquiredAt = moment(dataByValidAt.acquired_at).format(DATE_FORMAT)
    const eventAt = moment(dataByValidAt.sold_at || dataByValidAt.retired_at || dataByValidAt.impaired_at)

    let message: string | null = null
    if (type === ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED && validAt.isSameOrBefore(acquiredAt, 'date')) {
      message = i18n.t('components.AssetDetail.to_be_acquired_display_box_message', { acquiredAt })
    } else if (validAt.isSameOrAfter(eventAt, 'date')) {
      let eventKey: string | null = null
      if (dataByValidAt.sold_at) {
        eventKey = 'sold'
      } else if (dataByValidAt.retired_at) {
        eventKey = 'retired'
      } else if (dataByValidAt.impaired_at) {
        eventKey = 'impaired'
      }
      message = eventKey ? i18n.t(`components.AssetDetail.asset_has_been_${eventKey}`) : ''
    }
    if (registerEvent && registerEvent !== RegisterType.DIVIDED) {
      message = i18n.t(`components.AssetDetail.asset_has_been_${registerEvent}`)
    }

    const displayDateMessage = renderDisplayDateMessage(validAt, type)

    return (
      (displayDateMessage || message) && (
        <TopMessage>
          {displayDateMessage && (
            <div>
              <span>{displayDateMessage}</span>
            </div>
          )}
          {message && (
            <div>
              <span>{message}</span>
            </div>
          )}
        </TopMessage>
      )
    )
  }

  useEffect(() => {
    if (registerEvent === RegisterType.DIVIDED) {
      setIsShowDividingForm(false)
    }
  }, [registerEvent])

  const fetchAsset = () => {
    // eslint-disable-next-line
    const { valid_at, type: assetItemStatus } = queryString.parse(location.search) as AssetSingleViewQueryParams

    const assetStatuses = [ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED, ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD]

    const isTobeAcquiredOrRetireOrSole = assetStatuses.includes(assetItemStatus!)

    dispatch(fetchAssetByValidAt(biid, valid_at, !isTobeAcquiredOrRetireOrSole, true))
  }

  const handleSubmitChangeAssetType = (payload: any) => {
    Promise.resolve(dispatch(submitModifiedAssetType(biid, payload))).then(() => {
      setIsShowTypeModifyForm(false)
      fetchAsset()
    })
  }

  return (
    <MainTemplate
      containerClassName={ContainerClass}
      breadcrumb={breadcrumb}
      isLoadingApp={assetItemByValidAtSelector.loading}
    >
      <Section className="top">
        {renderTopMessage()}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
          <Button
            color="grey"
            size="custom"
            style={{ minWidth: 56, marginRight: 10 }}
            onClick={handleEditClick}
            disabled={isShowingEventForm || isRetiredOrSold || !fixedAssetPermissions?.isEdit}
            showTooltipMessage={!fixedAssetPermissions?.isEdit ? i18n.t('common.actions.no_permission') : undefined}
          >
            {i18n.t('common.actions.edit')}
          </Button>
          <DropdownMenuButton
            style={{ minWidth: 56, padding: '0 5px' }}
            trigger={['hover']}
            size="custom"
            menuSettings={dropdownMenuSettings}
            disabled={isShowingEventForm}
          >
            {i18n.t('pages.assets.actions.change_situation')}
          </DropdownMenuButton>
        </div>
      </Section>
      <Section className="body">
        <AssetDetail
          data={asset}
          dataByValidAt={dataByValidAt}
          commentPermissions={commentPermissions}
          onUpdateEvent={handleShowUpdateEvent}
          isShowConfirmMessage={isShowConfirmMessage}
          setIsShowConfirmMessage={setIsShowConfirmMessage}
        />
      </Section>
      {isShowUsageStartedForm && (
        <AssetUsageStartedForm
          isShow={isShowUsageStartedForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          onClose={handleCloseForm}
          onSubmit={handleCreateEventUsage}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowUpdateUsageStartedForm && (
        <AssetUsageStartedForm
          isShow={isShowUpdateUsageStartedForm}
          causes={causes}
          data={currentAssetEvent}
          errors={itemHistoryErrors}
          latestEvent={asset}
          formMode={FORM_MODES.EDIT}
          onClose={handleCloseForm}
          onSubmit={handleUpdateEventUsage}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowRetirementForm && (
        <AssetRetirementForm
          isShow={isShowRetirementForm}
          causes={causes}
          errors={itemHistoryErrors}
          loading={itemHistoryLoading}
          latestEvent={asset}
          onClose={handleCloseForm}
          onSubmit={handleCreateEventRetirement}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowUpdateRetirementForm && (
        <AssetRetirementForm
          isShow={isShowUpdateRetirementForm}
          causes={causes}
          data={currentAssetEvent}
          errors={itemHistoryErrors}
          loading={itemHistoryLoading}
          latestEvent={asset}
          formMode={FORM_MODES.EDIT}
          onClose={handleCloseForm}
          onSubmit={handleUpdateEventRetirement}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowMovementForm && (
        <AssetMovementForm
          isShow={isShowMovementForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          loading={itemHistoryLoading}
          onClose={handleCloseForm}
          onSubmit={handleCreateEventMovement}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowUpdateMovementForm && (
        <AssetMovementForm
          isShow={isShowUpdateMovementForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          loading={itemHistoryLoading}
          data={currentAssetEvent}
          formMode={FORM_MODES.EDIT}
          onClose={handleCloseForm}
          onSubmit={handleUpdateEventMovement}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowSellForm && (
        <AssetEventSellForm
          isShow={isShowSellForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          loading={itemHistoryLoading}
          onClose={handleCloseForm}
          onSubmit={handleCreateEventSell}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowUpdateSellForm && (
        <AssetEventSellForm
          isShow={isShowUpdateSellForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          loading={itemHistoryLoading}
          data={currentAssetEvent}
          formMode={FORM_MODES.EDIT}
          onClose={handleCloseForm}
          onSubmit={handleUpdateEventSell}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowImpairmentForm && (
        <AssetImpairmentForm
          errors={itemHistoryErrors}
          isShow={isShowImpairmentForm}
          setShowForm={setShowImpairmentForm}
        />
      )}
      {isShowDividingForm && (
        <DividingForm
          asset={asset}
          causes={causes}
          isShow={isShowDividingForm}
          permissions={dividingPermissions}
          setShowForm={setIsShowDividingForm}
          onHandleReloadCauses={handleReloadCauses}
          setRegisterEvent={setRegisterEvent}
          setIsShowConfirmMessage={setIsShowConfirmMessage}
        />
      )}
      {isShowTypeModifyForm && (
        <AssetModificationForm
          isShow={isShowTypeModifyForm}
          setShowForm={setIsShowTypeModifyForm}
          onSubmit={handleSubmitChangeAssetType}
        />
      )}
    </MainTemplate>
  )
}

export default AssetDetailPage
