import {
  buildPaginationParams,
  buildQueryFilterParams,
  convertSortValue,
  objectToQueryParams,
  RequestParam,
} from 'aa_common/front-end/helpers/request-helper'
import { parseError, parseResponse } from 'utils/ResponseHelper'

import base from './base'
import { IDepartmentGetSearch, IDepartmentPostSearch } from './model/department'

export const search: IDepartmentGetSearch = ({ filters, pageNumber, pageSize, sort }) => {
  const filterQueryString = objectToQueryParams(buildQueryFilterParams(filters))
  return base
    .get(`/v1/departments?${filterQueryString}`, {
      params: {
        ...buildPaginationParams(pageNumber, pageSize),
        sort: convertSortValue(sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const searchAuth: IDepartmentGetSearch = ({ filters, pageNumber, pageSize, sort }) => {
  const filterQueryString = objectToQueryParams(buildQueryFilterParams(filters))
  return base
    .get(`/v1/departments/get?${filterQueryString}`, {
      params: {
        ...buildPaginationParams(pageNumber, pageSize),
        sort: convertSortValue(sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const getListDepartmentOnCloud = () => {
  return base
    .get('/v1/acc_plus/departments')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const postSearch: IDepartmentPostSearch = ({ filters, sort, pageNumber, pageSize }) => {
  return base.post('/v1/departments/search', {
    ...buildQueryFilterParams(filters),
    ...buildPaginationParams(pageNumber, pageSize),
    sort: convertSortValue(sort || []),
  })
}

export const create = (data: any) => {
  return base.post('/v1/departments', data)
}

export const insert = (data: any) => {
  return base.post('/v1/departments/insert_record', data)
}

export const get = (id: number) => {
  return base.get(`/v1/departments/${id}`)
}

export const getByBiid = (biid: string) => {
  const params: any = {}
  params['filter[biid]'] = biid
  params.sort = '-valid_from'

  return base.get('/v1/departments', { params })
}

export const update = (id: number, data: any) => {
  return base.put(`/v1/departments/update_by_id/${id}`, data)
}

export const destroy = (biid: string, dryRun: boolean) => {
  return base.delete(`/v1/departments/delete_by_biid/${biid}`, { params: { dry_run: dryRun } })
}

export const deleteById = (id: number) => {
  return base.delete(`/v1/departments/delete_by_id/${id}`)
}

export const importFile = (charset: string, file: any) => {
  const formData = new FormData()
  formData.append('charset', charset)
  formData.append('file', file)

  return base.post('/v1/departments/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const exportFile = (charset: string, params: RequestParam) => {
  const filter = objectToQueryParams(buildQueryFilterParams(params))
  return base.get(`/v1/export/departments?charset=${charset}&${filter}`, {
    responseType: 'blob',
  })
}
