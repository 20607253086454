import { ACC_PLUS_JOURNAL_LINKAGE_STATUS } from 'aa_common/front-end/constants'
import { loadNs } from 'aa_common/front-end/i18n-config'

const t = loadNs(['components/organisms/accounting/general-journal-setting-tab'])

export const getConstant = () => {
  const DEPRECIATION_JOURNAL_METHODS = [
    {
      id: 1,
      label: t('depreciationJournalMethod.indirectly'),
    },
    {
      id: 2,
      label: t('depreciationJournalMethod.directly'),
    },
  ]

  const DEPRECIATION_JOURNAL_CYCLE_TYPES = [
    {
      id: 1,
      label: t('depreciationJournalCycleType.every_month'),
    },
    {
      id: 2,
      label: t('depreciationJournalCycleType.every_quarter'),
    },
  ]

  const DEPRECIATION_JOURNAL_TYPES = [
    {
      id: 1,
      label: t('depreciationJournalType.common_journal_entry'),
    },
    {
      id: 2,
      label: t('depreciationJournalType.adjusted_journal_entry'),
    },
  ]

  const CREATE_JOURNAL_METHODS = [
    {
      id: 1,
      label: t('createJournalMethod.create_journal_by_asset_category'),
    },
    {
      id: 2,
      label: t('createJournalMethod.create_journal_by_asset_category_unit_and_details_by_asset_unit'),
    },
    {
      id: 3,
      label: t('createJournalMethod.create_journal_by_asset'),
    },
  ]

  const SLIP_DATE_TYPES = [
    {
      id: 1,
      label: t('slipDate.date_of_fixed_asset_ledger'),
    },
    {
      id: 2,
      label: t('slipDate.last_day_of_accounting_period'),
    },
  ]

  const ACCOUNTING_PLUS_STATUS_VALUES = {
    unapproved: {
      id: ACC_PLUS_JOURNAL_LINKAGE_STATUS.UNAPPROVED,
      label: t('accountingPlusStatus.draft'),
    },
    approve: {
      id: ACC_PLUS_JOURNAL_LINKAGE_STATUS.APPROVED,
      label: t('accountingPlusStatus.approved'),
    },
  } as const

  const ACCOUNTING_PLUS_STATUS = Object.values(ACCOUNTING_PLUS_STATUS_VALUES)

  return {
    DEPRECIATION_JOURNAL_METHODS,
    DEPRECIATION_JOURNAL_CYCLE_TYPES,
    DEPRECIATION_JOURNAL_TYPES,
    CREATE_JOURNAL_METHODS,
    SLIP_DATE_TYPES,
    ACCOUNTING_PLUS_STATUS_VALUES,
    ACCOUNTING_PLUS_STATUS,
  }
}
