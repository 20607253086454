import { downloadFileByResponse } from 'aa_common/front-end/helpers/file-helper'
import * as causeApi from 'api/app/cause'
import { AxiosResponse } from 'axios'
import { DATA_IN_USED_ERROR_CODE } from 'constants/apiCode'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { changeLocation, setAppMessage, SHOW_IN_USED_MESSAGE, showErrorsAppCall } from 'store/app/actions'
import { parseError, parseImportErrors, parseValidationError } from 'utils/ResponseHelper'

import {
  CreateCauseRequest,
  DeleteCauseRequest,
  FetchCausesRequest,
  HandleSubmitCauseFailure,
  ImportCauseFailure,
  UpdateCauseRequest,
} from './action.model'
import { fetchCauseListFailure, fetchCauseListSuccess } from './actions'
import {
  CREATE_CAUSE_REQUEST,
  DELETE_CAUSE_FAILURE,
  DELETE_CAUSE_REQUEST,
  DELETE_CAUSE_SUCCESS,
  EXPORT_CAUSE_FAILURE,
  EXPORT_CAUSE_REQUEST,
  EXPORT_CAUSE_SUCCESS,
  FETCH_CAUSE_REQUEST,
  FETCH_CAUSE_SUCCESS,
  FETCH_CAUSES_REQUEST,
  FETCH_CAUSES_REQUEST_AUTH,
  HANDLE_SUBMIT_CAUSE_FAILURE,
  IMPORT_CAUSE_FAILURE,
  IMPORT_CAUSE_REQUEST,
  IMPORT_CAUSE_SUCCESS,
  SUBMIT_CAUSE_FAILURE,
  SUBMIT_CAUSE_SUCCESS,
  UPDATE_CAUSE_REQUEST,
} from './constant'

function* handleSubmitCauseFailure({ error }: HandleSubmitCauseFailure) {
  const errorResponse: AxiosResponse<any> = get(error, 'response')
  switch (errorResponse?.status) {
    case 422: {
      const validationErrors = get(error, 'response.data.errors')
      const payload = parseValidationError(validationErrors)
      yield put({ type: SUBMIT_CAUSE_FAILURE, payload })
      break
    }
    default: {
      yield put(showErrorsAppCall(parseError(error)))
      yield put({ type: SUBMIT_CAUSE_FAILURE })
    }
  }
}

export function* fetchCauseList() {
  const { data, errors } = yield call(causeApi.list)
  if (data) {
    yield put(fetchCauseListSuccess(data?.data || []))
  } else if (errors) {
    const payload = parseValidationError(errors)
    yield put(fetchCauseListFailure(payload))
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchCauseListAuth() {
  const { data, errors } = yield call(causeApi.listAuth)
  if (data) {
    yield put(fetchCauseListSuccess(data?.data || []))
  } else if (errors) {
    const payload = parseValidationError(errors)
    yield put(fetchCauseListFailure(payload))
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchCause({ id }: any) {
  try {
    const causeResponse: AxiosResponse = yield call(causeApi.get, id)
    const checkUsedResponse: AxiosResponse = yield call(causeApi.checkUsed, id)

    const causeData = {
      ...causeResponse.data.data,
      is_used: checkUsedResponse.data.data.is_used,
    }

    yield put({ type: FETCH_CAUSE_SUCCESS, payload: causeData })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createCause({ payload }: CreateCauseRequest) {
  try {
    yield call(causeApi.create, payload)
    yield put({ type: SUBMIT_CAUSE_SUCCESS })
    yield put(changeLocation('/master/causes'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitCauseFailure>({ type: HANDLE_SUBMIT_CAUSE_FAILURE, error })
  }
}

export function* updateCause({ id, payload }: UpdateCauseRequest) {
  try {
    yield call(causeApi.update, id, payload)
    yield put({ type: SUBMIT_CAUSE_SUCCESS })
    yield put(changeLocation('/master/causes'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitCauseFailure>({ type: HANDLE_SUBMIT_CAUSE_FAILURE, error })
  }
}

export function* deleteCause({ payload }: DeleteCauseRequest) {
  const { id, content } = payload

  try {
    const checkDeleteResponse: AxiosResponse = yield call(causeApi.destroy, id, true)
    const isAllowDelete = checkDeleteResponse.data.data && checkDeleteResponse.data.data.id
    if (isAllowDelete) {
      yield call(causeApi.destroy, id, false)
      yield put({ type: DELETE_CAUSE_SUCCESS })
      yield put({ type: FETCH_CAUSES_REQUEST })
      yield put(
        setAppMessage({
          type: 'success',
          content: i18n.t('common.messages.deleted_success'),
        })
      )
    }
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400 && get(data, 'errors[0].code') === DATA_IN_USED_ERROR_CODE) {
        yield put({
          type: SHOW_IN_USED_MESSAGE,
          payload: i18n.t('common.messages.deleted_failure_by_in_used', { name: content }),
        })
      } else {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure', { name: content }),
          })
        )
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
    yield put({ type: DELETE_CAUSE_FAILURE })
  }
}

export function* exportCauses({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(causeApi.exportFile, payload)
    yield downloadFileByResponse(response)
    yield put({ type: EXPORT_CAUSE_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      })
    )
  } catch (e) {
    yield put({ type: EXPORT_CAUSE_FAILURE })
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.exported_failure'),
      })
    )
    yield put(showErrorsAppCall(parseError(e)))
  }
}

export function* importCauses({ payload, file }: any) {
  try {
    yield call(causeApi.importFile, payload, file)
    yield put({ type: IMPORT_CAUSE_SUCCESS })
    yield put<FetchCausesRequest>({ type: FETCH_CAUSES_REQUEST })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.imported_success'),
      })
    )
  } catch (error: any) {
    const { status, data } = error.response

    if (status === 422 || status === 400) {
      const importErrors = parseImportErrors(data)
      yield put<ImportCauseFailure>({ type: IMPORT_CAUSE_FAILURE, payload: importErrors })
    } else {
      yield put(
        setAppMessage({
          type: 'failure',
          content: i18n.t('common.messages.imported_failure'),
        })
      )
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_CAUSES_REQUEST, fetchCauseList)
  yield takeLatest(FETCH_CAUSES_REQUEST_AUTH, fetchCauseListAuth)
  yield takeLatest(FETCH_CAUSE_REQUEST, fetchCause)
  yield takeLatest(CREATE_CAUSE_REQUEST, createCause)
  yield takeLatest(UPDATE_CAUSE_REQUEST, updateCause)
  yield takeLatest(DELETE_CAUSE_REQUEST, deleteCause)
  yield takeLatest(EXPORT_CAUSE_REQUEST, exportCauses)
  yield takeLatest(IMPORT_CAUSE_REQUEST, importCauses)
  yield takeLatest(HANDLE_SUBMIT_CAUSE_FAILURE, handleSubmitCauseFailure)
}
