import { parseDataForForm, parseDataForSubmit } from 'aa_common/front-end/helpers/asset-helper'
import { Asset } from 'models/asset'

export const parseAssetListForForm = (assetList: Asset[]) => {
  return assetList?.map((asset, index) => {
    return {
      ...parseDataForForm(asset),
      index,
    }
  })
}

export const parseAssetListForSubmit = (assetList: Asset[]) => {
  return assetList?.map(asset => {
    return {
      ...parseDataForSubmit(asset),
    }
  })
}
