import { CSVCharset } from 'models/csvCharset'

import { DeleteDepartmentRequest, FetchDepartmentsRequest, FetchDepartmentsRequestAuth } from './action.model'
import {
  CLEANUP_DEPARTMENT,
  CLEAR_DEPARTMENT_ITEM_ERRORS,
  CREATE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_BY_ID_REQUEST,
  DELETE_DEPARTMENT_REQUEST,
  EXPORT_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENT_BY_BIID_REQUEST,
  FETCH_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENTS_FAILURE,
  FETCH_DEPARTMENTS_ON_CLOUD_REQUEST,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_REQUEST_AUTH,
  FETCH_DEPARTMENTS_SUCCESS,
  HANDLE_FETCH_DEPARTMENTS_ON_CLOUD,
  IMPORT_DEPARTMENT_REQUEST,
  INSERT_DEPARTMENT_REQUEST,
  PARTIAL_CLEANUP_DEPARTMENT,
  UPDATE_DEPARTMENT_REQUEST,
} from './constant'
import { DeleteDepartmentPayload, DepartmentSearchPayload } from './model'

export const fetchDepartments = (payload: DepartmentSearchPayload = {}): FetchDepartmentsRequest => {
  return {
    type: FETCH_DEPARTMENTS_REQUEST,
    payload,
  }
}

export const fetchDepartmentsAuth = (payload: DepartmentSearchPayload = {}): FetchDepartmentsRequestAuth => ({
  type: FETCH_DEPARTMENTS_REQUEST_AUTH,
  payload,
})

export const fetchDepartmentsOnCloudRequest = () => ({
  type: FETCH_DEPARTMENTS_ON_CLOUD_REQUEST,
})

export const handleFetchDepartmentsOnCloud = (payload: any) => ({
  type: HANDLE_FETCH_DEPARTMENTS_ON_CLOUD,
  payload,
})

export const fetchDepartmentsSuccess = (payload: any) => ({
  type: FETCH_DEPARTMENTS_SUCCESS,
  payload,
})

export const fetchDepartmentsFailure = () => ({
  type: FETCH_DEPARTMENTS_FAILURE,
})

export const fetchDepartment = (id: number) => {
  return {
    type: FETCH_DEPARTMENT_REQUEST,
    id,
  }
}

export const fetchByBiid = (biid: string) => {
  return {
    type: FETCH_DEPARTMENT_BY_BIID_REQUEST,
    biid,
  }
}

export const createDepartment = (payload: any) => {
  return {
    type: CREATE_DEPARTMENT_REQUEST,
    payload,
  }
}

export const insertDepartment = (payload: any, callback?: any) => {
  return {
    type: INSERT_DEPARTMENT_REQUEST,
    payload,
    callback,
  }
}

export const updateDepartment = (id: number, payload: any, callback?: any) => {
  return {
    type: UPDATE_DEPARTMENT_REQUEST,
    id,
    payload,
    callback,
  }
}

export const clearDepartmentItemErrors = () => {
  return {
    type: CLEAR_DEPARTMENT_ITEM_ERRORS,
  }
}

export const deleteDepartment = (payload: DeleteDepartmentPayload): DeleteDepartmentRequest => {
  return {
    type: DELETE_DEPARTMENT_REQUEST,
    payload,
  }
}

export const deleteDepartmentById = (payload: any) => {
  return {
    type: DELETE_DEPARTMENT_BY_ID_REQUEST,
    payload,
  }
}

export const importDepartments = (charset: CSVCharset, file: any) => {
  return {
    type: IMPORT_DEPARTMENT_REQUEST,
    payload: {
      charset,
      file,
    },
    meta: { thunk: true },
  }
}
export type ImportDepartmentsAction = ReturnType<typeof importDepartments>

export const exportDepartments = (charset: CSVCharset, filters: any) => {
  return {
    type: EXPORT_DEPARTMENT_REQUEST,
    payload: {
      charset,
      filters,
    },
  }
}
export type ExportDepartmentsAction = ReturnType<typeof exportDepartments>

export const cleanupDepartment = () => {
  return {
    type: CLEANUP_DEPARTMENT,
  }
}

export const partialCleanupDepartment = () => {
  return {
    type: PARTIAL_CLEANUP_DEPARTMENT,
  }
}
