import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18n from 'i18n'
import React, { useCallback, useEffect, useState } from 'react'

import { PaginationButton, PaginationInput, PaginationWrapper } from './styles'

interface PaginationProps {
  disabled?: boolean
  isNoInfo?: boolean
  value?: number
  name: string
  nameClass?: string
  totalPages: number
  totalRecords: number
  onPageChanged?: (page: number) => void
}

const DELAY_TIMEOUT = 200
const firstPage = 1
const numberRegex = /^[0-9\b]+$/

const Pagination = ({
  value,
  disabled,
  name,
  totalRecords,
  totalPages,
  isNoInfo = false,
  onPageChanged,
  nameClass,
}: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState<number>(firstPage)
  const [inputValue, setInputValue] = useState<string>(firstPage.toString())

  const handleSetCurrentPage = (page: number) => setCurrentPage(page)

  useEffect(() => {
    if (!value) {
      handleSetCurrentPage(firstPage)
      setInputValue(firstPage.toString())
    } else {
      handleSetCurrentPage(value)
      setInputValue(value.toString())
    }
  }, [value])

  const handleInputChanged = (event: any) => {
    const numberValue = event.target.value
    if (numberValue === '' || numberRegex.test(numberValue)) {
      setInputValue(numberValue)
    }
  }

  const handleOnBlur = () => {
    setInputValue(currentPage.toString())
    handleSetCurrentPage(currentPage)
  }

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const ENTER_KEYCODE = 13
    if (event.keyCode === ENTER_KEYCODE) {
      if (inputValue === '') {
        setInputValue(firstPage.toString())
        setCurrentPage(() => {
          setTimeout(() => onPageChanged && onPageChanged(firstPage), DELAY_TIMEOUT)
          return firstPage
        })
      }
      if (inputValue !== '' && numberRegex.test(inputValue)) {
        let pageValue = parseInt(inputValue, 10)

        if (pageValue < 0) {
          pageValue = firstPage
        } else if (pageValue > totalPages) {
          pageValue = totalPages
        }

        setInputValue(pageValue.toString())
        setCurrentPage(() => {
          setTimeout(() => onPageChanged && onPageChanged(pageValue), DELAY_TIMEOUT)
          return pageValue
        })
      }
    }
  }

  const handlePrevClick = useCallback(() => {
    setInputValue(prevValue => (parseInt(prevValue, 10) - 1).toString())
    setCurrentPage(prevValue => {
      const prevPage = prevValue - 1
      setTimeout(() => onPageChanged && onPageChanged(prevPage), DELAY_TIMEOUT)
      return prevPage
    })
  }, [setInputValue, setCurrentPage, onPageChanged])

  const handleNextClick = useCallback(() => {
    setInputValue(prevValue => (parseInt(prevValue, 10) + 1).toString())
    setCurrentPage(prevValue => {
      const nextPage = prevValue + 1
      setTimeout(() => onPageChanged && onPageChanged(nextPage), DELAY_TIMEOUT)
      return nextPage
    })
  }, [setInputValue, setCurrentPage, onPageChanged])

  const isEmptyList = !totalRecords && !totalPages

  return (
    <PaginationWrapper className={nameClass}>
      <PaginationButton
        type="button"
        className="prev"
        disabled={isEmptyList || currentPage === firstPage || disabled}
        onClick={handlePrevClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </PaginationButton>
      <div style={{ marginRight: 10 }}>
        <PaginationInput
          disabled={isEmptyList || disabled}
          value={inputValue}
          name={`pagination-${name}`}
          onChange={handleInputChanged}
          onBlur={handleOnBlur}
          onKeyDown={handleKeydown}
        />
      </div>
      {isNoInfo ? (
        <span> / {totalPages}</span>
      ) : (
        totalRecords > 0 &&
        totalPages > 0 && (
          <span>{`${i18n.t('components.Pagination.page', { totalPages })} (${i18n.t(
            'components.AssetList.total_records',
            { totalRecords }
          )})`}</span>
        )
      )}
      <PaginationButton
        type="button"
        className="next"
        disabled={isEmptyList || currentPage === totalPages || disabled}
        onClick={handleNextClick}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </PaginationButton>
    </PaginationWrapper>
  )
}

export default React.memo(Pagination)
