import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, messageFn, Spin } from 'aa_common/front-end/antd'
import { ExportModal } from 'aa_common/front-end/components'
import { buildQueryFilterParams, downloadFileByResponse } from 'aa_common/front-end/helpers'
import { useCheckAgentLogin } from 'aa_common/front-end/hooks'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { exportWithCsvFormat } from 'api/app/depreciationFixedAsset'
import { DECLARATION_REPORT_EXPORT_MODE, DECLARATION_REPORT_TYPES } from 'AppExport/model'
import SummaryForm from 'components/declaration_forms/001'
import DecreaseForm from 'components/declaration_forms/002'
import IncreaseForm from 'components/declaration_forms/003'
import { BreadcrumbNavigation } from 'components/molecules'
import DepreciationFixedAssetReview from 'components/organisms/accounting/DepreciationFixedAssetReview'
import { MainTemplate } from 'components/templates'
import { FEATURES_ID } from 'constants/const'
import useModal from 'hooks/useModalHook'
import i18n from 'i18n'
import useUserPermission from 'lib/hooks/useUserPermission'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { exportTaxDeclarationReports } from 'store/accounting/depreciationFixedAsset/actions'

import ModalContent from './Modal'
import { ActionWrapper, ModalWrapper } from './styles'
import useReportsData from './useReportsData'

const exclamationIcon = (
  <ExclamationCircleFilled style={{ marginLeft: 5, marginTop: 5, color: '#EC4949', fontSize: 13 }} />
)

const DepreciationFixedAssetReviewPage = () => {
  const dispatch = useDispatch()
  const parameterUrl = useParams<any>()
  const submitDestinationId = parameterUrl?.submit_destination_id
  const taxYear = parameterUrl?.tax_year
  const [exportMode, setExportMode] = useState(DECLARATION_REPORT_EXPORT_MODE.IN_FISCAL_YEAR)
  const [exportType, setExportType] = useState<string>(ExportType.PDF)
  const [exporting, setExporting] = useState<boolean>(false)
  const [isShowExportModal, setShowExportModal] = useState<boolean>(false)
  const { permissions } = useUserPermission(FEATURES_ID.DEPRECIATION_FIXED_ASSET)

  const modal = useModal()
  const handleExport = (type: DECLARATION_REPORT_TYPES) => {
    const modalConfirmRef = modal.confirm({
      cancelText: i18n.t('components.ConfirmModal.cancel_process'),
      maskClosable: false,
      message: (
        <ModalWrapper>
          <ModalContent titleName={i18n.t('pages.accounting.DepreciationFixedAssetPage.modals.progress_title')} />
        </ModalWrapper>
      ),
    })
    Promise.race([
      modalConfirmRef,
      dispatch(
        exportTaxDeclarationReports({
          submit_destination_id: submitDestinationId,
          year: taxYear,
          type,
          mode: exportMode,
        })
      ),
    ])
      .then(result => {
        if (result) {
          window.open(result.url as string, '_blank')
        }
        modalConfirmRef.cancel()
      })
      .catch(() => {
        modalConfirmRef.cancel()
        modal.confirm({
          message: (
            <ModalWrapper>
              <ModalContent
                titleName={i18n.t('pages.accounting.DepreciationFixedAssetPage.modals.error_title')}
                statusProgress="exception"
                icon={exclamationIcon}
              />
            </ModalWrapper>
          ),
          cancelText: i18n.t('common.actions.ok'),
        })
      })
  }

  const {
    loading,
    summaryReport,
    increaseReport: { setPageNumber: setIncreaseReportPage, ...increaseReportProps },
    decreaseReport: { setPageNumber: setDecreaseReportPage, ...decreaseReportProps },
  } = useReportsData(submitDestinationId, taxYear, exportMode)
  const isAgentLogin = useCheckAgentLogin()

  const location = useLocation<{ submitDestinationName: string }>()
  const { submitDestinationName } = location?.state || {}
  const breadcrumb = useMemo(() => {
    const sectionName = submitDestinationName || summaryReport?.submit_destination?.name || ''
    return <BreadcrumbNavigation enableBackButton sectionName={sectionName} />
  }, [summaryReport, submitDestinationName])

  const handleExportWithPdfOption = () => {
    if (!permissions.isRead || isAgentLogin) return
    handleExport(DECLARATION_REPORT_TYPES.ALL)
  }

  const handleExportWithCsvOption = async (charset?: string) => {
    setExporting(true)
    try {
      const params = { fiscal_year: taxYear, submit_destination_id: submitDestinationId }
      const response = await exportWithCsvFormat({ charset, ...buildQueryFilterParams(params) })
      if (response) {
        downloadFileByResponse(response)
        messageFn().success(i18n.t('common.messages.exported_success'))
        setShowExportModal(false)
        setExporting(false)
      }
    } catch (err) {
      messageFn().error(err)
      setShowExportModal(false)
      setExporting(false)
    }
  }

  const handleClickExportButton = () => {
    if (exportType === ExportType.CSV) {
      setShowExportModal(true)
    } else {
      handleExportWithPdfOption()
    }
  }

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <Spin loading={exporting}>
        {/* ACTIONS */}
        <ActionWrapper>
          <div>
            <span className="radio-title">
              {i18n.t('pages.accounting.DepreciationFixedAssetPage.radios.export_types_title')}
            </span>
            <Radio.Group
              onChange={(evt: RadioChangeEvent) => {
                setExportType(ExportType.PDF) // Reset export type to default is PDF
                setExportMode(evt.target.value)
              }}
              value={exportMode}
              disabled={loading}
            >
              <Radio value={DECLARATION_REPORT_EXPORT_MODE.IN_FISCAL_YEAR}>
                {i18n.t('pages.accounting.DepreciationFixedAssetPage.radios.increase_decrease')}
              </Radio>
              <Radio value={DECLARATION_REPORT_EXPORT_MODE.ALL}>
                {i18n.t('pages.accounting.DepreciationFixedAssetPage.radios.all_fixed_assets')}
              </Radio>
            </Radio.Group>
          </div>
          <div>
            <Radio.Group value={exportType} onChange={e => setExportType(e.target.value)}>
              <Radio value={ExportType.PDF}>
                <span>PDF{i18n.t('pages.accounting.DepreciationFixedAssetPage.radios.format')} </span>
              </Radio>
              <Radio value={ExportType.CSV} disabled={exportMode !== DECLARATION_REPORT_EXPORT_MODE.ALL}>
                <span>CSV{i18n.t('pages.accounting.DepreciationFixedAssetPage.radios.format')} </span>
              </Radio>
            </Radio.Group>

            <Button color="grey" onClick={handleClickExportButton}>
              {i18n.t('pages.accounting.DepreciationFixedAssetPage.buttons.export_all')}
            </Button>
          </div>
        </ActionWrapper>

        <DepreciationFixedAssetReview
          loading={loading}
          content={
            <SummaryForm
              {...summaryReport}
              year={taxYear}
              isAllFixedAssetMode={exportMode === DECLARATION_REPORT_EXPORT_MODE.ALL}
            />
          }
        />
        <DepreciationFixedAssetReview
          loading={loading}
          content={
            <IncreaseForm
              {...increaseReportProps}
              year={taxYear}
              isAllFixedAssetMode={exportMode === DECLARATION_REPORT_EXPORT_MODE.ALL}
            />
          }
          isPagination
          currentPage={increaseReportProps.page}
          totalPages={increaseReportProps.total_page}
          totalRecords={increaseReportProps.totalRecord}
          onHandlePageChange={setIncreaseReportPage}
        />
        {exportMode === DECLARATION_REPORT_EXPORT_MODE.IN_FISCAL_YEAR && (
          <DepreciationFixedAssetReview
            loading={loading}
            content={<DecreaseForm {...decreaseReportProps} year={taxYear} />}
            isPagination
            currentPage={decreaseReportProps.page}
            totalPages={decreaseReportProps.total_page}
            totalRecords={decreaseReportProps.totalRecord}
            onHandlePageChange={setDecreaseReportPage}
          />
        )}

        <ExportModal
          isShow={isShowExportModal}
          title={i18n.t('pages.accounting.DepreciationFixedAssetPage.modal_title', {
            name: summaryReport?.submit_destination?.name,
          })}
          onCancel={() => setShowExportModal(false)}
          onExport={handleExportWithCsvOption}
          loading={exporting}
        >
          <div style={{ marginBottom: '5px' }}>
            {i18n.t('pages.accounting.DepreciationFixedAssetPage.select_file_format')}
          </div>
        </ExportModal>
      </Spin>
    </MainTemplate>
  )
}

enum ExportType {
  PDF = 'pdf',
  CSV = 'csv',
}

export default DepreciationFixedAssetReviewPage
