import { get } from 'lodash'
import { OfficeUser } from 'models/officeUser'
import { UserFormPayload } from 'models/user'

export const convertToOfficeUserListResponse = (responseData: any[]): OfficeUser[] => {
  if (!responseData) return []

  const convertedData: OfficeUser[] = responseData.map(item => {
    return {
      id: get(item, 'id', null),
      email: get(item, 'email', null),
      name: get(item, 'display_name', null),
      user_id: get(item, 'user_id', null),
      roles: get(item, 'roles', null),
    }
  })

  return convertedData
}

export const convertToOfficeUserItemResponse = (responseData: any): OfficeUser | null => {
  if (!responseData) return null

  return {
    id: get(responseData, 'id', null),
    email: get(responseData, 'email', null),
    name: get(responseData, 'display_name', null),
    user_id: get(responseData, 'user_id', null),
    roles: get(responseData, 'roles', null),
  }
}

export const convertToUserFormPayload = (user: OfficeUser): UserFormPayload | undefined => {
  if (!user) return

  return {
    user_id: user.user_id || -1,
    id: user.id || -1,
    name: user.name || '',
    email: user.email || '',
    role_biids: user.roles && user.roles.length > 0 ? user.roles.map(item => item.biid) : [],
  }
}
