import { Tooltip } from 'antd'
import { DefaultTooltipOverlay } from 'assets/styles/commonStyle'
import classNames from 'classnames'
import SortBarIcon from 'components/atoms/icons/SortBarIcon'
import { XYCoord } from 'dnd-core'
import React, { useRef } from 'react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'

import { TableActionButton } from './components/table-action-button'
import { Cell, Icons, Row } from './styles'

interface DragItem {
  index: number
  id: string
  type: string
}

interface TableRowProps {
  id: string
  bodyItem: any
  columns: any[]
  isFirst: boolean
  isLast: boolean
  index: number
  draggable: boolean
  draggableTooltipMessage?: string
  disableDragRow?: boolean
  disableActions?: {
    copy?: (record: any) => boolean
    edit?: (record: any) => boolean
    delete?: (record: any) => boolean
  }
  showActionsTooltip?: {
    copy?: (record: any) => string | undefined
    edit?: (record: any) => string | undefined
    delete?: (record: any) => string | undefined
  }
  onCopy?: (event: any) => void
  onEdit?: (event: any) => void
  onDelete?: (event: any) => void
  onFinishDragging?: (event: any) => void
  moveTableRow: (dragIndex: number, hoverIndex: number) => void
  isRenderLastColumns?: boolean
}

const TableRow: React.FC<TableRowProps> = ({
  id,
  index,
  bodyItem,
  isFirst,
  columns,
  isLast,
  draggable,
  disableDragRow,
  draggableTooltipMessage,
  isRenderLastColumns = true,
  disableActions,
  showActionsTooltip,
  onEdit,
  onDelete,
  onCopy,
  onFinishDragging = item => {
    return item
  },
  moveTableRow,
}) => {
  const ref = useRef<any>(null)
  const [, drop] = useDrop({
    accept: 'table-row',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveTableRow(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'table-row',
    item: { type: 'table-row', item: bodyItem, id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: item => onFinishDragging(item),
  })

  const isDraggable = !!(draggable && !disableDragRow)

  if (isDraggable) {
    drag(drop(ref))
  }

  const isDisableEdit = typeof disableActions?.edit === 'function' && disableActions?.edit(bodyItem)
  const isDisableDelete = typeof disableActions?.delete === 'function' && disableActions?.delete(bodyItem)
  const isDisableCopy = typeof disableActions?.copy === 'function' && disableActions?.copy(bodyItem)

  const showTooltip = {
    copy: showActionsTooltip?.copy && showActionsTooltip?.copy(bodyItem),
    edit: showActionsTooltip?.edit && showActionsTooltip?.edit(bodyItem),
    delete: showActionsTooltip?.delete && showActionsTooltip?.delete(bodyItem),
  }

  return (
    <Row
      id={id}
      ref={draggable ? ref : null}
      className={classNames({
        'draggable-row': isDraggable,
        first: isFirst && !isDraggable,
        last: isLast,
        dragging: isDragging,
      })}
    >
      {draggable && (
        <Cell className={classNames('first sortable', { disabled: !isDraggable })}>
          {draggableTooltipMessage ? (
            <Tooltip
              arrowPointAtCenter
              placement="topRight"
              overlayClassName={DefaultTooltipOverlay}
              destroyTooltipOnHide
              title={draggableTooltipMessage}
            >
              <SortBarIcon className="icon" />
            </Tooltip>
          ) : (
            <SortBarIcon className="icon" />
          )}
        </Cell>
      )}
      {columns.map((headerItem, index) => (
        <Cell key={headerItem.field} className={classNames({ first: index === 0 && !draggable })}>
          {bodyItem[headerItem.field]}
        </Cell>
      ))}
      {isRenderLastColumns ? (
        <Cell className="last">
          <Icons>
            {onCopy && (
              <TableActionButton
                showTooltipContent={showTooltip.copy}
                disabled={isDisableCopy}
                type="copy"
                onClick={() => onCopy(bodyItem)}
              />
            )}
            {onEdit && (
              <TableActionButton
                showTooltipContent={showTooltip.edit}
                disabled={isDisableEdit}
                type="edit"
                onClick={() => onEdit(bodyItem)}
              />
            )}
            {onDelete && (
              <TableActionButton
                showTooltipContent={showTooltip.delete}
                disabled={isDisableDelete}
                type="delete"
                onClick={() => onDelete(bodyItem)}
              />
            )}
          </Icons>
        </Cell>
      ) : null}
    </Row>
  )
}

export default TableRow
