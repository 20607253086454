/* eslint-disable react/default-props-match-prop-types */
import { ErrorsList, MainHeader } from 'aa_common/front-end/components'
import { Col, Layout, Row } from 'antd'
import classNames from 'classnames'
import { BreadcrumbNavigation } from 'components/molecules'
import TermsSelection from 'components/organisms/app/TermsSelection'
import TopMessage from 'components/organisms/app/TopMessage'
import MainMenu from 'components/organisms/MainMenu'
import { ERR_ACCOUNT_ITEM_DISABLED, ERR_ACCOUNT_SUB_ITEM_DISABLED } from 'constants/apiCode'
import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { TabPageTemplateProps } from './model'
import {
  ActionWrapper,
  Body,
  BreadcrumbArea,
  ErrorWrapper,
  FilterPanel,
  Header,
  TabPane,
  Tabs,
  TabsClass,
  TopMessage as TopMessageWrapper,
  Wrapper,
} from './styles'

const TabPageTemplate: React.FC<TabPageTemplateProps> = ({
  containerClassName,
  bodyLayoutClassName,
  tabsSettings,
  filterPanel,
  header,
  sidebar,
  breadcrumb,
  actions,
  topMessage,
  isShowTermSelection = false,
  onChangeTermSelection,
  type = 'line',
  topChildren,
  errors,
}) => {
  const { REACT_APP_ACC_PLUS } = process.env

  useEffect(() => {
    const handleResize = () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    window && window.addEventListener('resize', handleResize)

    return () => {
      window && window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isShowErrorTitle =
    errors &&
    errors.some((err: any) => err.code === ERR_ACCOUNT_ITEM_DISABLED || err.code === ERR_ACCOUNT_SUB_ITEM_DISABLED)
  const errsList =
    errors &&
    errors.map((err: any) => {
      return err.detail
    })

  return (
    <Wrapper>
      <Layout>
        <Header>{header}</Header>
        <Body>
          {sidebar}
          <Layout className={classNames('tabpage-template-body', bodyLayoutClassName)} id="app-main-body">
            <Layout.Content className={classNames(containerClassName)}>
              <BreadcrumbArea
                className={classNames({
                  'has-search-panel': !!filterPanel,
                })}
              >
                <Row>
                  <Col flex="1 1" style={{ minWidth: 0 }}>
                    {breadcrumb}
                  </Col>
                  {actions && (
                    <Col flex="0 1 auto">
                      <ActionWrapper>{actions}</ActionWrapper>
                    </Col>
                  )}
                </Row>
                {topChildren}
                {filterPanel && <FilterPanel>{filterPanel}</FilterPanel>}
                {isShowTermSelection && <TermsSelection onChange={onChangeTermSelection} />}

                {errsList && errsList.length > 0 && (
                  <ErrorWrapper>
                    <ErrorsList
                      title={
                        isShowErrorTitle ? (
                          <Trans i18nKey="pages.accounting.JournalList.err_messages.account_item_disabled">
                            会計Plusで無効化されている勘定科目または補助科目が使われています。
                            <br />
                            <Link to="/setting/auto-journal-settings#general-journal-settings-tab">
                              自動仕訳設定
                            </Link>{' '}
                            もしくは
                            <a href={`${REACT_APP_ACC_PLUS}/session/new`} target="_blank" rel="noreferrer">
                              会計Plusの勘定科目マスタ
                            </a>
                            を編集してください。
                          </Trans>
                        ) : (
                          ''
                        )
                      }
                      errors={errsList}
                      style={{ width: '900px' }}
                    />
                  </ErrorWrapper>
                )}

                <Tabs
                  type={type}
                  className={classNames(TabsClass, { isCard: type === 'card' })}
                  destroyInactiveTabPane={tabsSettings.destroyInactiveTabPane}
                  activeKey={tabsSettings.activeKey}
                  onChange={tabsSettings.onTabChange}
                >
                  {tabsSettings.tabs.length > 0 &&
                    tabsSettings.tabs.map(tabItem => {
                      const isCustomTabPaneCanDestroy =
                        !tabsSettings.destroyInactiveTabPane && tabItem.destroyWhenInactive
                      const destroyTab = isCustomTabPaneCanDestroy && tabsSettings.activeKey !== tabItem.key
                      return (
                        <TabPane disabled={tabItem.disabled} tab={tabItem.label} key={tabItem.key}>
                          {!destroyTab ? tabItem.children : null}
                        </TabPane>
                      )
                    })}
                </Tabs>
                <TopMessageWrapper>{topMessage}</TopMessageWrapper>
              </BreadcrumbArea>
            </Layout.Content>
          </Layout>
        </Body>
      </Layout>
    </Wrapper>
  )
}

TabPageTemplate.defaultProps = {
  header: <MainHeader />,
  sidebar: <MainMenu id="app-main-menu" />,
  breadcrumb: <BreadcrumbNavigation sectionName="Home" />,
  actions: undefined,
  topMessage: <TopMessage />,
  filterPanel: null,
  errors: [],
}

export default TabPageTemplate
