import Icon from '@ant-design/icons'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { Button as AntdButton } from 'antd'
import { ReactComponent as MenuDot } from 'assets/images/icons/menu-dot.svg'
import React from 'react'

const AntIcon = styled(Icon)``

export const MenuIcon = () => (
  <AntIcon style={{ fontSize: 25, cursor: 'pointer', marginLeft: -1 }} component={MenuDot} />
)

export const Button = styled(AntdButton)`
  padding: 0;
  height: 42px;
  width: 100%;
  text-align: left;
  border: none;
  background: transparent;

  &.item-button {
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const MenuStyle = css`
  border-radius: 4px;

  li {
    padding: 0;
    min-width: 160px;
    border-bottom: 1px solid #dce0e6;
    &:last-child {
      border-bottom: none;
    }

    &.ant-dropdown-menu-item-disabled,
    &.ant-dropdown-menu-submenu-title-disabled {
      cursor: pointer;
    }
  }

  .ant-tooltip-custom {
    width: 100%;
  }

  .acc-plus-url {
    padding: 8px 12px !important;
    color: #333333;
  }
`

export const TooltipWrapper = styled.div`
  display: inline-block;

  .ant-btn {
    &:hover {
      background: inherit;
    }

    &:disabled {
      color: #d4d8dd;
      border: none;
      pointer-events: none;
    }
  }
`
