import { Spin } from 'aa_common/front-end/antd'
import { ExportModal } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { RadioChangeEvent } from 'antd'
import { getUnderOverDepreciationReport } from 'api/app/report'
import RadioGroup from 'components/atoms/v2/RadioGroup'
import useResource from 'lib/hooks/useResource'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { exportUnderOverDepreciationReport } from 'store/accounting/underOverDepreciationReport/actions'
import {
  ExportUnderOverDepreciationReportPayload,
  UnderOverDepreciationReportPayload,
} from 'store/accounting/underOverDepreciationReport/model'
import { selectorExportUnderOverDepreciationReport } from 'store/accounting/underOverDepreciationReport/selectors'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsList } from 'store/settings/accountingPeriod/selectors'
import { selectHasAccountantAndTaxLedger } from 'store/settings/ledgerSetting/selectors'

import { ExportByUnit } from './constant'
import Form from './Form'
import { ReportTable } from './ReportTable'

const t = loadNs(['pages/accounting/under-over-depreciatioin-page', 'pages/accounting/increase-decrease-report'])
const UnderOverDepreciationReport = () => {
  const hasAccountantAndTaxLedger = useSelector(selectHasAccountantAndTaxLedger, isEqual)
  const dispatch = useDispatch()
  const [termYearId, setTermYearId] = useState<number>()
  const [isExportModalShow, setShowExportModal] = useState(false)
  const [exportUnitValue, setExportUnitValue] = useState(ExportByUnit.ASSET_UNIT)
  const termsSelector = useSelector(selectTermsList, isEqual)
  const underOverDepreciationReport = useSelector(selectorExportUnderOverDepreciationReport, isEqual)
  const currentMonthlyClosingSelector = useSelector(selectCurrentMonthlyClosing, isEqual)
  const [stateData, getReportData] = useResource(getUnderOverDepreciationReport)

  const currentMonthlyClosing = useMemo(() => currentMonthlyClosingSelector.data, [currentMonthlyClosingSelector.data])
  const terms = useMemo(() => termsSelector.data, [termsSelector.data])
  const isLoading = useMemo(
    () =>
      termsSelector?.loading ||
      currentMonthlyClosingSelector?.loading ||
      stateData?.isLoading ||
      underOverDepreciationReport.loading ||
      hasAccountantAndTaxLedger === null,
    [
      termsSelector.loading,
      currentMonthlyClosingSelector.loading,
      stateData?.isLoading,
      underOverDepreciationReport.loading,
      hasAccountantAndTaxLedger,
    ]
  )

  const EXPORT_BY_UNIT_OPTIONS = [
    { label: t('export_by_asset_unit'), value: ExportByUnit.ASSET_UNIT },
    {
      label: t('export_by_asset_category'),
      value: ExportByUnit.ASSET_CATEGORY,
    },
  ]

  const handleExportClick = (charset: string) => {
    if (termYearId) {
      const formValues: UnderOverDepreciationReportPayload = { term_id: termYearId }
      const payload: ExportUnderOverDepreciationReportPayload = { ...formValues, charset, output_unit: exportUnitValue }
      dispatch(exportUnderOverDepreciationReport(payload))
      setShowExportModal(false)
    }
  }

  const handleOnExportByUnitChanged = useCallback((event: RadioChangeEvent) => {
    const { value } = event.target
    setExportUnitValue(value)
  }, [])

  const changeTerm = useCallback((termYearId: any) => {
    setTermYearId(termYearId)
  }, [])

  useEffect(() => {
    if (termYearId) {
      getReportData({
        term_id: termYearId,
      })
    }
  }, [getReportData, termYearId])

  useEffect(() => {
    dispatch(fetchTerms())
    !currentMonthlyClosing && dispatch(fetchMonthlyClosingCurrent())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (terms && terms.length > 0) {
      const term_id = currentMonthlyClosing?.monthly_closing_setting?.term?.id || terms[0]?.id
      setTermYearId(term_id)
    }
  }, [terms, currentMonthlyClosing])

  return (
    <>
      <Spin loading={isLoading}>
        {termYearId && (
          <>
            <Form
              onClickExport={() => setShowExportModal(true)}
              terms={terms}
              currentTermYearId={termYearId}
              onChangeTerm={changeTerm}
            />
            <ReportTable dataTable={stateData?.data?.items || []} />
          </>
        )}
      </Spin>
      <ExportModal
        title={t('export_modal_title')}
        isShow={isExportModalShow}
        onCancel={() => setShowExportModal(false)}
        onExport={handleExportClick}
      >
        <div style={{ fontSize: 13 }}>
          <h4>{t('export_modal_description_output_unit')}</h4>
          <RadioGroup
            value={exportUnitValue}
            options={EXPORT_BY_UNIT_OPTIONS}
            name="output_unit"
            style={{ marginBottom: 30 }}
            onChange={handleOnExportByUnitChanged}
          />
          <h4>{t('export_modal_description_format')}</h4>
        </div>
      </ExportModal>
    </>
  )
}

export default UnderOverDepreciationReport
