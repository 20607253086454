import { AssignRolesOfficeUserPayload, InviteUserPayload, OfficeUser } from 'models/officeUser'
import { Role } from 'models/role'

import {
  AssignRolesOfficeUserFailure,
  AssignRolesOfficeUserRequest,
  AssignRolesOfficeUserSuccess,
  DeleteOfficeUserRequest,
  FetchCreatableOfficeUsersSuccess,
  FetchOfficeUserItemFailure,
  FetchOfficeUserItemRequest,
  FetchOfficeUserItemSuccess,
  FetchOfficeUserListFailure,
  FetchOfficeUserListRequest,
  FetchOfficeUserListSuccess,
  FetchUserRoleOptionsFailure,
  FetchUserRoleOptionsRequest,
  FetchUserRoleOptionsSuccess,
  InviteUserFailure,
  InviteUserRequest,
} from './action.model'
import {
  ASSIGN_ROLES_OFFICE_USER_FAILURE,
  ASSIGN_ROLES_OFFICE_USER_REQUEST,
  ASSIGN_ROLES_OFFICE_USER_SUCCESS,
  DELETE_OFFICE_USER_FAILURE,
  DELETE_OFFICE_USER_REQUEST,
  DELETE_OFFICE_USER_SUCCESS,
  FETCH_CREATABLE_OFFICE_USERS_FAILURE,
  FETCH_CREATABLE_OFFICE_USERS_REQUEST,
  FETCH_CREATABLE_OFFICE_USERS_SUCCESS,
  FETCH_OFFICE_USER_ITEM_FAILURE,
  FETCH_OFFICE_USER_ITEM_REQUEST,
  FETCH_OFFICE_USER_ITEM_SUCCESS,
  FETCH_OFFICE_USER_LIST_FAILURE,
  FETCH_OFFICE_USER_LIST_REQUEST,
  FETCH_OFFICE_USER_LIST_SUCCESS,
  FETCH_USER_ROLE_OPTIONS_FAILURE,
  FETCH_USER_ROLE_OPTIONS_REQUEST,
  FETCH_USER_ROLE_OPTIONS_SUCCESS,
  INVITE_USER_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  RESET_OFFICE_USER,
} from './constant'

export const fetchOfficeUserList = (): FetchOfficeUserListRequest => {
  return {
    type: FETCH_OFFICE_USER_LIST_REQUEST,
  }
}

export const handleFetchOfficeUserListSuccess = (payload: OfficeUser[]): FetchOfficeUserListSuccess => {
  return {
    type: FETCH_OFFICE_USER_LIST_SUCCESS,
    payload,
  }
}

export const handleFetchOfficeUserListFailure = (): FetchOfficeUserListFailure => ({
  type: FETCH_OFFICE_USER_LIST_FAILURE,
})

export const fetchUserRoleOptions = (): FetchUserRoleOptionsRequest => {
  return {
    type: FETCH_USER_ROLE_OPTIONS_REQUEST,
  }
}

export const handleFetchUserRoleOptionsSuccess = (payload: Role[]): FetchUserRoleOptionsSuccess => ({
  type: FETCH_USER_ROLE_OPTIONS_SUCCESS,
  payload,
})

export const handleFetchUserRoleOptionsFailure = (): FetchUserRoleOptionsFailure => ({
  type: FETCH_USER_ROLE_OPTIONS_FAILURE,
})

export const fetchOfficeUserItem = (id: number): FetchOfficeUserItemRequest => ({
  type: FETCH_OFFICE_USER_ITEM_REQUEST,
  payload: { id },
})

export const handleFetchOfficeUserSuccess = (payload: OfficeUser | null): FetchOfficeUserItemSuccess => ({
  type: FETCH_OFFICE_USER_ITEM_SUCCESS,
  payload,
})

export const handleFetchOfficeUserFailure = (): FetchOfficeUserItemFailure => ({ type: FETCH_OFFICE_USER_ITEM_FAILURE })

export const assignRolesOfficeUser = (payload: AssignRolesOfficeUserPayload): AssignRolesOfficeUserRequest => ({
  type: ASSIGN_ROLES_OFFICE_USER_REQUEST,
  payload,
})

export const handleSaveOfficeUserSuccess = (): AssignRolesOfficeUserSuccess => ({
  type: ASSIGN_ROLES_OFFICE_USER_SUCCESS,
})

export const handleSaveOfficeUserFailure = (errorPayload: any): AssignRolesOfficeUserFailure => ({
  type: ASSIGN_ROLES_OFFICE_USER_FAILURE,
  payload: errorPayload,
})

export const fetchCreatableUsers = () => ({ type: FETCH_CREATABLE_OFFICE_USERS_REQUEST })

export const handleFetchCreatableUsersSuccess = (payload: OfficeUser[]): FetchCreatableOfficeUsersSuccess => ({
  type: FETCH_CREATABLE_OFFICE_USERS_SUCCESS,
  payload,
})

export const handleFetchCreatableUsersFailure = () => ({ type: FETCH_CREATABLE_OFFICE_USERS_FAILURE })

export const inviteUser = (payload: InviteUserPayload): InviteUserRequest => ({
  type: INVITE_USER_REQUEST,
  payload,
})

export const handleInviteUserSuccess = () => ({ type: INVITE_USER_SUCCESS })

export const handleInviteUserFailure = (errorPayload: any): InviteUserFailure => ({
  type: INVITE_USER_FAILURE,
  payload: errorPayload,
})

export const deleteOfficeUser = (id: number, name: string): DeleteOfficeUserRequest => ({
  type: DELETE_OFFICE_USER_REQUEST,
  payload: { id, name },
})

export const handleDeleteOfficeUserSuccess = () => ({ type: DELETE_OFFICE_USER_SUCCESS })

export const handleDeleteOfficeUserFailure = () => ({ type: DELETE_OFFICE_USER_FAILURE })

export const resetOfficeUser = () => ({ type: RESET_OFFICE_USER })
