import { updateObjectValues } from 'aa_common/front-end/helpers/object-helper'
import { BasicForm } from 'components/molecules'
import { get, isEqual } from 'lodash'
import { FORM_MODES } from 'models/form'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { selectorAssetCategoryItem } from 'store/settings/assetCategory/selectors'

import Form from './Form'
import { ValueFields } from './helpers'
import { Wrapper } from './styles'

type AssetCategoryFormProps = {
  values?: any
  type: FORM_MODES
  onSubmit: (values: ValueFields) => void
}

const initialValues: ValueFields = {
  code: '',
  name: '',
  asset_type: null,
  excise_id: null,
  depreciation_method: null,
  service_life: null,
  asset_type_for_depreciable_property_tax: null,
  asset_type_for_corporate_tax: null,
  asset_structure_for_corporate_tax: '',
  asset_detail_for_corporate_tax: '',
  search_key: '',
}

const AssetCategoryForm = ({ values, type = FORM_MODES.NEW, onSubmit }: AssetCategoryFormProps) => {
  const [formValues, setFormValues] = useState<ValueFields | null>(null)

  const masterData = useSelector(selectMasterData, isEqual)
  const { loading, errors } = useSelector(selectorAssetCategoryItem, isEqual)

  useMemo(() => {
    if (type === FORM_MODES.NEW) {
      setFormValues(initialValues)
    } else if (values) {
      if (type === FORM_MODES.EDIT) {
        setFormValues({
          ...values,
          depreciation_method: get(values, ['depreciation_method', 'code']),
          asset_type_for_depreciable_property_tax: get(values, ['asset_type_for_depreciable_property_tax', 'code']),
          asset_type_for_corporate_tax: get(values, ['asset_type_for_corporate_tax', 'code']),
        })
      } else if (type === FORM_MODES.COPY) {
        const objectValues = updateObjectValues(initialValues, values)
        setFormValues({
          ...objectValues,
          code: '',
          depreciation_method: get(values, ['depreciation_method', 'code']),
          asset_type_for_depreciable_property_tax: get(values, ['asset_type_for_depreciable_property_tax', 'code']),
          asset_type_for_corporate_tax: get(values, ['asset_type_for_corporate_tax', 'code']),
        })
      }
    }
  }, [values, type])

  return (
    <Wrapper>
      <BasicForm loading={loading} initialValues={formValues} onSubmit={onSubmit} errors={errors}>
        <Form type={type} masterData={masterData} />
      </BasicForm>
    </Wrapper>
  )
}

export default AssetCategoryForm
