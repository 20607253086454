/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { LinkLabel } from 'aa_common/front-end/components'
import { FEATURES_ID } from 'aa_common/front-end/constants'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { NoDataImage } from 'components/atoms'
import useUserPermission from 'lib/hooks/useUserPermission'
import { ExciseSetting } from 'models/exciseSettings'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'

import { NoData, Wrapper } from './styles'

type Props = {
  exciseSettingByDate?: ExciseSetting | any
}

const t = loadNs([
  'common',
  'components/organisms/accounting/journal-list',
  'components/organisms/depreciation/depreciation-result-list',
])
const MessageNoJournals = ({ exciseSettingByDate }: Props) => {
  const dispatch = useDispatch()
  const {
    permissions: { isRead },
  } = useUserPermission(FEATURES_ID.SETTING_EXCISE)

  return (
    <>
      {exciseSettingByDate?.data?.automatic_journal_entry_accquisition_type === 1 ? (
        <Wrapper style={{ margin: '15px', fontSize: '13px' }}>
          <span style={{ marginRight: 5 }}>{t('message_no_journals')}</span>
          <LinkLabel
            showTooltipMessage={!isRead ? t('actions.no_permission') : undefined}
            disabled={!isRead}
            onClick={() => dispatch(changeLocation('/setting/excises'))}
          >
            {t('config_journals_type')}
          </LinkLabel>
        </Wrapper>
      ) : (
        <NoData>
          <NoDataImage text={t('no_data_msg')} imageHeight={200} />
        </NoData>
      )}
    </>
  )
}

export default MessageNoJournals
