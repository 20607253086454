import { Tooltip } from 'aa_common/front-end/antd'
import { MAXLENGTH_TEXT } from 'constants/app'
import React from 'react'

import { Wrapper } from './styles'

type Props = {
  content: string
  maxlength?: number
  style?: React.CSSProperties
}

const TagView: React.FC<Props> = ({ content, style, maxlength = MAXLENGTH_TEXT }) => {
  return (
    <Wrapper style={style}>
      {content.length > maxlength ? (
        <Tooltip
          content={content}
          tooltipStyles={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </Wrapper>
  )
}

export default TagView
