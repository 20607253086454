import { LEDGER_PURPOSE } from 'constants/masterData'
import { AssetLedger } from 'models/asset'
import { LedgerSetting, LedgerSettingsItem } from 'models/ledgerSetting'

export const isAccountingJournalTarget = (ledger?: LedgerSettingsItem | LedgerSetting) => {
  return [LEDGER_PURPOSE.ACCOUNTING, LEDGER_PURPOSE.TAX_AND_ACCOUNTING].includes(ledger?.ledger_purpose as any)
}

export const isTaxJournalTarget = (ledger?: LedgerSettingsItem | LedgerSetting) => {
  return [LEDGER_PURPOSE.TAX].includes(ledger?.ledger_purpose as any)
}

export function findAccountingLedger(ledgers?: LedgerSettingsItem[]) {
  return (ledgers || []).find(isAccountingJournalTarget)
}

export function getDepreciationCodeOfAccountingLedger(ledgers?: AssetLedger[]) {
  return (ledgers || []).find(({ ledger_setting }) => isAccountingJournalTarget(ledger_setting))
    ?.depreciation_method_code
}

export function isHaveBothTwoLedgers(ledgers?: LedgerSetting[]) {
  return (
    ledgers?.some((ledger: LedgerSetting) => isAccountingJournalTarget(ledger)) &&
    ledgers?.some((ledger: LedgerSetting) => isTaxJournalTarget(ledger))
  )
}
