import * as taxReport from 'api/app/depreciationFixedAsset'
import { AxiosResponse } from 'axios'
import { call, put, take, takeLatest } from 'redux-saga/effects'
import { showErrorsAppCall } from 'store/app/actions'
import { parseError } from 'utils/ResponseHelper'

import {
  ExportTaxDeclarationReports,
  ExportTaxDeclarationReportsFailure,
  ExportTaxDeclarationReportsSuccess,
  FetchTaxDeclarationDecreaseReport,
  FetchTaxDeclarationDecreaseReportFailure,
  FetchTaxDeclarationIncreaseReport,
  FetchTaxDeclarationIncreaseReportFailure,
  FetchTaxDeclarationReportList,
  FetchTaxDeclarationReportListFailure,
  FetchTaxDeclarationSummaryReport,
  FetchTaxDeclarationSummaryReportFailure,
} from './actions.model'
import {
  EXPORT_TAX_DECLARATION_REPORTS,
  EXPORT_TAX_DECLARATION_REPORTS_FAILURE,
  EXPORT_TAX_DECLARATION_REPORTS_SUCCESS,
  FETCH_TAX_DECLARATION_DECREASE_REPORT,
  FETCH_TAX_DECLARATION_DECREASE_REPORT_FAILURE,
  FETCH_TAX_DECLARATION_DECREASE_REPORT_SUCCESS,
  FETCH_TAX_DECLARATION_INCREASE_REPORT,
  FETCH_TAX_DECLARATION_INCREASE_REPORT_FAILURE,
  FETCH_TAX_DECLARATION_INCREASE_REPORT_SUCCESS,
  FETCH_TAX_DECLARATION_REPORT_LIST,
  FETCH_TAX_DECLARATION_REPORT_LIST_FAILURE,
  FETCH_TAX_DECLARATION_REPORT_LIST_SUCCESS,
  FETCH_TAX_DECLARATION_SUMMARY_REPORT,
  FETCH_TAX_DECLARATION_SUMMARY_REPORT_FAILURE,
  FETCH_TAX_DECLARATION_SUMMARY_REPORT_SUCCESS,
  ON_WEBSOCKET_MESSAGE,
} from './constant'

export function* fetchTaxDeclarationReportList({ payload }: FetchTaxDeclarationReportList) {
  const { year } = payload

  try {
    const response: AxiosResponse = yield call(taxReport.getTaxDeclarationReportList, year)

    yield put({ type: FETCH_TAX_DECLARATION_REPORT_LIST_SUCCESS, payload: response?.data?.data })
  } catch (error) {
    yield put<FetchTaxDeclarationReportListFailure>({
      type: FETCH_TAX_DECLARATION_REPORT_LIST_FAILURE,
      error,
    })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchTaxDeclarationSummaryReport({ payload }: FetchTaxDeclarationSummaryReport) {
  try {
    const response: AxiosResponse = yield call(taxReport.getTaxDeclarationSummaryReport, payload)
    yield put({ type: FETCH_TAX_DECLARATION_SUMMARY_REPORT_SUCCESS, payload: response?.data?.data })
  } catch (error) {
    yield put<FetchTaxDeclarationSummaryReportFailure>({
      type: FETCH_TAX_DECLARATION_SUMMARY_REPORT_FAILURE,
      error,
    })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchTaxDeclarationIncreaseReport({ payload }: FetchTaxDeclarationIncreaseReport) {
  try {
    const response: AxiosResponse = yield call(taxReport.getTaxDeclarationIncreaseReport, payload)
    const { data } = response
    const totalRecord = data?.meta?.total

    yield put({ type: FETCH_TAX_DECLARATION_INCREASE_REPORT_SUCCESS, payload: { ...data?.data, totalRecord } })
  } catch (error) {
    yield put<FetchTaxDeclarationIncreaseReportFailure>({
      type: FETCH_TAX_DECLARATION_INCREASE_REPORT_FAILURE,
      error,
    })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchTaxDeclarationDecreaseReport({ payload }: FetchTaxDeclarationDecreaseReport) {
  try {
    const response: AxiosResponse = yield call(taxReport.getTaxDeclarationDecreaseReport, payload)
    const { data } = response
    const totalRecord = data?.meta?.total

    yield put({ type: FETCH_TAX_DECLARATION_DECREASE_REPORT_SUCCESS, payload: { ...data?.data, totalRecord } })
  } catch (error) {
    yield put<FetchTaxDeclarationDecreaseReportFailure>({
      type: FETCH_TAX_DECLARATION_DECREASE_REPORT_FAILURE,
      error,
    })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* exportTaxDeclarationReports({ payload, meta }: ExportTaxDeclarationReports) {
  try {
    const response: AxiosResponse = yield call(taxReport.exportTaxDeclarationReports, payload)
    const exportRequestUuid = response.data.data.uuid
    while (true) {
      const action: Record<string, any> = yield take(ON_WEBSOCKET_MESSAGE)
      const { uuid, data, error } = action?.payload?.message || {}
      // eslint-disable-next-line no-continue
      if (exportRequestUuid !== uuid) continue

      if (data) {
        yield put<ExportTaxDeclarationReportsSuccess>({
          type: EXPORT_TAX_DECLARATION_REPORTS_SUCCESS,
          payload: data,
          meta,
        })
      } else {
        yield put<ExportTaxDeclarationReportsFailure>({
          type: EXPORT_TAX_DECLARATION_REPORTS_FAILURE,
          payload: error,
          meta,
          error: true,
        })
      }
    }
  } catch (error) {
    yield put<ExportTaxDeclarationReportsFailure>({
      type: EXPORT_TAX_DECLARATION_REPORTS_FAILURE,
      meta,
      error: true,
    })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_TAX_DECLARATION_SUMMARY_REPORT, fetchTaxDeclarationSummaryReport)
  yield takeLatest(FETCH_TAX_DECLARATION_REPORT_LIST, fetchTaxDeclarationReportList)
  yield takeLatest(FETCH_TAX_DECLARATION_INCREASE_REPORT, fetchTaxDeclarationIncreaseReport)
  yield takeLatest(FETCH_TAX_DECLARATION_DECREASE_REPORT, fetchTaxDeclarationDecreaseReport)
  yield takeLatest(EXPORT_TAX_DECLARATION_REPORTS, exportTaxDeclarationReports)
}
