import styled from '@emotion/styled'

export const ListWrapper = styled.div`
  margin: 15px 0;

  .first {
    width: 100px;
  }

  .link {
    color: #3b7de9;
    cursor: pointer;
  }
`

export const Modal = styled.div`
  .ant-spin-dot {
    color: #3b7de9;
  }

  .ant-spin-container {
    max-height: 500px;
    overflow-y: scroll;
  }
`
