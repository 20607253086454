import { DEPRECIATION_METHOD_CODE } from 'constants/masterData'
import { AssetLedger } from 'models/asset'
import React from 'react'

import {
  DepreciationComparisonTable,
  NewDecliningBalanceMethodTable,
  NoDepreciationTable,
  TableTemplate,
  TableTemplate2,
  TableTemplate3,
} from './tables'

interface IAmortizationInfoTable {
  ledger: AssetLedger | null
  depreciationRate: any
  isShowComparisonTable: boolean
  biid: string | undefined
}

const AmortizationInfoTable = ({ ledger, depreciationRate, isShowComparisonTable, biid }: IAmortizationInfoTable) => {
  const renderTable = () => {
    switch (ledger?.depreciation_method?.code) {
      case DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE:
      case DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE:
      case DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE:
      case DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET:
        return <TableTemplate ledger={ledger} depreciationRate={depreciationRate} />
      case DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250:
      case DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200:
        return <NewDecliningBalanceMethodTable ledger={ledger} depreciationRate={depreciationRate} />
      case DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD:
        return <TableTemplate2 ledger={ledger} depreciationRate={depreciationRate} />
      case DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT:
      case DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD:
      case DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE:
        return <TableTemplate3 ledger={ledger} depreciationRate={depreciationRate} />
      case DEPRECIATION_METHOD_CODE.NON_DEPRECIATION:
      case DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE:
        return <NoDepreciationTable ledger={ledger} />
      default:
        return null
    }
  }

  if (isShowComparisonTable) {
    return <DepreciationComparisonTable biid={biid} />
  }

  return renderTable()
}

export default AmortizationInfoTable
