/* eslint-disable react/display-name */
import { Button } from 'antd'
import { REGEX_FORMATS } from 'constants/regexs'
import React from 'react'
import processString from 'react-process-string'

export const renderComment = (textInput: string) => {
  return processString([
    {
      regex: REGEX_FORMATS.MENTION_USER,
      fn: (key: number, result: string) => {
        return (
          <Button type="link" style={{ padding: 0, height: 'auto' }} key={key}>
            {result[1]}
          </Button>
        )
      },
    },
    {
      regex: REGEX_FORMATS.URL,
      fn: (key: number, result: string) => {
        return (
          <a target="_blank" rel="noopener noreferrer" key={key} href={result[1]}>
            {result[1]}
          </a>
        )
      },
    },
    {
      regex: REGEX_FORMATS.BREAK_LINE,
      fn: (key: number) => {
        return <br key={key} />
      },
    },
  ])(textInput)
}
