import history from 'browserHistory'
import { put, select, takeLatest } from 'redux-saga/effects'
import { FETCH_MASTER_DATA_REQUEST } from 'store/masterData/actions'

import { ChangeLocation, RedirectUrlRequest } from './action.model'
import {
  CHANGE_LOCATION,
  INIT_APP_CHECK_PROGRESS,
  INIT_APP_FINISH,
  INIT_APP_START,
  REDIRECT_URL,
  setAppMessage,
  SHOW_ERRORS_APP_CALL,
} from './actions'

export const selector = {
  getMasterData: (state: any) => state.get('masterData') as Record<string, any>,
}

export function* initApp() {
  yield put({ type: FETCH_MASTER_DATA_REQUEST })
}

export function* initAppCheckProgress() {
  const masterDataState: ReturnType<typeof selector.getMasterData> = yield select(selector.getMasterData)

  if (!masterDataState.loading) {
    yield put({ type: INIT_APP_FINISH })
  }
}

export function* changeLocation({ payload }: ChangeLocation) {
  yield history.push(payload)
}

export function* redirectUrl({ payload }: RedirectUrlRequest) {
  yield (window.location.href = payload)
}

export function* showErrorsApp({ payload }: any) {
  const errors = payload?.errors || payload || []
  for (const error of errors) {
    yield put(
      setAppMessage({
        type: 'failure',
        content: error.detail,
      })
    )
  }
}

export default function* actionWatcher() {
  yield takeLatest(INIT_APP_START, initApp)
  yield takeLatest(INIT_APP_CHECK_PROGRESS, initAppCheckProgress)
  yield takeLatest(CHANGE_LOCATION, changeLocation)
  yield takeLatest(REDIRECT_URL, redirectUrl)
  yield takeLatest(SHOW_ERRORS_APP_CALL, showErrorsApp)
}
