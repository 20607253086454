import { IMasterData } from 'models/masterData'

export const getAcquireCategoryName = (masterData: IMasterData, code: number) => {
  if (!masterData) return ''

  const selectedItem = masterData.acquire_category.find(item => item.code === code)

  return selectedItem ? selectedItem.name_jp : ''
}

export const getAssetTypeForDepreciablePropertyTax = (masterData: IMasterData, code: number) => {
  if (!masterData) return ''

  const selectedItem = masterData.asset_type_for_depreciable_property_tax.find(item => item.code === code)

  return selectedItem ? selectedItem.name_jp : ''
}

export const getAssetTypeForCorporateTax = (masterData: IMasterData, code: number) => {
  if (!masterData) return ''

  const selectedItem = masterData.asset_type_for_corporate_tax.find(item => item.code === code)

  return selectedItem ? selectedItem.name_jp : ''
}
