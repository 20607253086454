import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { DatePicker } from 'antd'
import { YEAR_MONTH_FORMAT } from 'constants/app'
import { getIn, useFormikContext } from 'formik'
import { get } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { Wrapper } from './styles'

type Props = {
  placeholder: string
  monthField: string
  yearField: string
}

const YearMonthSelect = ({ placeholder, monthField, yearField }: Props) => {
  const { values, errors, setFieldValue } = useFormikContext()
  const [value, setValue] = useState(null)

  let isInValid = null

  if (get(errors, yearField) || get(errors, monthField)) {
    isInValid = true
  }

  const handleChange = (value: any) => {
    setValue(value)
    setFieldValue(monthField, value?.month() + 1) // eslint-disable-line no-unsafe-optional-chaining
    setFieldValue(yearField, value?.year())
  }

  useEffect(() => {
    const yearValue = getIn(values, yearField)
    const monthValue = getIn(values, monthField)

    if (yearValue && monthValue) {
      const timeValue: any = moment()
        .year(yearValue)
        .month(monthValue - 1)
      setValue(timeValue)
    }
  }, []) // eslint-disable-line

  return (
    <Wrapper>
      <DatePicker
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        className={isInValid ? 'error' : ''}
        picker="month"
        format={YEAR_MONTH_FORMAT}
      />
      {isInValid && <CommonErrorMsg>{get(errors, yearField) || get(errors, monthField)}</CommonErrorMsg>}
    </Wrapper>
  )
}

export default YearMonthSelect
