import { ASSET_CHANGE_SITUATION_TYPES, loadNs, yenWithoutSymbol } from 'aa_common/front-end'
import { ColumnType } from 'antd/es/table'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { DataTypes } from 'models/depreciation'
import {
  AssetCategoryObject,
  AssetObject,
  AssetTypeObject,
  EventAmount,
  MoneyUnits,
} from 'models/increaseDecreaseReport'
import React from 'react'

import { Link } from './styles'

const t = loadNs(['pages/accounting/increase-decrease-report'])
const parseMoney = (value: number, unit: MoneyUnits) => {
  if (unit === MoneyUnits.THOUSAND_YEN) {
    value = Math.floor(value / 1000)
  }

  return yenWithoutSymbol(value)
}

const createDefaultTableData = (columns: ColumnType<any>[]) => {
  const result: any = {}

  columns.forEach(column => {
    result[column.dataIndex as string] = yenWithoutSymbol(0)
  })

  return result
}

const getTotalSum = (changeSituationEvents: EventAmount[]) => {
  return (changeSituationEvents || [])
    .map(item => item.value)
    .reduce((prevValue, currentValue) => prevValue + currentValue, 0)
}

const getSumByChangeSituation = (
  changeSituationEvents: EventAmount[],
  changeSituation: ASSET_CHANGE_SITUATION_TYPES
) => {
  return (changeSituationEvents || [])
    .filter(item => item.change_situation_code === changeSituation)
    .map(item => item.value)
    .reduce((prevValue, currentValue) => prevValue + currentValue, 0)
}

const getDataColumns = (
  asset: AssetObject | AssetCategoryObject | AssetTypeObject,
  unit: MoneyUnits,
  isAccountant: boolean
) => {
  const record: any = {}

  const acquisition = getSumByChangeSituation(asset.amount.increases, ASSET_CHANGE_SITUATION_TYPES.ACQUISITION)
  record.increase_acquisition = parseMoney(acquisition, unit)

  const increaseSwitchCategory = getSumByChangeSituation(asset.amount.increases, ASSET_CHANGE_SITUATION_TYPES.SWITCH)
  record.increase_switch_category = parseMoney(increaseSwitchCategory, unit)

  const increaseOther = getTotalSum(asset.amount.increases) - acquisition - increaseSwitchCategory
  record.increase_other = parseMoney(increaseOther, unit)

  const decreaseRetirement = getSumByChangeSituation(asset.amount.decreases, ASSET_CHANGE_SITUATION_TYPES.RETIREMENT)
  record.decrease_retirement = parseMoney(decreaseRetirement, unit)

  const decreaseSell = getSumByChangeSituation(asset.amount.decreases, ASSET_CHANGE_SITUATION_TYPES.SELL)
  record.decrease_sell = parseMoney(decreaseSell, unit)

  const decreaseImpair = isAccountant
    ? getSumByChangeSituation(asset.amount.decreases, ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT)
    : 0

  if (isAccountant) {
    record.decrease_impairment = parseMoney(decreaseImpair, unit)
    record.accumulated_impaired_amount = parseMoney(asset.amount.accumulated_impaired_amount, unit)
  }

  const decreaseSwitchCategory = getSumByChangeSituation(asset.amount.decreases, ASSET_CHANGE_SITUATION_TYPES.SWITCH)
  record.decrease_switch_category = parseMoney(decreaseSwitchCategory, unit)

  const decreaseOther =
    getTotalSum(asset.amount.decreases) - (decreaseRetirement + decreaseSell + decreaseSwitchCategory + decreaseImpair)
  record.decrease_other = parseMoney(decreaseOther, unit)

  record.acquisition_cost = parseMoney(asset.amount.acquisition_cost, unit)
  record.accounting_previous_book_value = parseMoney(asset.amount.accounting_previous_book_value, unit)
  record.depreciation_amount = parseMoney(asset.amount.depreciation_amount, unit)
  record.accounting_current_book_value = parseMoney(asset.amount.accounting_current_book_value, unit)
  record.accumulated_depreciation_amount = parseMoney(asset.amount.accumulated_depreciation_amount, unit)

  return record
}

const parseDataForAsset = (
  columns: ColumnType<any>[],
  data: AssetObject[],
  unit: MoneyUnits,
  isAccountant: boolean
) => {
  const result: any = []
  let record: any = null

  data.forEach(asset => {
    record = {
      ...createDefaultTableData(columns),
      key: `asset-${asset.biid}`,
      biid: asset.biid,
      name: `${asset.code}-${asset.branch_code || ''} ${asset.name}`,
      service_life: asset.service_life,
      usage_started_at: asset.usage_started_at,
      depreciation_method: asset.depreciation_method,
      is_deleted: asset.is_deleted,
      type: DataTypes.ASSET,
      ...getDataColumns(asset, unit, isAccountant),
    }

    result.push(record)
  })

  return result
}

const parseDataForAssetCategory = (
  columns: ColumnType<any>[],
  data: AssetCategoryObject[],
  unit: MoneyUnits,
  isAccountant: boolean
) => {
  const result: any = []
  let record: any = null
  data.forEach(assetCategory => {
    record = {
      ...createDefaultTableData(columns),
      key: `asset-category-${assetCategory.asset_category_code}`,
      name: assetCategory.asset_category_name,
      type: DataTypes.ASSET_CATEGORY,
      ...getDataColumns(assetCategory, unit, isAccountant),
    }

    if (assetCategory.items) {
      record.children = parseDataForAsset(columns, assetCategory.items, unit, isAccountant)
    }

    result.push(record)
  })

  return result
}

const parseDataForAssetType = (
  columns: ColumnType<any>[],
  data: AssetTypeObject[],
  unit: MoneyUnits,
  isAccountant: boolean
) => {
  const result: any = []
  let record: any = null

  data.forEach((assetType: AssetTypeObject) => {
    record = {
      ...createDefaultTableData(columns),
      key: 'asset-type',
      name: assetType.type,
      type: DataTypes.ASSET_TYPE,
      ...getDataColumns(assetType, unit, isAccountant),
    }

    if (assetType.items) {
      record.children = parseDataForAssetCategory(columns, assetType.items, unit, isAccountant)
    }

    result.push(record)
  })

  return result
}

const renderIn18Info = (key: string, hasPrefix: boolean) => t(`report.${(hasPrefix ? 'current_month.' : '') + key}`)

const getDescription = (hasPrefix: boolean, key?: string) => {
  if (key) {
    const description = renderIn18Info(key, hasPrefix)
    return `\n(${description})`
  }
  return ''
}

const getColumnTitle = (isMonthFilter: boolean, hasPrefix: boolean, key1: string, key2?: string) => {
  const keyPeriod = `${key1}_period`
  return isMonthFilter
    ? `${renderIn18Info(key1, hasPrefix)} ${getDescription(hasPrefix, key2)}`
    : `${renderIn18Info(keyPeriod, hasPrefix)} ${getDescription(hasPrefix, key2)}`
}

const createColumn = (title: string, dataIndex: string, width: number = 120) => ({
  title,
  dataIndex,
  width,
})

export const buildColumns = (isMonthFilter: boolean, isAccountant: boolean) => {
  const columns: ColumnType<any>[] = [
    {
      title: '',
      dataIndex: 'name',
      width: 280,
      fixed: 'left',
      render: function renderDetail(value: string, record: any) {
        if (record.type === DataTypes.ASSET) {
          return (
            <Link
              aria-disabled={!!record.is_deleted}
              className={classNames({ disabled: record.is_deleted })}
              to={`/assets/${record.biid}`}
            >
              {record.code || ''} {record.name}
            </Link>
          )
        }

        return value
      },
    },
    createColumn(renderIn18Info('acquisition_cost', false), 'acquisition_cost'),
    createColumn(getColumnTitle(isMonthFilter, false, 'prev_month_end_book_value'), 'accounting_previous_book_value'),
    createColumn(getColumnTitle(isMonthFilter, true, 'increase', 'acquisition'), 'increase_acquisition'),
    createColumn(getColumnTitle(isMonthFilter, true, 'increase', 'other'), 'increase_other'),
    createColumn(getColumnTitle(isMonthFilter, true, 'decrease', 'retirement'), 'decrease_retirement'),
    createColumn(getColumnTitle(isMonthFilter, true, 'decrease', 'sell'), 'decrease_sell'),
    createColumn(getColumnTitle(isMonthFilter, true, 'decrease', 'other'), 'decrease_other'),
    createColumn(getColumnTitle(isMonthFilter, true, 'depreciation'), 'depreciation_amount'),
    createColumn(getColumnTitle(isMonthFilter, false, 'current_month_end_book_value'), 'accounting_current_book_value'),
    createColumn(renderIn18Info('accumulated_depreciation', false), 'accumulated_depreciation_amount'),
  ]

  if (isAccountant) {
    columns.splice(
      7,
      0,
      createColumn(getColumnTitle(isMonthFilter, true, 'decrease', 'impairment'), 'decrease_impairment')
    )
    columns.push(createColumn(renderIn18Info('accumulated_impairment', false), 'accumulated_impaired_amount'))
  }

  return columns
}

export const parseReportData = (data: any, unit: MoneyUnits, isMonthFilter: boolean): any[] => {
  if (isEmpty(data)) return []
  const isAccountant = data?.ledger_setting?.accounting_journal_target
  const columns = buildColumns(isMonthFilter, isAccountant)
  return parseDataForAssetType(columns, data.items, unit, isAccountant)
}
