import { parseError, parseResponse } from 'utils/ResponseHelper'

import base from './base'

export const getAutoGenerateSettings = () => {
  return base
    .get('/v1/auto_generating_serial_number_settings')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const getAutoGenerateSettingsAuth = () => {
  return base
    .get('/v1/auto_generating_serial_number_settings/get')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const createAutoGenerateSetting = (data: any) => {
  return base.post('/v1/auto_generating_serial_number_settings', data)
}

export const deleteAutoGenerateSetting = (id: number) => {
  return base.delete(`/v1/auto_generating_serial_number_settings/${id}`)
}

export const getInitialNumberSetting = (id: number) => {
  return base.get(`/v1/auto_generating_serial_number_settings/${id}`)
}

export const saveInitialNumberSetting = (id: number, data: any) => {
  return base.post(`/v1/auto_generating_serial_number_settings/${id}/save`, data)
}
