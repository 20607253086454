import styled from '@emotion/styled'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Button } from 'antd'
import React from 'react'

const t = loadNs(['components/molecules/filter-panel', 'components/organisms/assets/asset-list'])

interface IDatepickerFooterTemplate {
  onTodayClick: () => void
  onFinalDateOfProcessingMonthClick: () => void
}

const DatepickerFooterTemplate = ({ onTodayClick, onFinalDateOfProcessingMonthClick }: IDatepickerFooterTemplate) => {
  return (
    <DatepickerFooter>
      <DatepickerFooterActionItem>
        <Button type="link" onClick={onTodayClick}>
          {t('datepicker_options.today')}
        </Button>
      </DatepickerFooterActionItem>
      <DatepickerFooterActionItem>
        <Button type="link" onClick={onFinalDateOfProcessingMonthClick}>
          {t('datepicker_options.final_date_of_processing_month')}
        </Button>
      </DatepickerFooterActionItem>
    </DatepickerFooter>
  )
}

export default DatepickerFooterTemplate

const DatepickerFooter = styled.div`
  display: flex;
  flex-direction: column;
`

const DatepickerFooterActionItem = styled.div`
  border-bottom: 1px solid #f0f0f0;
  text-align: center;

  &:last-of-type {
    border-bottom: none;
  }
`
