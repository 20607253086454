import styled from '@emotion/styled'
import { BLUE_COLOR, GREY_BORDER_COLOR } from 'constants/styles'

export const MonthList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  width: 100%;

  .processing-tooltip {
    .ant-tooltip-inner {
      padding: 10px;
      border-radius: 4px;
    }
  }
`

export const MonthListItem = styled.li`
  color: white;
  padding: 2px 0;
  font-size: 13px;

  .text-label {
    width: 18px;
    text-align: center;
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.before-depreciation {
    background: ${GREY_BORDER_COLOR};
    border: 1px solid ${GREY_BORDER_COLOR};
  }
  &.select {
    background: ${BLUE_COLOR};
    border: 1px solid ${BLUE_COLOR};
  }
  &.current {
    background: white;
    color: ${BLUE_COLOR};
    border: 1px solid ${BLUE_COLOR};
  }
  &.unselect {
    background: #7c8291;
    border: 1px solid #7c8291;
  }
`
