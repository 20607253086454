import { CaretDownOutlined, CloseCircleFilled } from '@ant-design/icons'
import classNames from 'classnames'
import { INFINITE_SCROLL_PAGE_SIZE } from 'constants/pagination'
import { find, findIndex, flatten, get } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { QueryClient, QueryClientProvider, useInfiniteQuery } from 'react-query'
import ReactSelect, { components, InputActionMeta } from 'react-select'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { calculatePaginationTotalPages } from 'utils/ResponseHelper'
import { v4 as uuid } from 'uuid'

import Control from './components/Control'
import CustomMenuList from './components/MenuList'
import Option from './components/Option'
import SingleValue from './components/SingleValue'
import InfiniteScrollContext from './context'
import { BaseInfiniteScrollSelectProps, IInfiniteScrollContext } from './model'
import { DefaultClass } from './styles'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 60 * 1000,
    },
  },
})
const DEBOUNCE_INPUT_INTERVAL = 1000

const useComponentReactSelect = (handleOnBottomScrollReached: any, menuListRef: any) => {
  const componentReactSelect = useMemo<Partial<SelectComponents<any, false, any>> | undefined>(
    () => ({
      DropdownIndicator: props => {
        return (
          <components.DropdownIndicator {...props}>
            <CaretDownOutlined style={{ fontSize: 12 }} />
          </components.DropdownIndicator>
        )
      },
      ClearIndicator: props => {
        return (
          <components.ClearIndicator {...props}>
            <CloseCircleFilled />
          </components.ClearIndicator>
        )
      },
      Menu: props => {
        return <components.Menu {...props} className="menu" />
      },
      MenuList: props => {
        return (
          <CustomMenuList ref={menuListRef} menuListProps={props} onBottomScrollReach={handleOnBottomScrollReached} />
        )
      },
      Control,
      SingleValue,
      Option,
    }),
    [handleOnBottomScrollReached]
  )
  return { componentReactSelect }
}

const CoreInfiniteScrollSelect = ({
  name,
  mappingOptions,
  value: controlledValue,
  additionalQueries,
  valueField,
  textField,
  executeInfiniteScroll,
  pageSize = INFINITE_SCROLL_PAGE_SIZE,
  renderFooter,
  onChange,
  className,
  placeholder = '',
  disabled,
}: BaseInfiniteScrollSelectProps) => {
  const [pageInfo, setPageInfo] = useState({ page: 1, totalPages: 0, totalRecords: 0 })
  const [value, setValue] = useState<any>(null)
  const [inputValue, setInputValue] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const selectRef = useRef<any>(null)
  const menuListRef = useRef<any>(null)

  const uniqueId = useMemo(() => {
    return `infinite-scroll-${uuid().slice(0, 8)}`
  }, [])

  useEffect(() => {
    return () => {
      queryClient.clear()
    }
  }, [])
  useEffect(() => {
    setValue(controlledValue)
  }, [controlledValue])

  const [debounceInputValue] = useDebounce(inputValue.trim(), DEBOUNCE_INPUT_INTERVAL)

  const debouncedOnInputChanged = useDebouncedCallback((inputValue, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      menuListRef.current?.scrollTo(0)
    }
  }, DEBOUNCE_INPUT_INTERVAL)

  const { isLoading, isFetching, isRefetching, hasNextPage, data, refetch, fetchNextPage } = useInfiniteQuery(
    [`${name}`, { textSearch: debounceInputValue, additionalQueries }],
    async ({ pageParam = 1, queryKey }) => {
      const textSearch = get(queryKey, '1.textSearch')
      const additionalQueries = get(queryKey, '1.additionalQueries')
      const result =
        typeof executeInfiniteScroll === 'function'
          ? await executeInfiniteScroll({ page: pageParam, textSearch, pageSize, additionalQueries })
          : executeInfiniteScroll
      const totalRecords = Number(get(result, 'data.meta.total', 0))
      const totalPages = calculatePaginationTotalPages(totalRecords, pageSize)

      const pageInfo = {
        page: pageParam,
        totalPages,
        totalRecords,
      }

      setPageInfo(pageInfo)
      return {
        ...result,
        ...pageInfo,
      }
    },
    {
      getNextPageParam: lastPage => {
        const { page, totalPages } = lastPage
        return page < totalPages ? page + 1 : undefined
      },
    }
  )

  const renderCustomFooter = useMemo(() => {
    return renderFooter && renderFooter({ refetch, isLoading, isFetching, isRefetching })
  }, [refetch, isLoading, isFetching, isRefetching, renderFooter])

  const allLoadedOptions = useMemo(() => {
    if (data) {
      const flattenOptions = flatten(data.pages.map(page => get(page, 'data.data', [])))
      return flattenOptions
    }
    return []
  }, [data])

  const options = useMemo(() => {
    if (allLoadedOptions.length > 0) {
      const result = allLoadedOptions.map(item => {
        if (mappingOptions) {
          return mappingOptions(item)
        }
        return {
          value: get(item, valueField),
          label: get(item, textField),
        }
      })

      return result
    }

    return []
  }, [allLoadedOptions, valueField, textField]) // eslint-disable-line

  const handleOnMenuOpen = () => {
    setIsMenuOpen(true)
    const focusedIndex = findIndex(options, item => item.value === value?.value)
    setTimeout(() => {
      menuListRef.current?.scrollToItem({ index: focusedIndex, align: 'center' })
    })
  }

  const handleOnMenuClose = () => {
    const menuEl = document.querySelector(`#${uniqueId} .menu`)
    const containerEl = menuEl?.parentElement
    const clonedMenuEl: any = menuEl?.cloneNode(true)

    if (!clonedMenuEl) return

    clonedMenuEl.classList.add('menu--close')
    clonedMenuEl.addEventListener('animationend', () => {
      containerEl?.removeChild(clonedMenuEl)
    })

    containerEl?.appendChild(clonedMenuEl)

    setTimeout(() => {
      setIsMenuOpen(false)
    }, 150)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      setTimeout(() => {
        if (selectRef.current) {
          const { focusedOption } = selectRef.current.state
          const loadedOptions: any[] = selectRef.current.props.options
          const focusedIndex = findIndex(loadedOptions, item => item.value === focusedOption?.value)
          menuListRef.current?.scrollToItem(focusedIndex)
        }
      }, 1)
    } else if (event.key === 'Enter') {
      const isInputFocused = selectRef.current.state.isFocused
      if (isInputFocused && !isMenuOpen) {
        event.preventDefault()
        // This code will focused the selected value when open by enter pressed
        selectRef.current.blur()
        selectRef.current.focus()
        setIsMenuOpen(true)
      }
    }
  }

  const handleOnBottomScrollReached = useCallback(
    (event: any) => {
      fetchNextPage({ pageParam: event.page })
    },
    [fetchNextPage]
  )

  const context: IInfiniteScrollContext = useMemo(() => {
    return {
      totalRecords: pageInfo.totalRecords,
      totalPages: pageInfo.totalPages,
      hasMoreRows: hasNextPage || false,
      isFetching,
      isRefetching,
      isLoading,
      data,
      pageSize,
      renderFooter: renderCustomFooter,
    }
  }, [pageInfo, renderCustomFooter, pageSize, data, isFetching, isRefetching, isLoading, hasNextPage])
  const { componentReactSelect } = useComponentReactSelect(handleOnBottomScrollReached, menuListRef)

  return (
    <InfiniteScrollContext.Provider value={context}>
      <ReactSelect
        name={name}
        id={uniqueId}
        inputId={`${name}-input`}
        ref={selectRef}
        className={classNames(DefaultClass, className, 'aa-infinite-scroll')}
        classNamePrefix="aa-infinite-scroll"
        menuIsOpen={isMenuOpen}
        onMenuOpen={handleOnMenuOpen}
        onMenuClose={handleOnMenuClose}
        openMenuOnFocus
        options={options}
        isClearable
        isDisabled={disabled}
        value={controlledValue || value}
        inputValue={inputValue}
        onInputChange={(value, actionMeta) => {
          setInputValue(value)
          debouncedOnInputChanged(value, actionMeta)
        }}
        placeholder={placeholder}
        components={componentReactSelect}
        onKeyDown={handleKeyDown}
        onChange={value => {
          setValue(value)
          const selectedOption = find(allLoadedOptions, item => get(item, valueField) === value?.value)
          onChange?.(value, selectedOption)
        }}
        filterOption={() => true}
      />
    </InfiniteScrollContext.Provider>
  )
}

const InfiniteScrollSelect = (props: BaseInfiniteScrollSelectProps) => (
  <QueryClientProvider client={queryClient}>
    <CoreInfiniteScrollSelect {...props} />
  </QueryClientProvider>
)

export default InfiniteScrollSelect
