import * as accountingPeriodApi from 'api/app/accountingPeriod'
import { AxiosResponse } from 'axios'
import { DATA_IN_USED_ERROR_CODE } from 'constants/apiCode'
import { SAGA_TIME_DELAY } from 'constants/const'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, delay, put, takeLatest } from 'redux-saga/effects'
import { changeLocation, SET_APP_MESSAGE, showErrorsAppCall } from 'store/app/actions'
import { parseError, parseValidationError } from 'utils/ResponseHelper'

import {
  CLEANUP_TERMS,
  CREATE_TERM_PERIODS_REQUEST,
  CREATE_TERM_PERIODS_SUCCESS,
  FETCH_CHANGED_TERM_FAILURE,
  FETCH_CHANGED_TERM_REQUEST,
  FETCH_CHANGED_TERM_SUCCESS,
  FETCH_TERM_FAILURE,
  FETCH_TERM_REQUEST,
  FETCH_TERM_SUCCESS,
  FETCH_TERMS_FAILURE,
  FETCH_TERMS_FULL_FAILURE,
  FETCH_TERMS_FULL_REQUEST,
  FETCH_TERMS_FULL_SUCCESS,
  FETCH_TERMS_REQUEST,
  FETCH_TERMS_SUCCESS,
  GENERATE_TERM_PERIODS_REQUEST,
  GENERATE_TERM_PERIODS_SUCCESS,
  SUBMIT_TERM_PERIODS_FAILURE,
  UPDATE_TERM_PERIOD_REQUEST,
  UPDATE_TERM_PERIOD_SUCCESS,
} from './actions'

const errorPageUrl = '/errors/500'

export function* fetchTerms() {
  try {
    const response: AxiosResponse = yield call(accountingPeriodApi.getTerms)
    if (typeof response !== 'object') {
      yield put(changeLocation(errorPageUrl))
    }
    yield put({ type: FETCH_TERMS_SUCCESS, payload: response.data.data || [] })
    yield delay(SAGA_TIME_DELAY)
  } catch (error) {
    yield put({ type: FETCH_TERMS_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchTermsFull() {
  try {
    const response: AxiosResponse = yield call(accountingPeriodApi.getFullTerms)
    const responseData = get(response, 'data.data', [])
    if (typeof response !== 'object') {
      yield put(changeLocation(errorPageUrl))
    }
    yield put({ type: FETCH_TERMS_FULL_SUCCESS, payload: responseData })
  } catch (error) {
    yield put({ type: FETCH_TERMS_FULL_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchTerm({ id, meta }: any) {
  try {
    const response: AxiosResponse = yield call(accountingPeriodApi.getTermById, id)
    yield put({ type: FETCH_TERM_SUCCESS, payload: response.data.data, meta })
  } catch (error) {
    yield put({ type: FETCH_TERM_FAILURE, meta, error: true })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchChangedTerm({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(accountingPeriodApi.getChangedTermPeriod, payload)
    yield put({ type: FETCH_CHANGED_TERM_SUCCESS, payload: response.data.data[0] })
  } catch (error) {
    yield put({ type: FETCH_CHANGED_TERM_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* generateTerms({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(accountingPeriodApi.generateTerms, payload)
    yield put({ type: GENERATE_TERM_PERIODS_SUCCESS, payload: response.data.data[0] })
  } catch (error) {
    const payload = parseValidationError(get(error, 'response.data.errors', undefined))

    yield put({ type: SUBMIT_TERM_PERIODS_FAILURE, payload })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createTerms({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(accountingPeriodApi.createTerms, payload)
    yield put({ type: CREATE_TERM_PERIODS_SUCCESS, payload: response.data.data[0] })
    yield put({ type: CLEANUP_TERMS })
    yield put({ type: FETCH_TERMS_REQUEST })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'success',
        content: i18n.t('components.AccountingPeriodForm.create_terms_success_msg'),
      },
    })
    yield put(changeLocation('/assets'))
  } catch (error) {
    const status = get(error, 'response.status')
    if (status === 422) {
      const payload = parseValidationError(get(error, 'response.data.errors', undefined))
      yield put({ type: SUBMIT_TERM_PERIODS_FAILURE, payload })
    } else {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export function* updateTermPeriod({ payload, meta }: any) {
  try {
    yield call(accountingPeriodApi.updateTermPeriod, payload)
    yield put({ type: UPDATE_TERM_PERIOD_SUCCESS })
    yield put({ type: FETCH_TERM_REQUEST, id: payload.term_id })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'success',
        content: i18n.t('components.AccountingPeriodForm.create_terms_success_msg'),
      },
      meta,
    })
  } catch (error: any) {
    const { status, data } = error.response
    let payload = null

    if (status === 400 && get(data, 'errors[0].code') === DATA_IN_USED_ERROR_CODE) {
      yield put({
        type: SET_APP_MESSAGE,
        payload: {
          type: 'failure',
          content: i18n.t('components.AccountingPeriodForm.editing_failure_msg'),
        },
      })
    } else {
      payload = parseValidationError(get(error, 'response.data.errors', undefined))
      yield put(showErrorsAppCall(parseError(error)))
    }

    yield put({ type: SUBMIT_TERM_PERIODS_FAILURE, payload, error: true, meta })
  }
}

export default function* () {
  yield takeLatest(FETCH_TERMS_REQUEST, fetchTerms)
  yield takeLatest(FETCH_TERMS_FULL_REQUEST, fetchTermsFull)
  yield takeLatest(FETCH_TERM_REQUEST, fetchTerm)
  yield takeLatest(FETCH_CHANGED_TERM_REQUEST, fetchChangedTerm)
  yield takeLatest(GENERATE_TERM_PERIODS_REQUEST, generateTerms)
  yield takeLatest(CREATE_TERM_PERIODS_REQUEST, createTerms)
  yield takeLatest(UPDATE_TERM_PERIOD_REQUEST, updateTermPeriod)
}
