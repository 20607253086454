import * as depreciationResultApi from 'api/app/depreciationResult'
import {
  exportMonthlyClosingDepreciationResultURL,
  getDepreciationResultReportMonthlyClosing,
} from 'api/app/monthlyClosing'
import { AxiosResponse } from 'axios'
import i18n from 'i18n'
import { call, put, takeLatest } from 'redux-saga/effects'
import { setAppMessage, showErrorsAppCall } from 'store/app/actions'
import { parseError } from 'utils/ResponseHelper'

import {
  EXPORT_DEPRECIATION_RESULT_REQUEST,
  FETCH_DEPRECIATION_RESULT_FAILURE,
  FETCH_DEPRECIATION_RESULT_REQUEST,
  FETCH_DEPRECIATION_RESULT_SUCCESS,
} from './actions'

export function* fetchResults({ payload, isInMonthlyClosing }: any) {
  try {
    const response: AxiosResponse = isInMonthlyClosing
      ? yield call(getDepreciationResultReportMonthlyClosing, payload)
      : yield call(depreciationResultApi.getResults, payload)

    yield put({ type: FETCH_DEPRECIATION_RESULT_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put({ type: FETCH_DEPRECIATION_RESULT_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* exportDepreciationResult({ payload, isInMonthlyClosing }: any) {
  const reportUrl = isInMonthlyClosing
    ? exportMonthlyClosingDepreciationResultURL(payload)
    : depreciationResultApi.exportDepreciationResultURL(payload)

  yield put(
    setAppMessage({
      type: 'success',
      content: i18n.t('common.messages.exported_success'),
    })
  )
  yield window.open(reportUrl, '_blank')
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_DEPRECIATION_RESULT_REQUEST, fetchResults)
  yield takeLatest(EXPORT_DEPRECIATION_RESULT_REQUEST, exportDepreciationResult)
}
