import Form, { RenderFormProps } from 'components/molecules/Form'
import isEqual from 'lodash/isEqual'
import { LedgerSettingsItem } from 'models/ledgerSetting'
import { Term } from 'models/term'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectorIncreaseDecreaseReport } from 'store/accounting/increaseDecreaseReport/selectors'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { selectTermData, selectTermsList } from 'store/settings/accountingPeriod/selectors'
import { selectorLedgerSettingsList } from 'store/settings/ledgerSetting/selectors'

import ReportTable from './components/ReportTable'
import { getConstant } from './constant'
import FormFilter from './Form'

interface IncreaseDecreaseReportProps {
  terms: Term[]
  ledgers: LedgerSettingsItem[]
}

const { MONEY_UNIT_OPTIONS } = getConstant()
const initFormValues = {
  term_year: null,
  ledger: null,
  month_serial_number: null,
  money_unit: MONEY_UNIT_OPTIONS[0].value,
}

const IncreaseDecreaseReport = ({ terms, ledgers }: IncreaseDecreaseReportProps) => {
  const [isMonthFilter, setIsMonthFilter] = useState<boolean>(true)

  const increaseDecreaseReport = useSelector(selectorIncreaseDecreaseReport, isEqual)
  const termsSelector = useSelector(selectTermsList, isEqual)
  const termPeriods = useSelector(selectTermData, isEqual)
  const ledgersSelector = useSelector(selectorLedgerSettingsList, isEqual)
  const currentMonthlyClosing = useSelector(selectCurrentMonthlyClosing, isEqual)

  const reportData = useMemo(() => increaseDecreaseReport.data, [increaseDecreaseReport.data])

  const isLoading =
    currentMonthlyClosing.loading ||
    termPeriods.loading ||
    termsSelector.loading ||
    ledgersSelector.loading ||
    increaseDecreaseReport.loading

  return (
    <Form value={initFormValues} loading={isLoading}>
      {(form: RenderFormProps) => {
        return (
          <>
            <FormFilter terms={terms} ledgers={ledgers} formModule={form} onMonthFilterChanged={setIsMonthFilter} />
            <ReportTable reportData={reportData} formModule={form} isMonthFilter={isMonthFilter} />
          </>
        )
      }}
    </Form>
  )
}

export default IncreaseDecreaseReport
