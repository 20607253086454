import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import CauseForm from 'components/organisms/settings/CauseForm'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cleanupCause, createCause, fetchCause } from 'store/settings/cause/actions'
import { selectCauseItem } from 'store/settings/cause/selectors'

const t = loadNs(['pages/settings/causes'])

const CauseCopyPage = () => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('copy')} />
  const { id } = useParams<any>()

  const dispatch = useDispatch()
  const causeItemSelector = useSelector(selectCauseItem, isEqual)

  useEffect(() => {
    dispatch(fetchCause(id))

    return function cleanup() {
      dispatch(cleanupCause())
    }
  }, [id]) // eslint-disable-line

  const handleSubmit = useCallback(
    (values: any) => {
      dispatch(createCause(values))
    },
    [dispatch]
  )

  const copyValues = useMemo(() => {
    let values = null
    if (causeItemSelector.data && typeof causeItemSelector.data === 'object') {
      values = { ...causeItemSelector.data, code: '' }
    }
    return values
  }, [causeItemSelector.data])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <CauseForm
        errors={causeItemSelector.errors}
        onSubmit={handleSubmit}
        loading={causeItemSelector.loading}
        values={copyValues}
      />
    </MainTemplate>
  )
}

export default CauseCopyPage
