import { LedgerSettingsItem } from 'models/ledgerSetting'
import { MasterDataItem } from 'models/masterData'
import { AreaDataItem } from 'store/settings/area/model'
import { DepartmentItem } from 'store/settings/department/model'

import { AssetCategory } from './assetCategory'
import { Excise } from './excise'
import { Tag } from './tag'
import { User } from './user'

type CommonSelectValue = {
  value: any
  label: string
}

type File = {
  filename: string
  id: string
  url: string
}

export type FixedAssetListItem = {
  acquire_category: MasterDataItem
  acquire_destination_name: string
  acquired_at: string
  acquisition_cost: number
  acquisition_cost_excluded_excise: number
  area: AreaDataItem
  area_biid: string
  asset_category: AssetCategory
  asset_detail_for_corporate_tax: string
  asset_status: MasterDataItem
  asset_structure_for_corporate_tax: string
  asset_type_for_corporate_tax: MasterDataItem
  asset_type_for_depreciable_property_tax: MasterDataItem
  biid: string
  branch_code: string
  calculated_disposal_excise_value: number | null
  calculated_excise_value: number | null
  calculated_sold_excise_value: number | null
  change_situation: MasterDataItem
  change_situations?: FixedAssetListItem[]
  code: string
  commentCount: number
  created_at: string
  department: DepartmentItem
  department_biid: string
  depreciation_method_1: string
  disposal_cost: number | null
  disposal_cost_excluded_excise: number | null
  disposal_destination_name: string | null
  disposal_excise_id: number
  event_cause: any
  excise_id: number
  exemption_denominator: number
  exemption_numerator: number
  fixed_asset_ledgers: AssetLedger[]
  id: number
  manual_disposal_excise_value: number | null
  manual_excise_value: number | null
  manual_sold_excise_value: number | null
  memo: string
  name: string
  name_kana: string
  photos: any[]
  pre_submit_number: string
  quantity: string
  retired_at: string | null
  service_life_1: number
  sold_amount: number
  sold_amount_excluded_excise: number | null
  sold_at: string | null
  sold_destination_name: string
  sold_excise_id: number | null
  summary_for_asset_depreciation_tax: string
  tags: Tag
  unit: number | null
  usage_started_at: string | null
  user_info: User
  valid_from: string
  valid_to: string
  is_excluded: boolean
}

export type Asset = {
  id?: number
  biid?: string
  name: string
  name_kana: string
  code: string
  branch_code: string
  asset_category_id?: CommonSelectValue | null
  asset_category?: any
  area_biid?: CommonSelectValue | null
  area?: any
  department_biid: CommonSelectValue | null
  department?: any
  acquired_at: string | null
  min_acquired_at?: string
  usage_started_at: string | null
  sold_at: string | null
  sold_amount?: number
  sold_amount_excluded_excise?: number
  asset_type?: any
  asset_type_for_depreciable_property_tax_code?: number | null
  asset_type_for_depreciable_property_tax: any
  asset_type_for_corporate_tax_code?: number | null
  asset_type_for_corporate_tax?: any
  asset_detail_for_corporate_tax: string | null
  asset_structure_for_corporate_tax: string | null
  acquisition_cost: number
  acquisition_cost_input?: number
  disposal_cost: number
  disposal_cost_excluded_excise?: number
  consumption_tax_category?: number
  consumption_tax_amount?: number
  excise_id?: number
  excise?: Excise
  calculated_excise_value?: number
  manual_excise_value?: number
  acquisition_cost_excluded_excise: number
  acquisition_cost_included_excise: number
  quantity: number
  unit_id?: number
  unit?: any
  acquire_category_code?: number
  acquire_category?: any
  acquire_destination_name: string
  pre_submit_number: string
  has_exemption?: boolean
  exemption_numerator: number
  exemption_denominator: number
  summary_for_asset_depreciation_tax: string
  photos?: any[]
  event_photos?: any[]
  attachments?: any[]
  event_attachments?: any[]
  urls?: any[]
  event_urls?: any[]
  tags: any[]
  tag_names?: string[]
  memo: string
  comment?: string
  retired_at: string | null
  user_info?: any
  event_cause?: any
  change_situation?: any
  change_situations?: Asset[]
  valid_from?: string
  created_at?: string
  index?: number
  is_error?: boolean
  usage_period_months?: number
  residual_amount?: number
  is_exported?: boolean
  is_linked?: boolean
  fixed_asset_ledgers: AssetLedger[]
  edited_at: any
  deleted_at?: string
  transition_at?: string
  has_transition: boolean
  term_month?: any
  generating_method?: number
  is_excluded?: boolean
  impaired_at: string | null
  accumulated_impaired_amount: number
  impaired_amount: number
  remaining_book_value: number
  divided_at?: string | null
  recognized_at?: string | null
}

export type AssetLedger = {
  ledger_setting_id?: number
  name?: string
  depreciation_method?: any
  depreciation_method_code: number | null
  service_life: number
  bulk_equal_installment_depreciation_method?: string | number | null
  accounting_beginning_book_initial_value: number | null
  transition_beginning_book_value: number | null
  memorandum_value: number | null
  retirement_depreciation_ended_method: number | null
  depreciation_rate?: number
  revised_depreciation_rate?: number
  residual_amount?: number
  guarantee_rate?: number
  ledger_setting?: LedgerSettingsItem
  is_copied?: boolean
  is_memorandum_value_entered?: boolean
}

export interface AssetLedgerReport extends AssetLedger {
  ledger_setting: LedgerSettingsItem
  accumulated_depreciation_amount: number
  loss: number
  gain: number
}

export interface AssetReport extends Asset {
  acquisition_cost_included_excise: number
  acquisition_cost_excluded_excise: number
  fixed_asset_ledgers: AssetLedgerReport[]
}

export interface IFilterFacetSettingsData {
  area: any[]
  assetCategory: AssetCategory[]
  department: any[]
  tag: Tag[]
}

export type RetirementFixedAssetLedger = {
  ledger_setting_id: number
  retirement_depreciation_ended_method?: number | null
  depreciation_ended_method?: number
}

export enum ASSET_ITEM_STATUS_PARAM_TYPE {
  TO_BE_ACQUIRED = 'to_be_acquired',
  RETIRED_OR_SOLD = 'retired_or_sold',
}

export type AssetSingleViewQueryParams = {
  valid_at: string
  type?: ASSET_ITEM_STATUS_PARAM_TYPE
}

export type PhotoType = File & { thumbnail_url: string }
export type AttachmentType = File & { mime_type: string }
