import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadNs } from 'aa_common/front-end/i18n-config'
import React from 'react'

import { PaginationButton, PaginationWrapper } from './styles'

interface TermsPagination {
  termPeriods: any[]
  keyValue: string
  keyName: string
  index: number
  setIndex: (index: number) => void
  onChange: (values: any) => void
}

const t = loadNs(['common'], 'format')
const Pagination = ({ index, termPeriods, keyValue, keyName, onChange, setIndex }: TermsPagination) => {
  const handleOnClick = (index: number) => {
    setIndex(index)
    onChange(termPeriods?.[index]?.[keyValue])
  }

  return (
    <>
      {termPeriods && (
        <PaginationWrapper>
          <PaginationButton className="prev" disabled={index === 0} onClick={() => handleOnClick(index - 1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </PaginationButton>
          <span>{t('term_month', { month: termPeriods?.[index]?.[keyName] })}</span>
          <PaginationButton
            className="next"
            disabled={index === termPeriods.length - 1}
            onClick={() => handleOnClick(index + 1)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </PaginationButton>
        </PaginationWrapper>
      )}
    </>
  )
}

export default Pagination
