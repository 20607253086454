import { yen } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { AssetLedger } from 'aa_common/front-end/models/asset'
import { DEPRECIATION_METHOD_CODE } from 'constants/masterData'
import { get } from 'lodash'
import React from 'react'

import { AmortizationTable } from '../styles'

interface ITableTemplate3 {
  ledger: AssetLedger | null
  depreciationRate: any
}
const t = loadNs(['components/organisms/assets/asset-detail'])

const TableTemplate3 = ({ ledger, depreciationRate }: ITableTemplate3) => {
  return (
    <AmortizationTable>
      <tbody>
        <tr>
          <th>{t('depreciation_type')}</th>
          <td>
            <span>{get(ledger, 'depreciation_method.name_jp', 'ー')}</span>
          </td>

          <th>{t('residual_value')}</th>
          <td>
            <span>{ledger?.residual_amount ? yen(ledger?.residual_amount) : 'ー'}</span>
          </td>
        </tr>
        <tr>
          <th>
            {ledger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT
              ? t('service_life')
              : t('usage_period_months')}
          </th>
          <td>
            <span>
              {ledger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT
                ? t('service_life_amount', {
                    amount: get(ledger, 'usage_period_months', 'ー') / 12,
                  })
                : get(ledger, 'usage_period_months', 'ー')}
            </span>
          </td>

          <th />
          <td />
        </tr>
        <tr>
          <th>{t('depreciation_rate')}</th>
          <td>
            <span>{`${depreciationRate || 'ー'}`}</span>
          </td>
          <th>{t('memorandum')}</th>
          <td>
            <span>{ledger?.memorandum_value ? yen(ledger?.memorandum_value) : 'ー'}</span>
          </td>
        </tr>
      </tbody>
    </AmortizationTable>
  )
}

export default TableTemplate3
