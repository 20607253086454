import { buildQueryFilterParams } from 'aa_common/front-end/helpers/request-helper'

import base from './base'

interface FilterPayload {
  depreciation_method: number
  service_life: number
}

export const get = ({ depreciation_method, service_life }: FilterPayload) => {
  return base.get('/v1/depreciation_rates', {
    params: {
      ...buildQueryFilterParams({ depreciation_method, service_life }),
    },
  })
}
