import { loadNs } from 'aa_common/front-end/i18n-config'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { MonthlyClosing } from 'models/monthlyClosing'
import moment from 'moment'
import React, { useRef } from 'react'

import { ConvertedDepreciationResultTermMonthItem } from '../DepreciationTable/model'
import { MonthList, MonthListItem } from './styles'

const t = loadNs(['components/organisms/assets/asset-detail'])

interface IMonthSimulation {
  months: ConvertedDepreciationResultTermMonthItem[]
  acquiredAt: string
  usageStartedAt: string | null
  currentMonthlyClosing: MonthlyClosing
}

const MonthSimulation = ({ months, usageStartedAt, acquiredAt, currentMonthlyClosing }: IMonthSimulation) => {
  const containerRef = useRef(null)

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
      <MonthList ref={containerRef}>
        {currentMonthlyClosing &&
          months?.map(month => {
            const current = moment(currentMonthlyClosing.from_term_month.start_date)
            const startDate = moment(month.start_date)
            const endDate = moment(month.end_date)
            const usageStartedAtDate = moment(usageStartedAt)
            const acquiredAtDate = moment(acquiredAt)

            const assetHasBeenAcquired =
              (acquiredAtDate.isBetween(startDate, endDate, 'date', '[]') &&
                usageStartedAtDate.isBetween(startDate, endDate, 'date', '[]') &&
                usageStartedAtDate.isBetween(acquiredAtDate, endDate, 'date', '[]')) ||
              startDate.isAfter(acquiredAtDate)
            const assetAcquiredButNotUsed =
              assetHasBeenAcquired &&
              startDate.isBetween(acquiredAtDate, usageStartedAtDate, 'date', '[]') &&
              endDate.isBetween(acquiredAtDate, usageStartedAtDate, 'date', '[]')

            const isNotDepreciationYet =
              startDate.isBefore(current, 'date') && (!assetHasBeenAcquired || assetAcquiredButNotUsed)
            const hasStartDepreciation = !isNotDepreciationYet
            const isActive =
              current.isBetween(startDate, endDate, 'date', '[]') &&
              !month.hasBeenSoldOrRetired &&
              !month.hasCompletedDepreciation
            const monthHasBeenAppliedDepreciation =
              hasStartDepreciation &&
              current.isAfter(startDate, 'date') &&
              current.isAfter(endDate, 'date') &&
              !month.hasBeenSoldOrRetired &&
              !month.hasCompletedDepreciation
            const toBeAppliedDepreciation = hasStartDepreciation && !monthHasBeenAppliedDepreciation

            let component = null
            if (isActive) {
              component = (
                <Tooltip
                  key={`${month.id}-${month.year}`}
                  placement="top"
                  title={t('depreciation_calculation_processing')}
                  arrowPointAtCenter
                  overlayClassName="processing-tooltip"
                  getPopupContainer={() => containerRef.current as any}
                >
                  <MonthListItem className="current">
                    <div className="text-label">{month.month}</div>
                  </MonthListItem>
                </Tooltip>
              )
            } else {
              component = (
                <MonthListItem
                  key={`${month.id}-${month.year}`}
                  className={classNames({
                    'before-depreciation': isNotDepreciationYet,
                    unselect: toBeAppliedDepreciation,
                    select: monthHasBeenAppliedDepreciation,
                  })}
                >
                  <div className="text-label">{month.month}</div>
                </MonthListItem>
              )
            }

            return component
          })}
      </MonthList>
    </div>
  )
}

export default MonthSimulation
