import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18n from 'i18n'
import { Office } from 'models/office'
import React from 'react'
import { UserInfo } from 'store/session/model'

interface Props {
  onReload: () => void
  userInfo: UserInfo
  currentOffice: Office
  endPoint: string
}

const ConnectAccPlusText = ({ onReload, userInfo, currentOffice, endPoint }: Props) => {
  const { REACT_APP_ACC_PLUS } = process.env

  const queryParams = new URLSearchParams({
    tenant_uid: `${currentOffice?.id}`,
    login_hint: encodeURI(userInfo?.email),
    redirect_path: encodeURI(endPoint),
  })

  const handleText = () => {
    switch (endPoint) {
      case '/items':
        return i18n.t('common.connect_acc_plus.account_item')
      case '/excises/list':
        return i18n.t('common.connect_acc_plus.excise')
      default:
    }
  }

  return (
    <div style={{ textAlign: 'right', marginBottom: 12 }}>
      <span>{`${handleText()}${i18n.t('common.connect_acc_plus.text_1')}`}</span>
      <a
        style={{ margin: '0 5px', color: '#3B7DE9' }}
        href={`${REACT_APP_ACC_PLUS}/sso?${queryParams.toString()}`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: 5 }} />
        {i18n.t('common.connect_acc_plus.text_2')}
      </a>
      <span>{i18n.t('common.connect_acc_plus.text_3')}</span>
      <span style={{ color: '#3B7DE9', cursor: 'pointer' }} onClick={onReload}>
        {i18n.t('common.connect_acc_plus.text_4')}
      </span>
      <span>{i18n.t('common.connect_acc_plus.text_5')}</span>
    </div>
  )
}

export default ConnectAccPlusText
