import * as assetApi from 'api/app/asset'
import { AxiosResponse } from 'axios'
import i18n from 'i18n'
import { get } from 'lodash'
import { Asset } from 'models/asset'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  CHANGE_LOCATION,
  changeLocation,
  SET_APP_MESSAGE,
  setAppMessage,
  setLoadingApp,
  showErrorsAppCall,
} from 'store/app/actions'
import { parseError, parseImportErrors } from 'utils/ResponseHelper'

import {
  CREATE_BULK_ASSET_LIST_REQUEST,
  CREATE_BULK_ASSET_LIST_SUCCESS,
  IMPORT_ASSET_LIST_FAILURE,
  IMPORT_ASSET_LIST_REQUEST,
  IMPORT_ASSET_LIST_SUCCESS,
  INITIALIZE_FIXED_ASSETS_REQUEST,
} from './actions'
import { parseAssetListForForm, parseAssetListForSubmit } from './helper'
import { selectAssetImportListData } from './selectors'

export function* importAssets({ charset, file }: any): Generator<any> {
  try {
    yield put(setLoadingApp(true))
    const response = (yield call(assetApi.postValidateCsv, charset, file)) as AxiosResponse
    const data = get(response, ['data', 'data'])

    yield put({ type: IMPORT_ASSET_LIST_SUCCESS, payload: parseAssetListForForm(data || []) })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.imported_success'),
      })
    )
    yield put(changeLocation('./import/results'))
    yield put(setLoadingApp(false))
  } catch (err: any) {
    const { status, data } = err?.response || {}

    if (status === 422 || status === 409) {
      const payload = {
        data: parseAssetListForForm(get(data, 'data')),
        errors: parseImportErrors(data)?.details,
      }

      yield put({ type: IMPORT_ASSET_LIST_FAILURE, payload })
      yield put(changeLocation('./import/results'))
    } else {
      yield put(showErrorsAppCall(parseError(err)))
    }
    yield put(setLoadingApp(false))
  }
}

export function* createBulkAssets(): Generator<any> {
  yield put(setLoadingApp(true))
  const importAssetList = (yield select(selectAssetImportListData)) as Asset[]
  try {
    yield call(assetApi.createBulk, parseAssetListForSubmit(importAssetList))

    yield put({ type: CREATE_BULK_ASSET_LIST_SUCCESS })
    yield put({ type: CHANGE_LOCATION, payload: '/assets' })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'success',
        content: i18n.t('common.messages.imported_success'),
      },
    })
  } catch (err: any) {
    const { status, data } = err.response

    if (status === 422 || status === 409) {
      const responseAssetList = get(data, 'data') || []
      responseAssetList.forEach((item: any) => {
        const { index, is_error } = item
        importAssetList[index].is_error = is_error
      })

      const payload = {
        data: [...importAssetList],
        errors: parseImportErrors(data).details,
      }

      yield put({ type: IMPORT_ASSET_LIST_FAILURE, payload })
    } else {
      yield put({
        type: SET_APP_MESSAGE,
        payload: {
          type: 'failure',
          content: parseImportErrors(data).details,
        },
      })
    }
  }
  yield put(setLoadingApp(false))
}

export function* initializeFixedAssets({ meta }: any) {
  const { data, errors } = yield call(assetApi.initializeFixedAssets)
  if (data) {
    yield put(
      setAppMessage(
        {
          type: 'success',
          content: i18n.t('common.messages.deleted_success'),
        },
        meta
      )
    )
    window.location.reload()
  } else if (errors) {
    yield put(showErrorsAppCall(errors, meta))
  }
}

export default function* actionWatcher() {
  yield takeLatest(IMPORT_ASSET_LIST_REQUEST, importAssets)
  yield takeLatest(CREATE_BULK_ASSET_LIST_REQUEST, createBulkAssets)
  yield takeLatest(INITIALIZE_FIXED_ASSETS_REQUEST, initializeFixedAssets)
}
