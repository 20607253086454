import { formatMonth } from 'aa_common/front-end/helpers/format-helper'
import i18n from 'i18n'
import groupBy from 'lodash/groupBy'
import { JournalEventTypesMap, JournalLinkageDetailData } from 'models/JournalData'
import React from 'react'

import { ErrorWrapper } from './styles'

enum SUMMARY_ERROR_TYPES {
  OUT_OF_TERM = 'out_of_term',
  CLOSED_MONTH = 'closed_month',
  PERMISSION_DENIED = 'permission_denied',
  NOT_FOUND = 'not_found',
  UNBALANCED = 'unbalanced',
  INVALID_PARAMETER = 'invalid_parameter',
  VALIDATION_FAILED = 'validation_failed',
  INTERNAL_SERVER_ERROR = 'internal_server_error',
}

/**
 * The helper help render single or summary error
 */
export const genErrorMessages = (journalRes: JournalLinkageDetailData) => {
  const { summaryErrorType, termMonth, totalFail } = journalRes || {}

  /**
   * SINGLE ERROR
   */
  switch (summaryErrorType) {
    case SUMMARY_ERROR_TYPES.OUT_OF_TERM:
    case SUMMARY_ERROR_TYPES.CLOSED_MONTH:
    case SUMMARY_ERROR_TYPES.PERMISSION_DENIED:
    case SUMMARY_ERROR_TYPES.NOT_FOUND:
      return (
        <ErrorWrapper>
          <p>
            {i18n.t(`pages.accounting.JournalList.err_messages.${summaryErrorType}`, {
              year: termMonth?.from_term_month.year,
              month: formatMonth(termMonth?.from_term_month.month || 0),
            })}
          </p>
        </ErrorWrapper>
      )
    default:
      break
  }

  /**
   * SUMMARY ERROR
   */
  if (totalFail) {
    const journalErrors = journalRes?.data
      ?.filter(journal => journal.error_type)
      .map(journal => ({
        key: JournalEventTypesMap[journal.journal_event_type!],
        msg: i18n.t(`pages.accounting.JournalList.err_messages.${journal.error_type}`, {
          slipNumber: journal.slip_number,
        }),
      }))
    const groupedErrors = groupBy(journalErrors, 'key')
    return (
      <ErrorWrapper>
        <p>{i18n.t('pages.accounting.JournalList.err_messages.total_fail', { totalFail })}</p>
        {Object.entries(groupedErrors).map(([key, errors]) => {
          const t1 = i18n.t(`common.journal_event.${key}`)
          return (
            <dl key={key}>
              <dt className="group-err-name">{`[${t1}]`}</dt>
              <dd>
                <ul className="group-err-list">
                  {errors.map(err => (
                    <li key={err.msg}>{err.msg}</li>
                  ))}
                </ul>
              </dd>
            </dl>
          )
        })}
      </ErrorWrapper>
    )
  }

  return null
}
