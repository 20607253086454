import * as assetCommentApi from 'api/app/assetComment'
import { AxiosResponse } from 'axios'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { showErrorsAppCall } from 'store/app/actions'
import { UPDATE_COMMENT_COUNT_REQUEST } from 'store/asset/constant'
import { parseError, parseValidationError } from 'utils/ResponseHelper'

import {
  CREATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST,
  CREATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS,
  CREATE_ASSET_COMMENT_REQUEST,
  CREATE_ASSET_COMMENT_SUCCESS,
  DELETE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST,
  DELETE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS,
  DELETE_ASSET_ITEM_COMMENT_REQUEST,
  DELETE_ASSET_ITEM_COMMENT_SUCCESS,
  FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_FAILURE,
  FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_REQUEST,
  FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_SUCCESS,
  FETCH_ASSET_ITEM_COMMENTS_FAILURE,
  FETCH_ASSET_ITEM_COMMENTS_REQUEST,
  FETCH_ASSET_ITEM_COMMENTS_SUCCESS,
  SUBMIT_ASSET_COMMENT_FAILURE,
  UPDATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST,
  UPDATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS,
  UPDATE_ASSET_ITEM_COMMENT_REQUEST,
  UPDATE_ASSET_ITEM_COMMENT_SUCCESS,
} from './constant'

export function* fetchAssetItemComments({ assetBiid }: any) {
  try {
    const response: AxiosResponse = yield call(assetCommentApi.list, assetBiid)
    yield put({ type: FETCH_ASSET_ITEM_COMMENTS_SUCCESS, payload: response.data.data })
  } catch (error) {
    const payload = parseValidationError(get(error, 'response.data.errors', undefined))

    yield put({ type: FETCH_ASSET_ITEM_COMMENTS_FAILURE, payload })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchAssetItemCommentsCollectionView({ assetBiid }: any) {
  try {
    const response: AxiosResponse = yield call(assetCommentApi.list, assetBiid)
    yield put({
      type: FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_SUCCESS,
      payload: response.data.data,
      assetBiid,
    })
  } catch (error) {
    yield put({ type: FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createAssetComment({ assetBiid, content }: any) {
  try {
    const response: AxiosResponse = yield call(assetCommentApi.create, assetBiid, content)
    yield put({ type: CREATE_ASSET_COMMENT_SUCCESS, payload: response.data.data })
    yield put({ type: FETCH_ASSET_ITEM_COMMENTS_REQUEST, assetBiid })
  } catch (error) {
    const status = get(error, 'response.status')
    if (status === 422) {
      const payload = parseValidationError(get(error, 'response.data.errors', undefined))
      yield put({ type: SUBMIT_ASSET_COMMENT_FAILURE, payload })
    } else {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export function* createAssetCommentCollectionView({ assetBiid, content }: any) {
  try {
    const response: AxiosResponse = yield call(assetCommentApi.create, assetBiid, content)
    yield put({ type: CREATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS, payload: response.data.data, assetBiid })
    yield put({ type: UPDATE_COMMENT_COUNT_REQUEST, assetBiid })
    yield put({ type: FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_REQUEST, assetBiid })
  } catch (error) {
    const status = get(error, 'response.status')
    if (status === 422) {
      const payload = parseValidationError(get(error, 'response.data.errors', undefined))
      yield put({ type: SUBMIT_ASSET_COMMENT_FAILURE, payload })
    } else {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export function* updateAssetComment({ id, content }: any) {
  try {
    const response: AxiosResponse = yield call(assetCommentApi.update, id, content)
    yield put({ type: UPDATE_ASSET_ITEM_COMMENT_SUCCESS, payload: response.data.data })
  } catch (error) {
    const status = get(error, 'response.status')
    if (status === 422) {
      const payload = parseValidationError(get(error, 'response.data.errors', undefined))
      yield put({ type: SUBMIT_ASSET_COMMENT_FAILURE, payload })
    } else {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export function* updateAssetCommentCollectionView({ id, assetBiid, content }: any) {
  try {
    const response: AxiosResponse = yield call(assetCommentApi.update, id, content)
    yield put({ type: UPDATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS, payload: response.data.data, assetBiid })
  } catch (error) {
    const status = get(error, 'response.status')
    if (status === 422) {
      const payload = parseValidationError(get(error, 'response.data.errors', undefined))
      yield put({ type: SUBMIT_ASSET_COMMENT_FAILURE, payload })
    } else {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export function* deleteAssetComment({ id }: any) {
  try {
    const response: AxiosResponse = yield call(assetCommentApi.destroy, id)
    yield put({ type: DELETE_ASSET_ITEM_COMMENT_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* deleteAssetCommentCollectionView({ id, assetBiid }: any) {
  try {
    const response: AxiosResponse = yield call(assetCommentApi.destroy, id)
    yield put({ type: DELETE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS, payload: response.data.data, assetBiid })
    yield put({ type: UPDATE_COMMENT_COUNT_REQUEST, assetBiid })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_REQUEST, fetchAssetItemCommentsCollectionView)
  yield takeLatest(FETCH_ASSET_ITEM_COMMENTS_REQUEST, fetchAssetItemComments)
  yield takeLatest(CREATE_ASSET_COMMENT_REQUEST, createAssetComment)
  yield takeLatest(CREATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST, createAssetCommentCollectionView)
  yield takeLatest(UPDATE_ASSET_ITEM_COMMENT_REQUEST, updateAssetComment)
  yield takeLatest(UPDATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST, updateAssetCommentCollectionView)
  yield takeLatest(DELETE_ASSET_ITEM_COMMENT_REQUEST, deleteAssetComment)
  yield takeLatest(DELETE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST, deleteAssetCommentCollectionView)
}
