import { Notification } from 'models/notification'

import {
  CreateSessionRequest,
  CreateSessionSSORequest,
  FetchCurrentOfficeRequest,
  FetchCurrentUserRequest,
  FetchNotificationsFailure,
  FetchNotificationsRequest,
  FetchNotificationsSuccess,
  FetchUserInfoRequest,
  HandleCheckMfidCallback,
  HandleCheckOffices,
  HandleLogout,
  ReturnNoValidOfficeMessageFlag,
  SetSeenNotifications,
  SubmitTermOfUseRequest,
  VerifyAgentLoginFailure,
  VerifyAgentLoginRequest,
  VerifyAgentLoginSucess,
} from './action.model'
import { VerifyAgentLoginPayload, VerifyAgentLoginResponse } from './model'

export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'
export const CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE'

export const CREATE_SESSION_SSO_REQUEST = 'CREATE_SESSION_SSO_REQUEST'

export const FETCH_USER_INFO_REQUEST = 'FETCH_USER_INFO_REQUEST'
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS'
export const FETCH_USER_INFO_FAILURE = 'FETCH_USER_INFO_FAILURE'

export const FETCH_CURRENT_OFFICE_REQUEST = 'FETCH_CURRENT_OFFICE_REQUEST'
export const FETCH_CURRENT_OFFICE_SUCCESS = 'FETCH_CURRENT_OFFICE_SUCCESS'
export const FETCH_CURRENT_OFFICE_FAILURE = 'FETCH_CURRENT_OFFICE_FAILURE'

export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST'
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE'

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE'
export const SET_SEEN_NOTIFICATIONS = 'SET_SEEN_NOTIFICATIONS'

export const FETCH_USER_PERMISSION_REQUEST = 'FETCH_USER_PERMISSION_REQUEST'
export const HANDLE_FETCH_USER_PERMISSION = 'HANDLE_FETCH_USER_PERMISSION'

export const SUBMIT_TERM_OF_USE_REQUEST = 'SUBMIT_TERM_OF_USE_REQUEST'
export const HANDLE_CHECK_MFID_CALLBACK = 'HANDLE_CHECK_MFID_CALLBACK'
export const HANDLE_CHECK_OFFICES = 'HANDLE_CHECK_OFFICES'
export const SAVE_RECENT_OFFICE = 'SAVE_RECENT_OFFICE'

export const HANDLE_LOGOUT = 'HANDLE_LOGOUT'

export const RETURN_FLAG_MESSAGE_NO_VALID_OFFICE = 'RETURN_FLAG_MESSAGE_NO_VALID_OFFICE'

export const OPEN_WEBSOCKET_REQUEST = 'OPEN_WEBSOCKET_REQUEST'

export const ON_WEBSOCKET_MESSAGE = 'REDUX_WEBSOCKET::MESSAGE'
export const ON_WEBSOCKET_CONNECT = 'REDUX_WEBSOCKET::CONNECT'
export const ON_WEBSOCKET_OPEN = 'REDUX_WEBSOCKET::OPEN'
export const ON_WEBSOCKET_DISCONNECT = 'REDUX_WEBSOCKET::DISCONNECT'
export const ON_WEBSOCKET_CLOSED = 'REDUX_WEBSOCKET::CLOSED'
export const STATUS_CONNECT = 1
export const STATUS_OPEN = 2
export const STATUS_DISCONNECT = 3
export const STATUS_CLOSED = 0

export const VERIFY_AGENT_LOGIN_REQUEST = 'VERIFY_AGENT_LOGIN_REQUEST'
export const VERIFY_AGENT_LOGIN_SUCCESS = 'VERIFY_AGENT_LOGIN_SUCCESS'
export const VERIFY_AGENT_LOGIN_FAILURE = 'VERIFY_AGENT_LOGIN_FAILURE'

export const createSessionRequest = (
  code: string | number,
  nonce: any,
  redirectUri: string | null
): CreateSessionRequest => {
  return {
    type: CREATE_SESSION_REQUEST,
    payload: { code, nonce, redirect_uri: redirectUri },
  }
}

export const createSessionSSORequest = (
  code: string | number,
  nonce: any,
  redirectUri: string | null,
  tenantUid: number,
  redirectPath: string | null
): CreateSessionSSORequest => {
  return {
    type: CREATE_SESSION_SSO_REQUEST,
    payload: {
      code,
      nonce,
      redirect_uri: redirectUri,
      tenant_uid: tenantUid,
      redirect_path: redirectPath,
    },
  }
}

export const getUserInfo = (): FetchUserInfoRequest => ({
  type: FETCH_USER_INFO_REQUEST,
})

export const fetchCurrentUserRequest = (): FetchCurrentUserRequest => ({
  type: FETCH_CURRENT_USER_REQUEST,
})

export const fetchNotificationsRequest = (): FetchNotificationsRequest => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
})

export const fetchNotificationsSuccess = (payload: Notification[]): FetchNotificationsSuccess => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload,
})

export const fetchNotificationsFailure = (payload: any): FetchNotificationsFailure => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload,
})

export const setSeenNotifications = (payload: Notification[]): SetSeenNotifications => ({
  type: SET_SEEN_NOTIFICATIONS,
  payload,
})

export const fetchCurrentOffice = (): FetchCurrentOfficeRequest => {
  return { type: FETCH_CURRENT_OFFICE_REQUEST }
}

export const submitTermOfUseRequest = (): SubmitTermOfUseRequest => ({
  type: SUBMIT_TERM_OF_USE_REQUEST,
})

export const handleCheckMfidCallback = (state: string | null, code: string | null): HandleCheckMfidCallback => ({
  type: HANDLE_CHECK_MFID_CALLBACK,
  payload: { state, code },
})

export const verifyAgentLoginRequest = (payload: VerifyAgentLoginPayload): VerifyAgentLoginRequest => ({
  type: VERIFY_AGENT_LOGIN_REQUEST,
  payload,
})

export const verifyAgentLoginSuccess = (payload: VerifyAgentLoginResponse): VerifyAgentLoginSucess => ({
  type: VERIFY_AGENT_LOGIN_SUCCESS,
  payload,
})

export const verifyAgentLoginFailure = (payload: any): VerifyAgentLoginFailure => ({
  type: VERIFY_AGENT_LOGIN_FAILURE,
  payload,
})

export const handleCheckOffices = (): HandleCheckOffices => ({
  type: HANDLE_CHECK_OFFICES,
})

export const handleLogout = (): HandleLogout => ({
  type: HANDLE_LOGOUT,
})

export const returnNoOfficesMessage = (payload: any): ReturnNoValidOfficeMessageFlag => ({
  type: RETURN_FLAG_MESSAGE_NO_VALID_OFFICE,
  payload,
})

export const fetchUserPermissionRequest = () => ({
  type: FETCH_USER_PERMISSION_REQUEST,
})

export const handleFetchUserPermission = (payload: any) => ({
  type: HANDLE_FETCH_USER_PERMISSION,
  payload,
})
