import { formatDate } from 'aa_common/front-end/helpers'
import { getNs } from 'aa_common/front-end/i18n-config'
import { ReportsService } from 'aa_common/front-end/open-api'
import produce from 'immer'
import { useEffect, useState } from 'react'

import { SelectType, Term, TermResponse } from '../../model'
import { reportSlice } from '../../store'
import { REPORT_TYPE } from './index'

const { t, useNsReady } = getNs(['common', 'pages/accounting/corporate-income-tax'])

export const useInitTerm = () => {
  useNsReady()
  const [accountingPeriod, setAccountingPeriod] = useState<SelectType[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { terms, last_export: lastExport } = (await ReportsService.corporateTaxTermsList())?.data as TermResponse

        if (terms.length) {
          const data = terms.map(({ id, year, start_date, end_date }: Term) => ({
            id,
            text: `${t('format.term_year', { year })} (${formatDate(start_date)} ~ ${formatDate(end_date)}) ${t(
              'submission'
            )}`,
          }))

          setAccountingPeriod(data)
        }

        reportSlice.setFilters(
          s =>
            produce(s, d => {
              d.currentTermId = lastExport?.term_id || d.currentTermId
              d.reportType = lastExport?.report_type || d.reportType
            }),
          true
        )
      } catch (e) {
        reportSlice.setFilters({
          reportType: REPORT_TYPE.BY_ASSET_TYPE,
        })
      }
    }

    fetchData()
  }, [])

  return {
    accountingPeriod,
  }
}
