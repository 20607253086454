import { LinkLabel } from 'aa_common/front-end/components'
import { BreadcrumbNavigation } from 'components/molecules'
import LedgerSettingsTable from 'components/organisms/depreciation/LedgerSettingsTable'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import { LedgerSettingsEditItem, LedgerSettingsItem } from 'models/ledgerSetting'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  cleanupLedgerSettings,
  fetchLedgerSettingsList,
  saveLedgerSettingsList,
} from 'store/settings/ledgerSetting/actions'
import { selectorLedgerSettingsList } from 'store/settings/ledgerSetting/selectors'

const LedgerSettingsEditPage = () => {
  const history = useHistory()

  const dispatch = useDispatch()

  const ledgerSettingListSelector = useSelector(selectorLedgerSettingsList, isEqual)

  const memoizedLedgerSettingsList = useMemo(() => {
    return (
      ledgerSettingListSelector.data &&
      (ledgerSettingListSelector.data.map(item => ({
        ...item,
        key_id: item.key_id || item.id,
      })) as LedgerSettingsItem[])
    )
  }, [ledgerSettingListSelector.data])

  useEffect(() => {
    dispatch(fetchLedgerSettingsList())

    return () => {
      dispatch(cleanupLedgerSettings())
    }
  }, [dispatch])

  const handleBreadcrumbCancelClick = useCallback(() => {
    history.goBack()
  }, [history])

  const handleFormSave = useCallback(
    (payload: LedgerSettingsEditItem[]) => {
      dispatch(saveLedgerSettingsList({ items: payload }))
    },
    [dispatch]
  )

  const cancelLink = useMemo(() => {
    return (
      <LinkLabel style={{ fontSize: 13, marginRight: 15 }} onClick={handleBreadcrumbCancelClick}>
        {i18n.t('common.actions.cancel')}
      </LinkLabel>
    )
  }, [handleBreadcrumbCancelClick])

  const breadcrumb = (
    <BreadcrumbNavigation sectionName={i18n.t('components.LedgerSettings.title')} customBackElement={cancelLink} />
  )

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <LedgerSettingsTable
        loading={ledgerSettingListSelector.loading}
        data={memoizedLedgerSettingsList}
        onSubmitForm={handleFormSave}
      />
    </MainTemplate>
  )
}

export default LedgerSettingsEditPage
