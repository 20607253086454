import i18n from 'i18n'
import { useState } from 'react'

import { Button } from '../../../antd'
import { CSVCharset } from '../../../models'
import { CSVCharsetRadios } from '../../csv-charset-radios'
import { StyledModal } from './styles'

type Props = {
  isShow: boolean
  title: string
  loading?: boolean
  children?: any
  onCancel: () => void
  onExport: (encode: CSVCharset) => void
  isShortCharsetLabel?: boolean
}

const defaultCharset = 'utf-8'

export const ExportModal = ({ children, isShow, title, loading, onCancel, onExport, isShortCharsetLabel }: Props) => {
  const [charset, setCharset] = useState<CSVCharset>(defaultCharset)

  const handleChange = (charset: CSVCharset) => {
    setCharset(charset)
  }

  const handleExport = () => {
    onExport(charset)
  }

  return (
    <StyledModal
      title={title}
      visible={isShow}
      onCancel={onCancel}
      width={600}
      footer={[
        <Button key="back" color="grey" onClick={onCancel} data-testid="cancel-btn-modal">
          {i18n.t('common.actions.cancel')}
        </Button>,
        <Button key="submit" onClick={handleExport} loading={loading} data-testid="export-btn-modal">
          {i18n.t('components.ActionsList.export')}
        </Button>,
      ]}
      centered
    >
      {children}
      <CSVCharsetRadios
        defaultCharset={defaultCharset}
        handleChange={handleChange}
        isShortCharsetLabel={isShortCharsetLabel}
      />
    </StyledModal>
  )
}
