import { AssetReport } from 'aa_common/front-end/models'
import { MonthlyClosing, MonthlyClosingSetting, SummaryAssetEventMetrics } from 'models/monthlyClosing'
import { TermMonth } from 'models/term'

import { CurrentMonthlyClosingSelector } from './select.model'

export const selectMonthlyClosingCurrent = (state: any): MonthlyClosing => {
  return state.getIn(['accounting', 'monthlyClosing', 'current'])
}

export const selectCurrentMonthlyClosing = (state: any): CurrentMonthlyClosingSelector => {
  return {
    loading: state.getIn(['accounting', 'monthlyClosing', 'currentMonthlyClosing', 'loading']),
    data: state.getIn(['accounting', 'monthlyClosing', 'currentMonthlyClosing', 'data']),
  }
}

export const selectMonthlyClosingSummaryAssetEvents = (state: any): SummaryAssetEventMetrics => {
  return state.getIn(['accounting', 'monthlyClosing', 'summaryAssetEvents'])
}

export const selectMonthlyClosingNextSetting = (state: any): MonthlyClosingSetting => {
  return state.getIn(['accounting', 'monthlyClosing', 'nextSetting'])
}

export const selectMonthlyClosingsClosed = (state: any): MonthlyClosing[] => {
  return state.getIn(['accounting', 'monthlyClosing', 'closedMonthlyClosings'])
}

export const selectMonthlyClosingAssetEventsInCurrent = (state: any): { data: AssetReport[]; total: number } => {
  return state.getIn(['accounting', 'monthlyClosing', 'assetEvents', 'dataInCurrent'])
}

export const selectMonthlyClosingAssetEventsInPast = (state: any): { data: AssetReport[]; total: number } => {
  return state.getIn(['accounting', 'monthlyClosing', 'assetEvents', 'dataInPast'])
}

export const selectMonthlyClosingPreviousAssetEvents = (state: any): AssetReport[] => {
  return state.getIn(['accounting', 'monthlyClosing', 'previousAssetEvents'])
}

export const selectMonthlyClosingReselectTerms = (state: any): TermMonth[] => {
  return state.getIn(['accounting', 'monthlyClosing', 'monthlyClosingReselectTerms'])
}
