import { loadNs } from 'aa_common/front-end/i18n-config'
import { NumberInput, YearSelect } from 'components/atoms'
import { BasicForm, DatePicker, RadioList } from 'components/molecules'
import { DATE_FORMAT, DATE_FORMAT_RFC, YEAR_FORMAT } from 'constants/app'
import { ACCOUNTING_PERIOD_TERM_MONTH_TYPES } from 'constants/masterData'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useEffect } from 'react'

import { determineDefaultTermMonthDate } from './helpers'

const { Group, Item } = BasicForm

const t = loadNs(['components/organisms/settings/accounting-period-form'])

type Props = {
  onValuesChanged: (values: any) => void
}

const defaultTermMonth = [
  {
    label: t('start_date_of_month'),
    value: ACCOUNTING_PERIOD_TERM_MONTH_TYPES.BASE_ON_START_DATE,
  },
  {
    label: t('end_date_of_month'),
    value: ACCOUNTING_PERIOD_TERM_MONTH_TYPES.BASE_ON_END_DATE,
  },
]

const Form: React.FC<Props> = ({ onValuesChanged }) => {
  const { values, setFieldValue } = useFormikContext()

  const handleYearChanged = (year: number | null) => {
    setFieldValue('start_date', null)
    setFieldValue('end_date', null)

    if (year) {
      const firstDateOfYear = moment(year - 1, YEAR_FORMAT).startOf('year')
      const lastDateOfYear = moment(year, YEAR_FORMAT).endOf('year')

      setFieldValue('min_date', firstDateOfYear.format(DATE_FORMAT_RFC))
      setFieldValue('max_date', lastDateOfYear.format(DATE_FORMAT_RFC))
    } else {
      setFieldValue('min_date', null)
      setFieldValue('max_date', null)
    }
  }

  const handleStartDateChanged = (value: Moment | null) => {
    if (value) {
      const defaultTermMonthDate = determineDefaultTermMonthDate(value.format(DATE_FORMAT_RFC))
      const end_date = value.add(1, 'year').subtract(1, 'day')

      setFieldValue('end_date', end_date.format(DATE_FORMAT_RFC))
      setFieldValue('default_term_month_date', defaultTermMonthDate)
    } else {
      setFieldValue('end_date', null)
    }
  }

  const getEndDate = () => {
    const end_date = get(values, 'end_date')

    if (end_date) {
      return moment(end_date).format(DATE_FORMAT)
    }

    return null
  }

  const isShowDefaultTermDate = (): boolean => {
    const startDate = get(values, 'start_date')

    if (!startDate) {
      return false
    }

    if (moment(startDate).date() === 1) {
      return false
    }

    return true
  }

  useEffect(() => {
    onValuesChanged(values)
  }, [values]) // eslint-disable-line

  useEffect(() => {
    handleYearChanged(get(values, 'year'))
  }, []) // eslint-disable-line

  return (
    <Group>
      <Item required label={t('start_year')} style={{ borderTop: '1px solid #D4D8DD' }}>
        <YearSelect
          name="year"
          min={1900}
          max={2999}
          afterChanged={handleYearChanged}
          style={{ width: 180 }}
          allowClear={false}
        />
      </Item>
      <Item required label={t('period')}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label>{t('first')}</label>
          <NumberInput name="period" min={1} max={999} style={{ width: 60, marginLeft: 5 }} />
          <label style={{ marginLeft: 5 }}>{t('period')}</label>
        </div>
      </Item>
      <Item required label={t('accounting_period')} contentWidth={600}>
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <DatePicker
            name="start_date"
            minDateField="min_date"
            maxDateField="max_date"
            style={{ width: 180 }}
            placeholder={t('first_day_of_period')}
            allowClear={false}
            afterChanged={handleStartDateChanged}
          />
          <label style={{ marginLeft: 10, marginTop: 4 }}>〜</label>
          <label style={{ marginLeft: 10, marginTop: 4 }}>{getEndDate() || t('empty_data_msg')}</label>
        </div>
      </Item>
      {isShowDefaultTermDate() && (
        <Item label={t('accounting_period')}>
          <RadioList name="default_term_month_date" fields={defaultTermMonth} />
        </Item>
      )}
    </Group>
  )
}

export default Form
