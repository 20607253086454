import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { GREY_BORDER_COLOR } from 'constants/styles'
import React from 'react'

import { Wrapper } from './styles'

type Props = {
  width?: number
  onClick?: () => void
  showTooltipMessage?: string
  disabled?: boolean
}

const LineAddNew = ({ onClick, width = 900, showTooltipMessage, disabled }: Props) => {
  const render = () => {
    const color = disabled ? GREY_BORDER_COLOR : '#3B7DE9'

    return (
      <Wrapper
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        onClick={() => !disabled && onClick && onClick()}
      >
        <div className="content">
          <FontAwesomeIcon icon={faPlus} style={{ color }} className="plus-icon" />
        </div>
        <svg height="1" width="100%" className="line">
          <g fill="none" stroke={color} strokeWidth="1" width="100%">
            <path strokeDasharray="5,5" d={`M0 0 l${width} 0`} />
          </g>
        </svg>
      </Wrapper>
    )
  }

  return showTooltipMessage ? <Tooltip title={showTooltipMessage}>{render()}</Tooltip> : render()
}

export default LineAddNew
