/* eslint-disable react/display-name */
import { ComplexTable } from 'aa_common/front-end/antd'
import { ColumnType } from 'antd/es/table'
import classNames from 'classnames'
import { Role } from 'models/role'
import React, { useMemo } from 'react'

import ActionList from './ActionList'
import { roleTableColumns } from './settings'
import { TableClass } from './styles'

interface RoleListProps {
  data: Role[]
  loading: boolean
  onEditClick: (item: Role) => void
  onDeleteClick: (item: Role) => void
}

const RoleList = ({ data, loading, onEditClick, onDeleteClick }: RoleListProps) => {
  const columns = useMemo(() => roleTableColumns(), [])

  const tableActions: ColumnType<any> = useMemo(() => {
    return {
      key: 'role-actions',
      width: 80,
      render: value => <ActionList item={value} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />,
    }
  }, [onEditClick, onDeleteClick])

  return (
    <ComplexTable
      className={classNames(TableClass)}
      loading={loading}
      data={data}
      actions={tableActions}
      columns={columns}
      rowKey="id"
      enableSelectRows={false}
    />
  )
}

export default React.memo(RoleList)
