import { LedgerSettingsEditItem } from 'models/ledgerSetting'

export const convertSubmitPayload = (data: LedgerSettingsEditItem[]) => {
  if (!data) {
    return []
  }

  return data.map(item => {
    return {
      ...item,
      deleted: !!item.deleted,
    }
  })
}
