import * as tagApi from 'api/app/tag'
import { AxiosResponse } from 'axios'
import { DATA_IN_USED_ERROR_CODE } from 'constants/apiCode'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { changeLocation, setAppMessage, SHOW_IN_USED_MESSAGE, showErrorsAppCall } from 'store/app/actions'
import { parseError, parseValidationError } from 'utils/ResponseHelper'

import {
  CreateTagRequest,
  DeleteTagRequest,
  HandleSubmitTagFailure,
  SubmitTagFailure,
  UpdateTagRequest,
} from './action.model'
import { fetchTagListFailure, fetchTagListSuccess } from './actions'
import {
  CREATE_TAG_REQUEST,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  FETCH_TAG_REQUEST,
  FETCH_TAG_SUCCESS,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_REQUEST_AUTH,
  HANDLE_SUBMIT_TAG_FAILURE,
  SUBMIT_TAG_FAILURE,
  SUBMIT_TAG_SUCCESS,
  UPDATE_TAG_ORDER_FAILURE,
  UPDATE_TAG_ORDER_REQUEST,
  UPDATE_TAG_ORDER_SUCCESS,
  UPDATE_TAG_REQUEST,
} from './constant'

export function* handleSubmitTagFailure({ error }: HandleSubmitTagFailure) {
  const errorResponse: AxiosResponse<any> = get(error, 'response')
  switch (errorResponse?.status) {
    case 422: {
      const validationErrors = get(error, 'response.data.errors')
      const payload = parseValidationError(validationErrors)
      yield put<SubmitTagFailure>({ type: SUBMIT_TAG_FAILURE, formErrors: payload })
      break
    }
    default: {
      yield put(showErrorsAppCall(parseError(error)))
      yield put<SubmitTagFailure>({ type: SUBMIT_TAG_FAILURE })
    }
  }
}

export function* fetchTagList({ filters }: any) {
  const { data, errors } = yield call(tagApi.search, filters)
  if (data) {
    yield put(fetchTagListSuccess(data?.data || []))
  } else if (errors) {
    const payload = parseValidationError(errors)
    yield put(fetchTagListFailure(payload))
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchTagListAuth({ filters }: any) {
  const { data, errors } = yield call(tagApi.searchAuth, filters)
  if (data) {
    yield put(fetchTagListSuccess(data?.data || []))
  } else if (errors) {
    const payload = parseValidationError(errors)
    yield put(fetchTagListFailure(payload))
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchTag({ id }: any): Generator<any> {
  try {
    const response = (yield call(tagApi.get, id)) as AxiosResponse
    yield put({ type: FETCH_TAG_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createTag({ payload }: CreateTagRequest) {
  try {
    yield call(tagApi.create, payload)
    yield put({ type: SUBMIT_TAG_SUCCESS })
    yield put(changeLocation('/master/tags'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitTagFailure>({
      type: HANDLE_SUBMIT_TAG_FAILURE,
      error,
    })
  }
}

export function* updateTag({ id, payload }: UpdateTagRequest) {
  try {
    yield call(tagApi.update, id, payload)
    yield put({ type: SUBMIT_TAG_SUCCESS })
    yield put(changeLocation('/master/tags'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitTagFailure>({
      type: HANDLE_SUBMIT_TAG_FAILURE,
      error,
    })
  }
}

export function* updateTagOrder({ payload }: any) {
  try {
    yield call(tagApi.updateOrder, payload)
    yield put({ type: UPDATE_TAG_ORDER_SUCCESS })
  } catch (error) {
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.change_position_failure'),
      })
    )
    yield put({ type: UPDATE_TAG_ORDER_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  } finally {
    yield put({ type: FETCH_TAGS_REQUEST })
  }
}

export function* deleteTag({ payload }: DeleteTagRequest) {
  const { id, name } = payload

  try {
    const checkDeleteResponse: AxiosResponse = yield call(tagApi.destroy, id, true)
    const isAllowDelete = checkDeleteResponse.data.data && checkDeleteResponse.data.data.id
    if (isAllowDelete) {
      yield call(tagApi.destroy, id, false)
      yield put({ type: DELETE_TAG_SUCCESS })
      yield put({ type: FETCH_TAGS_REQUEST })
      yield put(
        setAppMessage({
          type: 'success',
          content: i18n.t('common.messages.deleted_success'),
        })
      )
    }
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400 && get(data, 'errors[0].code') === DATA_IN_USED_ERROR_CODE) {
        yield put({
          type: SHOW_IN_USED_MESSAGE,
          payload: i18n.t('common.messages.deleted_failure_by_in_used', { name }),
        })
      } else {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure', { name }),
          })
        )
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_TAGS_REQUEST, fetchTagList)
  yield takeLatest(FETCH_TAGS_REQUEST_AUTH, fetchTagListAuth)
  yield takeLatest(FETCH_TAG_REQUEST, fetchTag)
  yield takeLatest(CREATE_TAG_REQUEST, createTag)
  yield takeLatest(UPDATE_TAG_REQUEST, updateTag)
  yield takeLatest(UPDATE_TAG_ORDER_REQUEST, updateTagOrder)
  yield takeLatest(DELETE_TAG_REQUEST, deleteTag)
  yield takeLatest(HANDLE_SUBMIT_TAG_FAILURE, handleSubmitTagFailure)
}
