import { Button } from 'aa_common/front-end/antd'
import { RedirectToMasterPageLink, UnborderCollapse } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { AutoCompleteSelect, DatePicker, MediaUploader } from 'components/molecules'
import { FEATURES_ID } from 'constants/const'
import { ENDPOINTS } from 'constants/endpoints'
import { useFormikContext } from 'formik'
import useUserPermission from 'lib/hooks/useUserPermission'
import React from 'react'

import { BulkChangeEvent, rowSelectSliceAssetList } from '../../../../pages/assets/AssetListPage/store'
import { validate, ValueFields } from './helpers'
import { FormFields } from './styles'

const t = loadNs(['components/organisms/assets/asset-usage-started-form', 'common'])

const { Item, LeftGroup, Actions } = UnborderCollapse

type Props = {
  usageCauses: string[]
  onClose?: (e: any) => void
  onHandleReloadCauses?: () => void
}

const Form: React.FC<Props> = ({ usageCauses, onClose, onHandleReloadCauses }) => {
  const { values } = useFormikContext<ValueFields>()
  const isValidFields = validate(values)
  const bulkChangeEvent = rowSelectSliceAssetList.useSlice(state => state?.data?.bulkChangeEvent)
  const isBulkChangeEventStartedUsage = bulkChangeEvent === BulkChangeEvent.START_USAGE
  const { permissions } = useUserPermission(FEATURES_ID.MASTER_CAUSES)

  return (
    <>
      <FormFields>
        <LeftGroup>
          <Item label={t('used_at')} required>
            <DatePicker name="usage_started_at" />
          </Item>
          <Item label={t('usage_reason')} required>
            <AutoCompleteSelect
              name="event_cause_id"
              items={usageCauses}
              valueField="id"
              textField="content"
              childrenNode={
                <RedirectToMasterPageLink
                  permissions={permissions}
                  urlName={ENDPOINTS.CAUSES}
                  pageName={t('master.causes')}
                  onHandleReload={onHandleReloadCauses}
                />
              }
            />
          </Item>
          {!isBulkChangeEventStartedUsage && <MediaUploader />}
        </LeftGroup>
      </FormFields>
      <Actions>
        <Button color="grey" onClick={onClose} data-testid="cancelBtn">
          {t('actions.cancel')}
        </Button>
        <Button type="submit" disabled={!isValidFields} data-testid="submitBtn">
          {t('actions.register')}
        </Button>
      </Actions>
    </>
  )
}

export default Form
