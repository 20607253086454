export default {
  // molecules
  AutoCompleteSelect: require('./molecules/AutoCompleteSelect'),
  AutoCompleteText: require('./molecules/AutoCompleteText'),
  Pagination: require('./molecules/Pagination'),
  BasicForm: require('./molecules/BasicForm'),
  ConfirmModal: require('./molecules/modals/ConfirmModal'),
  ImportModal: require('./molecules/modals/ImportModal'),
  ExportModal: require('./molecules/modals/ExportModal'),
  FilterPanel: require('./molecules/FilterPanel'),
  ActionsList: require('./molecules/ActionsList'),
  UploadImageListField: require('./molecules/UploadImageListField'),
  UploadFileListField: require('./molecules/UploadFileListField'),
  DropdownToggle: require('./molecules/DropdownToggle'),
  RetrospectiveAssetModal: require('./molecules/RetrospectiveAssetModal'),
  ButtonSendJournal: require('./molecules/ButtonSendJournal'),
  ConfirmSendJournalModal: require('./molecules/modals/ConfirmSendJournalModal'),
  ErrorModal: require('./molecules/modals/ErrorModal'),

  // organisms
  AutoJournalSettings: require('./organisms/accounting/AutoJournalSettingTab'),
  GeneralAutoJournalSettings: require('./organisms/accounting/GeneralJournalSettingTab'),
  MonthlyClosingView: require('./organisms/accounting/MonthlyClosingView'),
  JournalList: require('./organisms/accounting/JournalList'),
  MonthlyClosingAssetEventList: require('./organisms/accounting/MonthlyClosingAssetEventList'),
  UnderOverDepreciationReport: require('./organisms/accounting/UnderOverDepreciationReport'),
  AssetEventsReport: require('./organisms/accounting/AssetEventsReport'),
  LedgerSettings: require('./organisms/depreciation/LedgerSettings'),
  AssetComment: require('./organisms/assets/AssetComment'),
  AssetList: require('./organisms/assets/AssetList'),
  AssetForm: require('./organisms/assets/AssetForm'),
  AssetFilterFacet: require('./organisms/assets/AssetFilterFacet'),
  AssetDetail: require('./organisms/assets/AssetDetail'),
  AssetUsageStartedForm: require('./organisms/assets/AssetUsageStartedForm'),
  AssetRetirementForm: require('./organisms/assets/AssetRetirementForm'),
  AssetMovementForm: require('./organisms/assets/AssetMovementForm'),
  SwitchCategoryForm: require('./organisms/assets/SwitchCategoryForm'),
  AssetEventSellForm: require('./organisms/assets/AssetEventSellForm'),
  AssetTypeModifyForm: require('./organisms/assets/AssetTypeModifyForm.json'),
  AssetImpairment: require('./organisms/assets/AssetImpairment'),
  AssetImportForm: require('./organisms/assets/AssetImportForm'),
  AssetImportResultList: require('./organisms/assets/AssetImportResultList'),
  AssetCategoryForm: require('./organisms/settings/AssetCategoryForm'),
  AssetCategoryList: require('./organisms/settings/AssetCategoryList'),
  AssetCategoryFilter: require('./organisms/settings/AssetCategoryFilter'),
  AreaForm: require('./organisms/settings/AreaForm'),
  AreaFilter: require('./organisms/settings/AreaFilter'),
  AreaList: require('./organisms/settings/AreaList'),
  ExciseListFilter: require('./organisms/settings/ExciseListFilter'),
  ExciseList: require('./organisms/settings/ExciseList'),
  SubmitDestinationList: require('./organisms/settings/SubmitDestinationList'),
  SubmitDestinationForm: require('./organisms/settings/SubmitDestinationForm'),
  UnitList: require('./organisms/settings/UnitList'),
  UnitForm: require('./organisms/settings/UnitForm'),
  UserForm: require('./organisms/settings/UserForm'),
  TagList: require('./organisms/settings/TagList'),
  TagForm: require('./organisms/settings/TagForm'),
  CauseList: require('./organisms/settings/CauseList'),
  CauseForm: require('./organisms/settings/CauseForm'),
  DepartmentFilter: require('./organisms/settings/DepartmentFilter'),
  DepartmentList: require('./organisms/settings/DepartmentList'),
  DepartmentForm: require('./organisms/settings/DepartmentForm'),
  AccountingPeriodForm: require('./organisms/settings/AccountingPeriodForm'),
  MainMenu: require('./organisms/MainMenu'),
  MainHeader: require('./organisms/MainHeader'),
  AgentLoginHeader: require('./organisms/AgentLoginHeader'),
  DepreciationResultList: require('./organisms/depreciation/DepreciationResultList'),
  OfficeSelectTable: require('./organisms/office/select_table'),
  AccountItemList: require('./organisms/settings/AccountItemList'),
  AccountItemForm: require('./organisms/settings/AccountItemForm'),
  AssetCodeAutoGenerationFormModal: require('./organisms/settings/AssetCodeAutoGenerationFormModal'),
  AssetCodeAutoGenerationView: require('./organisms/settings/AssetCodeAutoGenerationView'),
  AssetCodeStartNumberPage: require('./organisms/settings/AssetCodeStartNumberPage'),
  LogListItemDetail: require('./organisms/internalControl/LogListItemDetail'),
  OperationLogFilter: require('./organisms/internalControl/OperationLogFilter'),
  ExciseSettingView: require('./organisms/settings/ExciseSettingView'),
  ExciseSettingFormModal: require('./organisms/settings/ExciseSettingFormModal'),
  RoleList: require('./organisms/settings/RoleList'),
  SettingImportResultList: require('./organisms/settings/SettingImportResultList'),
  AssetDividing: require('./organisms/assets/AssetDividing'),
}
