import { get } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import {} from '../antd/error-message/index'
import { messageFn } from '../antd/message-function'
import { parseError } from '../helpers'

export type Request = (...otherArgs: any) => any

export const useResource = (apiRequest: Request, isShowMessage = true): UseResourceReturnType => {
  const [state, setState] = useState<RequestState>({ isLoading: false })

  const request = useCallback(
    (...otherArgs: any) => {
      setState({ isLoading: true })
      return Promise.resolve(apiRequest(...otherArgs))
        .then((response: any) => {
          const { status, data } = response || {}
          const result = {
            status,
            data: data?.data,
            meta: data?.meta,
            isLoading: false,
          }
          setState(result)
          return result
        })
        .catch((error: any) => {
          const errResult = {
            status: get(error, ['response', 'status']),
            error: get(error, ['response', 'data', 'errors']),
            isLoading: false,
          }
          setState(errResult)
          if (isShowMessage) {
            const errors = parseError(error)
            errors.errors.forEach((err: any) => {
              messageFn().error(err?.detail)
            })
          }
          return errResult
        })
    },
    [apiRequest, isShowMessage]
  )
  return useMemo(() => {
    const result = [{ ...state }, request]
    return result as UseResourceReturnType
  }, [state, request])
}

type RequestState = {
  data?: any
  meta?: any
  status?: number
  error?: any
  isLoading: boolean
}
type UseResourceReturnType = [RequestState, (...args: any) => Promise<any>]
