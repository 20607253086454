import { CSVCharset } from 'models/csvCharset'

export const IMPORT_ASSET_LIST_REQUEST = 'IMPORT_ASSET_LIST_REQUEST'
export const IMPORT_ASSET_LIST_SUCCESS = 'IMPORT_ASSET_LIST_SUCCESS'
export const IMPORT_ASSET_LIST_FAILURE = 'IMPORT_ASSET_LIST_FAILURE'

export const INITIALIZE_FIXED_ASSETS_REQUEST = 'INITIALIZE_FIXED_ASSETS_REQUEST'

export const SET_EDIT_ASSET_ITEM = 'SET_EDIT_ASSET_ITEM'
export const SET_IMPORT_ASSET_LIST = 'SET_IMPORT_ASSET_LIST'
export const SET_IMPORT_ASSET_LIST_ERRORS = 'SET_IMPORT_ASSET_LIST_ERRORS'

export const CREATE_BULK_ASSET_LIST_REQUEST = 'CREATE_BULK_ASSET_LIST_REQUEST'
export const CREATE_BULK_ASSET_LIST_SUCCESS = 'CREATE_ASSE_BULKT_LIST_SUCCESS'

export const CLEANUP_ASSET_IMPORT = 'CLEANUP_ASSET_IMPORT'

export const validateImportCsv = (charset: CSVCharset | null, file: File) => ({
  type: IMPORT_ASSET_LIST_REQUEST,
  charset,
  file,
})

export const setEditAssetItem = (asset: any) => ({
  type: SET_EDIT_ASSET_ITEM,
  payload: asset,
})

export const setImportAssetList = (assetList: any) => ({
  type: SET_IMPORT_ASSET_LIST,
  payload: assetList,
})

export const initializeFixedAssets = () => ({
  type: INITIALIZE_FIXED_ASSETS_REQUEST,
  meta: {
    thunk: true,
  },
})
