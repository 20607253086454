import { ConfirmModal } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Popover } from 'antd'
import classNames from 'classnames'
import IconComment from 'components/atoms/icons/CommentIcon'
import { convertComments, IAssetCommentItem } from 'components/organisms/assets/AssetCommentList/model'
import { Permission } from 'constants/permissions'
import { BLUE_COLOR } from 'constants/styles'
import { find } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createAssetCommentCollectionView,
  deleteAssetCommentCollectionView,
  fetchAssetItemCommentsCollectionView,
  updateAssetCommentCollectionView,
} from 'store/assetComment/actions'
import { getListAssetItemCommentSelector } from 'store/assetComment/selectors'

import CommentPopupContent from './CommentPopupContent'
import { CommentIconClass, IconCell, PopupOverlayClass } from './styles'

const t = loadNs(['components/organisms/assets/asset-comment'])
interface CommentPopupProps {
  item: any
  permissions: Permission
}

const CommentPopup = ({ item, permissions }: CommentPopupProps) => {
  const [visible, setPopupVisible] = useState<boolean>(false)
  const [numberOfComments, setCommentCount] = useState<number>(0)
  const [deletedComment, setDeletedComment] = useState<IAssetCommentItem | null>()
  const [disabledVisibleChanged, setDisableOnVisibleChange] = useState<boolean>(false)
  const [isFirstVisibleTriggered, setFirstVisibleTriggered] = useState<boolean>(false)
  const [mode, setMode] = useState<string | string[]>('hover')

  const dispatch = useDispatch()
  const listItems = useSelector(getListAssetItemCommentSelector)

  const memoizedComments = useMemo(() => {
    const targetItem = find(
      listItems.data,
      (fixedAssetItemComments: any) => fixedAssetItemComments.assetBiid === item.biid
    )
    return targetItem ? convertComments(targetItem.comments) : []
  }, [listItems, item])

  useEffect(() => {
    if (!isFirstVisibleTriggered && visible) {
      dispatch(fetchAssetItemCommentsCollectionView(item.biid))
      setFirstVisibleTriggered(true)
    }
  }, [visible, isFirstVisibleTriggered, item, dispatch])

  useEffect(() => {
    item && setCommentCount(item.commentCount)
  }, [item])

  useEffect(() => {
    if (visible && numberOfComments > 0 && mode === 'click') {
      // this will keep the comment textbox won't disappear after add comment;
      return
    }
    setMode(numberOfComments > 0 ? 'hover' : 'click')
  }, [numberOfComments, mode]) // eslint-disable-line

  const handlePopupVisibleChange = (visible: boolean) => {
    if (disabledVisibleChanged) return

    setPopupVisible(visible)
    if (!visible) {
      setMode('hover')
    }

    if (numberOfComments === 0) {
      setMode('click')
    }
    // TODO: handle overlap
  }

  const handleOnClick = () => {
    setMode('click')
    setPopupVisible(true)
    // TODO: handle overlap
  }

  const handleOnAddComment = useCallback(
    (content: string) => {
      dispatch(createAssetCommentCollectionView(item.biid, content))
    },
    [item, dispatch]
  )

  const handleOnEditComment = useCallback(
    (item: IAssetCommentItem, content: string) => {
      dispatch(updateAssetCommentCollectionView(item.id, item.fixedAssetBiid, content))
    },
    [dispatch]
  )

  const handleOnDeleteCommentClick = (item: IAssetCommentItem) => {
    setDeletedComment(item)
    setDisableOnVisibleChange(true)
  }

  const handleSubmitCommentDelete = () => {
    deletedComment && dispatch(deleteAssetCommentCollectionView(deletedComment.id, deletedComment.fixedAssetBiid))
    handleCancelCommentDelete()
  }

  const handleCancelCommentDelete = () => {
    setTimeout(() => setDisableOnVisibleChange(false), 200)
    setDeletedComment(null)
  }

  return (
    <Popover
      visible={visible}
      overlayClassName={PopupOverlayClass}
      onVisibleChange={handlePopupVisibleChange}
      placement="bottomRight"
      title={<span className="comment-popup-title">{t('comment')}</span>}
      content={
        <CommentPopupContent
          isEditMode={mode === 'click'}
          comments={numberOfComments > 0 ? memoizedComments : []}
          onAddComment={handleOnAddComment}
          onEditComment={handleOnEditComment}
          onDeleteComment={handleOnDeleteCommentClick}
          permissions={permissions}
        />
      }
      trigger={mode}
    >
      <div>
        <IconCell onClick={handleOnClick}>
          <IconComment
            className={classNames(CommentIconClass, {
              empty: numberOfComments <= 0,
              selected: mode === 'click' && visible,
            })}
          />
          <span style={{ color: mode === 'click' && visible ? `${BLUE_COLOR}` : 'black' }}>{numberOfComments}</span>
        </IconCell>
        <ConfirmModal
          title={<span>{t('delete_confirm_title')}</span>}
          message={t('delete_confirm_message')}
          visible={!!deletedComment}
          destroyOnClose
          onOK={handleSubmitCommentDelete}
          onCancel={handleCancelCommentDelete}
        />
      </div>
    </Popover>
  )
}

export default React.memo(CommentPopup)
