import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { getTermMonthByDate } from 'api/app/accountingPeriod'
import classNames from 'classnames'
import { DATE_FORMAT, DATE_FORMAT_RFC } from 'constants/app'
import { isEqual } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectAssetDetailPage } from 'store/asset/selectors'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsList } from 'store/settings/accountingPeriod/selectors'

import { StyledDatepicker } from './styles'

type ImpairmentDateProps = {
  name: string
  error: string
  required?: boolean
}

const ImpairmentDate = ({ name, error, required }: ImpairmentDateProps) => {
  const dispatch = useDispatch()
  const { data: termYears } = useSelector(selectTermsList, isEqual)
  const [renderValue, setValue] = useState<any>(null)
  const { asset } = useSelector(selectAssetDetailPage, isEqual)
  const [termSelected, setTermSelected] = useState<any>(null)

  useEffect(() => {
    dispatch(fetchTerms())
  }, [dispatch])

  const disabledDate = (date: Moment) => {
    const firstEventDate = moment(asset.usage_started_at || asset.transition_at || asset.acquired_at)
    if (termYears?.length > 0 && !!firstEventDate) {
      return !date.isBetween(firstEventDate, moment(termYears[termYears.length - 1]))
    }
    return false
  }

  const handleChangeTime = async (selectedTime: Moment, controllerOnChange: any) => {
    const termMonth = await getTermMonthByDate({
      'filter[time_date]': moment(selectedTime.endOf('month')).format(DATE_FORMAT_RFC),
    })
    if (termMonth && termMonth?.status === 200 && termMonth.data) {
      const endOfTermMonth = moment(termMonth.data.data?.end_date)
      setValue(endOfTermMonth)
      setTermSelected(endOfTermMonth)
      typeof controllerOnChange === 'function' && controllerOnChange(endOfTermMonth)
    }
  }

  return (
    <Controller
      name={name}
      defaultValue={null}
      rules={{ required }}
      render={({ onChange: controllerOnChange }) => {
        return (
          <div className="form_control">
            <StyledDatepicker
              name={name}
              picker="month"
              className={classNames({ error })}
              value={renderValue}
              format={() => {
                return termSelected ? termSelected?.format(DATE_FORMAT) : ''
              }}
              disabledDate={disabledDate}
              getPopupContainer={triggerNode => triggerNode.parentElement as HTMLElement}
              onChange={event => {
                const selectedTime = event?.endOf('month')
                if (selectedTime) {
                  handleChangeTime(selectedTime, controllerOnChange)
                }
              }}
            />
            {error && <CommonErrorMsg>{error}</CommonErrorMsg>}
          </div>
        )
      }}
    />
  )
}

export default ImpairmentDate
