import { Button } from 'aa_common/front-end/antd'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Divider } from 'antd'
import React, { useCallback } from 'react'

import InfinityMultiSelectPicker from '../../components/MultiSelectPicker/InfinityScrollerPicker'
import { BaseFormFilter } from '../../model'
import { MultiSelectFormWrapper } from './styles'

const t = loadNs(['common'])
interface InfinityMultiSelectFormProps extends BaseFormFilter {
  name: string
  valueField: string
  textField: string
  height: number
  additionalQueries?: any
  searchFunction: (payload: any) => Promise<any>
}

const InfinityMultiSelectForm = ({
  name,
  formModule,
  additionalQueries,
  searchFunction,
  setPopupShow,
  onFormSubmit,
  ...rest
}: InfinityMultiSelectFormProps) => {
  const { handleSubmit, getValues } = formModule

  const handleFormSubmit = useCallback(() => handleSubmit(onFormSubmit, onFormSubmit)(), [handleSubmit, onFormSubmit])

  const handleClickSubmit = () => {
    const submitFormValues = {
      [name]: getValues(name),
    }
    formModule.setValue('submitFormValues', submitFormValues)
    setPopupShow(false)
    handleFormSubmit()
  }

  return (
    <MultiSelectFormWrapper>
      <div style={{ padding: '0 5px' }}>
        <InfinityMultiSelectPicker
          name={name}
          listHeight={200}
          additionalQueries={additionalQueries}
          searchFunction={searchFunction}
          control={formModule.control}
          {...rest}
        />
      </div>
      <Divider style={{ marginBottom: 0, marginTop: 5 }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 10px', paddingTop: 10 }}>
        <Button type="button" color="blue" onClick={handleClickSubmit}>
          {t('actions.ok')}
        </Button>
      </div>
    </MultiSelectFormWrapper>
  )
}

export default InfinityMultiSelectForm
