import { loadNs } from 'aa_common/front-end/i18n-config'
import { TableColumn } from 'models/sorting'
import React from 'react'
import { Link } from 'react-router-dom'

const t = loadNs(['pages/accounting/depreciation-fixed-asset-page'])
export const getDepreciationFixedAssetColumns = (): TableColumn[] => [
  {
    field: 'submit_destination_code',
    text: t('columns.code'),
    sortable: false,
  },
  {
    field: 'submit_destination_name',
    text: t('columns.submit_destination_name'),
    sortable: false,
  },
  {
    field: 'apply_destination',
    text: t('columns.apply_destination'),
    sortable: false,
  },
  {
    field: 'total_export',
    text: t('columns.output_history'),
    sortable: false,
  },
]

export const convertDataToDisplay = (
  data: any,
  taxYear: number,
  openHistoryModal: (id: number, name: string) => void
): any => {
  return (
    data &&
    data.map((dataItem: any) => {
      const displayItem: any = { id: dataItem.id }
      const submitDestinationId = dataItem?.submit_destination_id
      const submitDestinationName = dataItem?.submit_destination_name

      getDepreciationFixedAssetColumns().forEach(({ field }: { field: string }) => {
        switch (field) {
          case 'submit_destination_name':
            displayItem[field] = (
              <Link
                to={{
                  pathname: `/tax-declaration/depreciation-fixed-asset/${submitDestinationId}/${taxYear}`,
                  state: { submitDestinationId, submitDestinationName },
                }}
                className="link"
              >
                {dataItem[field]}
              </Link>
            )
            break
          case 'total_export':
            displayItem[field] = (
              <span
                onClick={() => {
                  dataItem[field] > 0 && openHistoryModal(submitDestinationId, submitDestinationName)
                }}
                className={dataItem[field] === 0 ? '' : 'link'}
              >
                {dataItem[field]}回
              </span>
            )
            break
          default:
            displayItem[field] = dataItem[field]
            break
        }
      })

      return displayItem
    })
  )
}
