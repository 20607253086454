import Datepicker from 'components/atoms/v2/Datepicker'
import { DatepickerChangeEvent, RenderExtraFooterProps } from 'components/atoms/v2/Datepicker/model'
import { DATE_FORMAT_RFC } from 'constants/app'
import { isEqual } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'

import DatepickerFooterTemplate from './date-picker-footer-template'

interface IValidAtDatepicker {
  valid_at?: string | Moment
  onDateChanged: (value?: string) => void
}

const ValidAtDatepicker = ({ valid_at, onDateChanged }: IValidAtDatepicker) => {
  const [validAt, setValidAt] = useState<string | Moment | null>(null)

  const dispatch = useDispatch()
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)

  useEffect(() => {
    dispatch(fetchMonthlyClosingCurrent())
  }, []) // eslint-disable-line

  useEffect(() => {
    valid_at && setValidAt(moment(valid_at).format(DATE_FORMAT_RFC))
  }, [valid_at])

  const handleValidAtChanged = useCallback(
    (event: DatepickerChangeEvent) => {
      setValidAt(event.value)
      onDateChanged(event.value?.format(DATE_FORMAT_RFC))
    },
    [onDateChanged]
  )

  const renderExtraFooter = useCallback(
    ({ setDatepickerOpen }: RenderExtraFooterProps) => {
      const handleTodayClick = () => {
        const todayDate = moment().format(DATE_FORMAT_RFC)
        setValidAt(todayDate)
        setTimeout(() => onDateChanged(todayDate), 100)
        setDatepickerOpen(false)
      }

      const handleFinalDateOfProcessingMonthClick = () => {
        if (currentMonthlyClosing) {
          const endDate = currentMonthlyClosing.to_term_month.end_date
          setValidAt(endDate)
          setDatepickerOpen(false)
          setTimeout(() => onDateChanged(endDate), 100)
        }
      }

      return (
        <DatepickerFooterTemplate
          onTodayClick={handleTodayClick}
          onFinalDateOfProcessingMonthClick={handleFinalDateOfProcessingMonthClick}
        />
      )
    },
    [currentMonthlyClosing, onDateChanged]
  )

  return (
    <Datepicker
      value={validAt}
      name="valid_at"
      allowClear={false}
      className="date-picker"
      showToday={false}
      onDateChanged={handleValidAtChanged}
      renderExtraFooter={renderExtraFooter}
    />
  )
}

export default React.memo(ValidAtDatepicker)
