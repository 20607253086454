import { loadNs } from 'aa_common/front-end/i18n-config'
import { Table } from 'antd'
import { NoDataImage } from 'components/atoms'
import { ConnectAccPlusText } from 'components/molecules'
import { FEATURES_ID } from 'constants/const'
import useUserPermission from 'lib/hooks/useUserPermission'
import { isEqual } from 'lodash'
import { AccountItem } from 'models/accountItem'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentOffice, selectUserInfo } from 'store/session/selectors'
import { cleanupAccountItems, fetchAccountItemsAuth } from 'store/settings/accountItem/actions'
import {
  selectAccountItemList,
  selectAccountItemLoading,
  selectImportLoading,
} from 'store/settings/accountItem/selectors'

import { buildDataForTable, getColumns } from './helper'
import { EmptyImage, Wrapper } from './styles'

const t = loadNs(['components/organisms/settings/account-item-list'])

const AccountItemList = (_props: { onExportClick?: Function }) => {
  const dispatch = useDispatch()
  const accountItems = useSelector<any, AccountItem[]>(selectAccountItemList, isEqual) || []
  const accountItemLoading = useSelector(selectAccountItemLoading, isEqual)
  const loading = useSelector(selectImportLoading, isEqual) || false
  const { data: userInfo } = useSelector(selectUserInfo, isEqual)
  const { data: currentOffice } = useSelector(selectCurrentOffice, isEqual)
  const {
    permissions: { isRead, isEdit, isCreate, isDelete },
  } = useUserPermission(FEATURES_ID.MASTER_ACCOUNT_ITEMS)
  const CRUD = isRead && isCreate && isEdit && isDelete

  const expandedRowKeys = (): string[] => {
    return accountItems.map(item => item.unique_id as string)
  }

  useEffect(() => {
    dispatch(fetchAccountItemsAuth())

    return function cleanup() {
      dispatch(cleanupAccountItems())
    }
  }, []) // eslint-disable-line

  const handleOnReload = () => {
    dispatch(fetchAccountItemsAuth())
  }

  return (
    <Wrapper>
      {CRUD && (
        <ConnectAccPlusText
          userInfo={userInfo}
          currentOffice={currentOffice}
          onReload={handleOnReload}
          endPoint="/items"
        />
      )}
      {accountItems.length === 0 ? (
        <EmptyImage>
          <NoDataImage text={t('msg_no_data')} />
        </EmptyImage>
      ) : (
        <Table
          loading={loading || accountItemLoading}
          columns={getColumns()}
          dataSource={buildDataForTable(accountItems)}
          pagination={false}
          style={{ marginTop: 10 }}
          expandable={{
            defaultExpandAllRows: true,
            expandRowByClick: false,
            expandedRowKeys: expandedRowKeys(),
            expandIconColumnIndex: -1,
          }}
        />
      )}
    </Wrapper>
  )
}

export default AccountItemList
