import { UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import * as exciseApi from 'api/app/excise'
import { MoneyInput } from 'components/molecules'
import { ExciseSelect } from 'components/organisms'
import { EXCISE_CAN_EDIT_TAX, EXCISE_TAX_INPUT_FORMAT, EXCISE_TAX_METHOD } from 'constants/masterData'
import { useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import { Group } from '../events/styles'
import { ValueFields } from './helper'

const t = loadNs(['components/organisms/assets/asset-event-sell-form', 'common'])

const { Item } = UnborderCollapse
const contentItemWidth = 240
const itemWidth = '100%'

const SoldAmountWithExcise = ({ isEditMode }: { isEditMode?: boolean }) => {
  const { values, setFieldValue } = useFormikContext<ValueFields>()
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const [exciseSettingResponse, getExciseSettingByDate] = useResource(exciseApi.getExciseSettingsByDate)
  const [calculatedExciseValueResponse, getCalculatedExciseValue] = useResource(exciseApi.getExciseVatInfo)
  const soldAt: string | null = get(values, 'sold_at')
  const isTaxMethodExcluded = get(exciseSettingResponse.data, 'tax_method') === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const isTaxInputFormatExternalTax =
    get(exciseSettingResponse.data, 'tax_input_format') === EXCISE_TAX_INPUT_FORMAT.EXTERNAL_TAX
  const isCanEditTax = get(exciseSettingResponse.data, 'can_edit_tax') === EXCISE_CAN_EDIT_TAX.YES
  const soldExciseId: number | null = get(values, 'sold_excise_id')
  const soldAmountInput: number = get(values, 'sold_amount_input')
  const soldAmount: number = get(values, 'sold_amount')
  const manualSoldExciseValue: number | null = get(values, 'manual_sold_excise_value')
  const isSoldAmountIsZero = soldAmountInput === 0
  const isManualSoldExciseValue = !!manualSoldExciseValue || manualSoldExciseValue === 0

  useEffect(() => {
    if (soldAt) {
      getExciseSettingByDate(soldAt)
    }
  }, [soldAt]) // eslint-disable-line

  useEffect(() => {
    if (isDataLoaded && soldAt && soldExciseId && soldAmountInput) {
      getCalculatedExciseValue(soldAt, soldExciseId, soldAmountInput)
    }
  }, [soldExciseId, soldAt, soldAmountInput]) // eslint-disable-line

  useEffect(() => {
    if (isDataLoaded && soldAmountInput === 0) {
      setFieldValue('sold_amount', 0)
      setFieldValue('sold_amount_excluded_excise', 0)
      setFieldValue('calculated_sold_excise_value', 0)
      isCanEditTax && setFieldValue('manual_sold_excise_value', 0)
      setFieldValue('sold_excise_id', get(exciseSettingResponse.data, 'default_excise_sell_id'))
    }
  }, [soldAmountInput]) // eslint-disable-line

  useEffect(() => {
    if (!isTaxMethodExcluded && soldAmount === 0) {
      setFieldValue('sold_excise_id', get(exciseSettingResponse.data, 'default_excise_sell_id'))
    }
  }, [soldAmount]) // eslint-disable-line

  useEffect(() => {
    if (exciseSettingResponse.data) {
      const defaultExciseSoldAmountId = get(exciseSettingResponse.data, 'default_excise_sell_id')
      if (isEditMode) return
      setFieldValue(
        'sold_excise_id',
        soldExciseId !== defaultExciseSoldAmountId && !isEditMode ? defaultExciseSoldAmountId : soldExciseId
      )
    }
  }, [exciseSettingResponse.data]) // eslint-disable-line

  useEffect(() => {
    if ((manualSoldExciseValue || manualSoldExciseValue === 0) && isTaxMethodExcluded) {
      if (isTaxInputFormatExternalTax) {
        const soldAmountExcludedExcise = get(values, 'sold_amount_excluded_excise')
        setFieldValue('sold_amount', soldAmountExcludedExcise + manualSoldExciseValue)
      } else {
        const soldAmountIncludedExcise = get(values, 'sold_amount')
        setFieldValue('sold_amount_excluded_excise', soldAmountIncludedExcise - manualSoldExciseValue)
      }
    }
  }, [manualSoldExciseValue]) // eslint-disable-line

  useEffect(() => {
    if (calculatedExciseValueResponse.data) {
      setFieldValue('sold_amount', calculatedExciseValueResponse.data.included_excise_value)
      setFieldValue('sold_amount_excluded_excise', calculatedExciseValueResponse.data.excluded_excise_value)
      setFieldValue('calculated_sold_excise_value', calculatedExciseValueResponse.data.excise_value)
      isCanEditTax && setFieldValue('manual_sold_excise_value', null)
    }
  }, [calculatedExciseValueResponse.data]) // eslint-disable-line

  useEffect(() => {
    if (manualSoldExciseValue) {
      setFieldValue('calculated_sold_excise_value', manualSoldExciseValue)
    }

    setIsDataLoaded(true)
  }, []) // eslint-disable-line

  const handleOnChangeManualSoldExciseValue = (newValue: number) => {
    setFieldValue('manual_sold_excise_value', newValue)
  }

  const handleResetManualExciseValue = () => {
    const calculatedExciseValueResponseValue = calculatedExciseValueResponse.data

    if (manualSoldExciseValue !== null) {
      setFieldValue('manual_sold_excise_value', null)
    }

    if (calculatedExciseValueResponseValue) {
      setFieldValue('calculated_sold_excise_value', calculatedExciseValueResponseValue?.excise_value)
      setFieldValue('sold_amount', calculatedExciseValueResponseValue?.included_excise_value)
      setFieldValue('sold_amount_excluded_excise', calculatedExciseValueResponseValue?.excluded_excise_value)
    }
  }

  return (
    <>
      {isTaxMethodExcluded && isTaxInputFormatExternalTax && (
        <>
          <Item
            label={t('sold_amount_excluded_tax')}
            contentWidth={contentItemWidth}
            required
            style={{ width: itemWidth }}
          >
            <Group>
              <MoneyInput name="sold_amount_input" style={{ width: 240 }} />
            </Group>
          </Item>
          <Item label={t('sold_amount_excise')} contentWidth={contentItemWidth} required style={{ width: itemWidth }}>
            <Group>
              <ExciseSelect name="sold_excise_id" style={{ width: 240 }} isDisabled={isSoldAmountIsZero} />
            </Group>
          </Item>
          <Item
            label={t('sold_tax_amount')}
            contentWidth={contentItemWidth}
            style={{
              width: itemWidth,
              marginBottom: `${!isSoldAmountIsZero && isCanEditTax && isManualSoldExciseValue ? '20px' : '0'}`,
            }}
          >
            <Group>
              <UnderlineMoneyInput
                name="calculated_sold_excise_value"
                placeholder={t('sold_amount_excluded_tax_placeholder')}
                style={{ width: 240 }}
                editable={!isSoldAmountIsZero && isCanEditTax}
                onChange={handleOnChangeManualSoldExciseValue}
                isResetManualValue={!isSoldAmountIsZero && isCanEditTax && isManualSoldExciseValue}
                onHandleClickOnReset={handleResetManualExciseValue}
              />
            </Group>
          </Item>
          <Item label={t('sold_amount_included_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <UnderlineMoneyInput
                name="sold_amount"
                placeholder={t('sold_amount_included_tax_placeholder')}
                editable={false}
                style={{ width: 240 }}
              />
            </Group>
          </Item>
        </>
      )}
      {isTaxMethodExcluded && !isTaxInputFormatExternalTax && (
        <>
          <Item
            label={t('sold_amount_included_tax')}
            contentWidth={contentItemWidth}
            required
            style={{ width: itemWidth }}
          >
            <Group>
              <MoneyInput name="sold_amount_input" style={{ width: 240 }} />
            </Group>
          </Item>
          <Item label={t('sold_amount_excise')} contentWidth={contentItemWidth} required style={{ width: itemWidth }}>
            <Group>
              <ExciseSelect name="sold_excise_id" style={{ width: 240 }} isDisabled={isSoldAmountIsZero} />
            </Group>
          </Item>
          <Item
            label={t('sold_tax_amount')}
            contentWidth={contentItemWidth}
            style={{
              width: itemWidth,
              marginBottom: `${!isSoldAmountIsZero && isCanEditTax && isManualSoldExciseValue ? '20px' : '0'}`,
            }}
          >
            <Group>
              <UnderlineMoneyInput
                maxValue={get(values, 'sold_amount')}
                name="calculated_sold_excise_value"
                placeholder={t('sold_amount_excluded_tax_placeholder')}
                style={{ width: 240 }}
                editable={!isSoldAmountIsZero && isCanEditTax}
                onChange={handleOnChangeManualSoldExciseValue}
                isResetManualValue={!isSoldAmountIsZero && isCanEditTax && isManualSoldExciseValue}
                onHandleClickOnReset={handleResetManualExciseValue}
              />
            </Group>
          </Item>
          <Item label={t('sold_amount_excluded_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <UnderlineMoneyInput
                name="sold_amount_excluded_excise"
                placeholder={t('sold_amount_excluded_tax_placeholder')}
                editable={false}
                style={{ width: 240 }}
              />
            </Group>
          </Item>
        </>
      )}
      {!isTaxMethodExcluded && (
        <>
          <Item
            label={`${t('sold_amount_included_tax')}`}
            contentWidth={contentItemWidth}
            required
            style={{ width: itemWidth }}
          >
            <Group>
              <MoneyInput name="sold_amount" style={{ width: 240 }} />
            </Group>
          </Item>
          <Item label={t('sold_amount_excise')} contentWidth={contentItemWidth} required style={{ width: itemWidth }}>
            <Group>
              <ExciseSelect name="sold_excise_id" style={{ width: 240 }} isDisabled={!get(values, 'sold_amount')} />
            </Group>
          </Item>
        </>
      )}
    </>
  )
}

export default SoldAmountWithExcise
