import { AutoCompleteSelect, Spin } from 'aa_common/front-end/antd'
import { BreadcrumbNavigation } from 'components/molecules'
import DepreciationFixedAssetList from 'components/organisms/accounting/DepreciationFixedAssetList'
import { ListPageTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTaxDeclarationReportList } from 'store/accounting/depreciationFixedAsset/actions'
import { selectTaxDeclarationReportList } from 'store/accounting/depreciationFixedAsset/selectors'
import { selectMasterTaxDeclarationYear } from 'store/masterData/selectors'

import { ActionWrapper } from './styles'

const breadcrumb = (
  <BreadcrumbNavigation sectionName={i18n.t('pages.accounting.DepreciationFixedAssetPage.titles.title_page')} />
)

const DepreciationFixedAssetPage = () => {
  const dispatch = useDispatch()
  const { list: taxDeclarationReportList, loading } = useSelector(selectTaxDeclarationReportList, isEqual)
  const { masterTaxDeclaration, currentTaxInfo } = useSelector(selectMasterTaxDeclarationYear, isEqual)
  const [taxYearInfo, setTaxYearInfo] = useState<number>(currentTaxInfo?.code)

  useEffect(() => {
    taxYearInfo && dispatch(fetchTaxDeclarationReportList({ year: taxYearInfo }))
  }, [taxYearInfo, dispatch])

  const handleChangeTaxYear = (year: number) => setTaxYearInfo(year)

  return (
    <ListPageTemplate breadcrumb={breadcrumb}>
      <ActionWrapper>
        <AutoCompleteSelect
          style={{
            maxWidth: '200px',
            width: '100%',
          }}
          name="depreciation_assets_tax_declaration"
          valueField="code"
          textField="name_jp"
          value={taxYearInfo}
          onChange={handleChangeTaxYear}
          items={masterTaxDeclaration}
          allowClear={false}
        />
      </ActionWrapper>
      <Spin loading={loading}>
        <DepreciationFixedAssetList data={taxDeclarationReportList || []} taxYear={taxYearInfo} />
      </Spin>
    </ListPageTemplate>
  )
}

export default DepreciationFixedAssetPage
