import * as unitApi from 'api/app/unit'
import { AxiosResponse } from 'axios'
import { DATA_IN_USED_ERROR_CODE } from 'constants/apiCode'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { changeLocation, setAppMessage, SHOW_IN_USED_MESSAGE, showErrorsAppCall } from 'store/app/actions'
import { parseError, parseValidationError } from 'utils/ResponseHelper'

import {
  CreateUnitRequest,
  DeleteUnitRequest,
  HandleSubmitUnitFailure,
  SubmitUnitFailure,
  UpdateUnitRequest,
} from './action.model'
import { fetchUnitsRequestFailure, fetchUnitsRequestSuccess } from './actions'
import {
  CHANGE_UNIT_POSITION_FAILURE,
  CHANGE_UNIT_POSITION_REQUEST,
  CHANGE_UNIT_POSITION_SUCCESS,
  CREATE_UNIT_REQUEST,
  DELETE_UNIT_FAILURE,
  DELETE_UNIT_REQUEST,
  DELETE_UNIT_SUCCESS,
  FETCH_UNIT_FAILURE,
  FETCH_UNIT_REQUEST,
  FETCH_UNIT_SUCCESS,
  FETCH_UNITS_REQUEST,
  FETCH_UNITS_REQUEST_AUTH,
  HANDLE_SUBMIT_UNIT_FAILURE,
  SUBMIT_UNIT_FAILURE,
  SUBMIT_UNIT_SUCCESS,
  UPDATE_UNIT_REQUEST,
} from './constant'

export function* handleSubmitUnitFailure({ error }: HandleSubmitUnitFailure) {
  const errorResponse: AxiosResponse<any> = get(error, 'response')
  switch (errorResponse?.status) {
    case 422: {
      const validationErrors = get(error, 'response.data.errors')
      const payload = parseValidationError(validationErrors)
      yield put<SubmitUnitFailure>({ type: SUBMIT_UNIT_FAILURE, formErrors: payload })
      break
    }
    default: {
      yield put(showErrorsAppCall(parseError(error)))
      yield put<SubmitUnitFailure>({ type: SUBMIT_UNIT_FAILURE })
    }
  }
}

export function* fetchUnits() {
  const { data, errors } = yield call(unitApi.list)
  if (data) {
    yield put(fetchUnitsRequestSuccess(data?.data || []))
  } else if (errors) {
    yield put(fetchUnitsRequestFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchUnitsAuth() {
  const { data, errors } = yield call(unitApi.listAuth)
  if (data) {
    yield put(fetchUnitsRequestSuccess(data?.data || []))
  } else if (errors) {
    yield put(fetchUnitsRequestFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* createUnit({ payload }: CreateUnitRequest) {
  try {
    yield call(unitApi.create, payload)
    yield put(changeLocation('/master/units'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
    yield put({ type: SUBMIT_UNIT_SUCCESS })
  } catch (error) {
    yield put<HandleSubmitUnitFailure>({ type: HANDLE_SUBMIT_UNIT_FAILURE, error })
  }
}

export function* fetchUnit({ id }: Record<string, any>): Generator<any> {
  try {
    const response = (yield call(unitApi.get, id)) as AxiosResponse
    yield put({ type: FETCH_UNIT_SUCCESS, payload: response.data.data })
  } catch (err) {
    yield put({ type: FETCH_UNIT_FAILURE })
    yield put(showErrorsAppCall(parseError(err)))
  }
}

export function* updateUnit({ id, payload }: UpdateUnitRequest) {
  try {
    yield call(unitApi.update, id, payload)
    yield put(changeLocation('/master/units'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
    yield put({ type: SUBMIT_UNIT_SUCCESS })
  } catch (error) {
    yield put<HandleSubmitUnitFailure>({ type: HANDLE_SUBMIT_UNIT_FAILURE, error })
  }
}

export function* deleteUnit({ payload: { id, name } }: DeleteUnitRequest) {
  try {
    const checkDeleteResponse: AxiosResponse = yield call(unitApi.destroy, id, true)
    const isAllowDelete = checkDeleteResponse.data.data && checkDeleteResponse.data.data.id
    if (isAllowDelete) {
      yield call(unitApi.destroy, id, false)
      yield put({ type: DELETE_UNIT_SUCCESS })
      yield put({ type: FETCH_UNITS_REQUEST })
      yield put(
        setAppMessage({
          type: 'success',
          content: i18n.t('common.messages.deleted_success'),
        })
      )
    }
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400 && get(data, 'errors[0].code') === DATA_IN_USED_ERROR_CODE) {
        yield put({
          type: SHOW_IN_USED_MESSAGE,
          payload: i18n.t('common.messages.deleted_failure_by_in_used', { name }),
        })
      } else {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure', { name }),
          })
        )
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
    yield put({ type: DELETE_UNIT_FAILURE })
  }
}

export function* changeUnitPostion({ payload }: Record<string, any>) {
  try {
    yield call(unitApi.change_position, payload)
    yield put({ type: CHANGE_UNIT_POSITION_SUCCESS })
  } catch (err) {
    yield put({ type: CHANGE_UNIT_POSITION_FAILURE })
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.change_position_failure'),
      })
    )
    yield put(showErrorsAppCall(parseError(err)))
  } finally {
    // call units list API to reorder units
    yield put({ type: FETCH_UNITS_REQUEST })
  }
}

export default function* (): Generator<any> {
  yield takeLatest(FETCH_UNITS_REQUEST, fetchUnits)
  yield takeLatest(FETCH_UNITS_REQUEST_AUTH, fetchUnitsAuth)
  yield takeLatest(CREATE_UNIT_REQUEST, createUnit)
  yield takeLatest(FETCH_UNIT_REQUEST, fetchUnit)
  yield takeLatest(UPDATE_UNIT_REQUEST, updateUnit)
  yield takeLatest(DELETE_UNIT_REQUEST, deleteUnit)
  yield takeLatest(CHANGE_UNIT_POSITION_REQUEST, changeUnitPostion)
  yield takeLatest(HANDLE_SUBMIT_UNIT_FAILURE, handleSubmitUnitFailure)
}
