import styled from '@emotion/styled'
import { GREY_BORDER_COLOR } from 'constants/styles'

export const ItemWrapper = styled.div`
  border: 1px solid ${GREY_BORDER_COLOR};
  background: #ffffff;
  border-radius: 4px;
  width: auto;

  .header {
    padding: 10px 15px;
    border-bottom: 1px solid ${GREY_BORDER_COLOR};

    .period {
      .current {
        color: #666666;
      }
    }
  }

  .body {
    padding: 10px 15px 15px;
  }

  .footer {
    border-top: 1px solid ${GREY_BORDER_COLOR};
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .aa-link {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`
