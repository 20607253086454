import { useEffect, useRef, useState } from 'react'

export function useWebSocket(url: string) {
  const [recalculateData, setRecalculateData] = useState<RecalculateData | null>(null)
  const webSocketRef = useRef<WebSocket | null>(null)

  useEffect(() => {
    const createWebSocket = () => {
      const ws = new WebSocket(url)
      ws.onmessage = e => {
        const data = e?.data && JSON.parse(e?.data)
        setRecalculateData(data)
      }

      ws.onclose = () => {
        createWebSocket()
      }

      webSocketRef.current = ws
    }

    if (!webSocketRef.current) {
      createWebSocket()
    }

    return () => {
      if (webSocketRef.current) {
        webSocketRef.current.close()
      }
    }
  }, [url])

  return { recalculateData, setRecalculateData }
}

type RecalculateData = {
  total: number
  success_count: number
  status: number
  error: string
}
