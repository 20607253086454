import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Spin } from 'aa_common/front-end/antd'
import { ExportModal } from 'aa_common/front-end/components'
import { Table } from 'antd'
import { NoDataImage } from 'components/atoms'
import RadioGroup from 'components/atoms/v2/RadioGroup'
import { BasicForm } from 'components/molecules'
import i18n from 'i18n'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { Aggregation, MoneyUnits } from 'models/depreciation'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, useLocation } from 'react-router-dom'
import { addTaxDeclarationReportsFormFilterSetting } from 'store/accounting/depreciationFixedAsset/actions'
import { selectTaxDeclarationReportFormFilterSetting } from 'store/accounting/depreciationFixedAsset/selectors'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'
import {
  addDepreciationResultRowKey,
  exportDepreciationResult,
  fetchResults,
  removeDepreciationResultRowKey,
} from 'store/depreciation/result/actions'
import { selectDepreciationReportRowKeys, selectDepreciationResults } from 'store/depreciation/result/selectors'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsList } from 'store/settings/accountingPeriod/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectorLedgerSettingsList } from 'store/settings/ledgerSetting/selectors'

import Form from './Form'
import { buildColumns, buildMonthIds, parseDataForReport } from './helpers'
import { ExportModalBody, NoData, Wrapper } from './styles'

enum ExportByUnit {
  ASSET_UNIT = 2,
  ASSET_CATEGORY = 1,
}

const EXPORT_BY_UNIT_OPTIONS = [
  {
    label: i18n.t('components.DepreciationResultList.asset_unit'),
    value: ExportByUnit.ASSET_UNIT,
  },
  {
    label: i18n.t('components.DepreciationResultList.asset_category'),
    value: ExportByUnit.ASSET_CATEGORY,
  },
]

const exportDataFormDefault = {
  output_unit: ExportByUnit.ASSET_UNIT,
  charset: 'utf-8',
  ledger_setting_id: null,
  term_id: null,
  mode: Aggregation.DEPRECIATION_CALCULATION,
}

const customExpandIcon = ({ expanded, onExpand, record }: any) => {
  if (expanded) {
    return <CaretDownOutlined onClick={e => onExpand(record, e)} />
  }
  return get(record, 'children') && <CaretRightOutlined onClick={e => onExpand(record, e)} />
}

const DepreciationResultList = () => {
  const dispatch = useDispatch()
  const [initialValues, setInitialValue] = useState<any>(null)
  const [submitValues, setSubmitValues] = useState<any>(null)
  const [isShowExportModal, setIsShowExportModal] = useState(false)
  const [exportFormData, setExportFormData] = useState(exportDataFormDefault)
  const outputUnitRef = useRef(exportDataFormDefault.output_unit)
  const ledgerSettingsSelector = useSelector(selectorLedgerSettingsList, isEqual)
  const depreciationResultsSelector = useSelector(selectDepreciationResults, isEqual)
  const termsSelector = useSelector(selectTermsList, isEqual)
  const currentMonthlyClosingSelector = useSelector(selectCurrentMonthlyClosing, isEqual)
  const savedFormFilterSettings = useSelector(selectTaxDeclarationReportFormFilterSetting, isEqual).formFilter
  const rowKeys = useSelector(selectDepreciationReportRowKeys, isEqual)

  const terms = useMemo(() => termsSelector.data, [termsSelector.data])
  const ledgerSettings = useMemo(() => ledgerSettingsSelector.data, [ledgerSettingsSelector.data])
  const depreciationResults = useMemo(() => depreciationResultsSelector.data, [depreciationResultsSelector.data])
  const isLoading = termsSelector.loading || ledgerSettingsSelector.loading || depreciationResultsSelector.loading
  const currentMonthlyClosing = useMemo(() => currentMonthlyClosingSelector.data, [currentMonthlyClosingSelector.data])
  const [currencyUnit, setCurrencyUnit] = useState<number>(1)

  const location = useLocation()
  const isRedirectFromMonthlyClosing = location.pathname.includes('/accounting/monthly-closing')

  useEffect(() => {
    const defaultValues = savedFormFilterSettings || {
      ledger_setting_id: null,
      term_id: null,
      unit: MoneyUnits.YEN,
      mode: Aggregation.DEPRECIATION_CALCULATION,
    }

    const hasLedgerSettings = ledgerSettings && ledgerSettings.length > 0
    const hasTerms = terms && terms.length > 0
    if (hasTerms && hasLedgerSettings) {
      const ledger_setting_id = defaultValues?.ledger_setting_id || ledgerSettings[0].id
      const term_id = defaultValues?.term_id || currentMonthlyClosing?.monthly_closing_setting?.term?.id || terms[0].id

      setInitialValue({
        ...defaultValues,
        ledger_setting_id,
        term_id,
      })
    }
  }, [terms, ledgerSettings, currentMonthlyClosing, savedFormFilterSettings])

  useEffect(() => {
    if (!ledgerSettings || ledgerSettings?.length === 0) {
      dispatch(fetchLedgerSettingsList())
    }
    if (!terms || terms?.length === 0) {
      dispatch(fetchTerms())
    }
    !currentMonthlyClosing && dispatch(fetchMonthlyClosingCurrent())
  }, [terms, ledgerSettings]) //eslint-disable-line

  const handleExportUnit = (value: any) => {
    if (value) {
      setCurrencyUnit(value)
    }
  }

  const handleExport = (charset: string) => {
    const currencyUnitFormat = currencyUnit === 1 ? 1 : 1000
    const exportFormDataFormat = {
      ...exportFormData,
      charset,
      output_unit: outputUnitRef.current,
      currency_unit: currencyUnitFormat,
      mode: get(submitValues, 'mode'),
    }
    dispatch(exportDepreciationResult(exportFormDataFormat, isRedirectFromMonthlyClosing))
    setIsShowExportModal(false)
  }

  const handleSubmit = (values: any) => {
    dispatch(fetchResults(values, isRedirectFromMonthlyClosing))
    setSubmitValues(values)
    setExportFormData({
      ...exportFormData,
      ledger_setting_id: get(values, 'ledger_setting_id'),
      term_id: get(values, 'term_id'),
      mode: get(values, 'mode'),
    })
  }

  const hasData = () => (get(depreciationResults, ['items', '0', 'items']) || []).length > 0

  const handleClickAssetName = (assetItem: any) => {
    dispatch(changeLocation(`/assets/${assetItem.biid}`))
  }

  const highlightMonths = useMemo(() => {
    let months: number[] = []

    if (currentMonthlyClosing) {
      months = buildMonthIds(currentMonthlyClosing.from_term_month, currentMonthlyClosing.to_term_month)
    }

    return months
  }, [currentMonthlyClosing])

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      dispatch(addDepreciationResultRowKey(record.key))
    } else {
      dispatch(removeDepreciationResultRowKey(record.key))
    }
  }
  return (
    <Wrapper>
      <Spin loading={isLoading}>
        {initialValues && (
          <BasicForm initialValues={initialValues} onSubmit={handleSubmit}>
            <Form
              terms={terms}
              ledgers={ledgerSettings}
              hasData={hasData()}
              onValuesChanged={handleSubmit}
              onClickExport={() => setIsShowExportModal(true)}
              handleExportUnit={handleExportUnit}
            />
          </BasicForm>
        )}
        {hasData() ? (
          <Table
            columns={buildColumns(depreciationResults, handleClickAssetName, highlightMonths)}
            dataSource={parseDataForReport(depreciationResults, get(submitValues, 'unit'))}
            pagination={false}
            style={{ marginTop: 10 }}
            bordered
            scroll={{ x: 1675, y: 'calc(100vh - 240px)' }}
            expandedRowKeys={rowKeys.length > 0 ? rowKeys : undefined}
            expandable={{
              defaultExpandAllRows: false,
              expandRowByClick: false,
              expandIcon: customExpandIcon,
            }}
            onExpand={handleExpand}
          />
        ) : (
          <NoData>
            <NoDataImage text={i18n.t('components.DepreciationResultList.no_data_msg')} />
          </NoData>
        )}
      </Spin>
      <ExportModal
        title={i18n.t('components.DepreciationResultList.export_title')}
        isShow={isShowExportModal}
        onCancel={() => setIsShowExportModal(false)}
        onExport={handleExport}
      >
        <ExportModalBody>
          <h4>{i18n.t('components.DepreciationResultList.select_output_unit')}</h4>
          <RadioGroup
            name="output_unit"
            options={EXPORT_BY_UNIT_OPTIONS}
            defaultValue={outputUnitRef.current}
            onChange={e => (outputUnitRef.current = e.target.value)}
          />
          <h4>{i18n.t('components.DepreciationResultList.select_file_format')}</h4>
        </ExportModalBody>
      </ExportModal>
      <Prompt
        message={() => {
          // Save the form data
          dispatch(addTaxDeclarationReportsFormFilterSetting(submitValues))
          return true
        }}
      />
    </Wrapper>
  )
}

export default DepreciationResultList
