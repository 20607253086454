import { downloadFileByResponse } from 'aa_common/front-end/helpers/file-helper'
import * as submitDestinationApi from 'api/app/submitDestination'
import { AxiosResponse } from 'axios'
import { DATA_IN_USED_ERROR_CODE } from 'constants/apiCode'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { changeLocation, setAppMessage, SHOW_IN_USED_MESSAGE, showErrorsAppCall } from 'store/app/actions'
import { parseError, parseImportErrors, parseValidationError } from 'utils/ResponseHelper'

import {
  CreateSubmitDestinationRequest,
  DeleteSubmitDestinationRequest,
  FetchSubmitDestinationsRequest,
  FetchSubmitDestinationSuccess,
  HandleSubmitSubmitDestinationFailure,
  ImportSubmitDestinationFailure,
  SubmitSubmitDestinationFailure,
  UpdateSubmitDestinationRequest,
} from './action.model'
import { fetchSubmitDestinationsSuccess } from './actions'
import {
  CREATE_SUBMIT_DESTINATION_REQUEST,
  DELETE_SUBMIT_DESTINATION_FAILURE,
  DELETE_SUBMIT_DESTINATION_REQUEST,
  DELETE_SUBMIT_DESTINATION_SUCCESS,
  EXPORT_SUBMIT_DESTINATION_FAILURE,
  EXPORT_SUBMIT_DESTINATION_REQUEST,
  EXPORT_SUBMIT_DESTINATION_SUCCESS,
  FETCH_SUBMIT_DESTINATION_REQUEST,
  FETCH_SUBMIT_DESTINATION_SUCCESS,
  FETCH_SUBMIT_DESTINATIONS_REQUEST,
  FETCH_SUBMIT_DESTINATIONS_REQUEST_AUTH,
  HANDLE_SUBMIT_SUBMIT_DESTINATION_FAILURE,
  IMPORT_SUBMIT_DESTINATION_FAILURE,
  IMPORT_SUBMIT_DESTINATION_REQUEST,
  IMPORT_SUBMIT_DESTINATION_SUCCESS,
  SUBMIT_SUBMIT_DESTINATION_FAILURE,
  SUBMIT_SUBMIT_DESTINATION_SUCCESS,
  UPDATE_SUBMIT_DESTINATION_REQUEST,
} from './constant'

export const selector = {
  getFilterConditions: (state: any) =>
    state.getIn(['settings', 'submitDestination', 'list', 'filters']) as Record<string, any>,
  getSort: (state: any) => state.getIn(['settings', 'submitDestination', 'list', 'sort']) as string[],
}

export function* handleSubmitSubmitDestinationFailure({ error }: any) {
  const errorResponse: AxiosResponse<any> = get(error, 'response')
  switch (errorResponse?.status) {
    case 422: {
      const validationErrors = get(error, 'response.data.errors')
      const payload = parseValidationError(validationErrors)
      yield put<SubmitSubmitDestinationFailure>({ type: SUBMIT_SUBMIT_DESTINATION_FAILURE, formErrors: payload })
      break
    }
    default: {
      yield put(showErrorsAppCall(parseError(error)))
      yield put<SubmitSubmitDestinationFailure>({ type: SUBMIT_SUBMIT_DESTINATION_FAILURE })
    }
  }
}

export function* fetchSubmitDestinations(action: FetchSubmitDestinationsRequest) {
  const { data, errors } = yield call(submitDestinationApi.search, action.payload)
  if (data) {
    const totalRecords = get(data, 'meta.total', 0)
    yield put(
      fetchSubmitDestinationsSuccess({
        data: data?.data || [],
        totalRecords,
      })
    )
  } else if (errors) {
    yield put(fetchSubmitDestinationsSuccess([]))
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchSubmitDestinationsAuth(action: FetchSubmitDestinationsRequest) {
  const { data, errors } = yield call(submitDestinationApi.searchAuth, action.payload)
  if (data) {
    const totalRecords = get(data, 'meta.total', 0)
    yield put(
      fetchSubmitDestinationsSuccess({
        data: data?.data || [],
        totalRecords,
      })
    )
  } else if (errors) {
    yield put(fetchSubmitDestinationsSuccess([]))
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchSubmitDestination({ id }: any): Generator<any> {
  try {
    const response = (yield call(submitDestinationApi.get, id)) as AxiosResponse
    yield put<FetchSubmitDestinationSuccess>({
      type: FETCH_SUBMIT_DESTINATION_SUCCESS,
      payload: {
        data: response.data?.data,
        totalRecords: response.data?.meta?.total || 0,
      },
    })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createSubmitDestination({ payload }: CreateSubmitDestinationRequest) {
  try {
    yield call(submitDestinationApi.create, payload)
    yield put({ type: SUBMIT_SUBMIT_DESTINATION_SUCCESS })
    yield put(changeLocation('/master/submit-destinations'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitSubmitDestinationFailure>({
      type: HANDLE_SUBMIT_SUBMIT_DESTINATION_FAILURE,
      error,
    })
  }
}
export function* updateSubmitDestination({ id, payload }: UpdateSubmitDestinationRequest) {
  try {
    yield call(submitDestinationApi.update, id, payload)
    yield put({ type: SUBMIT_SUBMIT_DESTINATION_SUCCESS })
    yield put(changeLocation('/master/submit-destinations'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitSubmitDestinationFailure>({
      type: HANDLE_SUBMIT_SUBMIT_DESTINATION_FAILURE,
      error,
    })
  }
}

export function* deleteSubmitDestination({ payload }: DeleteSubmitDestinationRequest) {
  const { id, original_name } = payload

  try {
    const filters: ReturnType<typeof selector.getFilterConditions> = yield select(selector.getFilterConditions)
    const sort: ReturnType<typeof selector.getSort> = yield select(selector.getSort)

    yield call(submitDestinationApi.destroy, id, false)
    yield put({ type: DELETE_SUBMIT_DESTINATION_SUCCESS })
    yield put<FetchSubmitDestinationsRequest>({ type: FETCH_SUBMIT_DESTINATIONS_REQUEST, payload: { filters, sort } })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400 && get(data, 'errors[0].code') === DATA_IN_USED_ERROR_CODE) {
        yield put({
          type: SHOW_IN_USED_MESSAGE,
          payload: i18n.t('common.messages.deleted_failure_by_in_used', { name: original_name }),
        })
      } else {
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
    yield put({ type: DELETE_SUBMIT_DESTINATION_FAILURE })
  }
}

export function* importSubmitDestinations({ payload, file }: any) {
  try {
    yield call(submitDestinationApi.importFile, payload, file)
    yield put({ type: IMPORT_SUBMIT_DESTINATION_SUCCESS })
    yield put<FetchSubmitDestinationsRequest>({ type: FETCH_SUBMIT_DESTINATIONS_REQUEST, payload: {} })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.imported_success'),
      })
    )
  } catch (error: any) {
    const { status, data } = error.response

    if (status === 400 || status === 422) {
      const importedErrors = parseImportErrors(data)

      yield put<ImportSubmitDestinationFailure>({ type: IMPORT_SUBMIT_DESTINATION_FAILURE, payload: importedErrors })
    } else {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export function* exportSubmitDestinations({ payload }: any) {
  try {
    const filters: ReturnType<typeof selector.getFilterConditions> = yield select(selector.getFilterConditions)
    const response: AxiosResponse = yield call(submitDestinationApi.exportFile, payload, filters)
    yield downloadFileByResponse(response)
    yield put({ type: EXPORT_SUBMIT_DESTINATION_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      })
    )
  } catch (error) {
    yield put({ type: EXPORT_SUBMIT_DESTINATION_FAILURE })
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.exported_failure'),
      })
    )
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher(): Generator<any> {
  yield takeLatest(FETCH_SUBMIT_DESTINATIONS_REQUEST, fetchSubmitDestinations)
  yield takeLatest(FETCH_SUBMIT_DESTINATIONS_REQUEST_AUTH, fetchSubmitDestinationsAuth)
  yield takeLatest(FETCH_SUBMIT_DESTINATION_REQUEST, fetchSubmitDestination)
  yield takeLatest(CREATE_SUBMIT_DESTINATION_REQUEST, createSubmitDestination)
  yield takeLatest(UPDATE_SUBMIT_DESTINATION_REQUEST, updateSubmitDestination)
  yield takeLatest(DELETE_SUBMIT_DESTINATION_REQUEST, deleteSubmitDestination)
  yield takeLatest(IMPORT_SUBMIT_DESTINATION_REQUEST, importSubmitDestinations)
  yield takeLatest(EXPORT_SUBMIT_DESTINATION_REQUEST, exportSubmitDestinations)
  yield takeLatest(HANDLE_SUBMIT_SUBMIT_DESTINATION_FAILURE, handleSubmitSubmitDestinationFailure)
}
