import { loadNs } from 'aa_common/front-end/i18n-config'
import { AutoCompleteSelect, BasicForm } from 'components/molecules'
import isEqual from 'lodash/isEqual'
import { MONTHLY_CLOSING_CYCLES } from 'models/monthlyClosing'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  cleanupMonthlyClosingNextSeting,
  fetchMonthlyClosingClosed,
  fetchMonthlyClosingCurrent,
  fetchMonthlyClosingNextSeting,
  updateMonthlyClosingSeting,
} from 'store/accounting/monthlyClosing/actions'
import {
  selectMonthlyClosingCurrent,
  selectMonthlyClosingNextSetting,
  selectMonthlyClosingsClosed,
} from 'store/accounting/monthlyClosing/selectors'

import { BoxWrapper, FieldLabel, Notice, Wrapper } from './styles'

const t = loadNs(['common', 'components/organisms/accounting/monthly-closing-view'])
const closingCycles = [
  { value: MONTHLY_CLOSING_CYCLES.MONTHLY, text: t('units.month') },
  { value: MONTHLY_CLOSING_CYCLES.QUARTER, text: t('units.quarter') },
]

type FormFields = {
  setting_id: number
  closing_cycle: number
}

const MonthlyClosingSettingForm: React.FC = () => {
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState<FormFields | null>(null)
  const [isNextYearSetting, setIsNextYearSetting] = useState<boolean | null>(null)
  const monthlyClosingCurrent = useSelector(selectMonthlyClosingCurrent, isEqual)
  const closedMonthlyClosings = useSelector(selectMonthlyClosingsClosed, isEqual)
  const nextSetting = useSelector(selectMonthlyClosingNextSetting, isEqual)

  const handleSelectChanged = (value: any) => {
    const data = { closing_cycle: value }

    if (isNextYearSetting) {
      dispatch(updateMonthlyClosingSeting(nextSetting, data))
    } else {
      dispatch(updateMonthlyClosingSeting(monthlyClosingCurrent.monthly_closing_setting, data))
    }
  }

  useEffect(() => {
    if (monthlyClosingCurrent) {
      dispatch(fetchMonthlyClosingClosed(monthlyClosingCurrent.monthly_closing_setting.id))
    }
  }, [monthlyClosingCurrent]) // eslint-disable-line

  useEffect(() => {
    if (closedMonthlyClosings) {
      if (closedMonthlyClosings.length === 0) {
        // if have no closed monthly_closings
        setIsNextYearSetting(false)
        setInitialValues({
          setting_id: monthlyClosingCurrent.monthly_closing_setting.id,
          closing_cycle: monthlyClosingCurrent.monthly_closing_setting.closing_cycle,
        })
      } else {
        // if have closed monthly_closings
        setIsNextYearSetting(true)
        nextSetting &&
          setInitialValues({
            setting_id: nextSetting.id,
            closing_cycle: nextSetting.closing_cycle,
          })
      }
    }
  }, [closedMonthlyClosings, nextSetting]) // eslint-disable-line

  useEffect(() => {
    isNextYearSetting && dispatch(fetchMonthlyClosingNextSeting())
  }, [isNextYearSetting]) // eslint-disable-line

  useEffect(() => {
    !monthlyClosingCurrent && dispatch(fetchMonthlyClosingCurrent())

    return function cleanup() {
      dispatch(cleanupMonthlyClosingNextSeting())
    }
  }, []) // eslint-disable-line

  return (
    <Wrapper>
      {isNextYearSetting && (
        <Notice>
          {t('next_year_monthly_closing_notice', {
            year: monthlyClosingCurrent.monthly_closing_setting.term.year,
          })}
        </Notice>
      )}
      <BoxWrapper>
        {isNextYearSetting !== null && (
          <FieldLabel>
            {t('closing_cycle', {
              year:
                isNextYearSetting && nextSetting ? nextSetting.term.year : monthlyClosingCurrent.from_term_month.year,
              year_string: isNextYearSetting ? t('next_year') : t('current_year'),
            })}
          </FieldLabel>
        )}
        {initialValues && (
          <BasicForm initialValues={initialValues} onSubmit={() => true}>
            <AutoCompleteSelect
              name="closing_cycle"
              afterChanged={handleSelectChanged}
              items={closingCycles}
              style={{ width: 100 }}
              allowClear={false}
              allowSearch={false}
            />
          </BasicForm>
        )}
      </BoxWrapper>
    </Wrapper>
  )
}

export default MonthlyClosingSettingForm
