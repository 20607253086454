import { faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Popover } from 'antd'
import i18n from 'i18n'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSeenNotifications } from 'store/session/actions'
import { selectNotifications } from 'store/session/selectors'

import { Spin } from '../../antd/spin'
import { Tooltip } from '../../antd/tooltip'
import { URL_AA_NEW_ANNOUCEMENT, URL_AA_VIEW_RELEASE } from '../../constants/app'
import { formatDate } from '../../helpers/format-helper'
import { LinkNotificationSecondStyled, LinkNotificationStyled, NotificationWrapperClass } from './styles'

export const Notification = () => {
  const dispatch = useDispatch()
  const { notifications, loading, unreadCount } = useSelector(selectNotifications)
  const [notification, setNotification] = useState(notifications)

  useEffect(() => {
    const defaultTemporaryAnnouncement = {
      id: 1,
      title: 'サポートサイトのURL変更のお知らせ',
      url: URL_AA_NEW_ANNOUCEMENT,
      released_at: '2021-09-29',
      is_highlighted: true,
    }
    setNotification([...notifications, defaultTemporaryAnnouncement])
  }, [notifications])

  return (
    <Popover
      overlayClassName={NotificationWrapperClass}
      placement="bottomRight"
      arrowPointAtCenter
      trigger="click"
      content={
        <div>
          <Spin loading={loading} size="small">
            {notification.map(n => (
              <React.Fragment key={n.id}>
                <span className="release_date">{formatDate(n.released_at)}</span>
                <LinkNotificationStyled highlight={n.is_highlighted} href={n.url} target="_blank" rel="noreferrer">
                  {n.title}
                </LinkNotificationStyled>
              </React.Fragment>
            ))}
            {notification.length === 0 && (
              <span className="no_notification" style={{ opacity: loading ? 0 : 1 }}>
                {i18n.t('components.MainHeader.notification.no_notification')}
              </span>
            )}
          </Spin>
          <LinkNotificationSecondStyled href={URL_AA_VIEW_RELEASE} target="_blank" rel="noreferrer">
            {i18n.t('components.MainHeader.notification.view_list')}
          </LinkNotificationSecondStyled>
        </div>
      }
      onVisibleChange={visible => {
        if (!visible) dispatch(setSeenNotifications(notifications))
      }}
    >
      <Badge dot count={unreadCount} className="header-icon">
        <Tooltip
          arrowPointAtCenter
          placement="bottom"
          overlayStyle={{ pointerEvents: 'none' }}
          content={i18n.t('components.MainHeader.notification.tooltip')}
        >
          <FontAwesomeIcon icon={faBullhorn} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
        </Tooltip>
      </Badge>
    </Popover>
  )
}
