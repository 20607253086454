import { AutoCompleteSelect, Button } from 'aa_common/front-end/antd'
import { useModalState } from 'aa_common/front-end/hooks'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Modal } from 'antd'
import { RenderFormProps } from 'components/molecules/Form'
import { filter, find, isEmpty, isEqual, pick } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectorJournalSettingsForAssetCategoryList } from 'store/accounting/journal/selectors'

import { AUTO_JOURNAL_SETTING_FORM_FIELDS } from '../AutoJournalSettingHalfModal/const'
import { handleDisableAccoutItem } from '../JournalItemSettingForm/helper'
import { Actions, ModalWrapperClass } from './styles'

interface CopyAssetCategoriesAutoJournalModalProps {
  selectedAssetCategory: any
}

const t = loadNs(['common', 'components/organisms/accounting/auto-journal-setting-tab'])
const CopyAssetCategoriesAutoJournalModal = React.forwardRef(function CopyJournalItemSettingModal(
  props: CopyAssetCategoriesAutoJournalModalProps,
  ref: any
) {
  const { selectedAssetCategory } = props
  const { isShow, handleOnClose } = useModalState(ref)
  const [value, setValue] = useState<string | number>()
  const { reset, getValues } = useFormContext() as RenderFormProps

  const journalSettingsAssetCategoryListSelector = useSelector(selectorJournalSettingsForAssetCategoryList, isEqual)
  const journalSettingsAssetCategoryList = useMemo(() => {
    return filter(
      journalSettingsAssetCategoryListSelector.data || [],
      item => item.latestSettings && item.id !== selectedAssetCategory.id
    )
  }, [selectedAssetCategory, journalSettingsAssetCategoryListSelector.data])

  const handleValueChange = useCallback(item => {
    setValue(item)
  }, [])

  const handleModalClose = useCallback(
    (submitPayload?: any) => {
      setValue(undefined)
      handleOnClose(submitPayload)
    },
    [handleOnClose]
  )

  const handleOnCopySubmit = useCallback(() => {
    const targetItem = find(journalSettingsAssetCategoryList, item => item.id === value)
    if (targetItem && !isEmpty(targetItem.latestSettings)) {
      const settings = pick(targetItem.latestSettings, AUTO_JOURNAL_SETTING_FORM_FIELDS) || {}
      const { month_id, term_year_period } = getValues()
      const payload = { ...targetItem.latestSettings, month_id, term_year_period }
      const payloadAccountItemDisableable = handleDisableAccoutItem(payload)

      reset(payloadAccountItemDisableable)
      handleModalClose(settings)
    }
  }, [value, journalSettingsAssetCategoryList, reset, getValues, handleModalClose])

  return (
    <Modal
      wrapClassName={ModalWrapperClass}
      visible={isShow}
      closable={false}
      centered
      destroyOnClose={false}
      footer={
        <Actions>
          <Button color="grey" onClick={handleModalClose}>
            {t('actions.cancel')}
          </Button>
          <Button color="blue" disabled={!value} style={{ minWidth: 'unset' }} onClick={handleOnCopySubmit}>
            {t('actions.copy')}
          </Button>
        </Actions>
      }
      title={<h4 className="modal-title">{t('copy_journal_setting_item_title')}</h4>}
      onCancel={handleModalClose}
    >
      <span>{t('copy_journal_setting_item_message')}</span>
      <AutoCompleteSelect
        items={journalSettingsAssetCategoryList}
        name="copy-asset-categories-auto-journal-settings"
        placeholder={t('select_placeholder')}
        style={{ width: 240, marginTop: 10 }}
        valueField="id"
        value={value}
        textField="name"
        onChange={handleValueChange}
      />
    </Modal>
  )
})

export default CopyAssetCategoriesAutoJournalModal
