import { Spin } from 'aa_common/front-end/antd'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { RoleDetailForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import { FORM_MODES } from 'models/form'
import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchRoleDetail } from 'store/settings/role/actions'
import { selectRoleDetail } from 'store/settings/role/selectors'

const t = loadNs(['pages/settings/roles'])

const RoleNewPage = () => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('role_detail')} />
  const { biid } = useParams<any>()
  const dispatch = useDispatch()

  const { data, loading } = useSelector(selectRoleDetail, isEqual)

  useEffect(() => {
    dispatch(fetchRoleDetail(biid))
  }, []) // eslint-disable-line

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <Spin loading={loading}>
        <RoleDetailForm type={FORM_MODES.EDIT} biid={biid} data={data} />
      </Spin>
    </MainTemplate>
  )
}

export default memo(RoleNewPage)
