import * as assetCodeApi from 'api/app/assetCode'
import { AxiosResponse } from 'axios'
import { API_STATUS_CODE } from 'constants/apiCode'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { SET_APP_MESSAGE, setAppMessage, showErrorsAppCall } from 'store/app/actions'
import { parseError, parseValidationError } from 'utils/ResponseHelper'

import {
  CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE,
  CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST,
  CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS,
  DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE,
  DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST,
  DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS,
  FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST,
  FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST_AUTH,
  FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST,
  FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS,
  fetchAutoGenerateSettingsFailure,
  fetchAutoGenerateSettingsSuccess,
  SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_FAILURE,
  SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST,
  SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS,
} from './actions'

const { UNPROCESSABLE_ENTITY } = API_STATUS_CODE
const ERR_DELETE_FIRST_AUTO_GENERATE_SERIAL_NUMBER_SETTING = 'ERR_DELETE_FIRST_AUTO_GENERATE_SERIAL_NUMBER_SETTING'

function* fetchAutogenerateSetting() {
  const { data, errors } = yield call(assetCodeApi.getAutoGenerateSettings)
  if (data) {
    yield put(fetchAutoGenerateSettingsSuccess(data?.data || []))
  } else if (errors) {
    yield put(fetchAutoGenerateSettingsFailure())
    yield put(showErrorsAppCall(errors))
  }
}

function* fetchAutogenerateSettingAuth() {
  const { data, errors } = yield call(assetCodeApi.getAutoGenerateSettingsAuth)
  if (data) {
    yield put(fetchAutoGenerateSettingsSuccess(data?.data || []))
  } else if (errors) {
    yield put(fetchAutoGenerateSettingsFailure())
    yield put(showErrorsAppCall(errors))
  }
}

function* fetchInitialSerialNumberSetting({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(assetCodeApi.getInitialNumberSetting, payload)

    yield put({ type: FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

function* createAutoGenerateSetting({ payload, meta }: any) {
  try {
    const response: AxiosResponse = yield call(assetCodeApi.createAutoGenerateSetting, payload)

    yield put({ type: CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS, payload: response.data.data, meta })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
    yield put({ type: FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST_AUTH })
  } catch (error) {
    const status = get(error, 'response.status')
    const payload =
      status !== UNPROCESSABLE_ENTITY ? null : parseValidationError(get(error, 'response.data.errors', undefined))
    yield put({ type: CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE, payload, meta, error: true })
    if (status !== UNPROCESSABLE_ENTITY) {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

function* deleteAutogenerateSetting({ payload }: any) {
  try {
    yield call(assetCodeApi.deleteAutoGenerateSetting, payload)
    yield put({ type: DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
    yield put({ type: FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST_AUTH })
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400 && get(data, 'errors[0].code') === ERR_DELETE_FIRST_AUTO_GENERATE_SERIAL_NUMBER_SETTING) {
        yield put({
          type: SET_APP_MESSAGE,
          payload: {
            type: 'failure',
            content: get(data, 'errors[0].detail'),
          },
        })
      } else {
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
    yield put({ type: DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE })
  }
}

function* saveInitialSerialNumberSetting({ payload }: any) {
  try {
    const { id, data } = payload
    const response: AxiosResponse = yield call(assetCodeApi.saveInitialNumberSetting, id, data)

    yield put({ type: SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS, payload: response.data.data })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    const status = get(error, 'response.status')
    if (status === UNPROCESSABLE_ENTITY) {
      const payload = parseValidationError(get(error, 'response.data.errors', undefined))
      yield put({ type: SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_FAILURE, payload })
    } else {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST, fetchAutogenerateSetting)
  yield takeLatest(FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST_AUTH, fetchAutogenerateSettingAuth)
  yield takeLatest(CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST, createAutoGenerateSetting)
  yield takeLatest(DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST, deleteAutogenerateSetting)
  yield takeLatest(FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST, fetchInitialSerialNumberSetting)
  yield takeLatest(SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST, saveInitialSerialNumberSetting)
}
