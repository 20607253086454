import 'url-search-params-polyfill'

import { MFID_NONCE_KEY, MFID_REDIRECT_URI_KEY, MFID_STATE_KEY } from 'constants/auth'
import { createHmac } from 'crypto'
import { v4 as uuid } from 'uuid'

const SCOPE = 'openid+email'
const PROMPT = 'select_account'
const RESPONSE_TYPE = 'code'

const {
  REACT_APP_MFID_URL,
  REACT_APP_MFID_CLIENT_ID,
  REACT_APP_MFID_PROXY_SECRET_KEY,
  REACT_APP_MFID_PROXY_CALLBACK_URL,
} = process.env

export const buildMFIDAuthURL = (loginHint?: string | null) => {
  const state = uuid()
  sessionStorage.setItem(MFID_STATE_KEY, state)

  const nonce = uuid()
  sessionStorage.setItem(MFID_NONCE_KEY, nonce)

  // set redirectUri to sends to BE, we need to dinamically set redirectUri for Heroku review app
  // On staging or production, redirectUri parameters will be ignored
  const { origin } = window.location
  const redirectURL = buildRedirectURL(origin)
  sessionStorage.setItem(MFID_REDIRECT_URI_KEY, redirectURL)

  const queryParams = new URLSearchParams({
    state,
    nonce,
    scope: SCOPE,
    client_id: REACT_APP_MFID_CLIENT_ID || 'tmp',
    redirect_uri: encodeURIComponent(redirectURL),
    response_type: RESPONSE_TYPE,
  })

  if (loginHint) {
    queryParams.set('login_hint', loginHint)
  } else {
    queryParams.set('prompt', PROMPT)
  }

  return [`${REACT_APP_MFID_URL}/oauth/authorize`, decodeURIComponent(queryParams.toString())].join('?')
}

const buildRedirectURL = (origin: string) => {
  if (origin.match(/http.+-pr-\d+\.herokuapp.com$/) === null || REACT_APP_MFID_PROXY_SECRET_KEY === undefined) {
    return `${origin}/mfid/callback`
  }

  const callbackUrl = `${origin}/mfid/callback`
  const signature = mfidHerokuProxySignature(callbackUrl, REACT_APP_MFID_PROXY_SECRET_KEY)
  const params = new URLSearchParams({
    url: callbackUrl,
    signature,
  })

  return [REACT_APP_MFID_PROXY_CALLBACK_URL, decodeURIComponent(params.toString())].join('?')
}

const mfidHerokuProxySignature = (data: string, secret: string) => {
  return createHmac('sha256', secret).update(data).digest('hex')
}
