import { formatDate, yen } from 'aa_common/front-end/helpers/format-helper'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Col, Row } from 'antd'
import { EXCISE_TAX_METHOD } from 'constants/masterData'
import { get, isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { TableWrapperNew } from './styles'

type Props = {
  data: any
}
const t = loadNs(['components/organisms/assets/asset-detail', 'common'])

const BasicInfo: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch()
  const { data: exciseSetting } = useSelector(getExciseSettingsByDateList, isEqual)

  const acquiredAt = get(data, 'acquired_at')
  const transitionAt = get(data, 'transition_at')
  const manualExciseValue = get(data, 'manual_excise_value')
  const isTaxMethodExcluded = exciseSetting?.tax_method === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const isTaxInputFormat = !!exciseSetting?.tax_input_format

  useEffect(() => {
    const exciseSettingDate = transitionAt || acquiredAt

    exciseSettingDate && dispatch(fetchExciseSettingsByDate(exciseSettingDate))
  }, [acquiredAt, transitionAt]) // eslint-disable-line

  return (
    <TableWrapperNew style={{ marginLeft: 15 }}>
      <Row className="row-item" wrap={false}>
        <Col flex="160px" className="header">
          {t('name')}
        </Col>
        <Col flex="auto" className="content">
          {get(data, 'name')}
        </Col>
      </Row>
      <Row className="row-item" wrap={false}>
        <Col flex="160px" className="header">
          {t('name_kana')}
        </Col>
        <Col flex="auto" className="content">
          {get(data, 'name_kana')}
        </Col>
      </Row>
      <Row className="row-item" wrap={false}>
        <Col flex="160px" className="header">
          {t('asset_code')}
        </Col>
        <Col flex="auto" className="content">
          {get(data, 'code')}-{get(data, 'branch_code')}
        </Col>
      </Row>
      <Row className="row-item" wrap={false}>
        <Col flex="160px" className="header">
          {t('acquired_date')}
        </Col>
        <Col flex="auto" className="content">
          {formatDate(get(data, 'acquired_at'))}
        </Col>
      </Row>
      <Row className="row-item" wrap={false}>
        <Col flex="160px" className="header">
          {t('used_at')}
        </Col>
        <Col flex="auto" className="content">
          {formatDate(get(data, 'usage_started_at'))}
        </Col>
      </Row>
      <Row className="row-item" wrap={false} justify="center">
        <Col className="column-item" span={isTaxMethodExcluded && isTaxInputFormat ? 12 : 24}>
          <Row className="row-item" wrap={false}>
            <Col flex="160px" className="header">
              {isTaxMethodExcluded ? t('acquisition_cost_excluded_excise') : t('acquisition_cost_included_excise')}
            </Col>
            <Col flex="auto" className="content">
              {isTaxMethodExcluded
                ? yen(get(data, 'acquisition_cost_excluded_excise'))
                : yen(get(data, 'acquisition_cost'))}
            </Col>
          </Row>
        </Col>
        {isTaxMethodExcluded && isTaxInputFormat && (
          <Col className="column-item" span={12}>
            <Row className="row-item" wrap={false}>
              <Col flex="160px" className="header">
                {t('excise_amount')}
              </Col>
              <Col flex="auto" className="content">
                {!!manualExciseValue || manualExciseValue === 0
                  ? yen(get(data, 'manual_excise_value'))
                  : yen(get(data, 'calculated_excise_value'))}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </TableWrapperNew>
  )
}

export default BasicInfo
