import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { LEDGER_PURPOSE } from 'constants/masterData'
import { IMasterData } from 'models/masterData'
import React, { useEffect } from 'react'
import { Control, useWatch } from 'react-hook-form'

import { DEPRECIATION_METHOD } from '../model'

interface Props {
  index: number
  error: string
  defaultValue: number | string
  control: Control<{
    settings: any[]
    submitPayload: any[]
  }>
  masterData: IMasterData
}
const DepreciationMethod = (props: Props) => {
  const { index, defaultValue, error, control, masterData } = props
  const fieldName = `settings[${index}].bulk_equal_installment_depreciation_method`
  const ledgerPurpose = useWatch({
    name: `settings[${index}].ledger_purpose`,
    control,
  }) as LEDGER_PURPOSE

  const isTaxPurpose = [LEDGER_PURPOSE.TAX, LEDGER_PURPOSE.TAX_AND_ACCOUNTING].includes(ledgerPurpose)

  useEffect(() => {
    if (isTaxPurpose) {
      control.setValue(fieldName, DEPRECIATION_METHOD.CONTINUE_DEPRECIATION)
    }
  }, [control, fieldName, isTaxPurpose])

  return (
    <AutoCompleteSelect
      name={fieldName}
      items={masterData.bulk_equal_installment_depreciation_method}
      defaultValue={defaultValue}
      textField="name_jp"
      valueField="code"
      control={control}
      isShowError
      disabled={isTaxPurpose}
      error={error}
      allowClear={false}
    />
  )
}

export default DepreciationMethod
