import { BasicTable } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { DATE_FORMAT } from 'constants/app'
import { TermMonth } from 'models/term'
import moment from 'moment'
import React from 'react'

const t = loadNs(['components/organisms/settings/accounting-period-form', 'common'])

type Props = {
  data: TermMonth[]
  style?: React.CSSProperties
}

const columns = [
  { field: 'month', text: t('month'), sortable: false },
  { field: 'start_date', text: t('start_date'), sortable: false },
  { field: 'end_date', text: t('end_date'), sortable: false },
]

const parseData = (data: TermMonth[]) => {
  if (!data) return []

  return data.map(item => {
    const quarterText = t('lack_of_month_setting_text', { quarter: item.quarter })
    const halfTermText = item.half_term === 1 ? t('first_half') : t('second_half')
    const monthText = `${item.month}${t('units.month')} (${quarterText} ${halfTermText})`

    return {
      month: monthText,
      start_date: moment(item.start_date).format(DATE_FORMAT),
      end_date: moment(item.end_date).format(DATE_FORMAT),
    }
  })
}

const TermList: React.FC<Props> = ({ data, style }) => {
  return (
    <BasicTable
      style={style}
      loading={false}
      columns={columns}
      columnsWidth={[200, 200, 200, 'auto']}
      data={parseData(data)}
      emptyMessage={t('empty_data_msg')}
    />
  )
}

export default TermList
