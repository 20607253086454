import { objectToQueryParams } from 'aa_common/front-end/helpers/request-helper'
import { Typography } from 'antd'
import { ErrorTemplate } from 'components/templates'
import { URL_ZENDECK, ZENDECK_SERVICE_NAME, ZENDECK_TICKET_FORM_ID } from 'constants/app'
import i18n from 'i18n'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Translation } from 'react-i18next'

import { SESSION_INFO } from '../../../constants/auth'
import { WrapperClass } from './styles'

const { Link } = Typography

const headerContent = { header: '500', title: 'Internal Server Error' }

const InternalServerErrorPage = () => {
  const sessionUserInfo = JSON.parse(sessionStorage.getItem(SESSION_INFO) || '{}')

  useEffect(() => {
    document.title = i18n.t('common.page_title')
  }, []) // eslint-disable-line

  const contactToMFCloudCenter = useMemo(() => {
    const zendeckQueryParams = {
      identification_code: sessionUserInfo?.office_identification_code,
      mail: sessionUserInfo?.email,
      office_type_id: sessionUserInfo?.office_id,
      service_name: ZENDECK_SERVICE_NAME,
      ticket_form_id: ZENDECK_TICKET_FORM_ID,
      user_identification_code: sessionUserInfo?.identification_code,
    }

    return (
      <Translation i18n={i18n}>
        {t => {
          return (
            <div>
              <span>{t('pages.errorPages.problem_exists.text_1')}</span>
              <br />
              <Link
                disabled={isEmpty(sessionUserInfo)}
                href={`${URL_ZENDECK}?${objectToQueryParams(zendeckQueryParams)}`}
              >
                {t('pages.errorPages.problem_exists.link')}
              </Link>
              <span>{t('pages.errorPages.problem_exists.text_2')}</span>
            </div>
          )
        }}
      </Translation>
    )
  }, [sessionUserInfo])

  return (
    <ErrorTemplate contentClassName={WrapperClass} headerContent={headerContent}>
      <div className="text">
        <span>{i18n.t('pages.errorPages.internal_server_error.text_1')}</span>
        <br />
        <span>{i18n.t('pages.errorPages.internal_server_error.text_2')}</span>
        <br />
        <span>{i18n.t('pages.errorPages.internal_server_error.text_3')}</span>
        <br />
      </div>
      <div className="link">{contactToMFCloudCenter}</div>
    </ErrorTemplate>
  )
}

export default InternalServerErrorPage
