import { LinkLabel } from 'aa_common/front-end/components'
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg'
import { BreadcrumbNavigation } from 'components/molecules'
import UnderOverDepreciationReport from 'components/organisms/accounting/UnderOverDepreciationReport'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import NotFoundErrorPage from 'pages/errors/NotFoundErrorPage'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { changeLocation } from 'store/app/actions'
import { selectHasAccountantAndTaxLedger } from 'store/settings/ledgerSetting/selectors'

const UnderOverDepreciationReportPage = () => {
  const hasAccountantAndTaxLedger = useSelector(selectHasAccountantAndTaxLedger, isEqual)

  const dispatch = useDispatch()
  const location = useLocation()
  const { state }: any = location

  const handleCancel = () => {
    dispatch(changeLocation(state?.fromPath || '/accounting/monthly-closing'))
  }

  const cancelLink = (
    <LinkLabel onClick={handleCancel} style={{ paddingRight: '15px' }}>
      <CrossIcon />
    </LinkLabel>
  )

  const isRedirectFromMonthlyClosing = state?.fromPath.includes('/accounting/monthly-closing')

  const breadcrumb = (
    <BreadcrumbNavigation
      sectionName={i18n.t('pages.accounting.UnderOverDepreciationPage.page_title')}
      customBackElement={isRedirectFromMonthlyClosing ? cancelLink : undefined}
    />
  )

  return hasAccountantAndTaxLedger === null || hasAccountantAndTaxLedger ? (
    <MainTemplate breadcrumb={breadcrumb}>
      <UnderOverDepreciationReport />
    </MainTemplate>
  ) : (
    <NotFoundErrorPage />
  )
}
export default UnderOverDepreciationReportPage
