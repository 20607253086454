import { formatDate, yen } from 'aa_common/front-end/helpers/format-helper'
import { EXCISE_TAX_METHOD } from 'constants/masterData'
import i18n from 'i18n'
import { get, isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { TableWrapper } from './styles'

type Props = {
  data: any
}

const BasicInfo: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch()
  const { data: exciseSetting } = useSelector(getExciseSettingsByDateList, isEqual)

  const acquiredAt = get(data, 'acquired_at')
  const transAt = get(data, 'transition_at')
  const manualExciseValue = get(data, 'manual_excise_value')
  const isTaxMethodExcluded = exciseSetting?.tax_method === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const isTaxInputFormat = !!exciseSetting?.tax_input_format

  useEffect(() => {
    const exciseDate = transAt || acquiredAt
    exciseDate && dispatch(fetchExciseSettingsByDate(exciseDate))
  }, [acquiredAt, transAt]) // eslint-disable-line

  return (
    <TableWrapper style={{ marginLeft: 15 }}>
      <tbody>
        <tr>
          <th>{i18n.t('components.AssetDetail.name')}</th>
          <td>{get(data, 'name')}</td>
        </tr>
        <tr>
          <th>{i18n.t('components.AssetDetail.name_kana')}</th>
          <td>{get(data, 'name_kana')}</td>
        </tr>
        <tr>
          <th>{i18n.t('components.AssetDetail.asset_code')}</th>
          <td>
            {get(data, 'code')}-{get(data, 'branch_code')}
          </td>
        </tr>
        <tr>
          <th>{i18n.t('components.AssetDetail.acquired_date')}</th>
          <td>{formatDate(get(data, 'acquired_at'))}</td>
        </tr>
        <tr>
          <th>{i18n.t('components.AssetDetail.used_at')}</th>
          <td>{formatDate(get(data, 'usage_started_at'))}</td>
        </tr>
        <tr>
          <th>
            {isTaxMethodExcluded
              ? i18n.t('components.AssetDetail.acquisition_cost_excluded_excise')
              : i18n.t('components.AssetDetail.acquisition_cost_included_excise')}
          </th>
          <td>
            <span className="acquisition-cost">
              {isTaxMethodExcluded
                ? yen(get(data, 'acquisition_cost_excluded_excise'))
                : yen(get(data, 'acquisition_cost'))}
            </span>
            {isTaxMethodExcluded && isTaxInputFormat && (
              <>
                <span className="excise-amount-label">{i18n.t('components.AssetDetail.excise_amount')}</span>
                <span>
                  {!!manualExciseValue || manualExciseValue === 0
                    ? yen(get(data, 'manual_excise_value'))
                    : yen(get(data, 'calculated_excise_value'))}
                </span>
              </>
            )}
          </td>
        </tr>
      </tbody>
    </TableWrapper>
  )
}

export default BasicInfo
