export default {
  assets: require('./assets'),
  user: {
    TermOfUse: require('./user/TermOfUse'),
    LoginPage: require('./user/LoginPage'),
    CreateAccountingPeriod: require('./user/CreateAccountingPeriod'),
  },
  office: {
    New: require('./office/New.json'),
  },
  internalControl: {
    operationLog: require('./internalControl/operationLog'),
  },
  settings: {
    Office: require('./settings/office'),
    Areas: require('./settings/areas'),
    Roles: require('./settings/roles'),
    Users: require('./settings/users'),
    AssetCategories: require('./settings/assetCategories'),
    Causes: require('./settings/causes'),
    Excise: require('./settings/excise'),
    SubmitDestinations: require('./settings/submitDestinations'),
    Tags: require('./settings/tags'),
    Units: require('./settings/units'),
    Departments: require('./settings/departments'),
    AssetCode: require('./settings/assetCode'),
    ExciseSetting: require('./settings/exciseSetting'),
    ReCalculate: require('./settings/recalculate'),
    Import: require('./settings/import'),
  },
  accounting: {
    AutoJournalSettings: require('./accounting/AutoJournalSettingPage'),
    IncreaseDecreaseReport: require('./accounting/IncreaseDecreaseReport'),
    JournalList: require('./accounting/JournalList'),
    HistoryList: require('./accounting/HistoryList'),
    DepreciationFixedAssetPage: require('./accounting/DepreciationFixedAssetPage'),
    UnderOverDepreciationPage: require('./accounting/UnderOverDepreciatioinPage.json'),
  },
  errorPages: require('./errorPages'),
}
