/* eslint-disable react/display-name */
import { loadNs } from 'aa_common/front-end/i18n-config'
import { ColumnsType } from 'antd/es/table'

const t = loadNs(['components/organisms/settings/excise-list'])

export const rateText = (exciseRate: any) => {
  return ` ${exciseRate.reduced_flg !== 0 ? '(軽)' : ''}${exciseRate.rate * 100}%`
}

export const simpleName = (simpleExcise: any) => {
  return ` ${simpleExcise.name ? simpleExcise.name.replace('第', '') : ''}`
}

export const ExciseListTableSettings = (): ColumnsType<any> => {
  return [
    {
      title: t('excise_code'),
      key: 'code',
      width: 150,
      sorter: false,
      dataIndex: 'code',
    },
    {
      title: t('long_name'),
      key: 'long_name',
      sorter: false,
      dataIndex: 'long_name',
    },
    {
      title: t('short_name'),
      key: 'short_name',
      sorter: false,
      dataIndex: 'short_name',
    },
    {
      title: t('search_key'),
      key: 'search_key',
      sorter: false,
      width: 150,
      dataIndex: 'search_key',
    },
  ]
}
